import { ButtonByTeam } from 'components/button/ButtonByTeam';
import { Checkbox, Modal, Row, Spacing, Typography, neutral } from 'thesis-ui';

import { DevicePublicLabel } from '../styles/device-public';

import { ReactComponent as DeviceLabelIcon } from 'assets/icons/device-label.svg';

import { MODAL_KEY } from 'constants/modal-key';

import { type DeviceRecord, useAddDeviceChartPublicModalController } from '../core';

export const AddDeviceChartPublicModal: React.FC<{
  chartLabels: string[];
  callbackAddChart: (newDeviceRecords: DeviceRecord[]) => void;
}> = ({ chartLabels, callbackAddChart }) => {
  const {
    disabled,
    selectedLabel,
    isEmptyListLabel,
    listLabelAdd,
    onSubmitAddChart,
    onChangeSelectedDeviceLabel,
  } = useAddDeviceChartPublicModalController(chartLabels, callbackAddChart);

  const renderBody = () => {
    if (isEmptyListLabel) {
      return (
        <Row
          align="center"
          justify="center"
          style={{
            height: 140,
          }}
        >
          <Typography
            color={neutral['neutral-7']}
            style={{
              height: 40,
            }}
          >
            There’s no data label to select.
          </Typography>
        </Row>
      );
    }
    return (
      <Row direction="column">
        <Typography>Select the data label that you want to show their data.</Typography>

        {listLabelAdd.map((el) => {
          const checked = selectedLabel[el.label] === el.label;

          return (
            <Spacing key={el.label} spacing="xs" type="margin" placement="top">
              <Checkbox
                checked={checked}
                style={{ padding: '2px 2px 2px 0px', alignItems: 'start' }}
                onClick={onChangeSelectedDeviceLabel(el.label)}
              >
                <DevicePublicLabel align="flex-start">
                  <DeviceLabelIcon />
                  <Typography
                    style={{
                      textAlign: 'start',
                      marginLeft: 4,
                    }}
                  >
                    {el.label || 'No label'}
                  </Typography>
                </DevicePublicLabel>
              </Checkbox>
            </Spacing>
          );
        })}
      </Row>
    );
  };

  const renderFooter = () => {
    if (isEmptyListLabel) {
      return <Row />;
    }
    return <ButtonByTeam title="Add chart" disabled={disabled} onClick={onSubmitAddChart} />;
  };

  const bodyStyle: React.CSSProperties = {
    overflow: 'auto',
    maxHeight: 'calc(100vh - 286px)',
  };

  const footerStyle: React.CSSProperties = {
    padding: isEmptyListLabel ? 0 : 16,
  };

  return (
    <Modal
      header="Add chart"
      modalKey={MODAL_KEY.ADD_DEVICE_CHART_PUBLIC}
      size="md"
      footerStyle={footerStyle}
      bodyStyle={bodyStyle}
      footer={renderFooter()}
    >
      <Row direction="column">{renderBody()}</Row>
    </Modal>
  );
};
