import {
  Avatar,
  type DropdownMenuItem,
  Row,
  Select,
  Spacing,
  Typography,
  darkNeutralTrans,
  getFirstLetterInName,
  neutral,
} from 'thesis-ui';

import { getUserColor } from 'modules/generals/core/helper';

import { Dot, TitleLink } from '../styles/team-user';

import { TeamRole, type TeamUserCardProps, useTeamUserShareController } from '../core';
import { ListTeamRole } from '../core/constant';
import { roleOwnerOptions } from '../core/role-select';

export const TeamUserCard: React.FC<TeamUserCardProps> = ({ user, roleOptions }) => {
  const {
    changeRole,
    team,
    avatarBg,
    avatarBorder,
    isEdit,
    isUserActive,
    isAdmin,
    isLoggedAccount,
    isTeamOwner,
    onResend,
    onChangeRoleUser,
  } = useTeamUserShareController(user);

  const options = roleOptions.concat(roleOwnerOptions);
  const getOptions = () => {
    if (team?.role === TeamRole.TeamOwner || isAdmin) {
      return isUserActive ? options : options.filter((el) => el.key !== String(TeamRole.TeamOwner));
    }
    return [options[0], options[1], roleOwnerOptions[1]];
  };

  // need to refactor it, duplicate several times
  let avatarConfig = {
    // active user
    bg: avatarBg,
    color: neutral['neutral-1'],
    border: avatarBorder,
  };
  //
  if (isUserActive) {
    avatarConfig = {
      bg: getUserColor(user.user_fullname),
      border: `1px solid ${darkNeutralTrans['darkNeutralTrans-20']}`,
      color: neutral['neutral-1'],
    };
  }

  return (
    <Row
      align="center"
      justify="space-between"
      style={{
        height: 40,
      }}
    >
      <Row align="center">
        <Avatar
          items={{
            label: getFirstLetterInName(user.user_fullname, true),
          }}
          size="small"
          config={avatarConfig}
          containerStyle={{
            width: 'fit-content',
          }}
        />
        <Spacing spacing="xs" type="margin" placement="left">
          <Typography
            color={isUserActive ? neutral['neutral-11'] : neutral['neutral-7']}
            style={{
              whiteSpace: 'nowrap',
            }}
          >
            {isUserActive ? user.user_fullname : user.user_email}
          </Typography>
        </Spacing>

        {!isLoggedAccount && isEdit && !isTeamOwner && (
          <Row align="center">
            <Dot /> <TitleLink onClick={onResend(user.user_email, user.role)}>Resend</TitleLink>
          </Row>
        )}
      </Row>

      <Row justify="end" align="center">
        {isLoggedAccount || !isEdit || user.role === TeamRole.TeamOwner ? (
          <Typography>{ListTeamRole[user.role]}</Typography>
        ) : (
          <Select
            containerStyle={{
              width: 'fit-content',
            }}
            inputStyle={{
              border: 'none',
              backgroundColor: 'transparent',
              boxShadow: 'none',
              width: 'auto',
              paddingRight: 0,
              height: 40,
            }}
            dropdownConfig={{
              theme: 'dark',
              dropdownConfig: {
                offset: [5, 0],
                visible: changeRole.value,
                onClickOutside: () => changeRole.setValue(false),
              },
              dropdownMenuStyle: {
                width: 316,
              },
            }}
            options={getOptions()}
            onChange={(newItem: DropdownMenuItem) => onChangeRoleUser(newItem, user.id)}
            selectedKeys={String(user.role)}
            activeValue={ListTeamRole[user.role]}
            onClickSelect={() => changeRole.setValue((prev) => !prev)}
          />
        )}
      </Row>
    </Row>
  );
};
