import type { TabItemType } from 'thesis-ui/components/Navigation/Tabs/core/types';

import { PATH } from 'constants/path';

export const getTeamsTabs = (): TabItemType[] => {
  return [
    {
      label: 'My teams',
      key: `${PATH.TEAMS}/${PATH.MY_TEAMS}`,
    },
    {
      label: 'Shared with me',
      key: `${PATH.TEAMS}/${PATH.SHARED_WITH_ME_TEAMS}`,
    },
    {
      label: 'All teams',
      key: `${PATH.TEAMS}/${PATH.ALL_TEAMS}`,
    },
  ];
};
