import styled, { css } from 'styled-components';

import getContrastRatio from 'get-contrast-ratio';
import { RadiusConfig, blue, darkNeutralTrans, neutral, neutralTrans } from 'thesis-ui';

export const ButtonOverlay = styled.div<{
  disabled: boolean;
  color?: string;
}>`
  ${({ disabled, color = blue['blue-5'] }) => {
    //Contrast ratio is determine relative to black, the higher the ratio, the lighter the color is
    const ratio = getContrastRatio(color, neutral['neutral-11']);

    const isColorLight = ratio >= 11;
    const bgByRatio = isColorLight
      ? neutralTrans['neutralTrans-25']
      : darkNeutralTrans['darkNeutralTrans-20'];
    const bg = disabled ? 'transparent' : bgByRatio;

    return css`
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      border-radius: ${RadiusConfig.xxs}px;
      &:hover {
        background-color: ${bg};

        border: 1px solid ${isColorLight ? 'transparent' : bg};
      }
    `;
  }}
`;
export const ButtonOverlayIcon = styled.div<{
  disabled: boolean;
  color?: string;
}>`
  ${({ disabled, color = blue['blue-5'] }) => {
    const ratio = getContrastRatio(color, neutral['neutral-11']);
    const colorByRatio = ratio >= 5 ? neutral['neutral-11'] : neutral['neutral-1'];
    const newColor = disabled ? neutralTrans['neutralTrans-25'] : colorByRatio;

    return css`
      svg path[stroke] {
        stroke: ${newColor};
      }
      svg path[fill] {
        fill: ${newColor};
      }
      &:hover {
        svg path[stroke] {
          stroke: ${newColor};
        }
        svg path[fill] {
          fill: ${newColor};
        }
      }
    `;
  }}
`;
