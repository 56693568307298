import { type DropdownMenuItem } from 'thesis-ui';

import { DropDownLabel } from 'modules/team/components';

import { ReactComponent as DashboardIcon } from 'assets/icons/dashboard.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';

import { TeamsTableActionKey } from './enum';

export const getTeamsTableAction = (
  hasEdit: boolean,
  hasDelete: boolean,
  showDashboardItem?: boolean,
  enableDashboard?: boolean,
): DropdownMenuItem[] => {
  const data = [
    {
      key: TeamsTableActionKey.Share,
      label: <DropDownLabel title="Share" icon={<ShareIcon />} />,
    },
  ];

  if (showDashboardItem) {
    data.push({
      key: TeamsTableActionKey.dashboard,
      label: (
        <DropDownLabel
          title={enableDashboard ? 'Disable device dashboard' : 'Enable device dashboard'}
          icon={<DashboardIcon />}
        />
      ),
    });
  }

  if (hasDelete || hasEdit) {
    data.push({
      key: TeamsTableActionKey.Edit,
      label: <DropDownLabel title="Edit team" icon={<EditIcon />} />,
    });
  }

  if (hasDelete) {
    data.push({
      key: TeamsTableActionKey.Delete,
      label: <DropDownLabel title="Delete team" icon={<DeleteIcon />} />,
    });
  }
  return data;
};
