import { InputTitle } from 'components';
import { ButtonByTeam } from 'components/button/ButtonByTeam';
import {
  Modal,
  Radio,
  Row,
  Select,
  Spacing,
  TextInput,
  Typography,
  blockInvalidCharacters,
  red,
} from 'thesis-ui';

import { HideIconNumber } from '../styles/chart-setting-modal';

import { MODAL_KEY } from 'constants/modal-key';

import {
  type ChartSettingModalProps,
  ListYAxisSetting,
  deviceChartRefreshActions,
  useChartSettingModalController,
} from '../core';
import { DeviceChartYAxisSettingType } from '../core/enum';

export const ChartSettingModal: React.FC<ChartSettingModalProps> = ({
  chart,
  hasPermission,
  originChartKey,
  onSubmitSettingChart,
}) => {
  const {
    openSelectRefresh,
    name,
    refresh,
    refreshType,
    yAxisType,
    yAxisMin,
    yAxisMax,
    errorYAxis,
    disabled,
    handleChange,
    onChangeRefreshType,
    onChangeYAxis,
    handleSubmit,
  } = useChartSettingModalController({
    originChartKey,
    chart,
    hasPermission,
    onSubmitSettingChart,
  });

  return (
    <Modal
      header="Chart settings"
      modalKey={`${MODAL_KEY.SETTING_DEVICE_CHART}-${chart.id}`}
      size="md"
      footer={<ButtonByTeam title="Save" disabled={disabled} onClick={() => handleSubmit()} />}
    >
      <Row direction="column">
        {hasPermission && (
          <Spacing
            spacing="sm"
            placement="bottom"
            type="margin"
            style={{
              width: '100%',
            }}
          >
            <TextInput
              title="Chart name"
              type="text"
              name="name"
              value={name}
              onChange={handleChange}
            />
          </Spacing>
        )}

        <Spacing
          spacing="sm"
          placement="bottom"
          type="margin"
          style={{
            width: '100%',
          }}
        >
          <Row align="flex-end">
            <HideIconNumber
              style={{
                width: '100%',
              }}
            >
              <TextInput
                title="Refresh interval"
                type="number"
                name="refresh"
                value={refresh}
                onChange={handleChange}
                onKeyDown={(e) => blockInvalidCharacters(e, 'integer')}
              />
            </HideIconNumber>

            <Spacing spacing="sm" type="margin" placement="left">
              <Select
                inputStyle={{
                  width: 132,
                }}
                title=" "
                dropdownConfig={{
                  theme: 'dark',
                  dropdownConfig: {
                    offset: [0, 0],
                    visible: openSelectRefresh.value,
                    onClickOutside: () => openSelectRefresh.setValue(false),
                  },
                }}
                options={deviceChartRefreshActions}
                onChange={onChangeRefreshType}
                selectedKeys={String(refreshType)}
                onClickSelect={() => openSelectRefresh.setValue((prev) => !prev)}
              />
            </Spacing>
          </Row>
        </Spacing>
        <Row direction="column">
          <Typography weight={600}>Y-axis display setting</Typography>
          <Row
            align="center"
            style={{
              marginTop: 6,
            }}
          >
            {ListYAxisSetting.map((el, index) => {
              const spacing = index === 0 ? 'none' : 'xs';
              return (
                <Spacing key={el.key} spacing={spacing} placement="left" type="margin">
                  <Radio checked={yAxisType === el.key} onClick={onChangeYAxis(el.key)}>
                    {el.label}
                  </Radio>
                </Spacing>
              );
            })}
          </Row>
        </Row>
        {yAxisType === DeviceChartYAxisSettingType.Manually && (
          <Spacing
            spacing="sm"
            placement="top"
            type="margin"
            style={{
              width: '100%',
            }}
          >
            <Row direction="column">
              <Row align="center" justify="space-between">
                <Row
                  direction="column"
                  style={{
                    width: 173,
                  }}
                >
                  <InputTitle title="Y-axis min" required />
                  <HideIconNumber>
                    <TextInput
                      name="yAxisMin"
                      value={yAxisMin}
                      type="number"
                      onChange={handleChange}
                      onKeyDown={(e) => blockInvalidCharacters(e, 'integer')}
                      color={errorYAxis ? 'error' : 'default'}
                    />
                  </HideIconNumber>
                </Row>
                <Typography
                  style={{
                    marginTop: 25,
                  }}
                >
                  -
                </Typography>
                <Row
                  direction="column"
                  style={{
                    width: 173,
                  }}
                >
                  <InputTitle title="Y-axis max" required />
                  <HideIconNumber>
                    <TextInput
                      name="yAxisMax"
                      value={yAxisMax}
                      onChange={handleChange}
                      color={errorYAxis ? 'error' : 'default'}
                      onKeyDown={(e) => blockInvalidCharacters(e, 'integer')}
                      type="number"
                    />
                  </HideIconNumber>
                </Row>
              </Row>
              {errorYAxis && (
                <Typography
                  style={{
                    marginTop: 6,
                  }}
                  color={red['red-6']}
                >
                  Y-axis min must be smaller than Y-axis max
                </Typography>
              )}
            </Row>
          </Spacing>
        )}
      </Row>
    </Modal>
  );
};
