import { useEffect } from 'react';

import { useFormik } from 'formik';
import { showToastMessage } from 'thesis-ui';

import {
  type UserProfile,
  updateUserProfile,
  useAuthStore,
  userProfileSelector,
} from 'modules/auth/core';

import { hasEmptyValues, trimObject } from 'helpers';
import { apiCall } from 'hooks/service/api';

import { SUCCESS_MESSAGE } from 'constants/message';

import { defaultMyProfileRequest } from '../constant';
import { myProfileSchema } from '../schema';

export const useMyProfileController = () => {
  const userProfile = useAuthStore(userProfileSelector);

  const formik = useFormik({
    initialValues: defaultMyProfileRequest,
    validationSchema: myProfileSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const { companyName, fullName } = values || {};

      const result: UserProfile = await apiCall({
        url: `/api/auth/me`,
        data: trimObject({
          fullname: fullName,
          company_name: companyName,
        }),
        method: 'PATCH',
        isLoading: true,
        showError: true,
      });
      if (result?.id) {
        updateUserProfile({
          ...userProfile,
          ...result,
        });
        showToastMessage('success', SUCCESS_MESSAGE.UPDATE_INFORMATION);
      }
    },
  });

  const { values, isSubmitting, isValid, handleSubmit, handleChange, setValues } = formik;
  const { companyName, fullName } = values;

  useEffect(() => {
    if (!userProfile?.id) {
      return;
    }
    setValues({
      companyName: userProfile.company_name || '',
      fullName: userProfile.fullname || '',
    });
  }, [userProfile?.id]);

  const userCompanyName = userProfile?.company_name || '';

  const disabled =
    hasEmptyValues({ fullName }) ||
    isSubmitting ||
    !isValid ||
    (companyName?.trim() === userCompanyName && fullName.trim() === userProfile.fullname);

  const onCancel = () => {
    setValues({
      companyName: userProfile.company_name,
      fullName: userProfile.fullname,
    });
  };

  return {
    email: userProfile?.email,
    fullName,
    companyName,
    disabled,
    handleSubmit,
    handleChange,
    onCancel,
  };
};
