import { useRef } from 'react';
import { Prism } from 'react-syntax-highlighter';

import { Row, Spacing, Typography, blue, neutral } from 'thesis-ui';

import { BodyRaw, CommandCard, Label } from '../styles/api-key-item';

import { type APIKeyItemProps } from '../core/type';

import { copyToClipboard } from 'helpers';
import { useBoolean } from 'hooks';
import { useOutsideAlerter } from 'hooks/basic';
import { isNumber } from 'lodash';

import { API_URL } from 'constants/environment';

export const APIKeyItem: React.FC<APIKeyItemProps> = ({
  title,
  method,
  url,
  apiKey,
  code,
  label,
  clientWidth,
}) => {
  const visible = useBoolean(false);
  const commandRef = useRef<HTMLDivElement>(null);

  useOutsideAlerter(commandRef, () => {
    visible.setValue(false);
  });

  const onVisible = () => {
    visible.setValue(true);
    copyToClipboard(`${API_URL}/${url}?apiKey=${apiKey}`);
  };

  return (
    <Row direction="column">
      <Typography weight={600}>{title}</Typography>
      <CommandCard ref={commandRef} align="center" onClick={onVisible}>
        <Spacing spacing="xs" type="margin" placement="right">
          <Typography>{method}</Typography>
        </Spacing>
        <Typography
          className="text-ellipsis-1"
          style={{
            whiteSpace: 'nowrap',
            textAlign: 'start',
            width: isNumber(clientWidth) ? clientWidth - 62 : clientWidth,
            overflow: 'hidden',
            backgroundColor: visible.value ? blue['blue-2'] : 'transparent',
          }}
        >
          {`${API_URL}/${url}?apiKey=`}
          <span
            style={{
              color: blue['blue-6'],
            }}
          >
            {apiKey}
          </span>
        </Typography>
      </CommandCard>
      <Row
        align="center"
        style={{
          marginTop: 12,
        }}
      >
        {label}
      </Row>
      <BodyRaw direction="column">
        <Label>
          <Typography variant="body-2" weight={600}>
            json
          </Typography>
        </Label>
        <Prism
          codeTagProps={{
            style: {
              color: 'red !important',
            },
          }}
          lineNumberStyle={{
            color: 'yellow',
          }}
          customStyle={{
            backgroundColor: neutral['neutral-2'],
            padding: 0,
            margin: 0,
            fontFamily: 'Inter',
            fontSize: 14,
          }}
          language="javascript"
        >
          {code}
        </Prism>
      </BodyRaw>
    </Row>
  );
};
