import { FilterLabel } from 'components/filter';
import { Row, Spacing } from 'thesis-ui';

import { useDeviceFilterController } from '../core';
import { DeviceState, DeviceStatus } from '../core/enum';

export const DeviceFilterLabel = () => {
  const { listState, listStatus, deviceTableKey } = useDeviceFilterController();

  return (
    <Row align="center">
      {Boolean(listStatus?.length) && (
        <FilterLabel
          tableKey={deviceTableKey}
          field="status"
          title="Status"
          value={listStatus.map((el: number) => DeviceStatus[el]).join(', ')}
        />
      )}

      <Spacing spacing="xs" type="margin" placement="left">
        {Boolean(listState?.length) && (
          <FilterLabel
            tableKey={deviceTableKey}
            field="state"
            title="Public mode"
            value={listState.map((el: number) => DeviceState[el]).join(', ')}
          />
        )}
      </Spacing>
    </Row>
  );
};
