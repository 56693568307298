import { ButtonByTeam } from 'components/button/ButtonByTeam';
import { Button, Row, Spacing, Typography, neutral } from 'thesis-ui';

import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import { ReactComponent as EmptyState } from 'assets/images/empty-state.svg';

import type { EmptyDataProps } from './core/type';

import { isString } from 'lodash';

import { useController } from './core/controller';

export const EmptyData: React.FC<EmptyDataProps> = ({
  title,
  isFilter,
  permission,
  button,
  image,
  tableKey,
}) => {
  const { onClickAddDevice, onClearFilter } = useController(tableKey);

  const renderBody = () => {
    if (isFilter) {
      return (
        <Button variant="contained" size="large" onClick={onClearFilter}>
          Clear filter
        </Button>
      );
    }
    if (permission) {
      return (
        button ?? (
          <ButtonByTeam
            onClick={onClickAddDevice}
            title="Add device"
            size="large"
            icon={<AddIcon stroke={neutral['neutral-1']} />}
          />
        )
      );
    }
    return null;
  };

  return (
    <Row direction="column" align="center" justify="center" style={{ height: '100%' }}>
      {image ?? <EmptyState />}

      <Spacing spacing="xl" placement={permission ? ['top', 'bottom'] : 'top'} type="margin">
        {isString(title) ? <Typography className="empty-data-title">{title}</Typography> : title}
      </Spacing>
      {renderBody()}
    </Row>
  );
};
