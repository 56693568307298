import styled, { css } from 'styled-components';

import { RadiusConfig, Row, neutral, neutralTrans } from 'thesis-ui';

export const Header = styled(Row)`
  height: 60px;
  min-height: 60px;
  padding: 0px 24px;
  border-bottom: 1px solid ${neutralTrans['neutralTrans-15']};
  background: ${neutral['neutral-1']};
`;

export const Container = styled(Row)`
  padding: 24px;
  flex-grow: 1;
  overflow-y: auto;
  scrollbar-width: thin;
`;

export const Body = styled(Row)`
  border-radius: ${RadiusConfig.xs}px;
  border: 1px solid ${neutralTrans['neutralTrans-15']};
  background: ${neutral['neutral-1']};
`;

export const StyledTab = styled(Row)`
  padding: 0px 12px;
  border-bottom: 1px solid ${neutralTrans['neutralTrans-15']};
`;

export const StyledOutlet = styled(Row)<{ isMyTeams: boolean }>`
  ${({ isMyTeams }) => {
    const padding = isMyTeams ? `16px 24px 24px 24px` : '24px';
    return css`
      padding: ${padding};
    `;
  }}
`;
