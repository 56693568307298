import { ActionLogItem } from 'components/action-log/ActionLogItem';
import { EmptyData } from 'components/data/EmptyData';
import { Row, Spacing, Typography, blue } from 'thesis-ui';

import { Container, TitleLink } from '../styles/dashboard';

import { ReactComponent as EmptyLog } from 'assets/images/empty-log.svg';

import { useDashboardActionLogController } from '../core/controllers';

export const DashboardActionLog = () => {
  const { logs, isEmptyLogs, redirectActionPage, colorTeam } = useDashboardActionLogController();

  const renderContent = () => {
    if (isEmptyLogs) {
      return (
        <Row style={{ height: 360 }}>
          <EmptyData title="No action was logged. " permission={false} image={<EmptyLog />} />
        </Row>
      );
    }
    return (
      <Row direction="column">
        {logs.map((log) => {
          return <ActionLogItem log={log} key={log.id} colorTeam={colorTeam} />;
        })}
      </Row>
    );
  };
  return (
    <Container style={{ minHeight: 438 }}>
      <Spacing spacing="md" style={{ width: '100%', height: '100%' }}>
        <Row direction="column" style={{ height: '100%' }}>
          <Spacing spacing="xl" type="margin" placement="bottom" style={{ width: '100%' }}>
            <Row align="center" justify="space-between">
              <Typography variant="h5" weight={600}>
                Action log
              </Typography>
              {!isEmptyLogs && (
                <TitleLink color={blue['blue-6']} onClick={redirectActionPage}>
                  View all
                </TitleLink>
              )}
            </Row>
          </Spacing>

          <Row style={{ flexGrow: 1 }}>{renderContent()}</Row>
        </Row>
      </Spacing>
    </Container>
  );
};
