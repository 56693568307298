import { Tag } from 'thesis-ui';

import { ReactComponent as PrivateIcon } from 'assets/icons/private.svg';
import { ReactComponent as PublicIcon } from 'assets/icons/public.svg';

import { DeviceState } from '../core/enum';

export const PublicModeTag: React.FC<{
  state: DeviceState;
  iconRight?: React.ReactNode;
  onClick?: () => void;
}> = ({ state, iconRight, onClick }) => {
  const icon =
    state === DeviceState.Public ? (
      <PublicIcon
        style={{
          marginRight: 4,
        }}
      />
    ) : (
      <PrivateIcon
        style={{
          marginRight: 4,
        }}
      />
    );
  const color = state === DeviceState.Public ? 'blue' : 'neutral';

  return (
    <Tag
      onClick={onClick}
      iconLeft={icon}
      color={color}
      iconRight={iconRight}
      style={{
        cursor: iconRight ? 'pointer' : 'default',
      }}
    >
      {DeviceState[state]}
    </Tag>
  );
};
