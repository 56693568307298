import type { Authen, UserProfile } from './type';

import { pick } from 'lodash';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { LOCAL_STORAGE_AUTH_KEY } from 'constants/local-storage';

import { defaultAuthen, defaultUserProfile } from './constant';

interface State {
  userProfile: UserProfile;
  authen: Authen;
}

interface Store {
  state: State;
  updateUserProfile: (payload: UserProfile) => void;
  clearUserProfile: () => void;
  updateAuthen: (payload: Authen) => void;
  clearAuthen: () => void;
  clearAuthState: () => void;
}

const initState: State = {
  userProfile: defaultUserProfile,
  authen: defaultAuthen,
};

export const useAuthStore = create(
  persist(
    immer<Store>((set) => ({
      state: initState,
      updateUserProfile: (payload) => {
        set((store) => {
          store.state.userProfile = payload;
        });
      },
      clearUserProfile: () => {
        set((store) => {
          store.state.userProfile = defaultUserProfile;
        });
      },
      updateAuthen: (payload) => {
        set((store) => {
          store.state.authen = payload;
        });
      },
      clearAuthen: () => {
        set((store) => {
          store.state.authen = defaultAuthen;
        });
      },
      clearAuthState: () => {
        set((store) => {
          store.state = initState;
        });
      },
    })),
    {
      name: LOCAL_STORAGE_AUTH_KEY,
      partialize: (store: Store) => ({
        state: pick(store.state, 'authen'),
      }),
    },
  ),
);

export const { updateUserProfile, updateAuthen, clearUserProfile, clearAuthen, clearAuthState } =
  useAuthStore.getState();

export const userProfileSelector = (s: Store) => s.state.userProfile;

export const authenSelector = (s: Store) => s.state.authen;
