import { ChartDateRange } from 'enums/date-range';
import getContrastRatio from 'get-contrast-ratio';
import moment from 'moment';
import { blue, checkTypeDate, formatDate, neutral, neutralTrans } from 'thesis-ui';

import { useAuthStore, userProfileSelector } from 'modules/auth/core';
import { getAllTimezones } from 'modules/generals/core/helper';

import { isEmpty, isObject } from 'lodash';

import { getRangeOptions } from 'constants/data-range';

import { trimObject } from './compile-data';

export const roundNumber = (num: number, decimal: number) => {
  return Number(num.toFixed(decimal));
};

export function hasEmptyValues<T>(obj: T): boolean {
  if (!isObject(obj)) {
    return true;
  }
  return Object.values(trimObject(obj)).some((el) => !el);
}

export const getBgButtonByTeam = (disabled: boolean, color = blue['blue-5']) => {
  const ratio = getContrastRatio(color, neutral['neutral-11']);
  const newColor = ratio >= 5 ? neutral['neutral-11'] : neutral['neutral-1'];
  return {
    background: disabled ? neutralTrans['neutralTrans-3'] : color,
    color: disabled ? neutralTrans['neutralTrans-25'] : newColor,
    cursor: disabled ? 'initial' : 'pointer',
    border: `1px solid ${disabled ? 'transparent' : neutralTrans['neutralTrans-15']}`,
  };
};
export type FormDate = string | number | Date;

export const getTimezone = () => {
  const userProfile = userProfileSelector(useAuthStore.getState());
  if (userProfile?.timezone) {
    return userProfile?.timezone;
  }
  const allTimezones = getAllTimezones();
  const timezoneOffset = new Date().getTimezoneOffset();
  const currentTimezone = timezoneOffset / 60;
  const existTimezone = allTimezones.find((el) => el.offset === -currentTimezone);
  if (existTimezone) {
    return existTimezone.value;
  }
  return '+00:00';
};

export const formatDateByTimezone = (date: FormDate, typeFormat?: string) => {
  if (!date) {
    return '';
  }
  const timezone = getTimezone();

  return moment(new Date(checkTypeDate(date)))
    .clone()
    .utcOffset(timezone)
    .format(typeFormat ?? 'DD MMM yyyy, HH:mm:ss A');
};

export const formatDateAndTimeCombination = (date: FormDate) => {
  const newDate = formatDateByTimezone(date, 'DD MMM');
  const newTime = formatDateByTimezone(date, 'h:mm A');

  return `${newDate} at ${newTime}`;
};

export const getTableKeyById = (tableKey: string, id?: string) => {
  return `${tableKey}-${id}`;
};

export const copyToClipboard = (text: string) => {
  const textField = document.createElement('textarea');
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy'); //NOSONAR
  textField.remove();
};

const moveAndMutateArray = (array: any[], from: number, to: number) => {
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
};

export const reArrangeRowOrder = (rowOrders: any[], from: number, to: number) => {
  rowOrders = rowOrders.slice();
  moveAndMutateArray(rowOrders, from, to);
  return rowOrders;
};

export const formatUTCDate = (
  date: FormDate,
  typeFormat = 'yyyy-MM-DD HH:mm:ss',
  suffix?: string,
) => {
  const currentDate = date ? new Date(date) : new Date(); // Get the current date
  const timezone = getTimezone();
  const newSuffix = suffix ?? `GMT ${timezone}`;

  return `${formatDateByTimezone(currentDate, typeFormat)} ${newSuffix}`;
};

export const getUTCDate = (
  date: FormDate,
  isUTC = true,
  isLastTime = false,
  milliseconds = '000',
) => {
  const timezone = getTimezone();
  const allTimezones = getAllTimezones();
  const existTimezone = allTimezones.find((el) => el.value === timezone);
  let timezoneOffset = '+00:00';
  if (existTimezone) {
    timezoneOffset = existTimezone.value;
  }
  const currentDate = moment(date);
  if (!isUTC) {
    return currentDate.toDate();
  }
  if (isLastTime) {
    return currentDate.toDate();
  }

  return formatDateMoment(currentDate, timezoneOffset, milliseconds);
};

export const formatDateMoment = (
  currentDate: moment.Moment,
  timezoneOffset: string,
  milliseconds: string,
) => {
  return new Date(
    `${currentDate.format('YYYY-MM-DD')}T${currentDate.format(
      'HH:mm:ss',
    )}.${milliseconds}${timezoneOffset}`,
  );
};

export const convertDateToUTC = (
  date: FormDate,
  isUTC = true,
  isCurrentTime = false,
  isCurrentDate = false,
) => {
  const currentDate = date ? new Date(date) : new Date();
  const currentDateTime = currentDate.getTime();
  const currentTimezoneOffset = new Date().getTimezoneOffset();

  const newCurrentTimezone = currentTimezoneOffset * 60 * 1000;

  const currentTime = isUTC
    ? currentDateTime - newCurrentTimezone
    : currentDateTime + newCurrentTimezone;

  if (isCurrentTime) {
    return currentTime;
  }

  if (isCurrentDate) {
    return new Date(currentTime);
  }

  return new Date(currentTime).toISOString();
};

export const checkDateSelected = (
  currentDate: { start: Date; end: Date },
  newDate: { start: Date; end: Date },
) => {
  const formatType = 'dd-MM-H:mm';

  return (
    formatDate(newDate.start, formatType) === formatDate(currentDate.start, formatType) &&
    formatDate(newDate.end, formatType) === formatDate(currentDate.end, formatType)
  );
};

export const waitWithTimeout = (ms: number) =>
  new Promise<void>((resolve) => setTimeout(() => resolve(), ms));

export const getArraySort = (array: any, key: string, order: 'asc' | 'desc' = 'asc') => {
  if (isEmpty(array)) {
    return [];
  }
  const isAsc = order === 'asc';
  const indexKeyA = isAsc ? -1 : 1;
  const indexKeyB = isAsc ? 1 : -1;

  array.sort(function (a: any, b: any) {
    const keyA = a[key].toUpperCase();
    const keyB = b[key].toUpperCase();

    // Sort by key first
    if (keyA < keyB) return indexKeyA;
    if (keyA > keyB) return isAsc ? 1 : -1;

    // If key are the same, prioritize uppercase key first
    if (a[key] < b[key]) return indexKeyA;
    if (a[key] > b[key]) return indexKeyB;
    return 0;
  });

  return array;
};

export const checkStaticDateRangesSelected = (
  newDate: { start: Date; end: Date },
  field: ChartDateRange,
) => {
  const rangeOptions = getRangeOptions();

  const item = rangeOptions.find((el) => el.field === field);
  if (!item) {
    return false;
  }
  const formatType = 'dd-MM-H:mm';

  const selected =
    formatDate(item.start, formatType) === formatDate(newDate.start, formatType) &&
    formatDate(item.end, formatType) === formatDate(newDate.end, formatType);
  return selected;
};
