import { Outlet } from 'react-router-dom';

import { Container, Content, Menu } from './styles/layout';

import { SidebarMenu } from './components/SidebarMenu';

export const MasterLayout = () => {
  return (
    <Container>
      <Menu>
        <SidebarMenu />
      </Menu>
      <Content>
        <Outlet />
      </Content>
    </Container>
  );
};
