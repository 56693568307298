import HashLoader from 'react-spinners/HashLoader';

import { Row, Typography, neutral, neutralTrans, primary } from 'thesis-ui';
import { ChartLine } from 'thesis-ui-chart';

import { Container, Header, LoadingCard } from './styles/basic';

import { ReactComponent as DeviceLabelIcon } from 'assets/icons/device-label.svg';

import type { FormChartProps } from './core/types';

export const FormChart: React.FC<FormChartProps> = ({
  title,
  label,
  chartName,
  action,
  loading,
  emptyMessage,
  data,
  options,
  isEmptyData,
  isShowChart = true,
  isHeader = true,
}) => {
  const renderBody = () => {
    if (!isShowChart) {
      return null;
    }

    if (isEmptyData) {
      return (
        <Row
          align="center"
          justify="center"
          style={{
            minHeight: 248,
          }}
        >
          <Typography
            color={neutral['neutral-7']}
            style={{
              textAlign: 'center',
              maxWidth: 480,
            }}
          >
            {emptyMessage ?? `No data to show in chart.`}
          </Typography>
        </Row>
      );
    }

    return (
      <Row direction="column" align="center">
        <Typography
          weight={500}
          color={neutral['neutral-11']}
          className="text-ellipsis-1"
          style={{
            marginBottom: 8,
          }}
        >
          {chartName}
        </Typography>

        <ChartLine
          containerStyle={{
            height: 220,
            width: '100%',
          }}
          options={options}
          data={data}
        />
      </Row>
    );
  };
  const bodyStyle: React.CSSProperties = isShowChart
    ? {
        width: '100%',
        padding: 16,
      }
    : {};

  const headerStyle: React.CSSProperties = isShowChart
    ? {
        borderBottom: `1px solid ${neutralTrans['neutralTrans-15']}`,
      }
    : {};

  return (
    <Container className="form-chart" direction="column">
      {isHeader && (
        <Header style={headerStyle} justify="space-between" align="center">
          <Row direction="column">
            <Typography weight={600} className="text-ellipsis-1" style={{ textAlign: 'start' }}>
              {title}
            </Typography>
            <Row
              className="label"
              align="center"
              style={{
                marginTop: 2,
              }}
            >
              <DeviceLabelIcon className="device-label-icon" />
              <Typography
                variant="body-2"
                className="text-ellipsis-1"
                color={neutral['neutral-7']}
                style={{ textAlign: 'start', marginLeft: 4 }}
              >
                {label}
              </Typography>
            </Row>
          </Row>

          {action}
        </Header>
      )}

      <Row align="center" justify="center" style={{ position: 'relative', ...bodyStyle }}>
        {renderBody()}
        {loading && isShowChart && (
          <LoadingCard align="center" justify="center">
            <HashLoader color={primary['primary-6']} loading={true} size={50} />
          </LoadingCard>
        )}
      </Row>
    </Container>
  );
};
