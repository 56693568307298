import { Button, Checkbox, Modal, Row, Typography } from 'thesis-ui';

import { TitleLink } from 'modules/auth/styles/auth-layout';

import { usePageGoTo } from 'hooks';

import { MODAL_KEY } from 'constants/modal-key';
import { PATH } from 'constants/path';

import { useUpdateTermAndConditionController } from '../core';

export const TermAndPolicyUpdateModal: React.FC = () => {
  const { isChecked, agreeTerms } = useUpdateTermAndConditionController();

  return (
    <Modal
      header={
        <Typography variant="h5" weight={600}>
          Agree to updated Terms and Policies
        </Typography>
      }
      modalKey={MODAL_KEY.TERMS_AND_CONDITION_UPDATE}
      isShowBtnClose={false}
      size="md"
      footer={
        <Button
          variant="contained"
          color="primary"
          type="submit"
          size="large"
          onClick={agreeTerms}
          style={{
            width: '100%',
          }}
        >
          I agree
        </Button>
      }
    >
      <Typography weight={400} style={{ marginBottom: 8 }}>
        We have updated our Terms and Conditions and Privacy Policy.
      </Typography>
      <Row align="center">
        <Checkbox
          checked={isChecked.value}
          onClick={() => isChecked.setValue(!isChecked.value)}
          style={{
            alignItems: 'start',
            padding: 0,
          }}
        >
          <Typography
            style={{
              textAlign: 'start',
            }}
          >
            By clicking the button below, I agree with Tracelium Connect's{' '}
            <TitleLink
              onClick={usePageGoTo(PATH.TERMS_AND_CONDITIONS)}
              style={{
                margin: 0,
              }}
            >
              Terms and Conditions
            </TitleLink>{' '}
            and{' '}
            <TitleLink
              onClick={usePageGoTo(PATH.PRIVACY_POLICY)}
              style={{
                margin: 0,
              }}
            >
              Privacy Policy
            </TitleLink>
            .
          </Typography>
        </Checkbox>
      </Row>
    </Modal>
  );
};
