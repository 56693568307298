import { Navigate, Route, Routes } from 'react-router-dom';

import { PATH } from 'constants/path';

import { TeamsLayout } from './components';
import { TeamsPage } from './pages';

export const TeamsRoutes = () => (
  <Routes>
    <Route element={<TeamsLayout />}>
      <Route path={PATH.MY_TEAMS} element={<TeamsPage />} />
      <Route path={PATH.SHARED_WITH_ME_TEAMS} element={<TeamsPage />} />
      <Route path={PATH.ALL_TEAMS} element={<TeamsPage />} />
      <Route index element={<Navigate to={PATH.MY_TEAMS} />} />
    </Route>
  </Routes>
);
