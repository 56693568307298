import axios, { AxiosError, type AxiosRequestConfig, type AxiosResponse } from 'axios';
import { hidePageLoading, showPageLoading } from 'thesis-ui';

import { onLogout } from 'modules/layout/core/controller';

import { type APIConfig } from '../../types/api';

import { httpRequestErrors } from 'helpers';
import { debounce } from 'lodash';
import { publicAxiosInstance } from 'services/axios';

import { PATH } from 'constants/path';

/*Example of an API call:
 * const testApi = async () => {
 *  const response = await apiCall<any, Ward>({ url: '/api/wards/15', method: 'GET' });
 *  console.log(response);
 *};
 */

/**
 * Handle all of your Axios http requests
 *
 * @typedef {object} TRequest - This is the type of data or query of your request
 * @typedef {object} TResponse - This is the type of your returned data
 * @param url - Your request API
 * @param method - Ex: GET, POST, PATCH, etc.
 * @param config - axios-provided configs
 * @param onError - callback in case of Error request
 * @param onFinally - general callback when finished api calling
 *
 */

export async function apiCall<TRequest = unknown, TResponse = unknown>({
  url,
  data,
  method,
  config,
  onError,
  onFinally,
  isLoading,
  showError,
  isResponseHeader, // To-do-base,
  isPublic, // To-do-base
}: APIConfig<TRequest>): Promise<TResponse> {
  let request: Promise<AxiosResponse<TResponse>> = null as any;
  const axiosInstance = isPublic ? publicAxiosInstance : axios;

  switch (method) {
    case 'POST':
      request = axiosInstance.post<TRequest, AxiosResponse<TResponse>>(url, data, config);
      break;
    case 'GET':
      request = axiosInstance.get(url, {
        params: data,
        ...config,
      } as AxiosRequestConfig<TRequest>);
      break;
    case 'DELETE':
      request = axiosInstance.delete(url, {
        params: data,
        ...config,
      } as AxiosRequestConfig<TRequest>);
      break;

    case 'PUT':
      request = axiosInstance.put(url, data, config);
      break;

    case 'PATCH':
      request = axiosInstance.patch(url, data, config);
      break;
  }

  try {
    if (isLoading) {
      showPageLoading();
      // Show your loading
    }
    const response = await request;
    const dataResponse = (response.data || {}) as TResponse;
    if (isResponseHeader) {
      return {
        data: dataResponse,
        headers: response.headers,
      } as any;
    }
    return dataResponse;
  } catch (error) {
    const axiosError = error as AxiosError;
    const errorResponse = (axiosError.response?.data || {}) as TResponse;

    if (onError) {
      onError(axiosError);
    }
    if (showError) {
      handleApiError(axiosError);
    }

    return errorResponse;
  } finally {
    if (isLoading) {
      closeLoading();
      // Stop your loading here
    }

    if (onFinally) {
      onFinally();
    }
  }
}

function handleApiError(error: AxiosError) {
  if (error.response) {
    // Handle server response errors (e.g., status code is not in the 2xx range)
    switch (error.response.status) {
      case 413:
      case 400:
        httpRequestErrors(error.response.data);
        break;

      case 401:
        httpRequestErrors(error.response.data);
        onLogout();
        window.location.href = PATH.LOGIN;
        break;
      case 404:
        window.location.href = PATH.ERROR_404;
        break;
      case 403:
        window.location.href = PATH.ERROR_403;
        break;
    }
  } else if (error.request) {
    // Handle network request errors (e.g., no response received)
  } else {
    // Handle other errors
  }
}

export const closeLoading = debounce(() => {
  hidePageLoading();
}, 500);
