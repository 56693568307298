import { Row, Spacing, Typography } from 'thesis-ui';

import { type DeviceDetail } from 'modules/devices/core';

import { FilterLabelContainer } from '../styles/team-data-filter';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import { getItemAliasByDevice } from '../core';
import { useTeamDataFilterLabelController } from '../core/controllers';

export const TeamDataFilterLabel: React.FC<{
  devices: DeviceDetail[];
}> = ({ devices }) => {
  const { listDevice, onClearFilter } = useTeamDataFilterLabelController();
  return (
    <Row align="center" style={{ flexWrap: 'wrap' }}>
      {listDevice.map((el) => {
        const label = getItemAliasByDevice(el, devices);
        return (
          <Spacing
            key={el}
            spacing="xs"
            type="margin"
            placement="right"
            style={{ marginBottom: 12 }}
          >
            <FilterLabelContainer align="center">
              <Typography
                variant="body-2"
                className="text-ellipsis-1"
                style={{
                  maxWidth: 200,
                  textAlign: 'start',
                }}
                weight={500}
              >
                {label}
              </Typography>
              <CloseIcon
                onClick={onClearFilter(el)}
                style={{
                  marginLeft: 2,
                  cursor: 'pointer',
                }}
              />
            </FilterLabelContainer>
          </Spacing>
        );
      })}
    </Row>
  );
};
