export enum ChartDateRange {
  Custom,
  Today,
  'This week',
  'This month',
  'This year',
  'Last 5 minutes',
  'Last 1 hour',
  'Last 7 days',
  'Last 30 days',
  'Last 6 months',
}
