import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';

import { PageLoading, ToastMessageContainer } from 'thesis-ui';

import { authenSelector, useAuthStore } from 'modules/auth/core';
import { PublicModePage } from 'modules/public-mode/pages';
import { TermAndPolicyRoutes } from 'modules/terms-policy/Routers';

import { initAxiosInterceptor } from 'services';

import { PATH } from 'constants/path';

import { Error403Page } from './Error403Page';
import { Error404Page } from './Error404Page';
import { PrivateRoutes } from './PrivateRoutes';
import { PublicRoutes } from './PublicRoutes';

initAxiosInterceptor();

const AppRoutes = () => {
  const authen = useAuthStore(authenSelector);
  const { token } = authen;

  return (
    <BrowserRouter>
      <Routes>
        <Route path="error/*" element={<Outlet />}>
          <Route path="404" element={<Error404Page />} />
          <Route path="403" element={<Error403Page />} />
        </Route>
        <Route path="about/*" element={<TermAndPolicyRoutes />} />
        <Route path={PATH.PUBLIC_MODE} element={<PublicModePage />} />

        <Route path="/*" element={token ? <PrivateRoutes /> : <PublicRoutes />} />
      </Routes>
      <ToastMessageContainer />
      <PageLoading zIndex={999999} />
    </BrowserRouter>
  );
};

export { AppRoutes };
