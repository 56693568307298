import styled, { css } from 'styled-components';

import { RadiusConfig, Row, neutral, neutralTrans } from 'thesis-ui';

export const Container = styled(Row)`
  border-radius: ${RadiusConfig.xs}px;
  border: 1px solid ${neutralTrans['neutralTrans-15']};
  background: ${neutral['neutral-1']};
  margin-bottom: 24px;
`;

export const Header = styled(Row)`
  height: 64px;
  padding: 0px 16px;
  border-bottom: 1px solid ${neutralTrans['neutralTrans-15']};
  .rdrCalendarWrapper {
    width: 548px;
  }
`;

export const Body = styled.div`
  padding: 16px;
  min-height: 188px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FilterDateContainer = styled(Row)<{ isShowDateRange: boolean }>`
  width: fit-content;
  margin-left: 12px;
  p {
    white-space: nowrap !important;
  }
  .navigator {
    top: 75px !important;
  }
  .range-picker-container {
    width: 699px;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${({ isShowDateRange }) => {
    const display = isShowDateRange ? 'inline-flex' : 'none';
    return css`
      .rdrDateRangePickerWrapper {
        display: ${display};
      }
    `;
  }}
`;

export const DateRangePickerTab = styled(Row)`
  border-bottom: 1px solid ${neutralTrans['neutralTrans-15']};
  padding-left: 12px;
  .tab-line {
    bottom: -1px;
  }
`;
