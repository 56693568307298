import { closeModal, useDialogStore } from 'thesis-ui';

import type { ConfirmModalData } from './type';

export const useController = (modalKey: string) => {
  const modalProps: ConfirmModalData = useDialogStore((s) => s.state.data[modalKey]);

  const onCancel = () => {
    closeModal(modalKey);
  };

  return {
    ...modalProps,
    onCancel,
  };
};
