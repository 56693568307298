import { InputTitle } from 'components';
import { Button, Spacing, TextInput, Typography } from 'thesis-ui';

import { AuthForm } from '../styles';

import { InputPasswordTooltipMessage } from '../components';
import { useResetPasswordController } from '../core';

export const ResetPasswordPage = () => {
  const { disabled, password, passwordStatus, handleChange, handleSubmit } =
    useResetPasswordController();

  return (
    <AuthForm onSubmit={handleSubmit}>
      <Spacing spacing="xl" placement="bottom" type="margin">
        <Typography
          variant="h4"
          weight={700}
          style={{
            textAlign: 'center',
          }}
        >
          Set new password
        </Typography>
      </Spacing>

      <Spacing spacing="xl" placement="bottom" type="margin">
        <InputTitle title="New password" tooltipMessage={<InputPasswordTooltipMessage />} />
        <TextInput
          name="password"
          type="password"
          value={password}
          onChange={handleChange}
          helperText={passwordStatus.helperText}
          color={passwordStatus.color}
        />
      </Spacing>

      <Button
        variant="contained"
        color="primary"
        type="submit"
        size="large"
        style={{
          width: '100%',
        }}
        disabled={disabled}
      >
        Save
      </Button>
    </AuthForm>
  );
};
