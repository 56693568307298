import { Route, Routes } from 'react-router-dom';

import { DeviceCommandRoutes } from 'modules/device-command/Routers';
import { DevicesPage } from 'modules/devices';

import { PATH } from 'constants/path';

import { DeviceDetailLayout } from './components';
import { DeviceDashboard } from './components/DeviceDashboard';
import DeviceAPIKey from './pages/DeviceAPIKey';
import { DeviceAdd } from './pages/DeviceAdd';
import { DeviceData } from './pages/DeviceData';
import { DeviceInformation } from './pages/DeviceInformation';
import { DevicePublic } from './pages/DevicePublic';

export const DeviceRoutes = () => (
  <Routes>
    <Route>
      <Route index element={<DevicesPage />} />
      <Route path={PATH.TEAM_DEVICE_ADD} element={<DeviceAdd />} />
      <Route path={`${PATH.TEAM_DEVICE_DETAIL}/*`} element={<DeviceDetailLayout />}>
        <Route path={PATH.TEAM_DEVICE_DATA} element={<DeviceData />} />
        <Route path={`${PATH.TEAM_DEVICE_COMMAND}/*`} element={<DeviceCommandRoutes />} />
        <Route path={PATH.TEAM_DEVICE_INFORMATION} element={<DeviceInformation />} />
        <Route path={PATH.TEAM_DEVICE_PUBLIC} element={<DevicePublic />} />
        <Route path={PATH.TEAM_DEVICE_API_KEY} element={<DeviceAPIKey />} />
        <Route path={PATH.TEAM_DEVICE_DASHBOARD} element={<DeviceDashboard />} />
      </Route>
    </Route>
  </Routes>
);
