import { Button, Modal, RadiusConfig, Spacing, Typography } from 'thesis-ui';

import UpdateImg from 'assets/images/version-update.png';

import { MODAL_KEY } from 'constants/modal-key';

import { useNewFeatureModalController } from '../core';

export const VersionUpdateModal: React.FC = () => {
  const { content, confirmUpdateVersion } = useNewFeatureModalController();
  return (
    <Modal
      header={
        <Typography variant="h5" weight={600}>
          New update!
        </Typography>
      }
      modalKey={MODAL_KEY.VERSION_UPDATE}
      size="md"
      footer={
        <Button
          variant="contained"
          color="primary"
          type="submit"
          size="large"
          onClick={confirmUpdateVersion}
          style={{
            width: '100%',
          }}
        >
          Close
        </Button>
      }
    >
      <Spacing
        spacing="xl"
        placement="bottom"
        type="margin"
        style={{
          minHeight: 228,
          borderRadius: RadiusConfig.xs,
          backgroundImage: `url("${UpdateImg}")`,
        }}
      ></Spacing>
      <Typography weight={400}>
        {content?.title || ''}
        <ul style={{ margin: '12px 0 0 ', paddingLeft: 24, maxHeight: 200, overflowY: 'auto' }}>
          {(content?.data || []).map((el) => (
            <li key={el}>{el}</li>
          ))}
        </ul>
      </Typography>
    </Modal>
  );
};
