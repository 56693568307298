import React from 'react';
import { useNavigate } from 'react-router-dom';

import { InputTitle } from 'components';
import { Button, Row, Spacing, TextInput, Typography } from 'thesis-ui';

import { TitleLink } from '../styles';

import { PATH } from 'constants/path';

import type { FormSignUpCardProps } from '../core';
import { InputPasswordTooltipMessage } from './InputPasswordTooltipMessage';
import { TeamAndPolicyLabel } from './TeamAndPolicyLabel';

export const FormSignUpCard: React.FC<FormSignUpCardProps> = ({
  companyName,
  disabled,
  handleChange,
  isTermAndPolicy,
  password,
  passwordStatus,
}) => {
  const navigationTo = useNavigate();

  return (
    <React.Fragment>
      <Spacing spacing="sm" placement="bottom" type="margin">
        <InputTitle title="Password" required tooltipMessage={<InputPasswordTooltipMessage />} />
        <TextInput
          name="password"
          type="password"
          value={password}
          onChange={handleChange}
          helperText={passwordStatus.helperText}
          color={passwordStatus.color}
        />
      </Spacing>
      <Spacing spacing="sm" placement="bottom" type="margin">
        <TextInput
          title="Company"
          type="text"
          name="companyName"
          value={companyName}
          onChange={handleChange}
        />
      </Spacing>
      <TeamAndPolicyLabel isTermAndPolicy={isTermAndPolicy} />
      <Spacing spacing="xl" placement={['bottom', 'top']} type="margin">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          size="large"
          style={{
            width: '100%',
          }}
          disabled={disabled}
        >
          Sign up
        </Button>
      </Spacing>
      <Row align="center" justify="center">
        <Typography variant="body-1" weight={400}>
          Already had an account?
        </Typography>
        <TitleLink onClick={() => navigationTo(PATH.LOGIN)}> Log in</TitleLink>
      </Row>
    </React.Fragment>
  );
};
