import { Button, Spacing, Typography } from 'thesis-ui';

import { PasswordUpdatedContainer } from '../styles';

import { ReactComponent as TickCircleIcon } from 'assets/icons/tick-circle.svg';

import { PATH } from 'constants/path';

import { usePasswordUpdatedController } from '../core';

export const PasswordUpdatedPage = () => {
  const { navigationTo } = usePasswordUpdatedController();

  return (
    <PasswordUpdatedContainer>
      <TickCircleIcon />
      <Spacing spacing="xl" placement={['bottom', 'top']} type="margin">
        <Typography variant="h4" weight={600}>
          Password updated!
        </Typography>
      </Spacing>
      <Spacing spacing="xl" placement="bottom" type="margin">
        <Typography
          style={{
            textAlign: 'center',
          }}
        >
          You can now login with your new password.
        </Typography>
      </Spacing>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        size="large"
        style={{
          width: '100%',
        }}
        onClick={() => navigationTo(PATH.LOGIN)}
      >
        Back to Log in
      </Button>
    </PasswordUpdatedContainer>
  );
};
