import type React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { API_URL_RECAPTCHA } from 'constants/environment';

export const CaptchaContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={API_URL_RECAPTCHA}>{children} </GoogleReCaptchaProvider>
  );
};
