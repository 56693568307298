import styled from 'styled-components';

import { RadiusConfig, Row, Typography, neutral, neutralTrans } from 'thesis-ui';

export const Label = styled(Row)`
  height: 32px;
  min-width: 284px;
  width: 284px;
  border-radius: ${RadiusConfig.xxs}px;
  border: 1px solid ${neutralTrans['neutralTrans-15']};
  background: ${neutral['neutral-1']};
  padding-left: 10px;
  overflow: hidden;
`;

export const Link = styled(Typography)`
  cursor: pointer;
  white-space: nowrap;
  margin-left: 12px;
  &:hover {
    text-decoration: underline;
  }
`;

export const CommandDataContent = styled('div')`
  margin: 6px 0;
  width: 100%;
`;
