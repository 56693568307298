import { showToastMessage } from 'thesis-ui';

import { copyToClipboard } from 'helpers';
import { apiCall } from 'hooks/service/api';

import { SUCCESS_MESSAGE } from 'constants/message';

import { type DeviceDetail } from '..';
import { deviceDetailSelector, updateDeviceDetail, useDeviceStore } from '../store';

export const useDeviceAPIKeyController = () => {
  const device = useDeviceStore(deviceDetailSelector);

  const onCopyTokenKey = () => {
    if (!device) {
      return;
    }
    copyToClipboard(device.secure_token_keys);
    showToastMessage('success', SUCCESS_MESSAGE.COPY_KEY);
  };

  const onGenerateNewKey = async () => {
    if (!device) {
      return showToastMessage('error', SUCCESS_MESSAGE.GENERATED_KEY);
    }

    const result: DeviceDetail = await apiCall({
      url: `/api/teams/${device.team_id}/devices/${device?.id}?reset_secure_token=1`,
      method: 'PATCH',
      data: {},
      isLoading: true,
      showError: true,
    });
    if (result?.id) {
      showToastMessage('success', SUCCESS_MESSAGE.GENERATED_KEY);
      updateDeviceDetail({
        ...device,
        secure_token_keys: result.secure_token_keys,
      });
    }
  };

  return {
    tokenKey: device?.secure_token_keys?.toLocaleUpperCase() || '',
    onCopyTokenKey,
    onGenerateNewKey,
  };
};
