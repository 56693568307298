import type { TabItemType } from 'thesis-ui/components/Navigation/Tabs/core/types';

import { getDeviceCommandPath } from 'helpers';

import { PATH } from 'constants/path';

export const getDeviceCommandTabs = (teamId: string, deviceId: string): TabItemType[] => [
  {
    label: 'Command queue',
    key: getDeviceCommandPath(PATH.TEAM_DEVICE_COMMAND_QUEUE, teamId, deviceId),
  },
  {
    label: 'Collection',
    key: getDeviceCommandPath(PATH.TEAM_DEVICE_COMMAND_COLLECTION, teamId, deviceId),
  },
  {
    label: 'Action log',
    key: getDeviceCommandPath(PATH.TEAM_DEVICE_COMMAND_ACTION_LOG, teamId, deviceId),
  },
];
