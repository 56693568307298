import styled from 'styled-components';

import { Row } from 'thesis-ui';

export const Container = styled(Row)`
  button {
    background: transparent;
    margin-right: 8px;
  }
`;
