import { Button, Row, Spacing, TextInput, Typography } from 'thesis-ui';

import { AuthForm, TitleLink } from '../styles';

import { PATH } from 'constants/path';

import { useForgotPasswordController } from '../core';

export const ForgotPasswordPage = () => {
  const { email, disabled, emailStatus, handleChange, handleSubmit, navigationTo } =
    useForgotPasswordController();

  return (
    <AuthForm onSubmit={handleSubmit}>
      <Spacing spacing="xl" placement="bottom" type="margin">
        <Typography
          variant="h4"
          weight={700}
          style={{
            textAlign: 'center',
          }}
        >
          Forgot password
        </Typography>
      </Spacing>
      <Spacing spacing="xl" placement="bottom" type="margin">
        <Typography>
          Enter the email address associated with your account and we will send you an email to help
          you reset your password.
        </Typography>
      </Spacing>
      <Spacing spacing="xl" placement="bottom" type="margin">
        <TextInput
          title="Email address"
          name="email"
          value={email}
          onChange={handleChange}
          helperText={emailStatus.helperText}
          color={emailStatus.color}
        />
      </Spacing>

      <Spacing spacing="xl" placement="bottom" type="margin">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          size="large"
          style={{
            width: '100%',
          }}
          disabled={disabled}
        >
          Send
        </Button>
      </Spacing>
      <Row align="center" justify="center">
        <TitleLink onClick={() => navigationTo(PATH.LOGIN)}> Back to Log in</TitleLink>
      </Row>
    </AuthForm>
  );
};
