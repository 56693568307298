import { blue, neutral } from 'thesis-ui';

import './styles/pie-chart.css';

import type { PieChartProps } from './core/types';

const PieChart: React.FC<PieChartProps> = ({
  children,
  progress = 0,
  borderSize = 16,
  color = blue['blue-5'],
  progressBackground = neutral['neutral-2'],
  size = 160,
}) => {
  return (
    <div
      className="pie animate"
      style={
        {
          '--p': progress,
          '--b': `${borderSize}px`,
          '--c': color,
          '--w': `${size}px`,
          '--pb': progressBackground,
        } as any
      }
    >
      {children}
    </div>
  );
};
export default PieChart;
