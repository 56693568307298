import { Row, Spacing, Typography, formatDate, neutral } from 'thesis-ui';

import { ContentContainer } from '../styles/content';

import type { TermAndPolicyContentProps } from '../core';

export const Content: React.FC<TermAndPolicyContentProps> = ({ name, parts, updated }) => {
  return (
    <ContentContainer>
      <Row align="center" justify="space-between">
        <Typography weight={700} variant="h4">
          {name}
        </Typography>
        <Typography color={neutral['neutral-7']}>
          Updated {formatDate(updated, 'MMM yyyy')}
        </Typography>
      </Row>
      <Spacing spacing="sm" placement="top" type="margin">
        {Boolean(parts.length) &&
          parts.map((part) => (
            <Typography
              key={part.answer}
              dangerouslySetInnerHTML={{
                __html: part.answer,
              }}
            />
          ))}
      </Spacing>
    </ContentContainer>
  );
};
