import { InputColor } from 'enums';

export const getInputStatus = (value: string, messageSuccess: string, messageError: string) => {
  const result = {
    color: InputColor.Success,
    helperText: messageSuccess,
  };
  if (!value) {
    result.color = InputColor.Default;
    result.helperText = '';
  }
  if (value && messageError) {
    result.color = InputColor.Error;
    result.helperText = messageError;
  }
  return result;
};
