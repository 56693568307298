import styled, { css } from 'styled-components';

import { RadiusConfig, Row } from 'thesis-ui';

export const Container = styled(Row)<{
  bg: string;
}>`
  ${({ bg }) => {
    return css`
      width: fit-content;
      padding: 10px 12px 10px 10px;
      border-radius: ${RadiusConfig.xs}px;
      background-color: ${bg};
    `;
  }}
`;

export const Icon = styled.div`
  min-width: 24px;
  height: 24px;
`;
