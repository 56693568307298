import type { FormCollectionRequest } from './type';

import { API_URL } from 'constants/environment';

import { CommandType } from './enum';

export const defaultFormCollectionRequest: FormCollectionRequest = {
  name: '',
  data: '',
  type: CommandType.Collection,
};

export const COMMAND_LINK = `${API_URL}/api/command/execute`;
