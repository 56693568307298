import { Row, Spacing } from 'thesis-ui';

import type { TeamTabLabelProps } from '../core';

export const TeamTabLabel: React.FC<TeamTabLabelProps> = ({ icon, title }) => {
  return (
    <Row align="center">
      {icon}
      <Spacing
        spacing="xxs"
        type="margin"
        placement="left"
        style={{
          whiteSpace: 'nowrap',
        }}
      >
        {title}
      </Spacing>
    </Row>
  );
};
