import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { GeneralRoutes } from 'modules/generals/Routers';
import { MasterLayout } from 'modules/layout/MasterLayout';
import { TeamRoutes } from 'modules/team/Routers';
import { TeamsRoutes } from 'modules/teams/Routers';

import { includes } from 'lodash';

import { ListAuthPath, PATH } from 'constants/path';

const PrivateRoutes = () => {
  const location = useLocation();
  const isAuthPath = includes(ListAuthPath, location.pathname);

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path={`${PATH.TEAMS}/*`} element={<TeamsRoutes />} />
        <Route path={`${PATH.GENERALS}/*`} element={<GeneralRoutes />} />
        <Route path={PATH.TEAM} element={<TeamRoutes />} />

        <Route index element={<Navigate to={PATH.TEAMS} />} />
        <Route path="*" element={<Navigate to={isAuthPath ? PATH.TEAMS : PATH.ERROR_404} />} />
      </Route>
    </Routes>
  );
};

export { PrivateRoutes };
