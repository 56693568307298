import { Row, Spacing, Typography } from 'thesis-ui';

import type { DashboardStorageLabelProps } from '../core';

export const DashboardStorageLabel: React.FC<DashboardStorageLabelProps> = ({
  icon,
  label,
  value,
}) => {
  return (
    <Row align="center" style={{ whiteSpace: 'nowrap' }}>
      <Row style={{ marginRight: 8, width: 'fit-content' }}>{icon}</Row>
      <Spacing spacing="xxxs" placement="right" type="margin" style={{ width: 88 }}>
        <Typography style={{ textAlign: 'start' }}>{label}</Typography>
      </Spacing>
      <Row justify="end" style={{ width: 74 }}>
        <Typography weight={600}>{value}</Typography>
      </Row>
    </Row>
  );
};
