import { Navigate } from 'react-router-dom';

import { Row } from 'thesis-ui';

import { TeamRole, teamDetailSelector, useTeamStore } from 'modules/team';

import { getDevicePermission } from 'helpers/permission';

import { PATH } from 'constants/path';

const withDeviceRole =
  (Component: React.ComponentType) =>
  ({ ...props }) => {
    const team = useTeamStore(teamDetailSelector);
    if (team) {
      const hasPermission = getDevicePermission(team.role, TeamRole.TeamGuest);
      if (hasPermission) {
        return <Component {...props} />;
      }

      return <Navigate to={PATH.ERROR_404} replace />;
    }
    return <Row />;
  };

export default withDeviceRole;
