import PieChart from 'components/chart/PieChart';
import { Grid, Row, Spacing, Typography, neutral } from 'thesis-ui';

import { DeviceStatus } from 'modules/devices/core/enum';

import { Container, StorageLabel } from '../styles/dashboard';

import { ReactComponent as DeviceActiveIcon } from 'assets/icons/device-active.svg';
import { ReactComponent as DeviceInActiveIcon } from 'assets/icons/device-inactive.svg';
import { ReactComponent as DotBlueIcon } from 'assets/icons/dot-blue.svg';
import { ReactComponent as DotWhiteIcon } from 'assets/icons/dot-white.svg';
import { ReactComponent as TotalDeviceIcon } from 'assets/icons/total-device.svg';

import { useDashboardStorageController } from '../core';
import { DashboardDeviceCard } from './DashboardDeviceCard';
import { DashboardStorageLabel } from './DashboardStorageLabel';
import { StorageLimitLabel } from './DashboardStorageLimitLabel';

export const DashboardStorage = () => {
  const { team, progress, totalDevice, chartColor, redirectDevicePage } =
    useDashboardStorageController();

  return (
    <Row direction="column">
      <Spacing
        spacing="xl"
        placement="bottom"
        type="margin"
        style={{
          width: '100%',
        }}
      >
        <Grid direction="template-column" type="x" gap="24px" column={3}>
          <DashboardDeviceCard
            icon={<DeviceActiveIcon />}
            title="Active devices"
            value={team?.device?.active || 0}
            onClick={redirectDevicePage(DeviceStatus.Active)}
          />
          <DashboardDeviceCard
            icon={<DeviceInActiveIcon />}
            title="Inactive devices"
            value={team?.device?.inactive || 0}
            onClick={redirectDevicePage(DeviceStatus.Inactive)}
          />
          <DashboardDeviceCard
            icon={<TotalDeviceIcon />}
            title="Total devices"
            value={totalDevice || 0}
            onClick={redirectDevicePage()}
          />
        </Grid>
      </Spacing>
      <Container direction="column">
        <Spacing
          spacing="md"
          style={{
            width: '100%',
            padding: '16px 12px 20px',
          }}
        >
          <Row align="center" justify="space-between">
            <Typography variant="h5" weight={600}>
              Storage
            </Typography>
            <Spacing spacing="xs" type="margin" placement="left">
              <StorageLimitLabel storageLimit={team?.storage_limit} />
            </Spacing>
          </Row>

          <Row
            style={{
              width: '100%',
              marginTop: 13,
            }}
          >
            <Row align="center" justify="center">
              <Row align="center" style={{ width: 'fit-content' }}>
                <Spacing type="margin" placement="right" spacing="3xl">
                  <PieChart progress={progress} color={chartColor}>
                    <Row
                      direction="column"
                      align="center"
                      justify="center"
                      style={{ marginTop: 10 }}
                    >
                      <Typography color={neutral['neutral-7']} variant="body-2">
                        Storage used
                      </Typography>
                      <Typography variant="h3" weight={600}>
                        {`${progress}%`}
                      </Typography>
                    </Row>
                  </PieChart>
                </Spacing>
                <StorageLabel iconColor={chartColor} direction="column">
                  <DashboardStorageLabel
                    icon={<DotBlueIcon className="icon-used" />}
                    label="Used:"
                    value={team?.usage || '0 KB'}
                  />
                  <Spacing spacing="xxs" type="margin" placement="top">
                    <DashboardStorageLabel
                      icon={<DotWhiteIcon />}
                      label="Available:"
                      value={team?.storage_available || '0 KB'}
                    />
                  </Spacing>
                </StorageLabel>
              </Row>
            </Row>
          </Row>
        </Spacing>
      </Container>
    </Row>
  );
};
