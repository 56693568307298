import { ChartDateRange } from 'enums/date-range';
import { type FormDate, definedDateRanges, formatDate } from 'thesis-ui';

import type { DeviceRecord, FormSettingChart } from './type';

import {
  endOfDay,
  endOfYesterday,
  startOfDay,
  startOfYesterday,
  subDays,
  subHours,
  subMinutes,
  subMonths,
} from 'date-fns';
import { convertDateToUTC } from 'helpers';
import { includes, isUndefined } from 'lodash';

import { getRangeOptions } from 'constants/data-range';

import { defaultFormSettingChart } from './constant';
import {
  DeviceChartDateRangeTabKey,
  DeviceChartRefreshIntervalType,
  DeviceChartRelativeRange,
  DeviceChartYAxisSettingType,
} from './enum';

export const getRefreshInterval = (
  refreshType: DeviceChartRefreshIntervalType,
  refresh: number,
) => {
  let timer = 0;
  switch (refreshType) {
    case DeviceChartRefreshIntervalType.Second:
      timer = refresh * 1000; // 1second = 1000ms
      break;
    case DeviceChartRefreshIntervalType.Minute:
      timer = refresh * 60000; // 1minute = 60000ms
      break;

    default:
      timer = refresh * 3600000; // 1hour = 3600000ms
      break;
  }
  return timer;
};

export const getDataUploadContentHeight = (chartId: string, totalDataUpload: number) => {
  if (chartId) {
    return 280;
  }
  const heightItem = 16; // 1 item height 16px
  const totalHeight = 264;
  const maxItem = totalHeight / heightItem;
  return totalDataUpload > maxItem ? 560 : 280;
};

export const getContentDataPlacement = (
  totalDataUpload: number,
  heightContent: number,
  visible: boolean,
) => {
  if (!visible) {
    return 'end';
  }
  const totalHeightItem = totalDataUpload * 16; // 1 item 16px
  return totalHeightItem >= heightContent - 16 ? 'start' : 'end'; // padding top and bottom = 16px
};

export const getYAxisChartSetting = (setting: FormSettingChart) => {
  if (!setting || setting.yAxisType === DeviceChartYAxisSettingType.Auto) {
    return {};
  }
  return {
    min: setting.yAxisMin,
    max: setting.yAxisMax,
  };
};

export const getDeviceFilter = (parserFilter: any) => {
  let listStatus = [];
  let listState = [];

  if (parserFilter['status']) {
    listStatus = parserFilter['status'];
  }
  if (parserFilter['state']) {
    listState = parserFilter['state'];
  }
  return {
    listStatus,
    listState,
  };
};

export const updateSettingChartState = (chart: DeviceRecord) => {
  return {
    name: chart.name,
    refresh: chart?.refresh_interval_value ?? defaultFormSettingChart.refresh,
    refreshType: chart?.refresh_interval_type ?? defaultFormSettingChart.refreshType,
    yAxisType: chart?.y_axis_display_type ?? defaultFormSettingChart.yAxisType,
    yAxisMin: chart?.y_axis_min ?? defaultFormSettingChart.yAxisMin,
    yAxisMax: chart?.y_axis_max ?? defaultFormSettingChart.yAxisMax,
    startDate: chart.from_datetime
      ? (convertDateToUTC(chart.from_datetime, false) as Date)
      : defaultFormSettingChart.startDate,
    endDate: chart.to_datetime
      ? (convertDateToUTC(chart.to_datetime, false) as Date)
      : defaultFormSettingChart.endDate,
    dateRangeType: !isUndefined(chart.date_range_type)
      ? chart.date_range_type
      : defaultFormSettingChart.dateRangeType,
    dateRangeTabKey: !isUndefined(chart.tab_key)
      ? chart.tab_key
      : defaultFormSettingChart.dateRangeTabKey,
    relativeRangeTotal: !isUndefined(chart.relative_range_total)
      ? chart.relative_range_total
      : defaultFormSettingChart.relativeRangeTotal,
    relativeRangeAction: !isUndefined(chart.relative_range_action)
      ? chart.relative_range_action
      : defaultFormSettingChart.relativeRangeAction,
  };
};

export const getRangeSelected = (start: FormDate, end: FormDate) => {
  if (!start || !end) {
    return null;
  }
  const formatType = 'dd-MM-H:mm';
  const rangeOptions = getRangeOptions();
  const range = rangeOptions.find(
    (el) =>
      formatDate(el.start, formatType) === formatDate(start, formatType) &&
      formatDate(el.end, formatType) === formatDate(end, formatType),
  );
  return range;
};

export const getDateRangeField = (start: FormDate, end: FormDate) => {
  if (start && end) {
    const range = getRangeSelected(start, end);
    if (range) {
      return range.field;
    }
    //Select date custom
    const startAndEndOfDayRange = getRangeSelected(
      startOfDay(start as Date),
      endOfDay(end as Date),
    );
    if (startAndEndOfDayRange) {
      return startAndEndOfDayRange.field;
    }
    return ChartDateRange.Custom;
  }
  return ChartDateRange.Custom;
};

export const getDeviceChartDateFilter = (setting: FormSettingChart) => {
  const currentDate = new Date();

  if (setting.dateRangeTabKey === DeviceChartDateRangeTabKey.RELATIVE_RANGE) {
    return getDeviceChartDateFilterByRelativeRange(setting, currentDate);
  }
  return getDeviceChartDateFilterByQuickOptions(setting, currentDate);
};

const getDeviceChartDateFilterByQuickOptions = (setting: FormSettingChart, currentDate: Date) => {
  let startDate = new Date(setting?.startDate);
  let endDate = new Date(setting?.endDate);

  if (!isUndefined(setting?.dateRangeType) && setting.dateRangeType !== ChartDateRange.Custom) {
    if (setting.dateRangeType === ChartDateRange['Last 5 minutes']) {
      startDate = subMinutes(currentDate, 5);
      endDate = currentDate;
    }
    if (setting.dateRangeType === ChartDateRange['Last 1 hour']) {
      startDate = subHours(currentDate, 1);
      endDate = currentDate;
    }
    if (
      setting.dateRangeType !== ChartDateRange['Last 5 minutes'] &&
      setting.dateRangeType !== ChartDateRange['Last 1 hour']
    ) {
      const rangeOptions = getRangeOptions();
      const range = rangeOptions.find((el) => el.field === setting.dateRangeType);
      if (range) {
        startDate = range.start || currentDate;
        endDate = range.end || currentDate;
      }
    }
  }

  return {
    startDate,
    endDate,
  };
};

const getDeviceChartDateFilterByRelativeRange = (setting: FormSettingChart, currentDate: Date) => {
  const total = Number(setting.relativeRangeTotal || 1);
  let newStartDate = subMinutes(currentDate, total);
  let newEndDate = currentDate;

  switch (setting.relativeRangeAction) {
    case DeviceChartRelativeRange.HOUR:
      newStartDate = subHours(currentDate, total);
      break;
    case DeviceChartRelativeRange.DAY:
      if (total === 1) {
        newStartDate = startOfYesterday();
        newEndDate = endOfYesterday();
      } else {
        newStartDate = subDays(definedDateRanges.startOfToday, total - 1);
        newEndDate = definedDateRanges.endOfToday;
      }

      break;
    case DeviceChartRelativeRange.MONTH:
      newStartDate = subMonths(definedDateRanges.startOfToday, total);
      newEndDate = definedDateRanges.endOfToday;
      break;
  }
  return {
    startDate: newStartDate,
    endDate: newEndDate,
  };
};

export const getDataRangeLabel = (setting: FormSettingChart) => {
  const allTime = 'All time';
  if (!setting) {
    return allTime;
  }
  const {
    endDate,
    startDate,
    dateRangeType,
    dateRangeTabKey,
    relativeRangeAction,
    relativeRangeTotal,
  } = setting;
  if (dateRangeTabKey === DeviceChartDateRangeTabKey.RELATIVE_RANGE) {
    const actionLabel = DeviceChartRelativeRange[relativeRangeAction]?.toLocaleLowerCase();
    const newActionLabel = relativeRangeTotal > 1 ? `${actionLabel}s` : actionLabel;
    return `Last ${relativeRangeTotal} ${newActionLabel}`;
  }
  if (dateRangeType) {
    return ChartDateRange[dateRangeType];
  }
  if (startDate && endDate) {
    return `${formatDate(startDate, 'dd MMM')} - ${formatDate(endDate, 'dd MMM')}`;
  }
  return allTime;
};

export const getChartTimeUnit = (setting: FormSettingChart) => {
  if (!setting) {
    return undefined;
  }

  if (
    includes(
      [ChartDateRange['Last 5 minutes'], ChartDateRange['Last 1 hour']],
      setting?.dateRangeType,
    ) &&
    setting.dateRangeTabKey === DeviceChartDateRangeTabKey.QUICK_OPTIONS
  ) {
    return undefined;
  }
  if (
    includes(
      [DeviceChartRelativeRange.MINUTE, DeviceChartRelativeRange.HOUR],
      setting?.relativeRangeAction,
    ) &&
    setting.dateRangeTabKey === DeviceChartDateRangeTabKey.RELATIVE_RANGE
  ) {
    return undefined;
  }
  return 'hour';
};

export const checkDeviceChartFilterLastTime = (setting: FormSettingChart) => {
  if (!setting) {
    return false;
  }

  if (
    includes(
      [ChartDateRange['Last 5 minutes'], ChartDateRange['Last 1 hour']],
      setting?.dateRangeType,
    ) &&
    setting.dateRangeTabKey === DeviceChartDateRangeTabKey.QUICK_OPTIONS
  ) {
    return true;
  }

  if (
    includes(
      [DeviceChartRelativeRange.MINUTE, DeviceChartRelativeRange.HOUR],
      setting?.relativeRangeAction,
    ) &&
    setting.dateRangeTabKey === DeviceChartDateRangeTabKey.RELATIVE_RANGE
  ) {
    return true;
  }

  return false;
};
