export const TABLE_KEY = Object.freeze({
  MY_TEAMS: 'my-teams',
  SHARED_WITH_ME_TEAMS: 'shared-width-me',
  ALL_TEAMS: 'all-teams',
  TEAM_DATA: 'team-data',
  DEVICES: 'devices',
  DEVICES_COMMAND_QUEUE: 'devices-command-queue',
  DEVICES_COMMAND_COLLECTION: 'devices-command-collection',
  DEVICES_DATA_CHART: 'devices-data-chart',
  DEVICES_PUBLIC_CHART: 'devices-public-chart',
});
