import styled from 'styled-components';

import { neutral, neutralTrans } from 'thesis-ui';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: 62px;
  min-height: 74px;
  padding: 0px 24px;
  border-bottom: 1px solid ${neutralTrans['neutralTrans-15']};
  background-color: ${neutral['neutral-1']};
`;

export const Body = styled.div`
  width: 100%;
  flex-grow: 1;
  padding: 24px;
  overflow-y: auto;
  scrollbar-width: thin;
`;
