import styled from 'styled-components';

import { RadiusConfig, Row, Typography, neutral } from 'thesis-ui';

export const Container = styled(Row)`
  width: 180px;
  background-color: ${neutral['neutral-11']};
  border-radius: ${RadiusConfig.xs}px;
`;

export const Header = styled(Row)`
  padding: 12px 12px 0px 16px;
  height: 50px;
`;

export const Body = styled(Row)`
  padding: 16px;
  align-items: initial;
`;

export const TitleClearFilter = styled(Typography)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
