import { HttpStatusCode } from 'enums';
import { useFormik } from 'formik';
import { showToastMessage } from 'thesis-ui';

import { getInputStatus, hasEmptyValues, trimObject } from 'helpers';
import { apiCall } from 'hooks/service/api';

import { SUCCESS_MESSAGE } from 'constants/message';
import type { ApiResponse } from 'types';

import { defaultPasswordRequest } from '../constant';
import { passwordSchema } from '../schema';

export const usePasswordController = () => {
  const formik = useFormik({
    initialValues: defaultPasswordRequest,
    validationSchema: passwordSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const { currentPassword, newPassword } = values;
      const result: ApiResponse = await apiCall({
        url: `/api/auth/change-password`,
        data: trimObject({
          currentPassword,
          newPassword,
          confirmedNewPassword: newPassword,
        }),
        method: 'POST',
        isLoading: true,
        showError: true,
      });
      if (result?.statusCode === HttpStatusCode.Success) {
        showToastMessage('success', SUCCESS_MESSAGE.CHANGED_PASSWORD);
        setValues(defaultPasswordRequest);
      }
    },
  });

  const { values, errors, isSubmitting, isValid, handleSubmit, handleChange, setValues } = formik;
  const { currentPassword, newPassword } = values;

  const onCancel = () => {
    setValues(defaultPasswordRequest);
  };

  const currentPasswordStatus = getInputStatus(
    currentPassword,
    SUCCESS_MESSAGE.PASSWORD,
    errors?.currentPassword ?? '',
  );

  const newPasswordStatus = getInputStatus(
    newPassword,
    SUCCESS_MESSAGE.PASSWORD,
    errors?.newPassword ?? '',
  );

  const submitDisabled =
    hasEmptyValues({ currentPassword, newPassword }) || isSubmitting || !isValid;
  const cancelDisabled = !currentPassword && !newPassword;

  return {
    currentPassword,
    newPassword,
    submitDisabled,
    currentPasswordStatus,
    newPasswordStatus,
    cancelDisabled,
    handleSubmit,
    handleChange,
    onCancel,
  };
};
