import { Row, Typography, blue, orange } from 'thesis-ui';

import { Container, Icon } from '../styles/collection-label';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';

import { isString } from 'lodash';

import type { CollectionLabelProps } from '../core';

export const CollectionLabel: React.FC<CollectionLabelProps> = ({ type, label }) => {
  const renderIcon = () => {
    if (type === 'info') {
      return <InfoIcon />;
    }
    return <WarningIcon />;
  };

  const color = type === 'info' ? blue['blue-6'] : orange['orange-6'];
  const bg = type === 'info' ? blue['blue-1'] : orange['orange-1'];

  return (
    <Container bg={bg}>
      <Icon>{renderIcon()}</Icon>
      <Row
        align="center"
        style={{
          minHeight: 24,
          marginLeft: 8,
        }}
      >
        {isString(label) ? (
          <Typography
            style={{
              textAlign: 'start',
            }}
            color={color}
          >
            {label}
          </Typography>
        ) : (
          label
        )}
      </Row>
    </Container>
  );
};
