import { ChartPinAt } from 'enums/chart';

import { useAuthStore, userProfileSelector } from 'modules/auth/core';

export const useSettingChartKey = (pinAt: number, deviceId: string, label: string) => {
  const userProfile = useAuthStore(userProfileSelector);
  const chartKey = `UserId-${userProfile?.id}-${ChartPinAt[pinAt]}-${deviceId}-${label}`;

  return {
    chartKey,
  };
};
