import { Row, Typography, neutral } from 'thesis-ui';

import { ReactComponent as EyeSlashIcon } from 'assets/icons/eye-slash.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import { ReactComponent as TextDotsIcon } from 'assets/icons/text-dots.svg';

import { useBoolean } from 'hooks';

export const DeviceLabel: React.FC<{
  title: string;
  value: React.ReactNode;
  hideText?: boolean;
}> = ({ title, value, hideText }) => {
  const showText = useBoolean(false);

  return (
    <Row
      direction="column"
      style={{
        alignItems: 'initial',
      }}
    >
      <Typography
        weight={600}
        style={{
          marginBottom: 6,
          textAlign: 'start',
        }}
      >
        {title}
      </Typography>
      {hideText && !showText.value ? (
        <Row
          align="center"
          style={{
            height: 20,
          }}
        >
          <TextDotsIcon />
          <EyeIcon
            onClick={() => showText.setValue(true)}
            style={{
              marginLeft: 8,
              cursor: 'pointer',
            }}
          />
        </Row>
      ) : (
        <Row align="center">
          <Typography
            style={{
              textAlign: 'start',
              whiteSpace: 'pre-line',
            }}
            color={neutral[value ? 'neutral-11' : 'neutral-7']}
          >
            {value || 'No information'}
          </Typography>
          {hideText && (
            <EyeSlashIcon
              onClick={() => showText.setValue(false)}
              style={{
                marginLeft: 8,
                cursor: 'pointer',
              }}
            />
          )}
        </Row>
      )}
    </Row>
  );
};
