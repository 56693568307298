import { Navigate, Route, Routes } from 'react-router-dom';

import { PATH } from 'constants/path';

import { GeneralLayout } from './components';
import { MyProfilePage, PasswordPage, SettingPage } from './pages';

export const GeneralRoutes = () => (
  <Routes>
    <Route element={<GeneralLayout />}>
      <Route path={PATH.MY_PROFILE} element={<MyProfilePage />} />
      <Route path={PATH.PASSWORD} element={<PasswordPage />} />
      <Route path={PATH.SETTINGS} element={<SettingPage />} />
      <Route index element={<Navigate to={PATH.MY_PROFILE} />} />
    </Route>
  </Routes>
);
