import styled from 'styled-components';

import { RadiusConfig, Row, neutral, neutralTrans } from 'thesis-ui';

export const Container = styled(Row)`
  border-radius: ${RadiusConfig.xs}px;
  border: 1px solid ${neutralTrans['neutralTrans-15']};
  background: ${neutral['neutral-1']};
  padding: 12px;
  cursor: pointer;
  &:hover {
    border: 1px solid ${neutral['neutral-7']};
    .icon path[stroke] {
      stroke: ${neutral['neutral-11']};
    }
    .icon path[fill] {
      fill: ${neutral['neutral-11']};
    }
  }
`;
