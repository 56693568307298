import { useLocation, useNavigate } from 'react-router-dom';

import { Role, useAuthStore, userProfileSelector } from 'modules/auth/core';

import { PATH } from 'constants/path';

export const useTeamsLayoutController = () => {
  const location = useLocation();
  const navigationTo = useNavigate();
  const userProfile = useAuthStore(userProfileSelector);
  const isAdminUser = userProfile?.role_id === Role.Admin;
  const isMyTeamsPath = location.pathname.includes(PATH.MY_TEAMS);

  const onTabClick = (key: string) => {
    navigationTo(key);
  };

  return {
    isMyTeamsPath,
    isAdminUser,
    locationPath: location.pathname,
    onTabClick,
  };
};
