import { ERROR_MESSAGE, SUCCESS_MESSAGE } from 'constants/message';
import { MODAL_KEY } from 'constants/modal-key';

import { CommandType } from './enum';

export const getCollectionLabel = (isCommandMaximum: boolean) => {
  return isCommandMaximum
    ? 'You have reached the maximum limit of 5 commands.'
    : 'The maximum number of commands in collection is 5.';
};

export const getButtonTitleByType = (type: CommandType) => {
  return type === CommandType.Collection ? 'Add to collection' : 'Add to queue';
};

export const getCommandModalKey = (type: CommandType) => {
  return type === CommandType.Collection
    ? MODAL_KEY.FORM_COMMAND_COLLECTION
    : MODAL_KEY.FORM_COMMAND_QUEUE;
};

export const getCommandModalMessageError = (isEdit: boolean, type: CommandType) => {
  if (isEdit) {
    return ERROR_MESSAGE.EDIT;
  }

  return type === CommandType.Collection ? ERROR_MESSAGE.ADD_COLLECTION : ERROR_MESSAGE.ADD_QUEUE;
};

export const getCommandModalMessageSuccess = (isEdit: boolean, type: CommandType) => {
  if (isEdit) {
    return SUCCESS_MESSAGE.EDIT_COMMAND;
  }

  return type === CommandType.Collection
    ? SUCCESS_MESSAGE.ADD_COLLECTION
    : SUCCESS_MESSAGE.ADD_QUEUE;
};

export const getFormCommandModalURL = (
  isEdit: boolean,
  teamId: string,
  deviceId: string,
  modalId: string,
) => {
  return isEdit
    ? `/api/teams/${teamId}/devices/${deviceId}/commands/${modalId}`
    : `/api/teams/${teamId}/devices/${deviceId}/commands`;
};
