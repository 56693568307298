import { Row, Typography, neutral } from 'thesis-ui';

import type { NoItemProps } from '../core';

export const EmptyMessage: React.FC<NoItemProps> = ({ title }) => {
  return (
    <Row
      style={{
        padding: '8px 16px 8px 10px',
      }}
    >
      <Typography
        style={{
          textAlign: 'start',
        }}
        color={neutral['neutral-7']}
      >
        {title}
      </Typography>
    </Row>
  );
};
