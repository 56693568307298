import type { TabItemType } from 'thesis-ui/components/Navigation/Tabs/core/types';

import { getDeviceDetailPath } from 'helpers';
import { compact } from 'lodash';

import { PATH } from 'constants/path';

import { DeviceState } from './enum';

export const getDeviceTabs = (
  teamId: string,
  deviceId: string,
  hasDevicePermission: boolean,
  deviceState: DeviceState,
  enabledDashboard?: boolean,
): TabItemType[] => {
  const deviceDataTab = {
    label: 'Device data',
    key: getDeviceDetailPath(PATH.TEAM_DEVICE_DATA, teamId, deviceId),
  };

  const commandTab = {
    label: 'Commands',
    key: getDeviceDetailPath(PATH.TEAM_DEVICE_COMMAND, teamId, deviceId),
  };

  const deviceInformation = {
    label: 'Device information',
    key: getDeviceDetailPath(PATH.TEAM_DEVICE_INFORMATION, teamId, deviceId),
  };

  const publicMode = {
    label: 'Public mode',
    key: getDeviceDetailPath(PATH.TEAM_DEVICE_PUBLIC, teamId, deviceId),
  };

  const apiKey = {
    label: 'API key',
    key: getDeviceDetailPath(PATH.TEAM_DEVICE_API_KEY, teamId, deviceId),
  };

  const dashboardTab = enabledDashboard
    ? {
        label: 'Device Dashboard',
        key: getDeviceDetailPath(PATH.TEAM_DASHBOARD, teamId, deviceId),
      }
    : null;

  if (hasDevicePermission) {
    return compact([
      dashboardTab,
      deviceDataTab,
      commandTab,
      deviceInformation,
      publicMode,
      apiKey,
    ]);
  }

  if (deviceState === DeviceState.Public) {
    return [deviceDataTab, deviceInformation, publicMode];
  }

  return compact([dashboardTab, deviceDataTab, deviceInformation]);
};
