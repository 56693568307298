import {
  Avatar,
  Button,
  Dropdown,
  RadiusConfig,
  Row,
  Spacing,
  Tabs,
  Typography,
  getFirstLetterInName,
  neutral,
} from 'thesis-ui';

import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';
import { ReactComponent as ThreeDotsIcon } from 'assets/icons/three-dots.svg';

import { getImageUrl } from 'helpers';
import { isEmpty } from 'lodash';

import { useTeamHeaderController } from '../core';
import { teamActions } from '../core/action';
import { getTeamTabs } from '../core/tabs';

export const TeamHeader: React.FC<{
  teamLayoutRef: React.RefObject<HTMLDivElement>;
}> = ({ teamLayoutRef }) => {
  const {
    team,
    actionVisible,
    locationPath,
    onChangeDropdownAction,
    onClickMenuItem,
    onTabClick,
    openTeamShareModal,
    isTeamAdmin,
    isTeamOwner,
  } = useTeamHeaderController(teamLayoutRef);
  const tabs = getTeamTabs(team?.id ?? '');
  const actions = teamActions(isTeamOwner);
  return (
    <Row
      align="center"
      justify="space-between"
      style={{
        height: '100%',
      }}
    >
      <Row
        align="center"
        style={{
          height: '100%',
        }}
      >
        <Avatar
          containerStyle={{
            width: 'fit-content',
          }}
          items={{
            label: getFirstLetterInName(team?.name ?? '', true),
            src: team?.logo ? getImageUrl(team?.logo) : '',
            borderRadius: RadiusConfig.xs,
            bg: team?.logo ? 'transparent' : team?.colour,
            color: neutral['neutral-1'],
            border: '1px soild transparent',
          }}
          size="large"
        />
        <Row
          direction="column"
          justify="end"
          style={{
            height: '100%',
            marginLeft: 4,
            alignItems: 'initial',
          }}
        >
          <Typography
            weight={600}
            variant="h4"
            height={22}
            style={{
              marginLeft: 12,
              textAlign: 'start',
            }}
          >
            {team?.name}
          </Typography>
          {!isEmpty(tabs) && (
            <Tabs variant="text" items={tabs} activeKey={locationPath} onTabClick={onTabClick} /> // To-do-implement design: Check empty items tabs
          )}
        </Row>
      </Row>
      <Row align="center" justify="end">
        <Button
          onClick={openTeamShareModal}
          placementIcon="left"
          icon={<ShareIcon />}
          style={{
            height: 32,
            paddingLeft: 8,
            paddingRight: 12,
          }}
        >
          Share
        </Button>

        {isTeamAdmin ? (
          <Dropdown
            theme="dark"
            dropdownConfig={{
              placement: 'bottom-end',
              trigger: 'click',
              offset: [10, 5],
              visible: actionVisible.value,
              onClickOutside: onChangeDropdownAction,
            }}
            dropdownMenuStyle={{
              minWidth: 140,
            }}
            menu={{
              items: actions,
              selectedKeys: [],
              onClick: onClickMenuItem,
            }}
          >
            <Spacing spacing="sm" type="margin" placement="left">
              <Button
                onClick={onChangeDropdownAction}
                placementIcon="left"
                icon={<ThreeDotsIcon />}
                style={{
                  width: 32,
                  height: 32,
                }}
              />
            </Spacing>
          </Dropdown>
        ) : null}
      </Row>
    </Row>
  );
};
