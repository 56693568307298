import styled, { css } from 'styled-components';

import { BoxShadowConfig, RadiusConfig, blue, neutral, neutralTrans } from 'thesis-ui';

import BgAuth from 'assets/images/bg-auth.png';

export const AuthContainer = styled.div`
  display: flex;
  background: #0f1a30;
  width: 100%;
  min-height: 100vh;
`;

export const AuthContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${BgAuth});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
`;

export const AuthHeader = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex: 1 1;
  max-height: 116px;
`;

export const AuthBody = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const AuthOutlet = styled.div`
  border-radius: ${RadiusConfig.xs}px;
  width: 362px;
  padding: 24px;
  background: ${neutral['neutral-1']};
  box-shadow: ${BoxShadowConfig['trans-6']};
  border: 1px solid ${neutralTrans['neutralTrans-10']};
`;

export const AuthFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1 1;
  max-height: 200px;
`;

export const AuthForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const TermAndPolicyDot = styled.div`
  background-color: ${neutral['neutral-1']};
  min-width: 3px;
  width: 3px;
  height: 3px;
  border-radius: ${RadiusConfig.max}px;
`;

export const TitleLink = styled.span<{
  color?: string;
}>`
  ${({ color }) => {
    const linkColor = color ?? blue['blue-6'];
    return css`
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      cursor: pointer;
      color: ${linkColor};
      margin-left: 4px;
      &:hover {
        text-decoration-line: underline;
      }
    `;
  }}
`;
