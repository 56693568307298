import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEdgesState, useNodesState } from 'reactflow';

import { CANVAS_WIDTH, MAIN_NODE_WIDTH, NODE_WIDTH } from 'modules/devices/styles/device-dashboard';

import { differenceInDays } from 'date-fns';
import { formatUTCDate } from 'helpers';
import { getResponseData } from 'helpers/response';
import { apiCall } from 'hooks/service/api';
import { compact, sortBy } from 'lodash';

import { type DeviceRecord } from '..';
import { deviceDetailSelector, useDeviceStore } from '../store';

export const mainNodeId = 'main';

export const useDeviceDashboardController = () => {
  const params = useParams();
  const device = useDeviceStore(deviceDetailSelector);

  const { id, deviceId } = params;

  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);

  const [records, setRecords] = useState<{ records: DeviceRecord[]; label: string }[]>([]);
  const [latestRecords, setLatestRecords] = useState<{ label: string; detectedAlive: boolean }[]>(
    [],
  );

  // HARD CODE FOR Lexikon TEAM ONLY
  const LexikonTeamId = 'be852435-e5ec-4313-89cf-225711e1fb31';
  let deviceLabels = device?.labels || [];
  if (device?.team_id === LexikonTeamId) {
    const availableLabels = ['dev 1', 'dev 2', 'dev 3'];
    deviceLabels = device.labels.filter((i) => availableLabels.includes(i.label.toLowerCase()));
  }
  // Sort Label
  deviceLabels = sortBy( deviceLabels, ['label']);

  useEffect(() => {
    if (!deviceId || !id || deviceLabels.length === 0) {
      return;
    }

    const getRecords = async () => {
      const result: { data: DeviceRecord[] } = await apiCall({
        url: `/api/teams/${id}/devices/${deviceId}/records`,
        data: {
          sort: 'created_at',
          order: 'asc',
          filter: JSON.stringify({
            data_type: 'text',
            data: { $regex: 'Detected', $options: 'i' },
            label: { $in: deviceLabels.map((el) => el.label) },
          }),
        },
        method: 'GET',
        isLoading: true,
        showError: true,
      });

      const responseData: DeviceRecord[] = getResponseData(result?.data);

      const newRecords = deviceLabels.map((label) => ({
        label: label.label,
        records: responseData.filter((rec) => rec.label === label.label),
      }));

      setRecords(newRecords);
    };

    const getLastRecords = async () => {
      const getLastRecordByLabelPromises = deviceLabels.map(async (label) => {
        const result: { data: DeviceRecord[] } = await apiCall({
          url: `/api/teams/${id}/devices/${deviceId}/records`,
          data: {
            sort: 'created_at',
            order: 'desc',
            page: 1,
            page_size: 1,
            filter: JSON.stringify({
              label: label.label,
            }),
          },
          method: 'GET',
          isLoading: true,
          showError: true,
        });

        const responseData: DeviceRecord[] = getResponseData(result?.data);

        return responseData[0];
      });

      const latestRecordsByLabel = compact(await Promise.all(getLastRecordByLabelPromises));

      setLatestRecords(
        latestRecordsByLabel.map((rec) => ({
          label: rec.label,
          detectedAlive: differenceInDays(Date.now(), new Date(rec.created_at).getTime()) === 0,
        })),
      );
    };

    getRecords();
    getLastRecords();
  }, [id, deviceId, device?.id]);

  useEffect(() => {
    if (!records.length) {
      return;
    }

    const totalWidth = records.length * NODE_WIDTH;

    const mainNodeX = (totalWidth - MAIN_NODE_WIDTH) / 2;

    const offsetX = totalWidth < CANVAS_WIDTH ? (CANVAS_WIDTH - totalWidth) / 2 : 0;

    const childNodes = records.map((rec, index) => ({
      id: rec.label || 'No label',
      type: 'childNode',
      data: {
        label: rec.label || 'No label',
        logs: rec.records.slice(-10).map(
          // Get 10 last records
          (el) =>
            formatUTCDate(el.created_at) +
            '/' +
            (el.data.toLowerCase().includes('not detected') ? 'Not detected' : ' Detected'), // Keep a space before Detected
        ),
        detected: latestRecords.some((el) => el.label === rec.label && el.detectedAlive),
      },
      position: { x: offsetX + index * 324, y: 300 },
    }));

    setNodes([
      {
        id: mainNodeId,
        type: 'mainNode',
        position: { x: offsetX + mainNodeX, y: 0 },
        data: { label: 'None', detected: childNodes.some((node) => node.data.detected) },
      },
      ...childNodes,
    ]);

    setEdges(
      records.map((rec, index) => {
        const detected = latestRecords.some((el) => el.label === rec.label && el.detectedAlive);

        return {
          id: `m-${index}`,
          source: mainNodeId,
          target: rec.label || 'No label',
          type: 'smoothstep',
          className: `edge-line`,
          selectable: false,
          selected: detected,
          animated: detected,
          focusable: false,
        };
      }),
    );
  }, [records.length, JSON.stringify(latestRecords)]);

  return {
    nodes,
    edges,
  };
};
