import { Typography } from 'thesis-ui';

import { Container } from './styles';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import type { FilterLabelProps } from './core/type';

import { useController } from './core';

export const FilterLabel: React.FC<FilterLabelProps> = ({ field, tableKey, title, value }) => {
  const { onClearFilter } = useController(tableKey, field);
  return (
    <Container align="center">
      <Typography variant="body-2" weight={500}>{`${title}: ${value}`}</Typography>
      <CloseIcon
        onClick={onClearFilter}
        style={{
          marginLeft: 2,
          cursor: 'pointer',
        }}
      />
    </Container>
  );
};
