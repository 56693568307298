import { Navigate, Route, Routes } from 'react-router-dom';

import { PATH } from 'constants/path';

import DeviceCommandLayout from './components/DeviceCommandLayout';
import { CommandActionLog, DeviceCommandCollection } from './pages';
import { DeviceCommandQueue } from './pages/DeviceCommandQueue';

export const DeviceCommandRoutes = () => (
  <Routes>
    <Route element={<DeviceCommandLayout />}>
      <Route path={PATH.TEAM_DEVICE_COMMAND_QUEUE} element={<DeviceCommandQueue />} />
      <Route path={PATH.TEAM_DEVICE_COMMAND_COLLECTION} element={<DeviceCommandCollection />} />
      <Route path={PATH.TEAM_DEVICE_COMMAND_ACTION_LOG} element={<CommandActionLog />} />
      <Route index element={<Navigate to={PATH.TEAM_DEVICE_COMMAND_QUEUE} />} />
    </Route>
  </Routes>
);
