import { Row, Typography, neutral } from 'thesis-ui';

import { Icon } from '../styles/drop-down-label';

import type { DropDownLabelProps } from '../core';

export const DropDownLabel: React.FC<DropDownLabelProps> = ({ icon, title }) => {
  return (
    <Row align="center">
      <Icon>{icon}</Icon>
      <Typography color={neutral['neutral-1']}>{title}</Typography>
    </Row>
  );
};
