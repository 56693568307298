import { Tag, green, purple } from 'thesis-ui';

import { type UserTier, UserTierName } from 'modules/auth/core';

export const UserTierCard: React.FC<{
  tier: UserTier;
}> = ({ tier }) => {
  const getTabStyle = () => {
    let color = green['green-5'];
    let backgroundColor = green['green-1'];

    switch (tier.name) {
      case UserTierName.BetaProUser:
        color = '#F7AC1B'; // To do design: Tag orange orange-6: #F7AC1B
        backgroundColor = '#f7ac1b26'; // To do design: Tag orange orange-1: #F7AC1B
        break;
      case UserTierName.BetaUser:
        color = purple['purple-6'];
        backgroundColor = '#8F00FF26'; // To do design: Tag purple purple-1: #8F00FF26
        break;
    }

    return {
      color,
      backgroundColor,
    };
  };

  const tierName = tier.name || UserTierName.FreeUser;

  return (
    <Tag
      color="orange"
      style={{
        ...getTabStyle(),
        marginRight: 8,
      }}
    >
      {tierName}
    </Tag>
  );
};
