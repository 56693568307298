import { Button, Checkbox, Row, Tooltip, Typography, neutral, primary } from 'thesis-ui';

import { type DeviceRecord } from 'modules/devices/core';

import { Body, Container, Header, TitleClearFilter } from '../styles/team-data-filter';

import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';

import { includes } from 'lodash';

import { useTeamDataFilterController } from '../core';

export const TeamDataFilter: React.FC<{
  charts: DeviceRecord[];
}> = ({ charts }) => {
  const { selectedDevice, onChangeCheckbox, onClear } = useTeamDataFilterController();

  return (
    <Tooltip
      bg="transparent"
      trigger="click"
      arrow={false}
      placement="bottom-end"
      content={
        <Container direction="column">
          <Header align="center" justify="space-between">
            <Typography color={neutral['neutral-1']} weight={600} variant="h5">
              Filter
            </Typography>
            <TitleClearFilter
              color={primary['primary-6']}
              weight={600}
              variant="body-2"
              onClick={onClear}
            >
              Clear filter
            </TitleClearFilter>
          </Header>
          <Body className="body" direction="column">
            {charts.map((chart, index) => {
              const checked = includes(selectedDevice, chart.device_id);
              const marginBottom = index + 1 === charts.length ? 0 : 8;
              return (
                <Checkbox
                  checked={checked}
                  onClick={onChangeCheckbox(chart.device_id, !checked)}
                  key={chart.id}
                  theme="dark"
                  style={{
                    padding: '2px 0px',
                    marginBottom: marginBottom,
                    textAlign: 'start',
                    alignItems: 'start',
                    justifyContent: 'start',
                    width: '100%',
                  }}
                >
                  {chart.device_name || chart.device_id}
                </Checkbox>
              );
            })}
          </Body>
        </Container>
      }
      offset={[7, 5]}
      allowHTML
      interactive
    >
      <Row>
        <Button
          placementIcon="left"
          size="small"
          icon={<FilterIcon />}
          style={{
            paddingLeft: 8,
            paddingRight: 12,
          }}
        >
          Filter
        </Button>
      </Row>
    </Tooltip>
  );
};
