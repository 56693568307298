export const PATH = Object.freeze({
  //Auth
  LOGIN: '/login',
  SIGN_UP: '/sign-up',
  INVITATION: '/invitation',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  CONFIRM_EMAIL: '/confirm-email',
  VERIFY: '/verify',
  PASSWORD_UPDATED: '/password-updated',
  PUBLIC_MODE: '/team/:teamId/device/:deviceId/public-mode',

  GENERALS: '/generals',
  MY_PROFILE: 'my-profile',
  PASSWORD: 'password',
  SETTINGS: 'settings',

  //Term and Policy
  TERMS_AND_CONDITIONS: '/about/terms-and-conditions',
  PRIVACY_POLICY: '/about/privacy-policy',

  //Dashboard
  DASHBOARD: '/dashboard',

  //Teams
  TEAMS: '/teams',
  MY_TEAMS: 'my-teams',
  SHARED_WITH_ME_TEAMS: 'shared-width-me-teams',
  ALL_TEAMS: 'all-teams',

  //Team
  TEAM: '/team/:id/*',
  TEAM_DASHBOARD: 'dashboard',
  TEAM_DATA: 'data',
  // Team Devices
  TEAM_DEVICES: 'devices',
  TEAM_DEVICE_ADD: 'add',
  TEAM_DEVICE_DETAIL: ':deviceId',
  TEAM_DEVICE_DATA: 'data',
  TEAM_DEVICE_API_KEY: 'api-key',
  TEAM_DEVICE_COMMAND: 'command',
  TEAM_DEVICE_COMMAND_QUEUE: 'queue',
  TEAM_DEVICE_COMMAND_COLLECTION: 'collection',
  TEAM_DEVICE_COMMAND_ACTION_LOG: 'action-log',
  TEAM_DEVICE_DASHBOARD: 'dashboard',

  TEAM_DEVICE_INFORMATION: 'information',
  TEAM_DEVICE_PUBLIC: 'public',

  TEAM_ACTION_LOG: 'action-log',

  //User
  USER: '/user',

  //Error
  ERROR_404: '/error/404',
  ERROR_403: '/error/403',
});

export const ListAuthPath = [PATH.LOGIN, PATH.SIGN_UP, PATH.FORGOT_PASSWORD, PATH.RESET_PASSWORD];
export const ListTermAndPolicyPath = [PATH.LOGIN];
