import { Outlet } from 'react-router-dom';

import { ConfirmModal } from 'components';
import { GoToBack } from 'components/go-to-back';
import { Dropdown, type DropdownMenuItem, Row, Spacing, Tabs, Tag, blue } from 'thesis-ui';

import { Body, Container, StylesTab } from '../styles/device-detail-layout';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down-small.svg';

import { getBgButtonByTeam } from 'helpers';
import { isEmpty } from 'lodash';

import { MODAL_KEY } from 'constants/modal-key';

import { getDeviceTabs, useDeviceDetailLayoutController } from '../core';
import { DeviceState, DeviceStatus } from '../core/enum';
import { stateMenu, stateMenuInActive, statusMenu } from '../core/select';
import { PublicModeTag } from './PublicModeTag';

export const DeviceDetailLayout = () => {
  const {
    team,
    device,
    locationPath,
    visible,
    isDevicePublicModePath,
    isCustomPaddingPage,
    hasDevicePermission,
    onTabClick,
    onRedirectDevicePage,
    onChangeVisible,
    onChangeMenu,
    onSubmitChangeStatusToInActive,
  } = useDeviceDetailLayoutController();

  const tabs = device
    ? getDeviceTabs(
        device.team_id,
        device.id,
        hasDevicePermission,
        device.state,
        team?.enabled_device_dashboard,
      )
    : [];

  const bodyStyle = isDevicePublicModePath
    ? {}
    : {
        padding: isCustomPaddingPage ? '16px 24px 24px 24px' : '24px',
      };

  const borderRadiusTab = isDevicePublicModePath && !hasDevicePermission ? 6 : 0;

  return (
    <Container direction="column">
      <Spacing spacing="xl" type="margin" placement="bottom">
        <Row align="center">
          <GoToBack title={device?.alias ?? ''} onClick={onRedirectDevicePage} />
          <Spacing spacing="md" type="margin" placement="left">
            <Dropdown
              theme="dark"
              dropdownConfig={{
                offset: [10, 5],
                visible: visible.status,
                onClickOutside: onChangeVisible('status', !visible.status),
              }}
              dropdownMenuStyle={{
                minWidth: 120,
              }}
              menu={{
                items: statusMenu,
                selectedKeys: [],
                onClick: (item: DropdownMenuItem) => onChangeMenu('status', item),
              }}
            >
              <Tag
                onClick={onChangeVisible('status', !visible.status)}
                style={{
                  cursor: 'pointer',
                }}
                dot
                iconRight={
                  hasDevicePermission ? (
                    <ArrowDownIcon
                      style={{
                        marginLeft: 2,
                      }}
                    />
                  ) : null
                }
                color={device?.status === DeviceStatus.Active ? 'green' : 'red'}
              >
                {DeviceStatus[device?.status ?? DeviceStatus.Active]}
              </Tag>
            </Dropdown>
          </Spacing>
          <Spacing spacing="xs" type="margin" placement="left">
            <Dropdown
              theme="dark"
              dropdownConfig={{
                offset: [10, 5],
                visible: visible.state,
                onClickOutside: onChangeVisible('state', !visible.state),
              }}
              dropdownMenuStyle={{
                minWidth: 368,
              }}
              menu={{
                items: device?.status === DeviceStatus.Inactive ? stateMenuInActive : stateMenu,
                selectedKeys: [],
                onClick: (item: DropdownMenuItem) => onChangeMenu('state', item),
              }}
            >
              <PublicModeTag
                onClick={onChangeVisible('state', !visible.state)}
                state={device?.state ?? DeviceState.Private}
                iconRight={
                  hasDevicePermission ? (
                    <ArrowDownIcon
                      style={{
                        marginLeft: 2,
                      }}
                    />
                  ) : null
                }
              />
            </Dropdown>
          </Spacing>
        </Row>
      </Spacing>
      <Body isPublic={isDevicePublicModePath}>
        <StylesTab
          isPublic={isDevicePublicModePath}
          style={{
            borderBottomLeftRadius: borderRadiusTab,
            borderBottomRightRadius: borderRadiusTab,
          }}
        >
          {!isEmpty(tabs) && ( // To-do-implement design: Check empty items tabs
            <Tabs
              variant="text"
              items={tabs}
              activeKey={locationPath}
              onTabClick={onTabClick}
              tabItemStyle={{
                height: 52,
              }}
            />
          )}
        </StylesTab>
        <Row style={bodyStyle}>
          <Outlet />
        </Row>
      </Body>
      <ConfirmModal
        modalKey={MODAL_KEY.CHANGE_STATUS_TO_INACTIVE}
        overlay={{
          show: true,
          disabled: false,
          color: team?.colour ?? blue['blue-5'],
        }}
        submitButton={{
          buttonProps: {
            onClick: onSubmitChangeStatusToInActive,
            style: {
              ...getBgButtonByTeam(false, team?.colour),
              width: 178,
              position: 'relative',
            },
          },
          label: 'Change',
        }}
      />
    </Container>
  );
};
