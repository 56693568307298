import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { LOCAL_STORAGE_COUNT_DOWN_TIME } from 'constants/local-storage';

interface State {
  timer: Record<string, number>;
}

interface Store {
  state: State;
  updateTimer: (payload: { key: string; value: number }) => void;
  clearTimer: (key: string) => void;
}

const initState: State = {
  timer: {},
};

export const useCountDownTimeStore = create(
  persist(
    immer<Store>((set) => ({
      state: initState,
      updateTimer: (payload) => {
        set((store) => {
          store.state.timer[payload.key] = payload.value;
        });
      },
      clearTimer: (key: string) => {
        set((store) => {
          delete store.state.timer[key];
        });
      },
    })),
    {
      name: LOCAL_STORAGE_COUNT_DOWN_TIME,
    },
  ),
);

export const { updateTimer, clearTimer } = useCountDownTimeStore.getState();

export const timerSelector = (s: Store) => s.state.timer;
