import { Checkbox, Row, Typography } from 'thesis-ui';

import { TitleLink } from '../styles';

import { usePageGoTo } from 'hooks';

import { PATH } from 'constants/path';

import type { TeamAndPolicyLabelProps } from '../core';

export const TeamAndPolicyLabel: React.FC<TeamAndPolicyLabelProps> = ({ isTermAndPolicy }) => {
  return (
    <Row align="center">
      <Checkbox
        checked={isTermAndPolicy.value}
        onClick={() => isTermAndPolicy.setValue(!isTermAndPolicy.value)}
        style={{
          alignItems: 'start',
          padding: 0,
        }}
      >
        <Typography
          style={{
            textAlign: 'start',
          }}
        >
          By clicking Sign Up, I agree with Tracelium Connect's{' '}
          <TitleLink
            onClick={usePageGoTo(PATH.TERMS_AND_CONDITIONS)}
            style={{
              margin: 0,
            }}
          >
            Terms and Conditions
          </TitleLink>{' '}
          and{' '}
          <TitleLink
            onClick={usePageGoTo(PATH.PRIVACY_POLICY)}
            style={{
              margin: 0,
            }}
          >
            Privacy Policy
          </TitleLink>
          .
        </Typography>
      </Checkbox>
    </Row>
  );
};
