import styled, { css } from 'styled-components';

import { RadiusConfig, blue } from 'thesis-ui';

import { getImageUrl } from 'helpers';

import type { MenuItemLabelProps } from '../core';

export const Logo = styled.div<Pick<MenuItemLabelProps, 'logo'>>`
  ${({ logo }) => {
    return css`
      width: 20px;
      min-width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-image: url(${getImageUrl(logo)});
      border-radius: ${RadiusConfig.xxs}px;
    `;
  }}
`;

export const Name = styled.div<Pick<MenuItemLabelProps, 'colour'>>`
  ${({ colour = blue['blue-5'] }) => {
    return css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      min-width: 20px;
      height: 20px;
      background-color: ${colour};
      border-radius: ${RadiusConfig.xxs}px;
    `;
  }}
`;
