import styled from 'styled-components';

import { RadiusConfig, Row, neutral, neutralTrans } from 'thesis-ui';

export const Container = styled(Row)``;

export const Body = styled(Row)`
  border-radius: ${RadiusConfig.xs}px;
  background-color: ${neutral['neutral-1']};
  border: 1px solid ${neutralTrans['neutralTrans-15']};
  padding: 24px;
  width: 100%;
`;

export const Content = styled.div`
  max-width: 640px;
  min-width: 640px;
  display: flex;
  flex-direction: column;
`;
