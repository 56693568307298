import { Typography } from 'thesis-ui';

import { ContainerStorageLimitLabel } from '../styles/dashboard';

import type { StorageLimitLabelProps } from '../core/type';

export const StorageLimitLabel: React.FC<StorageLimitLabelProps> = ({ storageLimit }) => {
  return (
    <ContainerStorageLimitLabel align="center">
      <Typography variant="body-2" weight={500}>
        Total storage: {storageLimit || '0KB'}
      </Typography>
    </ContainerStorageLimitLabel>
  );
};
