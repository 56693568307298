import type { DropdownMenuItem } from 'thesis-ui';

import { DropDownLabel } from 'modules/team';

import { ReactComponent as AddQueueIcon } from 'assets/icons/add-queue.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as NoteIcon } from 'assets/icons/note.svg';

import { DeviceCommandKey } from './enum';

export const tableQueueActions: DropdownMenuItem[] = [
  {
    key: DeviceCommandKey.Edit,
    label: <DropDownLabel title="Edit command name and note" icon={<EditIcon />} />,
  },
  {
    key: DeviceCommandKey.ViewNode,
    label: <DropDownLabel title="View note" icon={<NoteIcon />} />,
  },
  {
    key: DeviceCommandKey.Delete,
    label: <DropDownLabel title="Delete command" icon={<DeleteIcon />} />,
  },
];

export const tableCollectionActions: DropdownMenuItem[] = [
  {
    key: DeviceCommandKey.AddToQueue,
    label: <DropDownLabel title="Add to queue" icon={<AddQueueIcon />} />,
  },
  {
    key: DeviceCommandKey.Edit,
    label: <DropDownLabel title="Edit" icon={<EditIcon />} />,
  },
  {
    key: DeviceCommandKey.Delete,
    label: <DropDownLabel title="Delete" icon={<DeleteIcon />} />,
  },
];
