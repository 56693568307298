import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useFormik } from 'formik';
import {
  type DropdownMenuItem,
  type QueryState,
  closeModal,
  initialQueryStateTable,
  openModal,
  showToastMessage,
  updateQueryStateTable,
  useTableStore,
} from 'thesis-ui';

import { teamDetailSelector, useTeamStore } from 'modules/team';

import { type ConfirmModalData } from 'components/modal/core/type';

import { getDeviceDetailPath, getTableKeyById, getTeamPath, trimObject } from 'helpers';
import { getDevicePermission } from 'helpers/permission';
import { useBoolean, useNumber, useString } from 'hooks';
import { apiCall } from 'hooks/service/api';
import { useClearTableQueryStateOnUnmount } from 'hooks/table';
import { cloneDeep, isEmpty, isEqual, remove } from 'lodash';

import { ERROR_MESSAGE, SUCCESS_MESSAGE } from 'constants/message';
import { MODAL_KEY } from 'constants/modal-key';
import { PATH } from 'constants/path';
import { TABLE_KEY } from 'constants/table';
import { type Pagination } from 'types/api';

import { type DeviceDetail, type DeviceVisibleKey, getDeviceFilter } from '..';
import { defaultDeviceFormRequest } from '../constant';
import { DeviceActionKey, DeviceState, DeviceStatus, DeviceWithUsage } from '../enum';
import { deviceFormSchema } from '../schema';
import { deviceDetailSelector, updateDeviceDetail, useDeviceStore } from '../store';

export const useDevicesController = () => {
  const navigationTo = useNavigate();
  const { id } = useParams();
  const deviceTableKey = getTableKeyById(TABLE_KEY.DEVICES, id);
  useClearTableQueryStateOnUnmount(deviceTableKey);

  const team = useTeamStore(teamDetailSelector);
  const queryState = useTableStore((s) => s.state.queryState[deviceTableKey]);
  const [devices, setDevices] = useState<DeviceDetail[]>([]);
  const total = useNumber(0);
  const isLoading = useBoolean(true);
  const deviceId = useString('');

  useEffect(() => {
    if (queryState) {
      if (team?.id) {
        getDevices();
      }
      return;
    }
    if (team?.id) {
      initialQueryStateTable(deviceTableKey, {
        page: 1,
        page_size: 10,
      });
    }
  }, [queryState, team]);

  const getDevices = async () => {
    isLoading.setValue(true);

    const result: {
      data: DeviceDetail[];
      pagination: Pagination;
    } = await apiCall({
      url: `/api/teams/${team?.id}/devices`,
      data: {
        ...queryState,
        with_usage: DeviceWithUsage.Active,
      },
      method: 'GET',
      isLoading: false,
      showError: true,
    });

    if (result?.data) {
      setDevices(result?.data ?? []);
    }
    if (result?.pagination) {
      total.setValue(result.pagination.total);
    }
    isLoading.setValue(false);
  };

  const onClickAddDevice = () => {
    navigationTo(`${getTeamPath(team?.id ?? '', PATH.TEAM_DEVICES)}/${PATH.TEAM_DEVICE_ADD}`);
  };

  const onClickMenuItem = (item: DropdownMenuItem, idDevice: string) => {
    if (item.key === DeviceActionKey.Edit && team) {
      navigationTo(getDeviceDetailPath(PATH.TEAM_DEVICE_INFORMATION, team.id, idDevice));
      return;
    }
    deviceId.setValue(idDevice);
    openModal(MODAL_KEY.DEVICE_DELETE, {
      header: `Delete device`,
      label: `All the device data will also be deleted. This action can not undo.`,
    } as ConfirmModalData);
  };

  const onSubmitDeleteDevice = async () => {
    if (!team?.id || !deviceId.value) {
      showToastMessage('error', ERROR_MESSAGE.DELETE_TEAM);
      return;
    }
    const result = await apiCall<any, DeviceDetail>({
      url: `/api/teams/${team?.id}/devices/${deviceId.value}`,
      method: 'DELETE',
      isLoading: true,
      showError: true,
    });

    if (result?.id && team?.id) {
      //To-do-contribute design: Add pagination helper to handle page index when deleting item
      if (queryState && devices.length <= 1 && queryState.page > 1) {
        updateQueryStateTable(deviceTableKey, { ...queryState, page: queryState.page - 1 });
      } else {
        getDevices();
      }
      closeModal(MODAL_KEY.DEVICE_DELETE);
      showToastMessage('success', SUCCESS_MESSAGE.DELETE_DEVICE);
      deviceId.setValue('');
    }
  };

  const onClickRow = (row: any) => {
    const original: DeviceDetail = row?.original;
    if (original?.id && team) {
      navigationTo(
        `${getDeviceDetailPath(
          team.enabled_device_dashboard ? PATH.TEAM_DEVICE_DASHBOARD : PATH.TEAM_DEVICE_DATA,
          team.id,
          original.id,
        )}`,
      );
    }
  };

  const updateQueryState = (key: string, newState: QueryState) => {
    updateQueryStateTable(key, newState);
  };

  const isFilter = Boolean(queryState?.filter);
  const devicePermission = getDevicePermission(team?.role);

  return {
    devicePermission,
    deviceTableKey,
    team,
    queryState,
    devices,
    total,
    isLoading,
    isFilter,
    onClickAddDevice,
    onClickMenuItem,
    onSubmitDeleteDevice,
    onClickRow,
    updateQueryState,
  };
};

export const useDeviceAddController = () => {
  const navigationTo = useNavigate();
  const team = useTeamStore(teamDetailSelector);

  const formik = useFormik({
    initialValues: defaultDeviceFormRequest,
    validationSchema: deviceFormSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      if (!team) {
        return;
      }
      const result: DeviceDetail = await apiCall({
        url: `/api/teams/${team?.id}/devices`,
        data: trimObject(values),
        method: 'POST',
        isLoading: true,
        showError: true,
      });
      if (result?.id) {
        showToastMessage('success', SUCCESS_MESSAGE.ADD_DEVICE);
        navigationTo(getDeviceDetailPath(PATH.TEAM_DEVICE_INFORMATION, team?.id, result?.id));
      }
    },
  });

  const onRedirectDevicePage = () => {
    navigationTo(getTeamPath(team?.id ?? '', PATH.TEAM_DEVICES));
  };

  const { values, isSubmitting, isValid, handleSubmit, handleChange } = formik;
  const { alias, custom_id, description, location } = values;
  const disabled = !alias || isSubmitting || !isValid;

  return {
    team,
    alias,
    custom_id,
    description,
    location,
    disabled,
    handleSubmit,
    handleChange,
    onRedirectDevicePage,
  };
};

export const useDeviceDetailLayoutController = () => {
  const params = useParams();
  const { deviceId } = params;

  const location = useLocation();
  const navigationTo = useNavigate();
  const team = useTeamStore(teamDetailSelector);
  const device = useDeviceStore(deviceDetailSelector);
  const [visible, setVisible] = useState({
    status: false,
    state: false,
  });

  useEffect(() => {
    if (deviceId && team?.id) {
      getDeviceDetail(deviceId, team?.id);
    }
  }, [deviceId, team]);

  const getDeviceDetail = async (id: string, teamId: string) => {
    const result: DeviceDetail = await apiCall({
      url: `/api/teams/${teamId}/devices/${id}`,
      data: {
        with_label: 1,
      },
      method: 'GET',
      isLoading: true,
      showError: true,
    });
    if (result?.id) {
      updateDeviceDetail(result);
    }
  };

  const onTabClick = (key: string) => {
    navigationTo(key);
  };

  const onRedirectDevicePage = () => {
    navigationTo(getTeamPath(team?.id ?? '', PATH.TEAM_DEVICES));
    updateDeviceDetail(null);
  };

  const onChangeVisible = (key: DeviceVisibleKey, value: boolean) => () => {
    if (!hasDevicePermission) {
      return;
    }
    setVisible({
      ...visible,
      [key]: value,
    });
  };

  const onChangeMenu = async (key: DeviceVisibleKey, value: DropdownMenuItem) => {
    setVisible({
      state: false,
      status: false,
    });
    if (!value.key) {
      return;
    }
    if (
      (key === 'status' && Number(value.key) === device?.status) ||
      (key === 'state' && Number(value.key) === device?.state)
    ) {
      return;
    }
    if (
      key === 'status' &&
      device?.status == DeviceStatus.Active &&
      device?.state === DeviceState.Public
    ) {
      openModal(MODAL_KEY.CHANGE_STATUS_TO_INACTIVE, {
        header: `Change status to Inactive`,
        label: `Changing status to Inactive will also change the public mode to Private. People with the public link to the device will no longer can access it.`,
      } as ConfirmModalData);

      return;
    }

    const result: DeviceDetail = await apiCall({
      url: `/api/teams/${team?.id}/devices/${device?.id}`,
      data: {
        [key]: Number(value?.key),
      },
      method: 'PATCH',
      isLoading: true,
      showError: true,
    });
    if (result?.id) {
      showToastMessage(
        'success',
        key === 'status'
          ? SUCCESS_MESSAGE.CHANGE_STATUS_DEVICE
          : SUCCESS_MESSAGE.CHANGE_PUBLIC_DEVICE,
      );
      updateDeviceDetail({
        ...device,
        ...result,
      });
    }
  };

  const onSubmitChangeStatusToInActive = async () => {
    const result: DeviceDetail = await apiCall({
      url: `/api/teams/${team?.id}/devices/${device?.id}`,
      data: {
        status: DeviceStatus.Inactive,
        state: DeviceState.Private,
      },
      method: 'PATCH',
      isLoading: true,
      showError: true,
    });
    if (result?.id) {
      showToastMessage('success', SUCCESS_MESSAGE.CHANGE_STATUS_DEVICE);
      closeModal(MODAL_KEY.CHANGE_STATUS_TO_INACTIVE);
      updateDeviceDetail({
        ...device,
        ...result,
      });
    }
  };

  const isDeviceDataPath = location.pathname.includes(PATH.TEAM_DEVICE_DATA);
  const isDevicePublicModePath = location.pathname.includes(PATH.TEAM_DEVICE_PUBLIC);
  const isCustomPaddingPage = [PATH.TEAM_DEVICE_DATA, PATH.TEAM_DEVICE_COMMAND].some((path) =>
    location.pathname.includes(path),
  );
  const hasDevicePermission = getDevicePermission(team?.role);

  return {
    team,
    device,
    locationPath: location.pathname,
    visible,
    isDeviceDataPath,
    isDevicePublicModePath,
    isCustomPaddingPage,
    hasDevicePermission,
    onTabClick,
    onRedirectDevicePage,
    onChangeVisible,
    onChangeMenu,
    onSubmitChangeStatusToInActive,
  };
};

export const useDeviceInformationController = () => {
  const team = useTeamStore(teamDetailSelector);
  const device = useDeviceStore(deviceDetailSelector);

  const formik = useFormik({
    initialValues: defaultDeviceFormRequest,
    validationSchema: deviceFormSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const result: DeviceDetail = await apiCall({
        url: `/api/teams/${team?.id}/devices/${device?.id}`,
        data: trimObject(values),
        method: 'PATCH',
        isLoading: true,
        showError: true,
      });
      if (result?.id) {
        showToastMessage('success', SUCCESS_MESSAGE.EDIT_DEVICE);
        updateDeviceDetail({
          ...device,
          ...result,
        });
      }
    },
  });
  const { values, isSubmitting, isValid, handleSubmit, handleChange, setValues } = formik;

  useEffect(() => {
    if (device) {
      setValues({
        custom_id: device.custom_id,
        alias: device.alias,
        description: device.description,
        location: device.location,
      });
    }
  }, [device]);

  const onCancelChanges = () => {
    if (!device) {
      return;
    }
    setValues({
      custom_id: device.custom_id,
      alias: device.alias,
      description: device.description,
      location: device.location,
    });
  };

  const { alias, custom_id, description, location } = values;

  const isChange = !isEqual(values, {
    custom_id: device?.custom_id,
    alias: device?.alias,
    description: device?.description,
    location: device?.location,
  });

  const disabled = !alias || isSubmitting || !isValid || !isChange;
  const hasDevicePermission = getDevicePermission(team?.role);

  return {
    team,
    device,
    alias,
    description,
    location,
    custom_id,
    disabled,
    isChange,
    hasDevicePermission,
    handleSubmit,
    handleChange,
    onCancelChanges,
  };
};

export const useDeviceFilterController = () => {
  const team = useTeamStore(teamDetailSelector);
  const deviceTableKey = getTableKeyById(TABLE_KEY.DEVICES, team?.id);
  const queryState = useTableStore((s) => s.state.queryState[deviceTableKey]);
  const parserFilter = queryState?.filter ? JSON.parse(queryState.filter) : {};

  const onChangeCheckbox = (key: DeviceVisibleKey, value: number, checked: boolean) => () => {
    const newFilter = cloneDeep(parserFilter);
    const newQueryState = cloneDeep(queryState);
    newFilter[key] = newFilter[key]?.length ? newFilter[key] : [];
    if (checked) {
      newFilter[key].push(value);
    } else {
      remove(newFilter[key], (el) => el === value);
    }
    if (!newFilter[key]?.length) {
      delete newFilter[key];
    }
    newQueryState.filter = isEmpty(newFilter) ? undefined : JSON.stringify(newFilter);
    newQueryState.page = 1;

    updateQueryStateTable(deviceTableKey, newQueryState);
  };

  const onClear = () => {
    const newQueryState = cloneDeep(queryState);
    newQueryState.filter = undefined;
    newQueryState.page = 1;
    updateQueryStateTable(deviceTableKey, newQueryState);
  };

  const { listStatus, listState } = getDeviceFilter(parserFilter);

  return { listStatus, listState, deviceTableKey, onChangeCheckbox, onClear };
};
