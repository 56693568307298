import { Button, Typography } from 'thesis-ui';

import { Container } from './styles';

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';

import type { GoToBackProps } from './core/type';

export const GoToBack: React.FC<GoToBackProps> = ({ onClick, title }) => {
  return (
    <Container align="center">
      <Button
        onClick={onClick}
        placementIcon="left"
        size="small"
        variant="text"
        style={{
          minWidth: 32,
        }}
        icon={<ArrowLeftIcon />}
      />
      <Typography
        variant="h4"
        weight={600}
        style={{
          textAlign: 'start',
        }}
      >
        {title}
      </Typography>
    </Container>
  );
};
