import styled from 'styled-components';

import { RadiusConfig, Row, neutral, neutralTrans } from 'thesis-ui';

export const SecureTokenContainer = styled(Row)`
  flex-grow: 1;
  margin-right: 64px;
  max-width: 640px;
`;

export const SecureTokenCard = styled(Row)`
  border-radius: ${RadiusConfig.xxs}px;
  border: 1px solid ${neutralTrans['neutralTrans-15']};
  background: ${neutral['neutral-2']};
  padding: 10px 12px;
  height: 52px;
  flex-grow: 1;
`;
