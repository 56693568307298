import styled from 'styled-components';

import { Grid, RadiusConfig, Row, neutral, neutralTrans } from 'thesis-ui';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
`;

export const Header = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${neutral['neutral-11']};
`;

export const Body = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  background-color: ${neutral['neutral-3']};
  height: calc(100vh - 60px);
  overflow: auto;
  padding: 24px 24px 80px 24px;
`;

export const Content = styled(Row)`
  border-radius: ${RadiusConfig.xs}px;
  border: 1px solid ${neutralTrans['neutralTrans-15']};
  background: ${neutral['neutral-1']};
`;

export const ContentHeader = styled.div`
  min-height: 52px;
  border-bottom: 1px solid ${neutralTrans['neutralTrans-15']};
  padding: 0px 12px;
  width: 100%;
`;

export const BodyChart = styled(Grid)`
  .form-chart {
    .label {
      margin-top: 0px !important;
      p {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        color: ${neutral['neutral-11']};
      }
      svg {
        min-width: 16px;
        height: 16px;
        path[stroke] {
          stroke: ${neutral['neutral-11']};
        }
        stroke-width: 1.5;
      }
    }
  }
`;

export const PublicModeInformationBody = styled(Row)`
  border-top: 1px solid ${neutralTrans['neutralTrans-15']};
  padding: 24px;
`;
