import styled from 'styled-components';

import { Grid, RadiusConfig, Row, neutral } from 'thesis-ui';

export const Container = styled(Row)``;

export const Header = styled(Row)`
  min-height: 32px;
  margin-bottom: 24px;
`;

export const Body = styled(Grid)`
  .device-chart-item {
    background-color: ${neutral['neutral-2']};
    border-radius: ${RadiusConfig.xs}px;
  }
  .form-chart {
    border-bottom: none;
    border-bottom-left-radius: ${RadiusConfig.none};
    border-bottom-right-radius: ${RadiusConfig.none};
    .label {
      margin-top: 0px !important;
      p {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        color: ${neutral['neutral-11']};
      }
      svg {
        min-width: 16px;
        height: 16px;
        path[stroke] {
          stroke: ${neutral['neutral-11']};
        }
        stroke-width: 1.5;
      }
    }
  }
`;
