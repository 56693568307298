import { Spacing, Typography } from 'thesis-ui';

import { Container } from '../styles/empty-data';

import { ReactComponent as EmptyState } from 'assets/images/empty-queue.svg';

import { type EmptyDataCommandQueueProps } from '../core';
import { ButtonAddCommand } from './ButtonAddCommand';

export const EmptyData: React.FC<EmptyDataCommandQueueProps> = ({
  title,
  permission,
  button,
  image,
}) => {
  const renderBody = () => {
    if (permission) {
      return button ?? <ButtonAddCommand disabled={false} />;
    }
    return null;
  };

  return (
    <Container
      direction="column"
      align="center"
      justify="center"
      style={{
        height: 400,
      }}
    >
      {image ?? <EmptyState />}
      <Spacing spacing="xl" placement={['top', 'bottom']} type="margin">
        <Typography>{title}</Typography>
      </Spacing>
      {renderBody()}
    </Container>
  );
};
