import { ActionLogList } from 'components/action-log/ActionLogList';
import { Row } from 'thesis-ui';

import { useCommandActionLogController } from '../core';

export const CommandActionLog = () => {
  const { logs, onLoadMore, isLoadMore, colorTeam } = useCommandActionLogController();

  return (
    <Row justify="center">
      <ActionLogList
        logs={logs}
        onLoadMore={onLoadMore}
        isLoadMore={isLoadMore.value}
        colorTeam={colorTeam}
      />
    </Row>
  );
};
