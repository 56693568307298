import { Outlet } from 'react-router-dom';

import { RadiusConfig, Row, Spacing, neutral } from 'thesis-ui';

import {
  AuthBody,
  AuthContainer,
  AuthContent,
  AuthFooter,
  AuthHeader,
  AuthOutlet,
  TermAndPolicyDot,
  TitleLink,
} from '../styles';

import { ReactComponent as Logo } from 'assets/images/logo.svg';

import { usePageGoTo } from 'hooks';

import { PATH } from 'constants/path';

import { useAuthLayoutController } from '../core';

const AuthLayout = () => {
  const { showTermAndPolicy } = useAuthLayoutController();

  return (
    <AuthContainer>
      <AuthContent>
        <AuthHeader>
          <Spacing spacing="6xl" type="margin" placement="top">
            <Logo />
          </Spacing>
        </AuthHeader>
        <AuthBody>
          <AuthOutlet>
            <Outlet />
          </AuthOutlet>
          {showTermAndPolicy && (
            <Spacing
              spacing="xs"
              placement="top"
              type="margin"
              style={{
                width: '100%',
                padding: '12px 16px',
                borderRadius: RadiusConfig.xs,
                backgroundColor: '#0F1A3080',
                backdropFilter: 'blur(4px)',
              }}
            >
              <Row align="center" justify="center">
                <TitleLink
                  onClick={usePageGoTo(PATH.TERMS_AND_CONDITIONS)}
                  color={neutral['neutral-1']}
                  style={{ margin: 0 }}
                >
                  Terms and Conditions
                </TitleLink>
                <Spacing spacing="sm" placement={['left', 'right']} type="margin">
                  <TermAndPolicyDot />
                </Spacing>
                <TitleLink color={neutral['neutral-1']} onClick={usePageGoTo(PATH.PRIVACY_POLICY)}>
                  Privacy Policy
                </TitleLink>
              </Row>
            </Spacing>
          )}
        </AuthBody>
        <AuthFooter />
      </AuthContent>
    </AuthContainer>
  );
};
export { AuthLayout };
