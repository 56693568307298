import styled from 'styled-components';

import { BoxShadowConfig, RadiusConfig, Row, neutral, neutralTrans } from 'thesis-ui';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${RadiusConfig.xs}px;
  background-color: ${neutral['neutral-1']};
  border: 1px solid ${neutralTrans['neutralTrans-15']};
  width: 312px;
  box-shadow: ${BoxShadowConfig['trans-6']};
  cursor: default;
`;

export const UserAction = styled(Row)`
  border-width: 1px 0px 1px 0px;
  border-color: ${neutralTrans['neutralTrans-15']};
  border-style: solid;
  padding: 8px !important;
`;

export const Version = styled.div`
  padding: 8px 16px;
`;

export const UserActionItem = styled(Row)`
  padding-left: 6px !important;
  padding-right: 8px !important;
  height: 36px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${neutralTrans['neutralTrans-6']};
  }
`;
