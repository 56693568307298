import styled from 'styled-components';

import { neutral, neutralTrans } from 'thesis-ui';

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const LoginForgotPasswordTitle = styled.span`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: ${neutral['neutral-7']};
  cursor: pointer;
  &:hover {
    color: ${neutral['neutral-11']};
  }
`;

export const Line = styled.div`
  width: 105px;
  height: 1px;
  background-color: ${neutralTrans['neutralTrans-10']};
`;

export const Logo = styled.img`
  margin-right: 10px;
`;
