import { useEffect } from 'react';

import { type DropdownMenuItem, showToastMessage } from 'thesis-ui';

import {
  type UserProfile,
  updateUserProfile,
  useAuthStore,
  userProfileSelector,
} from 'modules/auth/core';

import { useBoolean, useString } from 'hooks';
import { apiCall } from 'hooks/service/api';

import { ERROR_MESSAGE, SUCCESS_MESSAGE } from 'constants/message';

import { getCurrentTimezone } from '../helper';

export const useSettingController = () => {
  const userProfile = useAuthStore(userProfileSelector);
  const openSelectTimezone = useBoolean(false);
  const selectedTimezone = useString('');
  const disable = useBoolean(true);

  useEffect(() => {
    selectedTimezone.setValue(getCurrentTimezone(userProfile?.timezone));
  }, [userProfile?.id]);

  useEffect(() => {
    if (!userProfile?.timezone || !selectedTimezone.value) {
      return;
    }
    disable.setValue(userProfile?.timezone === selectedTimezone.value);
  }, [selectedTimezone.value]);

  const onSelectTimezone = (newItem: DropdownMenuItem) => {
    openSelectTimezone.setValue(false);
    if (newItem) {
      selectedTimezone.setValue(newItem.key);
    }
  };

  const onTimezoneCancel = () => {
    selectedTimezone.setValue(getCurrentTimezone(userProfile?.timezone));
  };

  const onTimezoneSave = async () => {
    if (!selectedTimezone.value) {
      showToastMessage('error', ERROR_MESSAGE.UPDATE_SETTING);
      return;
    }
    const result: UserProfile = await apiCall({
      url: `/api/auth/me`,
      data: {
        timezone: selectedTimezone.value,
      },
      method: 'PATCH',
      isLoading: true,
      showError: true,
    });
    if (result?.id) {
      updateUserProfile({
        ...userProfile,
        timezone: result.timezone,
      });
    }
    showToastMessage('success', SUCCESS_MESSAGE.UPDATE_SETTING);
    disable.setValue(true);
  };

  return {
    selectedTimezone,
    openSelectTimezone,
    disable: disable.value,
    onSelectTimezone,
    onTimezoneCancel,
    onTimezoneSave,
  };
};
