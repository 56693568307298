import { useState } from 'react';

import type { ActionLogDetail } from './type';

import { useBoolean, useNumber } from 'hooks';
import { apiCall } from 'hooks/service/api';
import { cloneDeep } from 'lodash';

import type { Pagination } from 'types/api';

export const useActionLogController = (teamId: string, filter?: string) => {
  const [logs, setLogs] = useState<ActionLogDetail[]>([]);
  const page = useNumber(1);
  const isLoadMore = useBoolean(false);

  const getActionLog = async (newPage?: number) => {
    const result: {
      data: ActionLogDetail[];
      pagination: Pagination;
    } = await apiCall({
      url: `/api/teams/${teamId}/logs`,
      data: {
        order: 'desc',
        page: newPage ?? page.value,
        page_size: 30,
        sort: 'created_at',
        filter: filter,
      },
      method: 'GET',
      isLoading: true,
      showError: true,
    });
    if (result?.data) {
      isLoadMore.setValue(result.data.length === 30); //page size 30
      if (newPage) {
        let newDataLogs = cloneDeep(logs);
        newDataLogs = newDataLogs.concat(result?.data);
        setLogs(newDataLogs);
        return;
      }
      setLogs(result?.data ?? []);
    }
  };

  const onLoadMore = () => {
    let newPage = cloneDeep(page.value);
    newPage += 1;
    page.setValue(newPage);
    getActionLog(newPage);
  };

  return {
    logs,
    page,
    isLoadMore,
    getActionLog,
    onLoadMore,
  };
};
