import { DropdownMenu } from 'components';
import { Button, type DropdownMenuItem, Tooltip, Typography, neutral } from 'thesis-ui';

import { ButtonTransparent } from 'components/dropdown-menu/styles';

import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';

import type { CommandDetail } from './type';

import { formatDateAndTimeCombination } from 'helpers';

import { CommandDataLabel } from '../components';
import { tableCollectionActions, tableQueueActions } from './select';

export const getQueueColumns = (
  devicePermission: boolean,
  onClickMenuItem: (item: DropdownMenuItem, command: CommandDetail) => void,
) => {
  const nameWidth = `calc((100vw - 315px - 320px) / 2)`; // 315px = drag + order + created_at +action + 320px = menu +padding body
  return [
    {
      id: 'drag_to_reorder',
      Header: '',
      width: '36px',
      minWidth: '36px',
      maxWidth: '36px',
    },
    {
      id: 'order',
      Header: (
        <Typography weight={600} style={{ textAlign: 'center', marginLeft: 6 }}>
          Order
        </Typography>
      ),
      width: '67px',
      minWidth: '67px',
      maxWidth: '67px',
      Cell: ({ ...props }) => {
        const index = props.row.index + 1;
        return (
          <Typography style={{ textAlign: 'center' }} color={neutral['neutral-7']}>
            {' '}
            {index}
          </Typography>
        );
      },
    },
    {
      id: 'name',
      Header: 'Command name',
      accessor: 'name',
      sort: false,
      sortType: 'name',
      width: nameWidth,
      minWidth: nameWidth,
      maxWidth: nameWidth,
    },
    {
      id: 'data',
      Header: 'Command data',
      accessor: 'data',
      sort: false,
      width: nameWidth,
      minWidth: nameWidth,
      maxWidth: nameWidth,
      Cell: ({ ...props }) => {
        const data = props.data[props.row.index]?.data;
        return <CommandDataLabel data={data} />;
      },
    },
    {
      id: 'created_at',
      Header: 'Added on',
      width: '160px',
      minWidth: '160px',
      maxWidth: '160px',
      sort: false,
      sortType: 'created_at',
      Cell: ({ ...props }) => {
        const createdAt = props.data[props.row.index]?.created_at;
        return (
          <Typography style={{ whiteSpace: 'nowrap' }}>
            {formatDateAndTimeCombination(createdAt)}
          </Typography>
        );
      },
    },
    {
      id: 'action',
      Header: '',
      width: '52px',
      minWidth: '52px',
      maxWidth: '52px',
      Cell: ({ ...props }) => {
        const originCommand: CommandDetail = props.data[props.row.index];
        if (!devicePermission) {
          return null;
        }
        return (
          <Tooltip
            arrow={false}
            placement="top"
            content="Delete command"
            offset={[0, 6]}
            allowHTML
            interactive
          >
            <ButtonTransparent>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  onClickMenuItem(tableQueueActions[2], originCommand);
                }}
                placementIcon="left"
                icon={<DeleteIcon />}
                style={{
                  width: 32,
                  height: 32,
                }}
              />
            </ButtonTransparent>
          </Tooltip>
        );
      },
    },
  ];
};

export const getCollectionColumns = (
  devicePermission: boolean,
  onClickMenuItem: (item: DropdownMenuItem, command: CommandDetail) => void,
) => {
  return [
    {
      id: 'name',
      Header: 'Command name',
      accessor: 'name',
      sort: true,
      sortType: 'name',
      width: '50%',
      minWidth: '50%',
    },
    {
      id: 'data',
      Header: 'Command data',
      accessor: 'data',
      sort: false,
      width: '50%',
      minWidth: '50%',
      Cell: ({ ...props }) => {
        const data = props.data[props.row.index]?.data;
        return <CommandDataLabel data={data} />;
      },
    },

    {
      id: 'created_at',
      Header: 'Added on',
      width: '160px',
      minWidth: '160px',
      sort: true,
      sortType: 'created_at',
      Cell: ({ ...props }) => {
        const createdAt = props.data[props.row.index]?.created_at;
        return <Typography> {formatDateAndTimeCombination(createdAt)}</Typography>;
      },
    },

    {
      id: 'action',
      Header: '',
      width: '40px',
      minWidth: '40px',
      Cell: ({ ...props }) => {
        const originCommand: CommandDetail = props.data[props.row.index];
        if (!devicePermission) {
          return null;
        }

        return (
          <DropdownMenu
            items={tableCollectionActions}
            onClickMenuItem={(item: DropdownMenuItem) => onClickMenuItem(item, originCommand)}
          />
        );
      },
    },
  ];
};
