import { type NavigateOptions, type To, useLocation, useNavigate } from 'react-router-dom';

export const usePageGoBack = (defaultPath?: string) => {
  const location = useLocation();
  const navigate = useNavigate();

  return () => {
    const state = location.state as { previousPath?: string };
    const newDefaultPath = defaultPath ?? (-1 as To);
    navigate(state?.previousPath ?? newDefaultPath);
  };
};

export const usePageGoTo = (to: To, options?: NavigateOptions) => {
  const navigate = useNavigate();

  return () => {
    navigate(to, options);
  };
};
