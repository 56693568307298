export const ERROR_MESSAGE = Object.freeze({
  UNKNOWN: 'Something went wrong.',
  EMAIL: 'Email incorrect format.',
  PASSWORD_VALIDATE: 'Incorrect password format.',
  TERM_AND_POLICY: 'Accept our Terms and Conditions and Privacy Policy to complete sign-up.',
  AGREE_TERM_AND_POLICY: 'Accept our Terms and Conditions and Privacy Policy to continue.',
  DELETE_TEAM: 'Failed to delete team.',
  DELETE_DEVICE: 'Failed to delete device.',
  ADD_COLLECTION: 'Failed to add to collection.',
  ADD_QUEUE: 'Failed to add to queue.',
  EDIT: 'Failed to save changes.',
  DELETE_COMMAND: 'Failed to delete command.',
  ADD_TO_QUEUE: 'Failed to add to queue.',
  ADD_DEVICE_CHART: 'Failed to add chart.',
  DELETE_DEVICE_CHART: 'Failed to delete chart.',
  SETTING_DEVICE_CHART: 'Failed to save changes.',
  DELETE_DEVICE_DATA: 'Failed to delete data.',
  EXPORT_DEVICE_DATA: 'Failed to export data.',
  SHOW_DEVICE_CHART: 'Failed to show chart.',
  GENERATED_KEY: 'Failed to generated new key.',
  REORDERED: 'Failed to reorder.',
  RECAPTCHA_AVAILABLE: 'Execute recaptcha not yet available.',
  UPDATE_SETTING: 'Failed to update settings.',
  UPDATE_INFORMATION: 'Failed to update information.',
});

export const SUCCESS_MESSAGE = Object.freeze({
  EMAIL: 'Email is valid!',
  PASSWORD: 'Password is valid!',
  RESEND: 'Resend Successfully!',
  RESET: 'Please check email for reset password link!',
  PASSWORD_UPDATED: 'Update password successfully!',
  CREATED_TEAM: 'Created team successfully!',
  EDIT_TEAM: 'Saved changes successfully!',
  DELETE_TEAM: 'Deleted team successfully!',
  INVITED: 'Invited successfully!',
  CHANGE_ROLE: 'Changed role successfully!',
  REMOVE: 'Remove successfully!',
  ADD_DEVICE: 'Added device successfully!',
  EDIT_DEVICE: 'Saved changes successfully!',
  DELETE_DEVICE: 'Deleted device successfully!',
  CHANGE_STATUS_DEVICE: 'Changed status successfully!',
  CHANGE_PUBLIC_DEVICE: 'Changed public mode successfully!',
  ADD_COLLECTION: 'Added to collection successfully!',
  ADD_QUEUE: 'Added to queue successfully!',
  EDIT_COMMAND: 'Saved changes successfully!',
  DELETE_COMMAND: 'Deleted command successfully!',
  ADD_TO_QUEUE: 'Added to queue successfully!',
  ADD_DEVICE_CHART: 'Added chart successfully!',
  DELETE_DEVICE_CHART: 'Deleted chart successfully!',
  SETTING_DEVICE_CHART: 'Saved changes successfully!',
  DELETE_DEVICE_DATA: 'Deleted data successfully!',
  EXPORT_DEVICE_DATA: 'Export data successfully!',
  COPY_LINK: 'Copied link successfully!',
  SHOW_DEVICE_CHART: 'Showed chart successfully!',
  COPY_KEY: 'Copied successfully!',
  GENERATED_KEY: 'Generated new key successfully!',
  REORDERED: 'Reordered successfully!',
  UPDATE_SETTING: 'Updated settings successfully!',
  UPDATE_INFORMATION: 'Updated information successfully!',
  CHANGED_PASSWORD: 'Changed password successfully!',
});
