import { EmptyData } from 'components/data/EmptyData';
import { Table, Typography, blue } from 'thesis-ui';

import {
  ContainerDevice,
  DeviceEmptyData,
  DeviceTable,
  HeaderDevice,
  TitleLink,
} from '../styles/dashboard';

import { isEmpty } from 'lodash';

import { getDevicesColumns } from '../core/columns';
import { useDashBoardDeviceUsedMostStorageController } from '../core/controllers';

export const DashboardDeviceUsedMostStorage = () => {
  const { devices, queryState, hasDevicePermission, redirectDevicePage, redirectDeviceDataPage } =
    useDashBoardDeviceUsedMostStorageController();
  const columns = getDevicesColumns();

  const renderBody = () => {
    if (isEmpty(devices)) {
      return (
        <DeviceEmptyData>
          <EmptyData title="The team has not added any devices." permission={hasDevicePermission} />
        </DeviceEmptyData>
      );
    }
    return (
      <DeviceTable>
        <Table
          tableKey=""
          columns={columns}
          dataSource={devices}
          total={5}
          queryState={queryState}
          onClickRow={redirectDeviceDataPage}
        />
      </DeviceTable>
    );
  };
  return (
    <ContainerDevice direction="column">
      <HeaderDevice align="center" justify="space-between">
        <Typography variant="h5" weight={600}>
          Top 5 devices used most storage
        </Typography>
        {!isEmpty(devices) && (
          <TitleLink color={blue['blue-6']} onClick={redirectDevicePage}>
            View all
          </TitleLink>
        )}
      </HeaderDevice>
      {renderBody()}
    </ContainerDevice>
  );
};
