import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  type QueryState,
  initialQueryState,
  initialQueryStateTable,
  updateQueryStateTable,
  useTableStore,
} from 'thesis-ui';

import { type DeviceDetail } from 'modules/devices/core';
import { DeviceStatus, DeviceWithUsage } from 'modules/devices/core/enum';

import type { TeamDetail } from '../type';
import { type ActionLogDetail } from 'components/action-log/core/type';

import { getDeviceDetailPath, getTableKeyById } from 'helpers';
import { getDevicePermission } from 'helpers/permission';
import { apiCall } from 'hooks/service/api';
import { cloneDeep, isEmpty, isUndefined } from 'lodash';

import { PATH } from 'constants/path';
import { TABLE_KEY } from 'constants/table';
import { type Pagination } from 'types/api';

import { getChartColor, getStorageProgress, getTeamPath } from '../helper';
import { teamDetailSelector, useTeamStore } from '../store';

export const useDashboardStorageController = () => {
  const navigateTo = useNavigate();
  const team = useTeamStore(teamDetailSelector);
  const deviceTableKey = getTableKeyById(TABLE_KEY.DEVICES, team?.id);
  const queryState = useTableStore((s) => s.state.queryState[deviceTableKey]);

  useEffect(() => {
    if (queryState || !team) {
      return;
    }
    initialQueryStateTable(deviceTableKey);
  }, [queryState, team]);

  const redirectDevicePage = (status?: DeviceStatus) => () => {
    if (!team) {
      return;
    }
    const newQueryState = cloneDeep(queryState);
    const parserFilter = newQueryState.filter ? JSON.parse(newQueryState.filter) : {};

    if (!isUndefined(status)) {
      parserFilter['status'] = [];
      parserFilter['status'].push(status);
    } else {
      delete parserFilter['status']; // Filter all devices
    }

    newQueryState.filter = isEmpty(parserFilter) ? undefined : JSON.stringify(parserFilter);
    newQueryState.page = 1;
    newQueryState.sort = 'created_at';
    newQueryState.order = 'desc';

    updateQueryStateTable(deviceTableKey, newQueryState);
    navigateTo(getTeamPath(team.id, PATH.TEAM_DEVICES));
  };

  const totalDevice = Number(team?.device?.active) + Number(team?.device?.inactive);
  const progress = getStorageProgress(team?.raw_usage ?? '', team?.raw_storage_limit ?? '');
  const chartColor = getChartColor(progress);

  return {
    team,
    progress,
    totalDevice,
    chartColor,
    redirectDevicePage,
  };
};

export const useDashBoardDeviceUsedMostStorageController = () => {
  const navigateTo = useNavigate();
  const team = useTeamStore(teamDetailSelector);
  const [devices, setDevices] = useState<DeviceDetail[]>([]);
  const deviceTableKey = getTableKeyById(TABLE_KEY.DEVICES, team?.id);

  const queryState: QueryState = {
    page: 1,
    sort: 'usage',
    order: 'desc',
    page_size: 5,
  };

  useEffect(() => {
    if (!team) {
      return;
    }
    getDevices();
  }, [team?.id]);

  const getDevices = async () => {
    const result: {
      data: DeviceDetail[];
      pagination: Pagination;
    } = await apiCall({
      url: `/api/teams/${team?.id}/devices`,
      data: {
        ...queryState,
        with_usage: DeviceWithUsage.Active,
      },
      method: 'GET',
      isLoading: false,
      showError: true,
    });

    if (result?.data) {
      setDevices(result?.data ?? []);
    }
  };

  const redirectDevicePage = () => {
    if (!team) {
      return;
    }
    const newQueryState = { ...initialQueryState, sort: 'usage', order: 'desc' as 'asc' | 'desc' };
    updateQueryStateTable(deviceTableKey, newQueryState);
    navigateTo(getTeamPath(team.id, PATH.TEAM_DEVICES));
  };

  const redirectDeviceDataPage = (row: any) => {
    const original: DeviceDetail = row?.original;
    if (original?.id && team) {
      navigateTo(
        `${getDeviceDetailPath(
          team.enabled_device_dashboard ? PATH.TEAM_DEVICE_DASHBOARD : PATH.TEAM_DEVICE_DATA,
          team.id,
          original.id,
        )}`,
      );
    }
  };

  const hasDevicePermission = getDevicePermission(team?.role);

  return {
    devices,
    queryState,
    hasDevicePermission,
    redirectDevicePage,
    redirectDeviceDataPage,
  };
};

export const useDashboardActionLogController = () => {
  const navigateTo = useNavigate();
  const team = useTeamStore(teamDetailSelector);
  const [logs, setLogs] = useState<ActionLogDetail[]>([]);

  useEffect(() => {
    if (!team) {
      return;
    }
    getActionLog();
  }, [team?.id]);

  const getActionLog = async () => {
    const result: {
      data: ActionLogDetail[];
      pagination: Pagination;
    } = await apiCall({
      url: `/api/teams/${team?.id}/logs`,
      data: {
        order: 'desc',
        page: 1,
        page_size: 10,
        sort: 'created_at',
      },
      method: 'GET',
      isLoading: true,
      showError: true,
    });

    if (result?.data) {
      setLogs(result?.data ?? []);
    }
  };

  const redirectActionPage = () => {
    if (!team) {
      return;
    }
    navigateTo(getTeamPath(team.id, PATH.TEAM_ACTION_LOG));
  };

  const isEmptyLogs = isEmpty(logs);

  return { logs, isEmptyLogs, colorTeam: team?.colour, redirectActionPage };
};

export const useDashboardLatesDataController = () => {
  const navigateTo = useNavigate();
  const team = useTeamStore(teamDetailSelector);
  const [devices, setDevices] = useState<DeviceDetail[]>([]);

  useEffect(() => {
    if (!team) {
      return;
    }
    getDevices();
  }, [team?.id]);

  const getDevices = async () => {
    const result: {
      data: DeviceDetail[];
      pagination: Pagination;
    } = await apiCall({
      url: `/api/teams/${team?.id}/devices`,
      data: {
        with_data: 20,
        order: 'desc',
        sort: 'created_at',
      },
      method: 'GET',
      isLoading: true,
      showError: true,
    });

    if (result?.data) {
      setDevices(result?.data.filter((el) => !isEmpty(el.records)) ?? []);
    }
  };

  const onViewMoreTeamData =
    (deviceId: string, teamId: string, teamDetail?: TeamDetail | null) => () => {
      navigateTo(
        getDeviceDetailPath(
          teamDetail?.enabled_device_dashboard ? PATH.TEAM_DEVICE_DASHBOARD : PATH.TEAM_DEVICE_DATA,
          teamId,
          deviceId,
        ),
      );
    };

  const isDeviceEmpty = isEmpty(devices);
  return { isDeviceEmpty, devices, onViewMoreTeamData, team };
};
