import { InputTitle } from 'components';
import { ButtonByTeam } from 'components/button/ButtonByTeam';
import { GoToBack } from 'components/go-to-back';
import { Row, Spacing, TextInput, Textarea } from 'thesis-ui';

import { Body, Container, Content } from '../styles/add-device';

import { useDeviceAddController } from '../core';

export const DeviceAdd = () => {
  const {
    alias,
    custom_id,
    description,
    disabled,
    location,
    handleChange,
    handleSubmit,
    onRedirectDevicePage,
  } = useDeviceAddController();

  return (
    <Container direction="column">
      <Spacing spacing="xl" placement="bottom" type="margin">
        <GoToBack title="Add device" onClick={onRedirectDevicePage} />
      </Spacing>
      <Body justify="center">
        <Content>
          <Row align="center">
            <Spacing
              style={{
                minWidth: 200,
              }}
              spacing="sm"
              placement="right"
              type="margin"
            >
              <InputTitle title="Device ID" />
              <TextInput type="text" name="custom_id" value={custom_id} onChange={handleChange} />
            </Spacing>
            <Row direction="column">
              <InputTitle title="Device name" required />
              <TextInput type="text" name="alias" value={alias} onChange={handleChange} />
            </Row>
          </Row>
          <Spacing spacing="sm" placement={['top', 'bottom']} type="margin">
            <TextInput
              title="Location"
              type="text"
              name="location"
              value={location}
              onChange={handleChange}
            />
          </Spacing>
          <Spacing spacing="sm" placement="bottom" type="margin">
            <Textarea
              title="Description"
              name="description"
              value={description}
              onChange={handleChange}
              maxLength={500}
              style={{
                resize: 'none',
              }}
            />
          </Spacing>
          <ButtonByTeam
            onClick={() => handleSubmit()}
            title="Add device"
            size="large"
            disabled={disabled}
            rootStyle={{ width: 125 }}
          />
        </Content>
      </Body>
    </Container>
  );
};
