import styled from 'styled-components';

import { RadiusConfig, neutral, neutralTrans } from 'thesis-ui';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
`;

export const Header = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${neutral['neutral-11']};
`;

export const Body = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  background-color: ${neutral['neutral-3']};
  height: calc(100vh - 56px);
  overflow: auto;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 984px;
  display: grid;
  grid-template-columns: 216px calc(100% - 216px);
`;

export const ContentLeft = styled.div``;

export const LeftMenu = styled.div`
  width: 216px;
  position: fixed;
  top: 80px;
  margin-left: 24px;
`;

export const ContentRight = styled.div`
  margin: 24px 0px 24px 24px;
  padding: 0px 24px 24px 24px;
`;

export const StyledOutlet = styled.div`
  border: 1px solid ${neutralTrans['neutralTrans-15']};
  border-radius: ${RadiusConfig.xxs}px;
  padding: 24px;
  background-color: ${neutral['neutral-1']};
`;
