import { Row, Spacing, Typography } from 'thesis-ui';

import { Body, Container, Content, Header } from '../styles';

import { ReactComponent as Logo } from 'assets/images/logo.svg';

import { PublicModeChart, PublicModeDeviceInformation } from '../components';
import { usePublicModePageController } from '../core';

export const PublicModePage = () => {
  const { device, redirectLogin } = usePublicModePageController();

  return (
    <Container>
      <Header>
        <Spacing spacing="xl" placement="left">
          <Logo
            onClick={redirectLogin}
            style={{
              cursor: 'pointer',
            }}
          />
        </Spacing>
      </Header>
      <Body>
        <Row direction="column">
          <Spacing spacing="xl" placement="bottom" type="margin">
            <Row
              align="center"
              style={{
                height: 32,
              }}
            >
              <Typography variant="h4" weight={600}>
                {device?.alias}
              </Typography>
            </Row>
          </Spacing>

          <Content direction="column">
            <PublicModeChart device={device} />
            <PublicModeDeviceInformation device={device} />
          </Content>
        </Row>
      </Body>
    </Container>
  );
};
