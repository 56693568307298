import styled, { css } from 'styled-components';

import { BoxShadowConfig, RadiusConfig, neutral, neutralTrans, primary } from 'thesis-ui';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormColor = styled.div`
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  width: 200px;
`;

export const ColorSelection = styled.div<{ isActive: boolean }>`
  ${({ isActive }) => {
    const borderColor = isActive ? primary['primary-6'] : neutral['neutral-1'];
    const boxShadow = isActive ? BoxShadowConfig['trans-15'] : 'none';

    return css`
      padding: 4px;
      height: 30px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: 1px solid ${borderColor};
      box-shadow: ${boxShadow};
      border-radius: ${RadiusConfig.xxs}px;
    `;
  }}
`;

export const ColorSelectionItem = styled.div<{ bg: string }>`
  ${({ bg }) => {
    return css`
      width: 100%;
      height: 100%;
      background-color: ${bg};
      border-radius: ${RadiusConfig.xxxs}px;
    `;
  }}
`;

export const InputSelection = styled.div<{ isActive?: boolean }>`
  ${({ isActive }) => {
    const borderColor = isActive ? primary['primary-6'] : neutralTrans['neutralTrans-15'];
    const boxShadow = isActive ? BoxShadowConfig['trans-15'] : 'none';

    return css`
      display: flex;
      cursor: pointer;
      align-items: center;
      margin-top: 6px;
      width: 116px;
      height: 32px;
      padding: 0px 4px;
      border-radius: ${RadiusConfig.xxs}px;
      border: 1px solid ${borderColor};
      box-shadow: ${boxShadow};
    `;
  }}
`;

export const InputColor = styled.div<{ color: string }>`
  ${({ color }) => {
    return css`
      width: 20px;
      height: 20px;
      margin-right: 6px;
      background-color: ${color};
      border-radius: ${RadiusConfig.xxxs}px;
    `;
  }}
`;

export const Picker = styled.div`
  display: flex;
  flex-direction: column;
  width: 260px !important;
  border-radius: ${RadiusConfig.xs}px !important;
  box-shadow: ${BoxShadowConfig['trans-6']} !important;
  background-color: ${neutral['neutral-1']};

  .chrome-picker {
    box-shadow: none !important;
    width: 100% !important;
    background-color: transparent !important;
    > div {
      height: 226px;
      border-top-left-radius: ${RadiusConfig.xs}px !important;
      border-top-right-radius: ${RadiusConfig.xs}px !important;
    }
  }
  .chrome-picker .hue-horizontal {
    height: 24px !important;
    border-radius: ${RadiusConfig.xs}px;
  }
  .chrome-picker .hue-horizontal > div > div {
    width: 10px !important;
    height: 28px !important;
    background-color: ${neutral['neutral-1']};
    border: 1px solid ${neutralTrans['neutralTrans-15']};
    border-radius: ${RadiusConfig.max}px !important;
    box-shadow: none !important;
    transform: translateY(-2px) !important;
  }
  .chrome-picker .flexbox-fix:nth-of-type(2) {
    display: none !important;
  }
`;

export const ActionBtnDelete = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: ${RadiusConfig.xxs}px;
  background-color: ${neutral['neutral-1']};
  border: 1px solid ${neutralTrans['neutralTrans-15']};
`;
