import styled from 'styled-components';

import { darkNeutralTrans } from 'thesis-ui';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 12px 12px 0px;
  div:nth-child(2) {
    padding: 0px;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${darkNeutralTrans['darkNeutralTrans-20']};
  margin-bottom: 12px;
`;
