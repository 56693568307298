import { Container } from '../styles/menu-header';

import { ReactComponent as Logo } from 'assets/images/menu-logo.svg';

export const MenuHeader = () => {
  return (
    <Container>
      <Logo />
    </Container>
  );
};
