import { ActionLogList } from 'components/action-log/ActionLogList';
import { Row, Spacing, Typography } from 'thesis-ui';

import { Body } from '../styles/team-action-log';

import { useTeamActionLogController } from '..';

export const TeamActionLog = () => {
  const { logs, onLoadMore, isLoadMore, colorTeam } = useTeamActionLogController();

  return (
    <Row direction="column">
      <Spacing spacing="xl" type="margin" placement="bottom">
        <Typography variant="h4" weight={600}>
          Action log
        </Typography>
      </Spacing>
      <Body justify="center">
        <ActionLogList
          logs={logs}
          onLoadMore={onLoadMore}
          isLoadMore={isLoadMore.value}
          colorTeam={colorTeam}
        />
      </Body>
    </Row>
  );
};
