import styled, { css } from 'styled-components';

import { RadiusConfig, Row, blue, neutral, neutralTrans, primary } from 'thesis-ui';

export const Container = styled(Row)<{ visible?: boolean }>`
  ${({ visible }) => {
    const border = `1px solid ${visible ? primary['primary-6'] : neutralTrans['neutralTrans-15']}`;
    const cursor = visible ? 'default' : 'pointer';

    return css`
      position: relative;
      padding: 8px 12px;
      border-bottom-left-radius: ${RadiusConfig.xs}px;
      border-bottom-right-radius: ${RadiusConfig.xs}px;
      border: ${border};
      cursor: ${cursor};
      &:hover {
        background: ${neutral['neutral-4']} !important;
      }
    `;
  }}
`;

export const Header = styled(Row)`
  min-height: 32px;
  margin-bottom: 24px;
`;

export const Link = styled.a`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-decoration-line: underline;
  color: ${blue['blue-6']};
  cursor: pointer;
`;
