import { Grid, Row, Spacing, Typography } from 'thesis-ui';

import { DashboardActionLog } from '../components/DashboardActionLog';
import { DashboardDeviceUsedMostStorage } from '../components/DashboardDeviceUsedMostStorage';
import { DashboardLatestData } from '../components/DashboardLatestData';
import { DashboardStorage } from '../components/DashboardStorage';

export const Dashboard = () => {
  return (
    <Row direction="column">
      <Spacing spacing="xl" type="margin" placement="bottom">
        <Typography variant="h5" weight={600}>
          Dashboard
        </Typography>
      </Spacing>
      <Grid direction="template-column" type="full" gap="24px" column={2}>
        <DashboardStorage />
        <DashboardDeviceUsedMostStorage />
        <DashboardLatestData />
        <DashboardActionLog />
      </Grid>
    </Row>
  );
};
