import { closeModal, showToastMessage, useDialogStore } from 'thesis-ui';

import type { ChangeLogContent } from 'modules/auth/core';
import { useController } from 'modules/layout/core/controller';

import { useBoolean } from 'hooks';
import { apiCall } from 'hooks/service/api';

import { ERROR_MESSAGE } from 'constants/message';
import { MODAL_KEY } from 'constants/modal-key';

export const useUpdateTermAndConditionController = () => {
  const { getChangeLog } = useController(false);

  const isChecked = useBoolean(false);
  const agreeTerms = async () => {
    if (!isChecked.value) {
      showToastMessage('error', ERROR_MESSAGE.AGREE_TERM_AND_POLICY);
      return;
    }
    apiCall<any, any>({
      url: '/api/about/create-agreement',
      method: 'POST',
      isLoading: true,
      showError: true,
      onFinally() {
        closeModal(MODAL_KEY.TERMS_AND_CONDITION_UPDATE);
        getChangeLog();
      },
    });
  };
  return { isChecked, agreeTerms };
};

export const useNewFeatureModalController = () => {
  const content: ChangeLogContent = useDialogStore((s) => s.state.data[MODAL_KEY.VERSION_UPDATE]);
  const confirmUpdateVersion = () => {
    apiCall<any, any>({
      url: '/api/change-logs',
      method: 'POST',
      isLoading: true,
      showError: true,
      onFinally() {
        closeModal(MODAL_KEY.VERSION_UPDATE);
      },
    });
  };
  return {
    content,
    confirmUpdateVersion,
  };
};
