import type { ChartOptions } from 'chart.js';
import moment from 'moment';

import { formatDateMoment, getTimezone } from 'helpers';
import { isEmpty } from 'lodash';

export const getChartOptions = (
  startDate: Date,
  endDate: Date,
  isLastTime: boolean,
  timeUnit?: string,
  yAxisSetting?: {
    min?: number;
    max?: number;
  },
): ChartOptions<'line'> => {
  const timezone = getTimezone();
  const momentStartDate = moment(startDate);
  const momentEndDate = moment(endDate);
  const minChart = isLastTime
    ? moment(startDate).toDate().getTime()
    : formatDateMoment(momentStartDate, timezone, '000').getTime();
  const maxChart = isLastTime
    ? moment(endDate).toDate().getTime()
    : formatDateMoment(momentEndDate, timezone, '000').getTime();

  return {
    elements: {
      point: {
        radius: 3,
      },
    },
    layout: {
      padding: {
        top: -50,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function (context: any) {
            if (isEmpty(context)) {
              return undefined;
            }
            const { x } = context[0]?.parsed || {};
            return [
              `${moment(x).utcOffset(timezone).format('YYYY-MM-DD HH:mm:ss')} GMT ${timezone}`,
            ];
          },
        },
      },
    },
    scales: {
      x: {
        type: 'time',
        ticks: {
          maxTicksLimit: 7,
          callback: (time: any) => {
            return [moment(time).utcOffset(timezone).format('MMM DD, HH:mm'), `GMT ${timezone}`];
          },
        },
        time: {
          unit: timeUnit as any,
        },
        min: minChart,
        max: maxChart,
      },
      y: {
        ...yAxisSetting,
      },
    },
  };
};
