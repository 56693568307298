import { showToastMessage } from 'thesis-ui';

import { isArray, isString } from 'lodash';

import { ERROR_MESSAGE } from 'constants/message';

export const httpRequestErrors = (errors: any) => {
  if (isArray(errors.errors) && errors.errors?.length) {
    errors.errors.forEach((error: any) => {
      showToastMessage('error', error);
    });
    return;
  }

  if (errors.message) {
    showToastMessage('error', errors.message);
    return;
  }

  if (isString(errors.errors)) {
    showToastMessage('error', errors.errors);

    return;
  }
  showToastMessage('error', ERROR_MESSAGE.UNKNOWN);
};
