import { blue, green, orange, pink, purple, red } from 'thesis-ui';

export const LETTER_COLOR_CONFIG = Object.freeze({
  AGMSY: blue['blue-5'],
  BHNTZ: purple['purple-5'],
  CIOU: pink['pink-5'],
  DJPV: red['red-5'],
  EKQW: orange['orange-5'],
  FLRX: green['green-5'],
});
