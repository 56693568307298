import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { getChartOptions } from 'components/chart/core/constant';
import { ChartDataType, ChartPinAt, ChartType } from 'enums/chart';
import {
  type DropdownMenuItem,
  closeModal,
  openModal,
  showPageLoading,
  showToastMessage,
  useDialogStore,
} from 'thesis-ui';

import { useAuthStore, userProfileSelector } from 'modules/auth/core';
import { teamDetailSelector, useTeamStore } from 'modules/team';

import type { ConfirmModalData } from 'components/modal/core/type';

import {
  copyToClipboard,
  getArraySort,
  getDevicePublicModePath,
  getUTCDate,
  waitWithTimeout,
} from 'helpers';
import { getDevicePermission } from 'helpers/permission';
import { useBoolean, useString } from 'hooks';
import { apiCall } from 'hooks/service/api';
import { cloneDeep, includes, isEmpty, isUndefined, remove } from 'lodash';

import { URL_FE } from 'constants/environment';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from 'constants/message';
import { MODAL_KEY } from 'constants/modal-key';
import { PATH } from 'constants/path';
import { type Pagination } from 'types/api';

import {
  type DeviceDetail,
  type DeviceLabel,
  type DeviceRecord,
  type DeviceVisibleKey,
  getDeviceChartDateFilter,
} from '..';
import { DeviceChartYAxisSettingType, DeviceState, DeviceStatus } from '../enum';
import {
  clearSettingChartByKey,
  deviceDetailSelector,
  updateDeviceDetail,
  useDeviceSettingChartStore,
  useDeviceStore,
} from '../store';
import {
  checkDeviceChartFilterLastTime,
  getChartTimeUnit,
  updateSettingChartState,
} from './../helper';

export const useDevicePublicController = () => {
  const navigationTo = useNavigate();
  const userProfile = useAuthStore(userProfileSelector);
  const team = useTeamStore(teamDetailSelector);
  const device = useDeviceStore(deviceDetailSelector);
  const settings = useDeviceSettingChartStore((s) => s.state.setting) || {};
  const openSelectState = useBoolean(false);

  const [deviceCharts, setDeviceCharts] = useState<DeviceRecord[]>([]);
  const chartId = useString('');

  useEffect(() => {
    if (!device?.id || !team?.id || !userProfile?.id) {
      return;
    }
    const hasPermission = getDevicePermission(team.role);
    if (!hasPermission && device.state === DeviceState.Private) {
      navigationTo(PATH.ERROR_404);
      return;
    }
    getDeviceCharts();
  }, [device, team, userProfile?.id]);

  const getDeviceCharts = async () => {
    const newSettings = cloneDeep(settings);
    Object.keys(newSettings).forEach((key) => {
      if (key.startsWith(`UserId-${userProfile?.id}-${ChartPinAt[2]}-${device?.id}`)) {
        clearSettingChartByKey(key);
      }
    });

    const result: {
      data: DeviceRecord[];
      pagination: Pagination;
    } = await apiCall({
      url: `/api/teams/${device?.team_id}/charts`,
      data: {
        sort: 'created_at',
        order: 'desc',
        filter: JSON.stringify({
          type: ChartType.Line,
          pin_at: ChartPinAt.PublicPage,
          device_id: device?.id,
        }),
      },
      method: 'GET',
      isLoading: true,
      showError: true,
    });
    setDeviceCharts([]);
    if (result?.data) {
      setDeviceCharts(result?.data ?? []);
    }
  };

  const onChangeDeviceState = (newItem: DropdownMenuItem) => {
    if (!newItem) {
      openSelectState.setValue(false);
      return;
    }
    onUpdateDevice('state', Number(newItem.key));
  };

  const onChangeDeviceStatus = () => {
    onUpdateDevice('status', DeviceStatus.Active);
  };

  const onUpdateDevice = async (key: DeviceVisibleKey, value: number) => {
    const result: DeviceDetail = await apiCall({
      url: `/api/teams/${device?.team_id}/devices/${device?.id}`,
      data: {
        [key]: value,
      },
      method: 'PATCH',
      isLoading: true,
      showError: true,
    });
    if (result?.id) {
      openSelectState.setValue(false);
      showToastMessage('success', SUCCESS_MESSAGE.CHANGE_PUBLIC_DEVICE);
      updateDeviceDetail({
        ...device,
        ...result,
      });
    }
  };

  const onCopyLink = () => {
    if (!device) {
      return;
    }
    copyToClipboard(`${URL_FE}${getDevicePublicModePath(device?.team_id, device?.id)}`);
    showToastMessage('success', SUCCESS_MESSAGE.COPY_LINK);
  };

  const onAddDeviceChart = async () => {
    openModal(MODAL_KEY.ADD_DEVICE_CHART_PUBLIC);
  };

  const callbackAddChart = (newDeviceRecords: DeviceRecord[]) => {
    const newCharts = newDeviceRecords.concat(cloneDeep(deviceCharts));
    setDeviceCharts(newCharts);
  };

  const onOpenModalDeleteChart = (originChartId: string) => {
    openModal(MODAL_KEY.DELETE_DEVICE_CHART_PUBLIC, {
      header: `Delete chart`,
      label: `All the settings you made with this chart will also be deleted. This action can not undo.`,
    } as ConfirmModalData);
    chartId.setValue(originChartId);
  };

  const onDeleteChart = async () => {
    if (!chartId.value || !device) {
      return showToastMessage('error', SUCCESS_MESSAGE.DELETE_DEVICE_CHART);
    }

    const result: DeviceRecord = await apiCall({
      url: `/api/teams/${device?.team_id}/charts/${chartId.value}`,
      method: 'DELETE',
      isLoading: true,
      showError: true,
    });

    if (result?.id) {
      showToastMessage('success', SUCCESS_MESSAGE.DELETE_DEVICE_CHART);
      const newCharts = cloneDeep(deviceCharts);
      remove(newCharts, (el) => el.id === chartId.value);
      setDeviceCharts(newCharts);
      closeModal(MODAL_KEY.DELETE_DEVICE_CHART_PUBLIC);
      chartId.setValue('');
    }
  };

  const selectStateDisable = device?.status === DeviceStatus.Inactive;
  const isPublicView = !selectStateDisable && device?.state === DeviceState.Public;
  const hasDevicePermission = getDevicePermission(team?.role);
  const isDeviceChartEmpty = isEmpty(deviceCharts);
  const isEmptyLabel = isEmpty(device?.labels);

  return {
    selectStateDisable,
    device,
    openSelectState,
    deviceCharts,
    isPublicView,
    hasDevicePermission,
    isDeviceChartEmpty,
    isEmptyLabel,
    userProfile,
    onChangeDeviceState,
    onChangeDeviceStatus,
    onCopyLink,
    onAddDeviceChart,
    onOpenModalDeleteChart,
    onDeleteChart,
    callbackAddChart,
  };
};

export const useDevicePublicChartController = (
  originChart: DeviceRecord,
  originDevice?: DeviceDetail,
) => {
  const deviceState = useDeviceStore(deviceDetailSelector);
  const chartKey = `Public-${ChartPinAt[originChart.pin_at]}-${originChart.device_id}`;
  const device = originDevice ?? deviceState;
  const setting = updateSettingChartState(originChart);
  const { startDate, endDate } = getDeviceChartDateFilter(setting);
  const isLastTime = checkDeviceChartFilterLastTime(setting);

  const [datasets, setDatasets] = useState<number[]>([]);
  const [labels, setLabels] = useState<string[]>([]);

  useEffect(() => {
    setDatasets([]);
    setLabels([]);
    if (originChart.id && device?.id) {
      getRecordDevice();
    }
  }, [originChart, device]);

  const getRecordDevice = async () => {
    const result: {
      data: DeviceRecord[];
      pagination: Pagination;
    } = await apiCall({
      url: `/api/teams/${device?.team_id}/devices/${device?.id}/records`,
      data: {
        sort: 'created_at',
        order: 'desc',
        filter: JSON.stringify({
          data_type: ChartDataType.Number,
          command_id: originChart.command_id,
          label: originChart.label,
          created_at: {
            $gte: getUTCDate(startDate, true, isLastTime),
            $lte: getUTCDate(endDate, true, isLastTime, '999'),
          },
        }),
      },
      method: 'GET',
      isLoading: true,
      showError: true,
      isPublic: Boolean(originDevice),
    });

    if (result?.data?.length) {
      setDatasets(result?.data.map((el) => Number(el.data)));
      setLabels(result?.data.map((el) => el.created_at));
    }
  };

  const yAxisSetting =
    !isUndefined(originChart.y_axis_min) &&
    !isUndefined(originChart.y_axis_max) &&
    originChart.y_axis_display_type === DeviceChartYAxisSettingType.Manually
      ? {
          min: originChart.y_axis_min,
          max: originChart.y_axis_max,
        }
      : undefined;

  const timeUnit = getChartTimeUnit(setting);

  const chartOptions = getChartOptions(startDate, endDate, isLastTime, timeUnit, yAxisSetting);

  return {
    datasets,
    device,
    labels,
    chartOptions,
    chartKey,
  };
};

export const useAddDeviceChartPublicModalController = (
  chartLabels: string[],
  callbackAddChart: (newDeviceRecords: DeviceRecord[]) => void,
) => {
  const device = useDeviceStore(deviceDetailSelector);
  const modalKey = useDialogStore((s) => s.state.modalKey[MODAL_KEY.ADD_DEVICE_CHART_PUBLIC]);
  const [selectedLabel, setSelectedLabel] = useState<Record<string, string>>({});

  useEffect(() => {
    setSelectedLabel({});
  }, [modalKey]);

  const onChangeSelectedDeviceLabel = (label: string) => () => {
    const newSelected = cloneDeep(selectedLabel);
    if (!isUndefined(newSelected[label])) {
      delete newSelected[label];
    } else {
      newSelected[label] = label;
    }
    setSelectedLabel(newSelected);
  };

  const onSubmitAddChart = async () => {
    if (!device) {
      showToastMessage('error', ERROR_MESSAGE.ADD_DEVICE_CHART);
      return;
    }
    const newSelected = getArraySort(
      cloneDeep(
        Object.keys(selectedLabel).map((key) => {
          return {
            label: key,
          };
        }),
      ),
      'label',
      'desc',
    );

    showPageLoading();
    const results: DeviceRecord[] = [];
    for (const el of newSelected) {
      const result: DeviceRecord = await apiCall({
        url: `/api/teams/${device?.team_id}/charts`,
        data: {
          name: 'Chart name',
          type: ChartType.Line,
          pin_at: ChartPinAt.PublicPage,
          device_id: device?.id,
          label: el.label === 'No label' ? '' : el.label,
          date_range_type: 7,
          tab_key: 0,
          relative_range_action: 1,
          relative_range_total: 1,
        },
        method: 'POST',
        isLoading: false,
        showError: true,
      });
      results.unshift(result);
      await waitWithTimeout(1000);
    }

    showToastMessage('success', SUCCESS_MESSAGE.ADD_DEVICE_CHART);
    closeModal(MODAL_KEY.ADD_DEVICE_CHART_PUBLIC);
    callbackAddChart(results);
  };

  const disabled = isEmpty(selectedLabel);

  const orderDeviceLabels: DeviceLabel[] =
    device && !isEmpty(device.labels)
      ? getArraySort(
          cloneDeep(
            device.labels.map((el) => {
              return {
                ...el,
                label: el.label || 'No label',
              };
            }),
          ),
          'label',
        )
      : [];

  const listLabelAdd = orderDeviceLabels.filter((el) => !includes(chartLabels, el.label));

  const isEmptyListLabel = isEmpty(listLabelAdd);

  return {
    disabled,
    selectedLabel,
    listLabelAdd,
    isEmptyListLabel,
    onChangeSelectedDeviceLabel,
    onSubmitAddChart,
  };
};
