import { useLocation, useNavigate } from 'react-router-dom';

import { HttpStatusCode } from 'enums';
import { showToastMessage } from 'thesis-ui';

import { Role, updateUserProfile, useAuthStore, userProfileSelector } from 'modules/auth/core';

import { compileFormData } from 'helpers';
import { useString } from 'hooks';
import { apiCall } from 'hooks/service/api';
import { isEmpty, uniqueId } from 'lodash';

import { ERROR_MESSAGE, SUCCESS_MESSAGE } from 'constants/message';

import { getUserColor } from '../helper';

export const useGeneralLayoutController = () => {
  const location = useLocation();
  const navigationTo = useNavigate();
  const userProfile = useAuthStore(userProfileSelector);
  const userColor = getUserColor(userProfile?.fullname);
  const keyInputFile = useString();

  const onTabClick = (key: string) => {
    navigationTo(key);
  };

  const fileSelectedHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentFiles: File[] = [];
    if (event.target.files) {
      for (const [, file] of Object.entries(event.target.files)) {
        currentFiles.push(file);
      }
    }
    if (isEmpty(currentFiles)) {
      showToastMessage('error', ERROR_MESSAGE.UPDATE_INFORMATION);
      return;
    }
    keyInputFile.setValue(uniqueId('general_'));
    const result: any = await apiCall({
      url: `/api/auth/me`,
      data: compileFormData({
        avatar: currentFiles[0],
      }),
      method: 'PATCH',
      isLoading: true,
      showError: true,
    });

    if (result?.id) {
      updateUserProfile({
        ...userProfile,
        ...result,
      });
      showToastMessage('success', SUCCESS_MESSAGE.UPDATE_INFORMATION);
    }

    if (result.message && result.statusCode === HttpStatusCode.ERROR) {
      showToastMessage('error', result.message);
    }
  };

  const isAdmin = userProfile?.role_id === Role.Admin;

  return {
    isAdmin,
    userProfile,
    userColor,
    locationPath: location.pathname,
    keyInputFile,
    fileSelectedHandler,
    onTabClick,
  };
};
