import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 12px;
  height: 60px;
  min-height: 60px;
  width: 100%;
  margin-bottom: 12px;
`;
