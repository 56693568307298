import { ChromePicker } from 'react-color';

import { InputTitle } from 'components';
import {
  AvatarUpload,
  Button,
  Modal,
  Row,
  Spacing,
  TextInput,
  Tooltip,
  Typography,
  neutral,
  red,
} from 'thesis-ui';

import {
  ActionBtnDelete,
  ColorSelection,
  ColorSelectionItem,
  Container,
  FormColor,
  InputColor,
  InputSelection,
  Picker,
} from '../styles/form-team';

import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as TailingIcon } from 'assets/icons/tailing.svg';

import { MODAL_KEY } from 'constants/modal-key';

import { useFormTeamController } from '../core';
import { LIST_COLOR_SELECT, MAX_FILE_SIZE } from '../core/constant';

export const FormTeamModal = () => {
  const {
    color,
    customColor,
    isCustomColorActive,
    disabled,
    name,
    isErrorAvatar,
    logoRef,
    isEdit,
    logoURL,
    handleChange,
    handleSubmit,
    onChangePicker,
    onChangeColorSelection,
    onErrorAvatar,
    onChangeAvatar,
    onDeleteLogo,
  } = useFormTeamController();

  const header = `${isEdit ? 'Edit' : 'Create'} team`;
  const button = isEdit ? 'Save' : 'Create team';

  return (
    <Modal
      header={header}
      modalKey={MODAL_KEY.FORM_TEAM}
      size="sm"
      footer={
        <Button
          variant="contained"
          color="primary"
          type="submit"
          size="large"
          onClick={() => handleSubmit()}
          style={{
            width: '100%',
          }}
          disabled={disabled}
        >
          {button}
        </Button>
      }
    >
      <Container>
        <Spacing spacing="md" placement="bottom" type="margin">
          <InputTitle title="Team name" required />
          <TextInput name="name" value={name} onChange={handleChange} />
        </Spacing>
        <Row justify="space-between">
          <Row direction="column">
            <Typography
              weight={600}
              style={{
                marginBottom: 6,
              }}
            >
              Logo
            </Typography>

            <AvatarUpload
              id="logo"
              size="lg"
              ref={logoRef}
              icon={<TailingIcon />}
              accept=".png,.jpeg,.svg,.webp"
              maxFileSize={MAX_FILE_SIZE}
              onError={onErrorAvatar}
              onChangeFile={onChangeAvatar}
              url={logoURL.value}
              actionBtn={
                <ActionBtnDelete onClick={onDeleteLogo}>
                  <DeleteIcon />
                </ActionBtnDelete>
              }
              style={{ cursor: 'pointer' }}
            />
            {isErrorAvatar && (
              <Typography
                weight={600}
                style={{
                  marginTop: 4,
                }}
                color={red['red-5']}
                variant="body-3"
              >{`Max file size is 10Mb`}</Typography>
            )}
          </Row>
          <Row direction="column">
            <Typography
              weight={600}
              style={{
                marginBottom: 6,
              }}
            >
              Color
            </Typography>
            <FormColor>
              {LIST_COLOR_SELECT.map((el) => {
                const isActive = color.toLowerCase() === el && isCustomColorActive.value === false;
                return (
                  <ColorSelection
                    isActive={isActive}
                    key={el}
                    onClick={onChangeColorSelection(el.toUpperCase())}
                  >
                    <ColorSelectionItem bg={el} />
                  </ColorSelection>
                );
              })}
            </FormColor>
            <Tooltip
              bg="transparent"
              placement="top-start"
              arrow={false}
              offset={[220, -74]}
              trigger="click"
              interactive
              onShow={() => {
                onChangePicker({ hex: customColor.value });
                isCustomColorActive.setValue(true);
              }}
              onHide={() => {
                if (customColor.value.length < 4) {
                  onChangePicker({ hex: neutral['neutral-13'] });
                }
              }}
              content={
                <Picker>
                  <ChromePicker
                    color={customColor.value}
                    onChange={onChangePicker}
                    disableAlpha
                    styles={{
                      default: {
                        body: {
                          padding: '20px 16px 29px 16px',
                          height: 'auto',
                        },
                        color: {
                          display: 'none',
                        },
                      },
                    }}
                  />
                  <Spacing spacing="md" placement={['bottom', 'left', 'right']}>
                    <Row align="center">
                      <Typography
                        weight={600}
                        style={{
                          marginRight: 12,
                        }}
                      >
                        Hex
                      </Typography>
                      <TextInput
                        name="color"
                        value={customColor.value}
                        onChange={(e) => {
                          customColor.setValue(e.target.value);
                          handleChange(e);
                        }}
                      />
                    </Row>
                  </Spacing>
                </Picker>
              }
            >
              <InputSelection
                isActive={
                  color.toLowerCase() === customColor.value.toLowerCase() &&
                  isCustomColorActive.value
                }
              >
                <InputColor color={customColor.value} />
                <Typography>{customColor.value}</Typography>
              </InputSelection>
            </Tooltip>
          </Row>
        </Row>
      </Container>
    </Modal>
  );
};
