import { useEffect, useState } from 'react';

import { ChartPinAt, ChartType } from 'enums/chart';
import {
  closeModal,
  initialQueryState,
  initialQueryStateTable,
  openModal,
  showPageLoading,
  showToastMessage,
  updateQueryStateTable,
  useDialogStore,
  useTableStore,
} from 'thesis-ui';

import { type DeviceDetail, type DeviceRecord, clearSettingChartByKey } from 'modules/devices/core';

import { type ConfirmModalData } from 'components/modal/core/type';

import { getArraySort, getTableKeyById, waitWithTimeout } from 'helpers';
import { getDevicePermission } from 'helpers/permission';
import { getResponseData } from 'helpers/response';
import { useString } from 'hooks';
import { apiCall } from 'hooks/service/api';
import { cloneDeep, includes, isEmpty, remove } from 'lodash';

import { ERROR_MESSAGE, SUCCESS_MESSAGE } from 'constants/message';
import { MODAL_KEY } from 'constants/modal-key';
import { TABLE_KEY } from 'constants/table';
import { type Pagination } from 'types/api';

import { getSelectedDeviceSort, updateTeamDataQueryState } from '../helper';
import {
  isRefreshDataChartSelector,
  teamDetailSelector,
  updateRefreshChart,
  useTeamStore,
} from '../store';

export const useTeamDataController = () => {
  const team = useTeamStore(teamDetailSelector);
  const isRefreshChart = useTeamStore(isRefreshDataChartSelector);
  const teamDataTableKey = getTableKeyById(TABLE_KEY.TEAM_DATA, team?.id);
  const queryState = useTableStore((s) => s.state.queryState[teamDataTableKey]);
  const queryFilter = queryState?.filter ? JSON.parse(queryState.filter) : {};
  const filterKey = 'device_id';
  const deviceFilter = queryFilter[filterKey];

  const [charts, setCharts] = useState<DeviceRecord[]>([]);
  const chartId = useString('');
  const chartKey = useString('');
  const [devices, setDevices] = useState<DeviceDetail[]>([]);

  useEffect(() => {
    if (!team?.id) {
      return;
    }
    if (queryState) {
      getData();
      return;
    }
    initialQueryStateTable(teamDataTableKey, {
      ...initialQueryState,
      page_size: 100,
    });
  }, [queryState?.page_size, team]);

  useEffect(() => {
    if (isRefreshChart) {
      getCharts();
      updateRefreshChart(false);
    }
  }, [isRefreshChart]);

  const getData = async () => {
    await getDevices();
    await getCharts();
  };

  const getCharts = async () => {
    const result: {
      data: DeviceRecord[];
      pagination: Pagination;
    } = await apiCall({
      url: `/api/teams/${team?.id}/charts`,
      data: {
        sort: 'created_at',
        order: 'desc',
        filter: JSON.stringify({
          type: ChartType.Line,
          pin_at: ChartPinAt.DataPage,
        }),
      },
      method: 'GET',
      isLoading: true,
      showError: true,
    });

    setCharts(getResponseData(result?.data));
  };

  const getDevices = async () => {
    const result: {
      data: DeviceDetail[];
    } = await apiCall({
      url: `/api/teams/${team?.id}/devices`,
      data: {
        with_label: 1,
        sort: 'created_at',
        order: 'desc',
      },
      method: 'GET',
      isLoading: true,
      showError: true,
    });

    const newDevices: DeviceDetail[] = getResponseData(result?.data);

    setDevices(
      newDevices.map((el) => {
        const orderLabels = getArraySort(
          el.labels?.map((elLabel) => {
            return {
              ...elLabel,
              label: elLabel.label || 'No label',
            };
          }),
          'label',
        );
        return {
          ...el,
          labels: orderLabels,
        };
      }),
    );
  };

  const onAddChart = () => {
    openModal(MODAL_KEY.ADD_DATA_CHART);
  };

  const onOpenModalDeleteChart = (newChartId: string, newChartKey: string) => {
    openModal(MODAL_KEY.DELETE_DEVICE_CHART, {
      header: `Delete chart`,
      label: `All the settings you made with this chart will also be deleted. This action can not undo.`,
    } as ConfirmModalData);
    chartId.setValue(newChartId);
    chartKey.setValue(newChartKey);
  };

  const onDeleteChart = async () => {
    if (!chartId.value || !team) {
      return showToastMessage('error', SUCCESS_MESSAGE.DELETE_DEVICE_CHART);
    }

    const result: DeviceRecord = await apiCall({
      url: `/api/teams/${team.id}/charts/${chartId.value}`,
      method: 'DELETE',
      isLoading: true,
      showError: true,
    });

    if (result?.id) {
      showToastMessage('success', SUCCESS_MESSAGE.DELETE_DEVICE_CHART);
      closeModal(MODAL_KEY.DELETE_DEVICE_CHART);
      getCharts();

      const newQueryState = updateTeamDataQueryState(
        queryState,
        queryFilter,
        filterKey,
        result.device_id,
      );
      updateQueryStateTable(teamDataTableKey, newQueryState);
      setTimeout(() => {
        clearSettingChartByKey(chartKey.value);
      }, 2000);
      chartId.setValue('');
      chartKey.setValue('');
    }
  };

  const isEmptyChart = isEmpty(charts);
  const isDeviceEmpty = isEmpty(devices);

  const isFilter = !isEmpty(deviceFilter);
  const listChartByFilter = isEmpty(deviceFilter)
    ? charts
    : charts.filter((el) => includes(deviceFilter, el.device_id));

  const hasDevicePermission = getDevicePermission(team?.role);

  return {
    team,
    charts,
    listChartByFilter,
    isEmptyChart,
    isDeviceEmpty,
    devices,
    isFilter,
    teamDataTableKey,
    hasDevicePermission,
    onAddChart,
    onOpenModalDeleteChart,
    onDeleteChart,
  };
};

export const useAddDataChartModalController = (devices: DeviceDetail[]) => {
  const team = useTeamStore(teamDetailSelector);
  const modalKey = useDialogStore((s) => s.state.modalKey[MODAL_KEY.ADD_DATA_CHART]);
  const [selectedDevice, setSelectedDevice] = useState<Record<string, string[]>>({});

  useEffect(() => {
    setSelectedDevice({});
  }, [modalKey]);

  const onChangeSelectedDevice = (newDevice: DeviceDetail, newChecked: boolean) => () => {
    const newSelected = cloneDeep(selectedDevice);
    const labels = newDevice.labels.map((el) => el.label);
    if (newChecked) {
      newSelected[newDevice.id] = labels;
    } else {
      delete newSelected[newDevice.id];
    }
    setSelectedDevice(newSelected);
  };

  const onChangeSelectedDeviceLabel =
    (deviceId: string, label: string, newChecked: boolean) => () => {
      const newSelected = cloneDeep(selectedDevice);
      if (!isEmpty(newSelected[deviceId])) {
        const newLabels = newSelected[deviceId];
        if (newChecked) {
          newLabels.push(label);
        } else {
          remove(newLabels, (el) => el === label);
        }
        newSelected[deviceId] = newLabels;
      } else {
        newSelected[deviceId] = [label];
      }
      setSelectedDevice(newSelected);
    };

  const onSubmitAddChart = async () => {
    if (!team || isEmpty(selectedDevice)) {
      return showToastMessage('error', ERROR_MESSAGE.ADD_DEVICE_CHART);
    }
    const newSelected = getSelectedDeviceSort(cloneDeep(selectedDevice), devices);

    showPageLoading();
    for (const el of newSelected) {
      await apiCall({
        url: `/api/teams/${team.id}/charts`,
        data: {
          name: 'Chart name',
          type: ChartType.Line,
          pin_at: ChartPinAt.DataPage,
          device_id: el.id,
          label: el.label === 'No label' ? '' : el.label,
        },
        method: 'POST',
        isLoading: false,
        showError: true,
      });
      await waitWithTimeout(1000);
    }
    showToastMessage('success', SUCCESS_MESSAGE.ADD_DEVICE_CHART);
    closeModal(MODAL_KEY.ADD_DATA_CHART);
    updateRefreshChart(true);
  };

  const disabled = isEmpty(selectedDevice);

  return {
    disabled,
    selectedDevice,
    onChangeSelectedDevice,
    onSubmitAddChart,
    onChangeSelectedDeviceLabel,
  };
};

export const useTeamDataFilterController = () => {
  const team = useTeamStore(teamDetailSelector);
  const teamDataTableKey = getTableKeyById(TABLE_KEY.TEAM_DATA, team?.id);
  const queryState = useTableStore((s) => s.state.queryState[teamDataTableKey]);

  const { filter } = queryState ?? {};
  const parserFilter = filter ? JSON.parse(filter) : {};
  const filterKey = 'device_id';

  const onChangeCheckbox = (value: string, checked: boolean) => () => {
    const newFilter = cloneDeep(parserFilter);
    const newQueryState = cloneDeep(queryState);
    if (checked) {
      newFilter['device_id'] = newFilter[filterKey]?.length ? newFilter[filterKey] : [];
      newFilter[filterKey].push(value);
    } else {
      remove(newFilter[filterKey], (el) => el === value);
    }

    newQueryState.filter = isEmpty(newFilter) ? undefined : JSON.stringify(newFilter);
    newQueryState.page = 1;

    updateQueryStateTable(teamDataTableKey, newQueryState);
  };

  const onClear = () => {
    const newQueryState = cloneDeep(queryState);
    newQueryState.filter = undefined;
    newQueryState.page = 1;
    updateQueryStateTable(teamDataTableKey, newQueryState);
  };

  const selectedDevice: string[] = parserFilter[filterKey];

  return { selectedDevice, onChangeCheckbox, onClear };
};

export const useTeamDataFilterLabelController = () => {
  const team = useTeamStore(teamDetailSelector);
  const teamDataTableKey = getTableKeyById(TABLE_KEY.TEAM_DATA, team?.id);
  const queryState = useTableStore((s) => s.state.queryState[teamDataTableKey]);
  const { filter } = queryState ?? {};
  const parserFilter = filter ? JSON.parse(filter) : {};
  const filterKey = 'device_id';
  const listDevice: string[] = parserFilter[filterKey] || [];

  const onClearFilter = (deviceId: string) => () => {
    const newFilter = cloneDeep(parserFilter);
    const newQueryState = cloneDeep(queryState);
    remove(newFilter[filterKey], (el) => el === deviceId);
    newQueryState.filter = isEmpty(newFilter) ? undefined : JSON.stringify(newFilter);
    updateQueryStateTable(teamDataTableKey, newQueryState);
  };

  return { listDevice, onClearFilter };
};
