import { Outlet } from 'react-router-dom';

import {
  Avatar,
  RadiusConfig,
  Row,
  Spacing,
  Tabs,
  Tag,
  Typography,
  getFirstLetterInName,
  neutral,
  neutralTrans,
} from 'thesis-ui';

import { UserTierCard } from 'modules/layout/components/UserTierCard';

import {
  Body,
  Container,
  ContainerAvatar,
  Header,
  ReCommend,
  StyledOutlet,
  StyledTab,
} from '../styles/general-layout';

import { getImageUrl } from 'helpers';
import { isEmpty } from 'lodash';

import { useGeneralLayoutController } from '../core/controllers/genera-layout';
import { getGeneraTabs } from '../core/tabs';

export const GeneralLayout = () => {
  const {
    isAdmin,
    userProfile,
    userColor,
    locationPath,
    keyInputFile,
    fileSelectedHandler,
    onTabClick,
  } = useGeneralLayoutController();

  const tabs = getGeneraTabs();

  return (
    <Container align="center" justify="center">
      <Body direction="column">
        <Header direction="column">
          <input
            id="container-update-avatar"
            onChange={fileSelectedHandler}
            accept={'image/*'}
            type="file"
            style={{ display: 'none' }}
            key={keyInputFile.value}
          />
          <ContainerAvatar
            style={{
              borderRadius: RadiusConfig.max,
            }}
          >
            <label
              htmlFor="container-update-avatar"
              style={{
                borderRadius: RadiusConfig.max,
              }}
            >
              <Avatar
                items={{
                  label: getFirstLetterInName(userProfile?.fullname, true),
                  src: userProfile?.avatar ? getImageUrl(userProfile.avatar) : '',
                }}
                size="large"
                config={{
                  bg: userProfile?.avatar ? 'transparent' : userColor,
                  color: neutral['neutral-1'],
                  border: ` 1px solid ${neutralTrans['neutralTrans-15']}`,
                }}
                containerStyle={{
                  width: 'fit-content',
                  cursor: 'pointer',
                  borderRadius: RadiusConfig.max,
                }}
                width={128}
              />
            </label>
          </ContainerAvatar>
          <Row
            direction="column"
            style={{
              paddingLeft: 144,
              marginBottom: 8,
            }}
          >
            <Spacing spacing="xs" type="margin" placement="bottom">
              <Row>
                {userProfile?.tier && <UserTierCard tier={userProfile?.tier} />}
                {isAdmin && <Tag color="blue">Admin</Tag>}
              </Row>
            </Spacing>
            <Typography
              weight={600}
              variant="h5"
              style={{
                textAlign: 'start',
              }}
            >
              {userProfile?.fullname}
            </Typography>
            <Typography
              weight={500}
              variant="body-2"
              color={neutral['neutral-7']}
              style={{
                marginTop: 4,
              }}
            >
              {userProfile?.id}
            </Typography>
          </Row>
          <ReCommend align="flex-end">
            <Typography variant="body-2" color={neutral['neutral-7']}>
              Recommended avatar size is 128 x 128 pixels
            </Typography>
          </ReCommend>
        </Header>
        <StyledTab>
          {!isEmpty(tabs) && (
            <Tabs
              variant="text"
              items={tabs}
              activeKey={locationPath}
              onTabClick={onTabClick}
              tabItemStyle={{
                height: 39,
              }}
            />
          )}
        </StyledTab>
        <StyledOutlet>
          <Outlet />
        </StyledOutlet>
      </Body>
    </Container>
  );
};
