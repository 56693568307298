import { EmptyData } from 'components/data/EmptyData';
import { RadiusConfig, Row, Spacing, Typography, neutral } from 'thesis-ui';

import { DeviceDataUpload } from 'modules/devices/components';

import { Container, TitleLink } from '../styles/dashboard';

import { ReactComponent as EmptyUpload } from 'assets/images/empty-upload.svg';

import { orderBy } from 'lodash';

import { useDashboardLatesDataController } from '../core';

export const DashboardLatestData = () => {
  const { isDeviceEmpty, devices, onViewMoreTeamData, team } = useDashboardLatesDataController();

  const renderBody = () => {
    if (isDeviceEmpty) {
      return (
        <Row align="center" style={{ height: 360 }}>
          <EmptyData
            title="No data was uploaded from any device."
            permission={false}
            image={<EmptyUpload />}
          />
        </Row>
      );
    }
    return (
      <Row direction="column">
        {devices.map((device, index) => {
          const marginBottom = index + 1 === devices.length ? 0 : 24;
          return (
            <Row key={device.id} direction="column" style={{ marginBottom: marginBottom }}>
              <Row align="center" justify="space-between" style={{ marginBottom: 8 }}>
                <Typography style={{ textAlign: 'start' }} className="text-ellipsis-1" weight={600}>
                  {device.alias}
                </Typography>
                <Spacing spacing="md" type="margin" placement="left">
                  <TitleLink
                    variant="body-2"
                    color={neutral['neutral-7']}
                    onClick={onViewMoreTeamData(device.id, device.team_id, team)}
                  >
                    View more
                  </TitleLink>
                </Spacing>
              </Row>
              <DeviceDataUpload
                rootStyle={{
                  borderRadius: RadiusConfig.xs,
                  background: neutral['neutral-2'],
                }}
                deviceUploads={orderBy(device.records || [], 'created_at', 'asc')}
                heightContent={68}
              />
            </Row>
          );
        })}
      </Row>
    );
  };

  return (
    <Container style={{ minHeight: 438 }}>
      <Spacing spacing="md" style={{ width: '100%', height: '100%' }}>
        <Row direction="column" style={{ height: '100%' }}>
          <Spacing spacing="xl" type="margin" placement="bottom">
            <Typography variant="h5" weight={600}>
              Latest data
            </Typography>
          </Spacing>

          {renderBody()}
        </Row>
      </Spacing>
    </Container>
  );
};
