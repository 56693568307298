import { ButtonByTeam } from 'components/button/ButtonByTeam';
import { Checkbox, Modal, Row, Spacing, Typography, neutral } from 'thesis-ui';

import { DeviceChartLabel } from '../styles/team-data';

import { ReactComponent as DeviceLabelIcon } from 'assets/icons/device-label.svg';

import { type AddDataChartModalProps } from '../core/type';

import { isEmpty } from 'lodash';

import { MODAL_KEY } from 'constants/modal-key';

import { getListDeviceChartAdd, useAddDataChartModalController } from '../core';

export const AddDataChartModal: React.FC<AddDataChartModalProps> = ({ devices, chartLabels }) => {
  const deviceAddChart = getListDeviceChartAdd(devices, chartLabels);
  const {
    selectedDevice,
    disabled,
    onChangeSelectedDevice,
    onSubmitAddChart,
    onChangeSelectedDeviceLabel,
  } = useAddDataChartModalController(deviceAddChart);

  const isDeviceEmpty = !deviceAddChart.length;

  const renderBody = () => {
    if (isDeviceEmpty) {
      return (
        <Row
          align="center"
          justify="center"
          style={{
            height: 140,
          }}
        >
          <Typography
            color={neutral['neutral-7']}
            style={{
              height: 40,
            }}
          >
            There’s no device to select.
          </Typography>
        </Row>
      );
    }
    return (
      <Row direction="column">
        <Typography>
          Select the device and label that you want to show their data in chart.
        </Typography>

        {deviceAddChart.map((device) => {
          const deviceChecked = selectedDevice[device.id]?.length === device.labels?.length;
          const deviceLine = !isEmpty(selectedDevice[device.id]);

          return (
            <Spacing key={device.id} spacing="xl" type="margin" placement="top">
              <Row direction="column">
                <Checkbox
                  checked={deviceLine ? true : deviceChecked}
                  isLine={deviceChecked ? false : deviceLine}
                  style={{ padding: '2px 2px 2px 0px', alignItems: 'start' }}
                  onClick={onChangeSelectedDevice(device, !deviceChecked)}
                >
                  <Typography
                    weight={600}
                    style={{
                      textAlign: 'start',
                    }}
                  >
                    {device.alias}
                  </Typography>
                </Checkbox>
                {!isEmpty(device.labels) && (
                  <Spacing spacing="md" type="padding" placement="left">
                    {device.labels.map((el) => {
                      const labelChecked = selectedDevice[device.id]
                        ? selectedDevice[device.id].some((elLabel) => elLabel === el.label)
                        : false;

                      return (
                        <Spacing key={el.label} spacing="xs" type="margin" placement="top">
                          <Checkbox
                            checked={labelChecked}
                            style={{ padding: '2px 2px 2px 0px', alignItems: 'start' }}
                            onClick={onChangeSelectedDeviceLabel(
                              device.id,
                              el.label,
                              !labelChecked,
                            )}
                          >
                            <DeviceChartLabel align="flex-start">
                              <DeviceLabelIcon />
                              <Typography
                                style={{
                                  textAlign: 'start',
                                  marginLeft: 4,
                                }}
                              >
                                {el.label || 'No label'}
                              </Typography>
                            </DeviceChartLabel>
                          </Checkbox>
                        </Spacing>
                      );
                    })}
                  </Spacing>
                )}
              </Row>
            </Spacing>
          );
        })}
      </Row>
    );
  };

  const renderFooter = () => {
    if (isDeviceEmpty) {
      return <Row />;
    }
    return <ButtonByTeam title="Add chart" disabled={disabled} onClick={onSubmitAddChart} />;
  };

  // To-do-implement design: Add max height in modal: top-margin = 80px and bottom-margin = 80px, header = 50px, footer = 76px
  const bodyStyle: React.CSSProperties = {
    overflow: 'auto',
    maxHeight: 'calc(100vh - 286px)',
  };

  const footerStyle: React.CSSProperties = {
    padding: isDeviceEmpty ? 0 : 16,
  };

  return (
    <Modal
      header="Add chart"
      modalKey={MODAL_KEY.ADD_DATA_CHART}
      size="md"
      footerStyle={footerStyle}
      bodyStyle={bodyStyle}
      footer={renderFooter()}
    >
      <Row direction="column">{renderBody()}</Row>
    </Modal>
  );
};
