import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ApiStoreKey } from 'enums';

import type { TermAndPolicyDetail } from './type';

import {
  useFetchApiToStore,
  useFetchStore,
  valueByStateKeySelector,
} from 'hooks/service/fetch-store';

import { PATH } from 'constants/path';

export const useTermAndPolicyLayoutController = () => {
  const navigationTo = useNavigate();

  const redirectLogin = () => {
    navigationTo(PATH.LOGIN);
  };

  return {
    redirectLogin,
  };
};

export const useTermAndPolicyMenuController = (refPage: React.RefObject<HTMLDivElement>) => {
  const navigationTo = useNavigate();
  const location = useLocation();

  const redirectTo = (path: string) => () => {
    if (refPage.current) {
      refPage.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    navigationTo(path);
  };

  return {
    redirectTo,
    locationPath: location.pathname,
  };
};

export const useTermAndPolicyController = (storeKey: ApiStoreKey) => {
  const { fetchApi } = useFetchApiToStore(storeKey, {
    url: `/api/about/${storeKey}`,
    method: 'GET',
    isLoading: true,
    showError: true,
  });

  useEffect(() => {
    fetchApi();
  }, [storeKey]);

  const result: TermAndPolicyDetail = useFetchStore(valueByStateKeySelector(storeKey));
  const { updated_at, parts } = result?.data ?? {
    updated_at: '',
    parts: [],
  };

  return {
    updated_at,
    parts,
  };
};
