import type { TabItemType } from 'thesis-ui/components/Navigation/Tabs/core/types';

import { PATH } from 'constants/path';

export const getGeneraTabs = (): TabItemType[] => {
  return [
    {
      label: 'My profile',
      key: `${PATH.GENERALS}/${PATH.MY_PROFILE}`,
    },
    {
      label: 'Password',
      key: `${PATH.GENERALS}/${PATH.PASSWORD}`,
    },
    {
      label: 'Settings',
      key: `${PATH.GENERALS}/${PATH.SETTINGS}`,
    },
  ];
};
