import { Row, Spacing, Typography } from 'thesis-ui';

import { DeviceDetailCard } from 'modules/devices/components/DeviceDetailCard';
import type { DeviceDetail } from 'modules/devices/core';

import { PublicModeInformationBody } from '../styles';

export const PublicModeDeviceInformation: React.FC<{
  device: DeviceDetail | null;
}> = ({ device }) => {
  return (
    <PublicModeInformationBody direction="column">
      <Spacing spacing="xl" placement="bottom" type="margin">
        <Typography variant="h5" weight={600}>
          Device information
        </Typography>
      </Spacing>
      <Row justify="center">
        <DeviceDetailCard
          device={device}
          rootStyled={{
            marginBottom: 16,
          }}
        />
      </Row>
    </PublicModeInformationBody>
  );
};
