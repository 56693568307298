import { ChartDataType, ChartPinAt, ChartType } from 'enums/chart';
import { ChartDateRange } from 'enums/date-range';
import { type DateRangePickerInputState, definedDateRanges } from 'thesis-ui';

import type {
  DeviceFilterState,
  DeviceFilterStatus,
  DeviceFormRequest,
  DeviceRecord,
  FormSettingChart,
  RelativeRange,
} from './type';

import { endOfDay, startOfDay, subDays } from 'date-fns';

import {
  DeviceChartDateRangeTabKey,
  DeviceChartRefreshIntervalType,
  DeviceChartRelativeRange,
  DeviceChartYAxisSettingType,
  DeviceState,
  DeviceStatus,
} from './enum';

export const defaultDeviceFormRequest: DeviceFormRequest = Object.freeze({
  custom_id: '',
  alias: '',
  description: '',
  location: '',
});

export const defaultFormSettingChart: FormSettingChart = Object.freeze({
  name: 'Chart name',
  refresh: 30,
  refreshType: DeviceChartRefreshIntervalType.Minute,
  yAxisType: DeviceChartYAxisSettingType.Auto,
  yAxisMin: 0,
  yAxisMax: 1000,
  startDate: subDays(definedDateRanges.startOfToday, 6),
  endDate: definedDateRanges.endOfToday,
  dateRangeType: ChartDateRange['Last 7 days'],
  dateRangeTabKey: DeviceChartDateRangeTabKey.QUICK_OPTIONS,
  relativeRangeTotal: 1,
  relativeRangeAction: DeviceChartRelativeRange.HOUR,
});

export const defaultDeviceChart: DeviceRecord = Object.freeze({
  id: '',
  command_id: '',
  created_at: '',
  deleted_at: '',
  device_id: '',
  name: 'Chart name',
  pin_at: ChartPinAt.DevicePage,
  team_id: '',
  type: ChartType.Line,
  updated_at: '',
  data: '',
  data_type: ChartDataType.Text,
  file_name: '',
  device_name: '',
  label: '',
  tab_key: 0,
  relative_range_total: 1,
  relative_range_action: 1,
});

export const deviceFilterStatus: DeviceFilterStatus[] = [
  {
    key: DeviceStatus.Active,
    label: 'Active',
  },
  {
    key: DeviceStatus.Inactive,
    label: 'Inactive',
  },
];

export const deviceFilterState: DeviceFilterState[] = [
  {
    key: DeviceState.Public,
    label: 'Public',
  },
  {
    key: DeviceState.Private,
    label: 'Private',
  },
];

export const ListYAxisSetting: {
  label: string;
  key: DeviceChartYAxisSettingType;
}[] = [
  {
    key: DeviceChartYAxisSettingType.Auto,
    label: 'Auto adjust',
  },
  {
    key: DeviceChartYAxisSettingType.Manually,
    label: 'Manually adjust',
  },
];

export const defaultDateExport: DateRangePickerInputState[] = [
  {
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
    key: 'selection',
  },
];

export const defaultRelativeRange: RelativeRange = {
  action: 1,
  total: 1,
};
