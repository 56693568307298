import { Outlet } from 'react-router-dom';

import { Row, Tabs } from 'thesis-ui';

import { Container } from '../styles/device-command-layout';

import withDeviceRole from 'hooks/withDeviceRole';
import { isEmpty } from 'lodash';

import { getDeviceCommandTabs, useDeviceCommandLayoutController } from '../core';

const DeviceCommandLayout = () => {
  const { device, locationPath, onTabClick } = useDeviceCommandLayoutController();

  const tabs = device ? getDeviceCommandTabs(device.team_id, device.id) : [];

  return (
    <Container direction="column">
      <Row
        style={{
          marginBottom: 24,
        }}
      >
        {!isEmpty(tabs) && ( // To-do-implement design: Check empty items tabs
          <Tabs
            variant="contained"
            items={tabs}
            activeKey={locationPath}
            onTabClick={onTabClick}
            tabItemStyle={{
              height: 32,
              marginRight: 12,
            }}
          />
        )}
      </Row>
      <Outlet />
    </Container>
  );
};

export default withDeviceRole(DeviceCommandLayout);
