import styled, { css } from 'styled-components';

import { RadiusConfig, Row, neutral, neutralTrans } from 'thesis-ui';

export const Container = styled(Row)``;

export const Body = styled.div<{ isPublic: boolean }>`
  ${({ isPublic }) => {
    const bg = isPublic ? 'transparent' : neutral['neutral-1'];
    const border = isPublic ? 'none' : `1px solid ${neutralTrans['neutralTrans-15']}`;

    return css`
      width: 100%;
      border-radius: ${RadiusConfig.xs}px;
      background-color: ${bg};
      border: ${border};
    `;
  }}
`;

export const StylesTab = styled.div<{ isPublic: boolean }>`
  ${({ isPublic }) => {
    const border = isPublic
      ? `border: 1px solid ${neutralTrans['neutralTrans-15']}`
      : `border-bottom: 1px solid ${neutralTrans['neutralTrans-15']}`;
    const borderRadius = isPublic ? RadiusConfig.xs : RadiusConfig.none;
    const bg = isPublic ? neutral['neutral-1'] : 'transparent';
    return css`
      padding-left: 12px;
      ${border};
      background-color: ${bg};
      border-top-right-radius: ${borderRadius}px;
      border-top-left-radius: ${borderRadius}px;
    `;
  }}
`;
