import { useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useLocation, useNavigate } from 'react-router-dom';

import { HttpStatusCode } from 'enums';
import { useFormik } from 'formik';
import queryString from 'query-string';
import { clearTimer } from 'stores';
import { showToastMessage } from 'thesis-ui';
import isEmail from 'validator/lib/isEmail';

import { getCurrentTimezone } from 'modules/generals/core/helper';

import type { Authen } from './type';

import { getInputStatus, hasEmptyValues, trimObject } from 'helpers';
import { useBoolean, useCountdownTime, useString } from 'hooks';
import { apiCall } from 'hooks/service/api';
import { includes } from 'lodash';

import { ERROR_MESSAGE, SUCCESS_MESSAGE } from 'constants/message';
import { ListTermAndPolicyPath, PATH } from 'constants/path';
import type { ApiResponse } from 'types';

import {
  RECAPTCHA_ACTION,
  defaultForgotPasswordRequest,
  defaultInvitationRequest,
  defaultLoginRequest,
  defaultResetPasswordRequest,
  defaultSignUpRequest,
} from './constant';
import { ConfirmEmailType, UserSystem } from './enum';
import {
  forgotPasswordSchema,
  invitationSchema,
  loginSchema,
  resetPasswordSchema,
  signUpSchema,
} from './schema';
import { updateAuthen } from './store';

export const useAuthLayoutController = () => {
  const location = useLocation();
  const showTermAndPolicy = includes(ListTermAndPolicyPath, location.pathname);
  return {
    showTermAndPolicy,
  };
};

export const useLoginController = () => {
  const navigationTo = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [userSystem, setUserSystem] = useState(UserSystem.TraceliumConnect);

  const formik = useFormik({
    initialValues: defaultLoginRequest,
    validationSchema: loginSchema,
    onSubmit: async (values, { setSubmitting }) => {
      if (!executeRecaptcha) {
        showToastMessage('error', ERROR_MESSAGE.RECAPTCHA_AVAILABLE);
        return;
      }
      const captcha = await executeRecaptcha(RECAPTCHA_ACTION.LOGIN);

      setSubmitting(true);
      const result: Authen = await apiCall({
        url: `/api/auth/login`,
        data: {
          ...values,
          captcha: captcha,
          type: userSystem,
        },
        method: 'POST',
        isLoading: true,
        showError: true,
      });
      if (result?.token) {
        updateAuthen({
          token: result.token,
        });
      }
    },
  });

  const { values, errors, isSubmitting, isValid, handleSubmit, handleChange } = formik;
  const handleUserSystem = () => {
    setUserSystem((prev) =>
      prev === UserSystem.TraceliumConnect ? UserSystem.Tracelium : UserSystem.TraceliumConnect,
    );
  };

  const { email, password } = values;
  const emailStatus = getInputStatus(
    email,
    SUCCESS_MESSAGE.EMAIL,
    !isEmail(email.trim()) ? ERROR_MESSAGE.EMAIL : '',
  );
  const passwordStatus = getInputStatus(password, SUCCESS_MESSAGE.PASSWORD, errors?.password ?? '');
  const disabled =
    hasEmptyValues({ email, password }) || isSubmitting || !isValid || !isEmail(email.trim());

  return {
    email,
    password,
    emailStatus,
    passwordStatus,
    disabled,
    userSystem,
    handleSubmit,
    handleChange,
    navigationTo,
    handleUserSystem,
  };
};

export const useSignUpController = () => {
  const navigationTo = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const isTermAndPolicy = useBoolean(false);

  const formik = useFormik({
    initialValues: defaultSignUpRequest,
    validationSchema: signUpSchema,
    onSubmit: async (values, { setSubmitting }) => {
      if (!executeRecaptcha) {
        showToastMessage('error', ERROR_MESSAGE.RECAPTCHA_AVAILABLE);
        return;
      }
      const captcha = await executeRecaptcha(RECAPTCHA_ACTION.SIGN_UP);

      if (!isTermAndPolicy.value) {
        showToastMessage('error', ERROR_MESSAGE.TERM_AND_POLICY);
        return;
      }
      setSubmitting(true);
      const { companyName, fullName, email, ...propsValues } = values || {};

      const result: ApiResponse = await apiCall({
        url: `/api/auth/register`,
        data: trimObject({
          ...propsValues,
          email,
          fullname: values.fullName,
          company_name: values.companyName,
          captcha,
          timezone: getCurrentTimezone(''),
        }),
        method: 'POST',
        isLoading: true,
        showError: true,
      });
      if (result?.statusCode === HttpStatusCode.Success) {
        navigationTo(PATH.CONFIRM_EMAIL, {
          state: {
            type: ConfirmEmailType.Register,
            email: email,
          },
        });
      }
    },
  });

  const { values, errors, isSubmitting, isValid, handleSubmit, handleChange } = formik;
  const { email, password, companyName, fullName } = values;
  const emailStatus = getInputStatus(
    email,
    SUCCESS_MESSAGE.EMAIL,
    !isEmail(email) ? ERROR_MESSAGE.EMAIL : '',
  );
  const passwordStatus = getInputStatus(password, SUCCESS_MESSAGE.PASSWORD, errors?.password ?? '');
  const disabled =
    hasEmptyValues({ email, password, fullName }) || isSubmitting || !isValid || !isEmail(email);

  return {
    email,
    password,
    fullName,
    companyName,
    emailStatus,
    passwordStatus,
    isTermAndPolicy,
    disabled,
    handleSubmit,
    handleChange,
    navigationTo,
  };
};

export const useConfirmEmailController = () => {
  const navigationTo = useNavigate();
  const location = useLocation();
  const { email, type } = location.state || {};
  const { isTimerRunning, startTimer, timer } = useCountdownTime(type);

  useEffect(() => {
    if (!email) {
      navigationTo(PATH.LOGIN);
    }
  }, [email]);

  const handleResend = async () => {
    if (isTimerRunning) {
      return;
    }
    const result: ApiResponse = await apiCall({
      url: `/api/auth/resend-email/${type}/${email}`,
      method: 'POST',
      isLoading: true,
      showError: true,
    });
    // Call your resend functionality here
    if (result?.statusCode === HttpStatusCode.Success) {
      clearTimer(type);
      startTimer();
      showToastMessage('success', SUCCESS_MESSAGE.RESEND);
    }
  };

  return { email, type, isTimerRunning, timer, handleResend };
};

export const useVerifyController = () => {
  const location = useLocation();
  const { approval } = location.state || {};

  const isVerify = useBoolean(false);

  useEffect(() => {
    if (isVerify.value || approval) {
      return;
    }
    const parsed: any = queryString.parse(location.search);
    if (parsed?.verification_token) {
      verifyEmail(parsed?.verification_token);
    }
  }, [location]);

  const verifyEmail = async (token: string) => {
    await apiCall({
      url: `/api/auth/verify/${token}`,
      method: 'POST',
      isLoading: true,
    });
    isVerify.setValue(true);
  };
};

export const useForgotPasswordController = () => {
  const navigationTo = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const formik = useFormik({
    initialValues: defaultForgotPasswordRequest,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setSubmitting }) => {
      if (!executeRecaptcha) {
        showToastMessage('error', ERROR_MESSAGE.RECAPTCHA_AVAILABLE);
        return;
      }
      const captcha = await executeRecaptcha(RECAPTCHA_ACTION.FORGOT_PASSWORD);

      setSubmitting(true);
      const result: ApiResponse = await apiCall({
        url: `/api/auth/forgot-password`,
        data: {
          ...values,
          captcha,
        },
        method: 'POST',
        isLoading: true,
        showError: true,
      });
      if (result?.statusCode === HttpStatusCode.Success) {
        showToastMessage('success', SUCCESS_MESSAGE.RESET);
        navigationTo(PATH.CONFIRM_EMAIL, {
          state: {
            type: ConfirmEmailType.ForgotPassword,
            email: values.email,
          },
        });
      }
    },
  });

  const { values, isSubmitting, isValid, handleSubmit, handleChange } = formik;
  const { email } = values;
  const emailStatus = getInputStatus(
    email,
    SUCCESS_MESSAGE.EMAIL,
    !isEmail(email) ? ERROR_MESSAGE.EMAIL : '',
  );

  const disabled = hasEmptyValues({ email }) || isSubmitting || !isValid || !isEmail(email);

  return { email, emailStatus, disabled, navigationTo, handleSubmit, handleChange };
};

export const useResetPasswordController = () => {
  const navigationTo = useNavigate();
  const location = useLocation();
  const token = useString('');
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    const parsed: any = queryString.parse(location.search);
    if (parsed?.token) {
      token.setValue(parsed?.token);
    }
  }, [location]);

  const formik = useFormik({
    initialValues: defaultResetPasswordRequest,
    validationSchema: resetPasswordSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      if (!executeRecaptcha) {
        showToastMessage('error', ERROR_MESSAGE.RECAPTCHA_AVAILABLE);
        return;
      }
      const captcha = await executeRecaptcha(RECAPTCHA_ACTION.RESET_PASSWORD);

      const { password } = values;
      const result: ApiResponse = await apiCall({
        url: `/api/auth/reset-password`,
        data: trimObject({
          captcha,
          password,
          resetPasswordToken: token.value,
          confirmedPassword: values.password,
        }),
        method: 'POST',
        isLoading: true,
        showError: true,
      });
      if (result?.statusCode === HttpStatusCode.Success) {
        showToastMessage('success', SUCCESS_MESSAGE.PASSWORD_UPDATED);
        navigationTo(PATH.PASSWORD_UPDATED);
      }
    },
  });

  const { values, errors, isSubmitting, isValid, handleSubmit, handleChange } = formik;
  const { password } = values;

  const passwordStatus = getInputStatus(password, SUCCESS_MESSAGE.PASSWORD, errors?.password ?? '');

  const disabled = hasEmptyValues({ password }) || isSubmitting || !isValid;

  return { password, disabled, passwordStatus, handleSubmit, handleChange };
};

export const usePasswordUpdatedController = () => {
  const navigationTo = useNavigate();
  return {
    navigationTo,
  };
};

export const useInvitationController = () => {
  const navigationTo = useNavigate();
  const location = useLocation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const parsedAccountInfoInLocation = queryString.parse(location.search);
  const { email, token } = parsedAccountInfoInLocation || {};
  const isTermAndPolicy = useBoolean(false);

  const formik = useFormik({
    initialValues: defaultInvitationRequest,
    validationSchema: invitationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      if (!executeRecaptcha) {
        showToastMessage('error', ERROR_MESSAGE.RECAPTCHA_AVAILABLE);
        return;
      }
      const captcha = await executeRecaptcha(RECAPTCHA_ACTION.SIGN_UP);

      if (!isTermAndPolicy.value) {
        showToastMessage('error', ERROR_MESSAGE.TERM_AND_POLICY);
        return;
      }

      setSubmitting(true);
      const { companyName, fullName, ...propsValues } = values || {};
      const result: ApiResponse = await apiCall({
        url: `/api/auth/create-password-and-verify/${token}`,
        data: trimObject({
          ...propsValues,
          fullname: values.fullName,
          company_name: values.companyName,
          confirmedPassword: values.password,
          captcha,
          timezone: getCurrentTimezone(''),
        }),
        method: 'POST',
        isLoading: true,
        showError: true,
      });
      if (result?.statusCode === HttpStatusCode.Success) {
        navigationTo(PATH.VERIFY, {
          state: {
            approval: true,
          },
        });
      }
    },
  });

  const { values, errors, isSubmitting, isValid, handleSubmit, handleChange } = formik;
  const { password, companyName, fullName } = values;

  const passwordStatus = getInputStatus(password, SUCCESS_MESSAGE.PASSWORD, errors?.password ?? '');
  const disabled = hasEmptyValues({ password, fullName }) || isSubmitting || !isValid;

  return {
    password,
    fullName,
    companyName,
    passwordStatus,
    isTermAndPolicy,
    disabled,
    email,
    handleSubmit,
    handleChange,
    navigationTo,
  };
};
