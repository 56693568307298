import type { MenuCollapseKey } from 'modules/team';

import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { LOCAL_STORAGE_MENU_COLLAPSED } from 'constants/local-storage';

interface State {
  collapsed: Record<MenuCollapseKey, boolean>;
}

interface Store {
  state: State;
  updateCollapsed: (key: MenuCollapseKey) => void;
  clearCollapse: () => void;
}

const initState: State = {
  collapsed: {
    allTeams: false,
    myTeams: true,
    sharedWithMe: true,
  },
};

export const useMenuCollapsedStore = create(
  persist(
    immer<Store>((set) => ({
      state: initState,
      updateCollapsed: (key) => {
        set((store) => {
          store.state.collapsed[key] = !store.state.collapsed[key];
        });
      },
      clearCollapse: () => {
        set((store) => {
          store.state = initState;
        });
      },
    })),
    {
      name: LOCAL_STORAGE_MENU_COLLAPSED,
    },
  ),
);

export const { clearCollapse, updateCollapsed } = useMenuCollapsedStore.getState();

export const menuCollapsedSelector = (s: Store) => s.state.collapsed;
