import React from 'react';

import { ConfirmModal } from 'components';
import { blue } from 'thesis-ui';

import { getBgButtonByTeam } from 'helpers';

import { MODAL_KEY } from 'constants/modal-key';

import { useTeamModalController, useTeamUserShareController } from '../core';
import { FormTeamModal } from './FormTeamModal';
import { StorageLimitReachedModal } from './StorageLimitReachedModal';
import { TeamShareModal } from './TeamShareModal';
import { TermAndPolicyUpdateModal } from './TermAndPolicyUpdateModal';
import { VersionUpdateModal } from './VersionUpdateModal';

export const TeamModal = () => {
  const { onSubmit } = useTeamModalController();
  const { team, onSubmitTransfer } = useTeamUserShareController();

  return (
    <React.Fragment>
      <FormTeamModal />
      <TeamShareModal />
      <VersionUpdateModal />
      <TermAndPolicyUpdateModal />
      <ConfirmModal
        modalKey={MODAL_KEY.CONFIRM}
        submitButton={{
          buttonProps: {
            onClick: onSubmit,
          },
        }}
      />
      <ConfirmModal
        modalKey={MODAL_KEY.TRANSFER}
        overlay={{
          show: true,
          disabled: false,
          color: team?.colour ?? blue['blue-5'],
        }}
        submitButton={{
          buttonProps: {
            onClick: onSubmitTransfer,
            style: {
              ...getBgButtonByTeam(false, team?.colour),
              width: 178,
              position: 'relative',
            },
          },
          label: 'Transfer role',
        }}
      />
      <StorageLimitReachedModal colour={team?.colour || blue['blue-5']} />
    </React.Fragment>
  );
};
