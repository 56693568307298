import { EmptyValue } from 'components/data/EmptyValue';
import { neutral } from 'thesis-ui';

export const getDevicesColumns = () => {
  return [
    {
      id: 'custom_id',
      Header: 'Device ID',
      minWidth: '140px',
      maxWidth: '140px',
      Cell: ({ ...props }) => {
        const customId = props.data[props.row.index].custom_id;

        return (
          <EmptyValue
            value={customId}
            rootStyle={{
              color: neutral['neutral-7'],
              wordBreak: 'break-word',
            }}
          />
        );
      },
    },
    {
      id: 'alias',
      Header: 'Device name',
      accessor: 'alias',
      width: '100%',
      minWidth: '100%',
    },
    {
      id: 'usage',
      Header: 'Used',
      width: '108px',
      minWidth: '108px',
      Cell: ({ ...props }) => {
        const usage = props.data[props.row.index]?.usage;

        return <EmptyValue value={usage} />;
      },
    },
  ];
};
