import { InputTitle } from 'components';
import { Button, Row, Spacing, TextInput } from 'thesis-ui';

import { InputPasswordTooltipMessage } from 'modules/auth/components';

import { usePasswordController } from '../core/controllers/password';

export const PasswordPage = () => {
  const {
    currentPassword,
    newPassword,
    currentPasswordStatus,
    newPasswordStatus,
    submitDisabled,
    cancelDisabled,
    handleChange,
    handleSubmit,
    onCancel,
  } = usePasswordController();

  return (
    <Row direction="column">
      <Spacing
        spacing="sm"
        placement="bottom"
        type="margin"
        style={{
          width: '100%',
        }}
      >
        <TextInput
          name="currentPassword"
          type="password"
          title="Old password"
          value={currentPassword}
          onChange={handleChange}
          helperText={currentPasswordStatus.helperText}
          color={currentPasswordStatus.color}
        />
      </Spacing>
      <InputTitle title="New password" tooltipMessage={<InputPasswordTooltipMessage />} />
      <TextInput
        name="newPassword"
        type="password"
        value={newPassword}
        onChange={handleChange}
        helperText={newPasswordStatus.helperText}
        color={newPasswordStatus.color}
      />
      <Spacing
        spacing="xl"
        placement="top"
        type="margin"
        style={{
          width: '100%',
        }}
      >
        <Row align="center" justify="space-between">
          <Button
            style={{
              width: 198,
            }}
            disabled={cancelDisabled}
            variant="contained"
            size="large"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            style={{
              width: 198,
            }}
            disabled={submitDisabled}
            onClick={() => handleSubmit()}
            variant="contained"
            size="large"
            color="primary"
          >
            Save
          </Button>
        </Row>
      </Spacing>
    </Row>
  );
};
