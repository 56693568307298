import type {
  Authen,
  ForgotPasswordRequest,
  InvitationRequest,
  LoginRequest,
  ResetPasswordRequest,
  SignUpRequest,
  UserProfile,
} from './type';

import { ConfirmEmailType, Role, UserTierName } from './enum';

/*
  Passwords must be at least 8 characters long, at least one uppercase letter,
  one lowercase letter, one special character and one number!
*/
export const REGEX_PASSWORD =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_#^()+=~`{}|/:;'"<>[,.-])[A-Za-z\d@$!%*?&_#^()+=~`{}|/:;'"<>[,.-]{8,}$/;

export const defaultLoginRequest: LoginRequest = {
  email: '',
  password: '',
  captcha: 'string',
};

export const defaultUserProfile: UserProfile = {
  email: '',
  id: '',
  company_name: '',
  fullname: '',
  permissions: [],
  policies: [],
  version: '',
  role_id: Role.User,
  timezone: '+00:00',
  tier: {
    id: '',
    name: UserTierName.FreeUser,
  },
};

export const defaultAuthen: Authen = {
  token: '',
};

export const defaultSignUpRequest: SignUpRequest = {
  email: '',
  password: '',
  captcha: 'string',
  companyName: '',
  fullName: '',
};

export const defaultForgotPasswordRequest: ForgotPasswordRequest = {
  email: '',
  captcha: 'string',
};

export const defaultResetPasswordRequest: ResetPasswordRequest = {
  password: '',
  captcha: 'string',
  confirmedPassword: '',
  resetPasswordToken: '',
};

export const defaultInvitationRequest: InvitationRequest = {
  captcha: 'string',
  companyName: '',
  fullName: '',
  password: '',
};

export const EMAIL_CONFIRM_TEXT: Record<ConfirmEmailType, { title: string; label: string }> = {
  [ConfirmEmailType.Register]: {
    title: 'Verify your email',
    label: 'We have sent you a confirmation link via email:',
  },
  [ConfirmEmailType.ForgotPassword]: {
    title: 'Reset password',
    label: 'We have sent a link to reset password via email:',
  },
};

export const RECAPTCHA_ACTION = Object.freeze({
  LOGIN: 'login',
  SIGN_UP: 'sign_up',
  FORGOT_PASSWORD: 'forgot_password',
  RESET_PASSWORD: 'reset_password',
});
