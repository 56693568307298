import ReactDOM from 'react-dom/client';

import { AppRoutes } from 'routing/AppRoutes';

import './styles/fonts.css';
import './styles/global.css';
import 'thesis-ui/styles/toast.css';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<AppRoutes />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
