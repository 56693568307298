import { Route, Routes } from 'react-router-dom';

import { ApiStoreKey } from 'enums';

import { replacePath } from 'helpers';

import { PATH } from 'constants/path';

import { TermAndPolicyLayout } from './components';
import { TermAndPolicyPage } from './pages';

export const TermAndPolicyRoutes = () => (
  <Routes>
    <Route element={<TermAndPolicyLayout />}>
      <Route
        path={replacePath(PATH.TERMS_AND_CONDITIONS, '/about/', '')}
        element={
          <TermAndPolicyPage name="Terms and Conditions" storeKey={ApiStoreKey.TermAndConditions} />
        }
      />

      <Route
        path={replacePath(PATH.PRIVACY_POLICY, '/about/', '')}
        element={<TermAndPolicyPage name="Privacy Policy" storeKey={ApiStoreKey.PrivacyPolicy} />}
      />
    </Route>
  </Routes>
);
