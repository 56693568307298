import { ButtonByTeam } from 'components/button/ButtonByTeam';
import { Button, DateRangePickerInput, Dropdown, Row, Spacing } from 'thesis-ui';

import { ReactComponent as ExportIcon } from 'assets/icons/export.svg';
import { ReactComponent as ThreeDotsIcon } from 'assets/icons/three-dots.svg';

import { type DeviceDataActionProps, deviceDataActions } from '../core';

export const DeviceDataAction: React.FC<DeviceDataActionProps> = ({
  openExport,
  dateExport,
  actionVisible,
  onChangeExportDate,
  onChangeOpenExport,
  onSubmitExport,
  onChangeDropdownAction,
  onClickMenuItem,
}) => {
  return (
    <Row
      align="center"
      style={{
        width: 'fit-content',
      }}
    >
      <DateRangePickerInput
        isStaticRanges={false}
        openMenu={openExport.value}
        state={dateExport}
        onChange={onChangeExportDate}
        onClickOutside={onChangeOpenExport(false)}
        dropdownContent={
          <Row
            align="center"
            justify="space-between"
            style={{
              padding: '4px 16px 16px 16px',
            }}
          >
            <Button
              onClick={onChangeOpenExport(false)}
              style={{
                width: 113,
              }}
              size="large"
            >
              Cancel
            </Button>

            <ButtonByTeam
              onClick={onSubmitExport}
              title="Export"
              size="large"
              rootStyle={{
                width: 113,
              }}
            />
          </Row>
        }
        selectBody={
          <Spacing spacing="sm" type="margin" placement="right">
            <Button
              onClick={onChangeOpenExport(!openExport.value)}
              placementIcon="left"
              icon={<ExportIcon />}
              size="small"
            >
              Export
            </Button>
          </Spacing>
        }
      />

      <Dropdown
        theme="dark"
        dropdownConfig={{
          placement: 'bottom-end',
          trigger: 'click',
          offset: [7, 5],
          visible: actionVisible.value,
          onClickOutside: onChangeDropdownAction,
        }}
        dropdownMenuStyle={{
          minWidth: 133,
        }}
        menu={{
          items: deviceDataActions,
          selectedKeys: [],
          onClick: onClickMenuItem,
        }}
      >
        <Button
          onClick={onChangeDropdownAction}
          placementIcon="left"
          icon={<ThreeDotsIcon />}
          style={{
            width: 32,
            height: 32,
          }}
        />
      </Dropdown>
    </Row>
  );
};
