import { ConfirmModal } from 'components';
import { Spacing, Table, Tooltip, openModal } from 'thesis-ui';

import { ButtonDrag, Container, StyledButtonAdd, StyledDraggable } from '../styles/command-queue';

import { ReactComponent as GridVerticalIcon } from 'assets/icons/grip-vertical.svg';

import { MODAL_KEY } from 'constants/modal-key';

import { ButtonAddCommand, CollectionLabel } from '../components';
import { EmptyData } from '../components/EmptyData';
import { CommandType, getQueueColumns, useDeviceCommandQueueController } from '../core';
import { AddCommandFromCollectionModal } from '../modal/AddCommandFromCollectionModal';
import { CommandNoteModal } from '../modal/CommandNoteModal';
import { FormCommandModal } from '../modal/FormCommandModal';

export const DeviceCommandQueue = () => {
  const {
    queues,
    queryState,
    total,
    isLoading,
    devicePermission,
    deviceQueueTableKey,
    onClickMenuItem,
    updateQueryState,
    callbackWhenAddCollection,
    callbackWhenAddQueue,
    onDeleteQueue,
    onDraggableEnd,
  } = useDeviceCommandQueueController();

  const columns = getQueueColumns(devicePermission, onClickMenuItem);

  const renderBody = () => {
    if (isLoading.value) {
      return null;
    }

    if (!queues.length) {
      return <EmptyData title="No command in queue." permission={devicePermission} />;
    }

    return (
      <Table
        columns={columns}
        tableKey={deviceQueueTableKey}
        dataSource={queues}
        total={total.value}
        queryState={queryState}
        updateQueryState={updateQueryState}
        onClickRow={(row) => openModal(MODAL_KEY.COMMAND_NOTE, row.original)}
        isDraggable={true}
        useDragHandle={true}
        rowDraggableStyle={StyledDraggable as any} // To-do-implement design: Change type rowDraggableStyle
        onDraggableEnd={onDraggableEnd}
        iconDraggable={
          <Tooltip
            arrow={false}
            placement="top"
            content="Drag to reorder"
            offset={[0, 6]}
            allowHTML
            interactive
          >
            <ButtonDrag className="button-drag" align="center" justify="center">
              <GridVerticalIcon />
            </ButtonDrag>
          </Tooltip>
        }
        paginationConfigs={{
          stylePaginationContainer: {
            display: 'none',
          },
        }}
      />
    );
  };
  return (
    <Container direction="column">
      {Boolean(queues.length) && (
        <StyledButtonAdd>
          <ButtonAddCommand
            disabled={!devicePermission}
            size="small"
            placement="bottom-end"
            offset={[7, 0]}
          />
        </StyledButtonAdd>
      )}
      <Spacing spacing="md" placement="bottom" type="margin">
        <CollectionLabel
          label="The top command will be retrieved first. Once a command is retrieved, it will be automatically removed from the queue."
          type="info"
        />
      </Spacing>
      {renderBody()}
      <AddCommandFromCollectionModal callback={callbackWhenAddQueue} />
      <FormCommandModal
        type={CommandType.Collection}
        modalKey={MODAL_KEY.FORM_COMMAND_COLLECTION}
        callback={callbackWhenAddCollection}
      />
      <FormCommandModal
        type={CommandType.Queue}
        modalKey={MODAL_KEY.FORM_COMMAND_QUEUE}
        callback={callbackWhenAddQueue}
      />
      <ConfirmModal
        modalKey={MODAL_KEY.DELETE_QUEUE}
        submitButton={{
          buttonProps: {
            onClick: onDeleteQueue,
          },
        }}
      />
      <CommandNoteModal modalKey={MODAL_KEY.FORM_COMMAND_QUEUE} />
    </Container>
  );
};
