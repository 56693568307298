import styled from 'styled-components';

import { neutral } from 'thesis-ui';

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 4px;
  svg path[stroke] {
    stroke: ${neutral['neutral-1']};
  }
  svg path[fill] {
    fill: ${neutral['neutral-1']};
  }
`;
