import axios, { AxiosError } from 'axios';

import { authenSelector, useAuthStore } from 'modules/auth/core';

import { API_URL } from '../constants/environment';

const getAccessToken: () => string = () => {
  /*TO DO: Get user's access token from your current storage
    E.g: localStorage.getItem("access_token")
   */
  const auth = authenSelector(useAuthStore.getState());

  return auth?.token;
};

const createHeaderToken = () => {
  const tokenType = 'Bearer'; //Modify your logic to get more token types
  const accessToken = getAccessToken();
  return `${tokenType} ${accessToken}`;
};

const initAxiosInterceptor = () => {
  axios.interceptors.request.use((config) => {
    //Set timeout if server takes too long to response: config.timeout = DEFAULT_MAX_TIMEOUT;

    config.url = `${config.url?.indexOf('http') !== 0 ? API_URL : ''}${config.url}`;
    config.headers.Authorization = createHeaderToken();
    config.headers.Accept = 'application/json';

    // TO DO: If you want loading effect when sending request, put your start loading function here
    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      //Put your close loading function here
      return response;
    },
    (error: AxiosError) => {
      return Promise.reject(error);
    },
  );
};

export const publicAxiosInstance = axios.create({
  baseURL: API_URL,
});

export { initAxiosInterceptor };
