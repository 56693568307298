import React from 'react';

import { ConfirmModal } from 'components';
import { ButtonByTeam } from 'components/button/ButtonByTeam';
import type { ButtonSize } from 'components/button/core';
import { Row, Spacing, Table, openModal } from 'thesis-ui';

import { StyledButtonAdd } from '../styles/command-queue';

import { ReactComponent as AddIcon } from 'assets/icons/add.svg';

import { MODAL_KEY } from 'constants/modal-key';

import { CollectionLabel } from '../components';
import { EmptyData } from '../components/EmptyData';
import { CommandType, getCollectionColumns, useDeviceCommandCollectionController } from '../core';
import { CommandNoteModal } from '../modal/CommandNoteModal';
import { FormCommandModal } from '../modal/FormCommandModal';

export const DeviceCommandCollection = () => {
  const {
    collections,
    queryState,
    total,
    isLoading,
    devicePermission,
    deviceCollectionTableKey,
    isCommandMaximum,
    collectionLabel,
    disableBtnAddCommand,
    onClickMenuItem,
    updateQueryState,
    callbackWhenAddCollection,
    onAddCommand,
    onDeleteCollection,
  } = useDeviceCommandCollectionController();

  const columns = getCollectionColumns(devicePermission, onClickMenuItem);

  const renderButtonAddCommand = (size: ButtonSize) => {
    return (
      <ButtonByTeam
        size={size}
        icon={<AddIcon />}
        disabled={disableBtnAddCommand}
        title="Add command"
        onClick={onAddCommand}
      />
    );
  };

  const renderBody = () => {
    if (isLoading.value) {
      return null;
    }

    if (!collections.length) {
      return (
        <EmptyData
          title="No command in collection"
          permission={devicePermission}
          button={renderButtonAddCommand('large')}
        />
      );
    }
    return (
      <Table
        columns={columns}
        tableKey={deviceCollectionTableKey}
        dataSource={collections}
        total={total.value}
        queryState={queryState}
        updateQueryState={updateQueryState}
        onClickRow={(row) => openModal(MODAL_KEY.COMMAND_NOTE, row.original)}
        paginationConfigs={{
          stylePaginationContainer: {
            display: 'none',
          },
        }}
      />
    );
  };

  return (
    <Row
      direction="column"
      style={{
        height: '100%',
      }}
    >
      {Boolean(collections.length) && (
        <React.Fragment>
          <Spacing spacing="md" type="margin" placement="bottom">
            <CollectionLabel label={collectionLabel} type={isCommandMaximum ? 'warning' : 'info'} />
          </Spacing>
          <StyledButtonAdd>{renderButtonAddCommand('small')}</StyledButtonAdd>
        </React.Fragment>
      )}

      {renderBody()}
      <FormCommandModal
        type={CommandType.Collection}
        modalKey={MODAL_KEY.FORM_COMMAND_COLLECTION}
        callback={callbackWhenAddCollection}
      />

      <ConfirmModal
        modalKey={MODAL_KEY.DELETE_COLLECTION}
        submitButton={{
          buttonProps: {
            onClick: onDeleteCollection,
          },
        }}
      />
      <CommandNoteModal modalKey={MODAL_KEY.FORM_COMMAND_COLLECTION} />
    </Row>
  );
};
