import type { DropdownMenuItem } from 'thesis-ui';

import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';

import { DropDownLabel } from '../components';
import { TeamActionKey } from './enum';

export const teamActions = (hasDelete = false): DropdownMenuItem[] => {
  const data = [
    {
      key: TeamActionKey.EditTeam,
      label: <DropDownLabel title="Edit team" icon={<EditIcon />} />,
    },
  ];
  if (hasDelete) {
    data.push({
      key: TeamActionKey.DeleteTeam,
      label: <DropDownLabel title="Delete team" icon={<DeleteIcon />} />,
    });
  }

  return data;
};
