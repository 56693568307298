import { InputTitle } from 'components';
import { Button, Row, Spacing, TextInput, Typography, neutralTrans } from 'thesis-ui';

import { AuthForm, Line, TitleLink } from '../styles';
import { Logo } from '../styles/login';

import TraceliumConnectLogo from 'assets/images/tracelium-connect-logo.svg';
import TraceliumLogo from 'assets/images/tracelium-logo.svg';

import { PATH } from 'constants/path';

import { InputPasswordTooltipMessage } from '../components';
import { UserSystem, useLoginController } from '../core';

export const LoginPage = () => {
  const {
    email,
    password,
    passwordStatus,
    emailStatus,
    disabled,
    userSystem,
    handleUserSystem,
    handleChange,
    handleSubmit,
    navigationTo,
  } = useLoginController();

  const isLoginWithTraceliumConnect = userSystem === UserSystem.TraceliumConnect;

  const btnLogo = isLoginWithTraceliumConnect ? TraceliumLogo : TraceliumConnectLogo;
  const btnTitle = isLoginWithTraceliumConnect ? 'Tracelium' : 'Tracelium Connect';
  const title = isLoginWithTraceliumConnect ? 'Log In' : 'Log In with Tracelium account';

  return (
    <AuthForm onSubmit={handleSubmit}>
      <Spacing spacing="xl" placement="bottom" type="margin">
        <Typography
          variant="h4"
          weight={700}
          style={{
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>
      </Spacing>
      <Spacing spacing="sm" placement="bottom" type="margin">
        <TextInput
          title="Email"
          name="email"
          value={email}
          onChange={handleChange}
          helperText={emailStatus.helperText}
          color={emailStatus.color}
        />
      </Spacing>
      <InputTitle title="Password" tooltipMessage={<InputPasswordTooltipMessage />} />
      <TextInput
        name="password"
        type="password"
        value={password}
        onChange={handleChange}
        helperText={passwordStatus.helperText}
        color={passwordStatus.color}
        autoComplete="new-password"
      />
      {isLoginWithTraceliumConnect && (
        <Spacing spacing="sm" placement="top" type="margin">
          <TitleLink
            onClick={() => navigationTo(PATH.FORGOT_PASSWORD)}
            style={{
              margin: 0,
              width: 'fit-content',
            }}
          >
            Forgot password?
          </TitleLink>
        </Spacing>
      )}
      <Spacing spacing="xl" placement={['bottom', 'top']} type="margin">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          size="large"
          style={{
            width: '100%',
          }}
          disabled={disabled}
        >
          Login
        </Button>
      </Spacing>
      {isLoginWithTraceliumConnect && (
        <Spacing type="margin" placement="bottom" spacing="xl">
          <Row align="center" justify="center">
            <Typography variant="body-1" weight={400}>
              Don’t have an account?
            </Typography>
            <TitleLink onClick={() => navigationTo(PATH.SIGN_UP)}> Sign up</TitleLink>
          </Row>
        </Spacing>
      )}
      <Row justify="space-between" align="center">
        <Line />
        <Typography color={neutralTrans['neutralTrans-25']} variant="body-2">
          or login with
        </Typography>
        <Line />
      </Row>
      <Spacing type="margin" placement="top" spacing="sm">
        <Button
          onClick={handleUserSystem}
          placementIcon="left"
          icon={<Logo src={btnLogo} />}
          variant="contained"
          size="large"
          style={{
            width: '100%',
          }}
        >
          {btnTitle}
        </Button>
      </Spacing>
    </AuthForm>
  );
};
