import { ButtonByTeam } from 'components/button/ButtonByTeam';
import { Button, Modal, Radio, Row, Spacing, Typography, neutral } from 'thesis-ui';

import { ReactComponent as AddIcon } from 'assets/icons/add.svg';

import { MODAL_KEY } from 'constants/modal-key';

import {
  type AddCommandFromCollectionModalProps,
  useAddCommandFromCollectionModalController,
} from '../core';

export const AddCommandFromCollectionModal: React.FC<AddCommandFromCollectionModalProps> = ({
  callback,
}) => {
  const {
    collections,
    collectionSelect,
    isCollection,
    disabled,
    onOpenFormAddCommand,
    onCollectionSelect,
    handleSubmit,
  } = useAddCommandFromCollectionModalController(callback);

  const renderBody = () => {
    if (isCollection) {
      return (
        <Row direction="column">
          <Spacing spacing="xs" type="margin" placement="bottom">
            <Typography>
              Select the command from collection that you want to add to queue.
            </Typography>
          </Spacing>
          {collections.map((collection, index) => {
            const marginBottom = index + 1 === collections.length ? 0 : 8;
            const checked = collectionSelect?.id === collection.id;
            return (
              <Radio
                key={collection.id}
                checked={checked}
                style={{ marginBottom: marginBottom, height: 'fit-content', width: '100%' }}
                onClick={onCollectionSelect(collection)}
              >
                <Typography style={{ textAlign: 'start', width: 'calc(100% - 24px)' }}>
                  {collection.name}
                </Typography>
              </Radio>
            );
          })}
        </Row>
      );
    }
    return (
      <Spacing spacing="xl" placement="top" style={{ width: '100%' }}>
        <Typography color={neutral['neutral-7']} style={{ textAlign: 'center' }}>
          There’s no command in collection to select.
        </Typography>
      </Spacing>
    );
  };

  const renderFooter = () => {
    if (isCollection) {
      return <ButtonByTeam onClick={handleSubmit} title="Add to queue" disabled={disabled} />;
    }
    return (
      <Row justify="center" style={{ marginBottom: 24 }}>
        <Button onClick={onOpenFormAddCommand} size="large" placementIcon="left" icon={<AddIcon />}>
          Add command in collection
        </Button>
      </Row>
    );
  };

  return (
    <Modal
      header="Add command from collection"
      modalKey={MODAL_KEY.ADD_COMMAND_FROM_COLLECTION}
      size="md"
      bodyStyle={{ padding: isCollection ? 16 : '16px 16px 8px 16px' }}
      footer={renderFooter()}
    >
      <Row direction="column">{renderBody()}</Row>
    </Modal>
  );
};
