import styled from 'styled-components';

import { RadiusConfig, Row, neutral, neutralTrans } from 'thesis-ui';

export const Container = styled(Row)`
  padding: 24px;
  min-height: 100vh;
`;

export const Body = styled(Row)`
  width: 440px;
  margin-top: 34px;
  border-radius: ${RadiusConfig.xs}px;
  border: 1px solid ${neutralTrans['neutralTrans-15']};
  background-color: ${neutral['neutral-1']};
  position: relative;
  min-height: 511px;
`;

export const Header = styled(Row)`
  padding: 16px;
`;

export const ContainerAvatar = styled(Row)`
  position: absolute;
  top: -34px;
  width: fit-content;
  div:nth-child(1) {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }
`;

export const ReCommend = styled(Row)`
  height: 32px;
`;

export const StyledTab = styled(Row)`
  padding-left: 4px;
  border-bottom: 1px solid ${neutralTrans['neutralTrans-15']};
`;

export const StyledOutlet = styled.div`
  padding: 24px 16px 16px 16px;
  width: 100%;
`;
