import { blue, getFirstLetterInName } from 'thesis-ui';

import { LETTER_COLOR_CONFIG } from 'constants/avatar';

import allTimezones from '../timezone.json';

export const getUserColor = (fullName: string) => {
  if (!fullName) {
    return 'transparent';
  }
  const firstLetter = getFirstLetterInName(fullName, true);
  const keyColorConfig = Object.keys(LETTER_COLOR_CONFIG).find((key) =>
    key.includes(firstLetter.toUpperCase()),
  );

  if (keyColorConfig) {
    return LETTER_COLOR_CONFIG[
      keyColorConfig as 'AGMSY' | 'BHNTZ' | 'CIOU' | 'DJPV' | 'EKQW' | 'FLRX'
    ];
  }
  return blue['blue-5'];
};

export const getAllTimezones = () => {
  return allTimezones;
};

export const getCurrentTimezone = (originTimezone: string) => {
  let timezone = originTimezone || '';
  if (!timezone) {
    const timezoneOffset = new Date().getTimezoneOffset();
    const currentTimezone = timezoneOffset / 60;
    const existTimezone = allTimezones.find((el) => el.offset === -currentTimezone);
    if (existTimezone) {
      timezone = existTimezone.value;
    }
  }
  return timezone;
};
