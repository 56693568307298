import { type CSSProperties, useEffect, useRef } from 'react';
import ReactFlow, { Handle, Position } from 'reactflow';
import 'reactflow/dist/style.css';

import { EmptyData } from 'components/data/EmptyData';
import { Row, Typography, neutral } from 'thesis-ui';

import { CanvasContainer, CustomNode, LegendContainer } from '../styles/device-dashboard';
import { Header } from '../styles/device-data';

import { ReactComponent as DeviceLabelIcon } from 'assets/icons/device-label.svg';
import { ReactComponent as HouseIcon } from 'assets/icons/house.svg';
import { ReactComponent as EmptyState } from 'assets/images/empty-state.svg';

import { mainNodeId, useDeviceDashboardController } from '../core/controllers';

const dotStyles: CSSProperties = {
  width: 11,
  height: 11,
  border: 'none',
};

const getNodeBg = (detected: boolean) => (detected ? 'red' : '#D4D4D4');

const MainNode = ({ data: { detected } }: { data: { detected: boolean } }) => (
  <div>
    <HouseIcon />

    <Handle
      type="source"
      position={Position.Bottom}
      isConnectable={false}
      id={mainNodeId}
      style={{ ...dotStyles, bottom: -1, background: getNodeBg(detected) }}
    />
  </div>
);

const RecordNode = ({
  data: { label, logs, detected },
}: {
  data: { label: string; logs: string[]; detected: boolean };
}) => {
  const ref = useRef<any>(null);

  useEffect(() => {
    ref.current?.scrollTo({ top: ref.current?.scrollHeight });
  }, []);

  return (
    <div style={{ padding: 12 }}>
      <CustomNode
        direction="column"
        className="nowheel"
        style={{ borderColor: detected ? 'red' : undefined }}
      >
        <Row className="header" align="center">
          <DeviceLabelIcon className="device-label-icon" />
          <Typography variant="body-1" weight={600} className="text-ellipsis-1">
            {label}
          </Typography>
        </Row>

        <div
          style={{
            overflow: 'hidden',
            borderBottomLeftRadius: 6,
            borderBottomRightRadius: 6,
            width: '100%',
          }}
        >
          <div className="content nowheel" ref={ref}>
            <Row direction="column">
              {logs.length ? (
                logs.map((log, index) => <Typography key={index}>{log}</Typography>)
              ) : (
                <Typography variant="body-1" color="#7A7A7A">
                  No message
                </Typography>
              )}
            </Row>
          </div>
        </div>

        <Handle
          type="target"
          position={Position.Top}
          isConnectable={false}
          id={label}
          style={{ ...dotStyles, top: 6.5, background: getNodeBg(detected) }}
        />
      </CustomNode>
    </div>
  );
};

const nodeTypes = {
  mainNode: MainNode,
  childNode: RecordNode,
};

export const DeviceDashboard = () => {
  const { nodes, edges } = useDeviceDashboardController();

  return (
    <div style={{ width: '100%' }}>
      <Header justify="space-between" align="center">
        <Typography weight={600} variant="h5">
          Command activity
        </Typography>

        {edges.length ? (
          <LegendContainer>
            <Row align="center">
              <div className="nota-1" />
              <Typography color={neutral['neutral-7']}>Detected within 24 hours</Typography>
            </Row>
            <Row align="center">
              <div className="nota-2" />
              <Typography color={neutral['neutral-7']}>Not Detected within 24 hours.</Typography>
            </Row>
          </LegendContainer>
        ) : (
          <div />
        )}
      </Header>

      {edges.length ? (
        <CanvasContainer>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            proOptions={{ hideAttribution: true }}
            nodeTypes={nodeTypes}
            nodesDraggable={false}
            zoomOnScroll={false}
            minZoom={1}
          />
        </CanvasContainer>
      ) : (
        <Row align="center" style={{ minHeight: 408 }}>
          <EmptyData title="No command is created." permission={false} image={<EmptyState />} />
        </Row>
      )}
    </div>
  );
};
