import { ReactComponent as ActionLogIcon } from 'assets/icons/action-log.svg';
import { ReactComponent as DashboardIcon } from 'assets/icons/dashboard.svg';
import { ReactComponent as DataIcon } from 'assets/icons/data.svg';
import { ReactComponent as DevicesIcon } from 'assets/icons/devices.svg';

import type { TabItemType } from 'thesis-ui/components/Navigation/Tabs/core/types';

import { PATH } from 'constants/path';

import { TeamTabLabel } from '../components';
import { getTeamPath } from './helper';

export const getTeamTabs = (id: string): TabItemType[] => {
  return [
    {
      label: <TeamTabLabel icon={<DashboardIcon />} title="Dashboard" />,
      key: getTeamPath(id ?? '', PATH.TEAM_DASHBOARD),
    },
    {
      label: <TeamTabLabel icon={<DataIcon />} title="Data" />,
      key: getTeamPath(id ?? '', PATH.TEAM_DATA),
    },
    {
      label: <TeamTabLabel icon={<DevicesIcon />} title="Devices" />,
      key: getTeamPath(id ?? '', PATH.TEAM_DEVICES),
    },
    {
      label: <TeamTabLabel icon={<ActionLogIcon />} title="Action log" />,
      key: getTeamPath(id ?? '', PATH.TEAM_ACTION_LOG),
    },
  ];
};
