import { useEffect, useRef } from 'react';
import { HashLoader } from 'react-spinners';

import { ChartDataType } from 'enums/chart';
import { Row, Tooltip, Typography, blue, neutral, primary } from 'thesis-ui';

import { Container, Link } from '../styles/device-data-upload';
import { LoadingCard } from 'components/chart/styles/basic';

import { formatUTCDate } from 'helpers';
import { useBoolean } from 'hooks';
import { useOutsideAlerter } from 'hooks/basic';
import { isEmpty } from 'lodash';

import { type DeviceDataUploadProps, getContentDataPlacement } from '../core';

export const DeviceDataUpload: React.FC<DeviceDataUploadProps> = ({
  deviceUploads,
  heightContent,
  rootStyle,
  isDataUploadLoading,
}) => {
  const visible = useBoolean(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const totalUploads = deviceUploads.length;

  useEffect(() => {
    visible.setValue(false);
  }, [JSON.stringify(deviceUploads), isDataUploadLoading]);

  useOutsideAlerter(containerRef, () => {
    visible.setValue(false);
  });

  const onVisible = () => {
    visible.setValue(true);
  };

  const renderUploadValue = (type: ChartDataType, value: string, fileName: string) => {
    if (type === ChartDataType.File) {
      if (visible.value) {
        return (
          <Link href={value} download={true} target="_blank">
            {fileName}
          </Link>
        );
      }
      return (
        <span
          style={{
            textDecorationLine: 'underline',
            color: blue['blue-6'],
          }}
        >
          {fileName}
        </span>
      );
    }
    return value;
  };

  const tooltipBg = visible.value ? 'transparent' : neutral['neutral-11'];
  const tooltipContent = visible.value ? '' : 'Click to scroll and access links';
  const containerStyle: React.CSSProperties = {
    height: heightContent,
    overflow: visible.value ? 'auto' : 'hidden',
    ...rootStyle,
  };

  const renderLoading = () => {
    if (isDataUploadLoading) {
      return (
        <LoadingCard align="center" justify="center">
          <HashLoader color={primary['primary-6']} loading={true} size={50} />
        </LoadingCard>
      );
    }
    return null;
  };

  if (!totalUploads) {
    return (
      <Container
        align="center"
        justify="center"
        style={{
          height: heightContent,
          cursor: 'inherit',
        }}
      >
        <Typography color={neutral['neutral-7']}>No data to show.</Typography>
        {renderLoading()}
      </Container>
    );
  }

  return (
    <Tooltip
      content={tooltipContent}
      bg={tooltipBg}
      arrow={false}
      placement="top"
      offset={[0, -(heightContent / 2)]}
    >
      <Container
        ref={containerRef}
        onClick={onVisible}
        visible={visible.value}
        direction="column"
        justify={getContentDataPlacement(totalUploads, heightContent, visible.value)}
        style={containerStyle}
      >
        {!isEmpty(deviceUploads) &&
          deviceUploads.map((upload) => {
            return (
              <Row key={upload.id} align="center">
                <Typography
                  variant="body-2"
                  style={{
                    textAlign: 'start',
                  }}
                >
                  {`${formatUTCDate(upload.created_at, 'yyyy-MM-DD HH:mm:ss')}${
                    upload.label ? `/${upload.label}` : ''
                  }/${upload.data_type}/`}
                  {renderUploadValue(upload.data_type, upload.data, upload.file_name)}
                </Typography>
              </Row>
            );
          })}

        {renderLoading()}
      </Container>
    </Tooltip>
  );
};
