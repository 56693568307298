import { Button, Modal, Row, Select, Spacing, TextInput } from 'thesis-ui';

import { ButtonOverlay } from 'styles/button';

import { getBgButtonByTeam } from 'helpers';

import { MODAL_KEY } from 'constants/modal-key';

import { TeamUserCard } from '../components/TeamUserCard';
import { TeamRole, useTeamShareModalController } from '../core';
import { ListTeamRole } from '../core/constant';
import { roleBaseOptions } from '../core/role-select';

export const TeamShareModal = () => {
  const {
    team,
    hasShare,
    roleKey,
    roleSelect,
    disabled,
    email,
    emailStatus,
    teamUsers,
    handleChange,
    handleSubmit,
    onChangeSelectTeamRole,
    isAdmin,
  } = useTeamShareModalController();

  const getOptions = () => {
    if (team?.role === TeamRole.TeamOwner || isAdmin) {
      return roleBaseOptions;
    }
    return [roleBaseOptions[0], roleBaseOptions[1]];
  };

  return (
    <Modal header="Share" modalKey={MODAL_KEY.TEAM_SHARE} size="lg" isShowFooter={false}>
      {hasShare && (
        <Spacing spacing="xs" type="margin" placement="bottom">
          <Row align="flex-start">
            <TextInput
              name="email"
              value={email}
              onChange={handleChange}
              helperText={emailStatus.helperText}
              color={emailStatus.color}
              containerStyle={{
                paddingRight: 0,
              }}
              placeholder="Invite by email"
              suffix={
                <Select
                  inputStyle={{
                    border: 'none',
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    width: 'auto',
                    paddingRight: 0,
                  }}
                  dropdownConfig={{
                    theme: 'dark',
                    dropdownConfig: {
                      offset: [5, 0],
                      visible: roleSelect.value,
                      onClickOutside: () => roleSelect.setValue(false),
                    },
                    dropdownMenuStyle: {
                      width: 316,
                    },
                  }}
                  options={getOptions()}
                  onChange={onChangeSelectTeamRole}
                  selectedKeys={String(roleKey.value)}
                  activeValue={ListTeamRole[roleKey.value]}
                  onClickSelect={() => roleSelect.setValue((prev) => !prev)}
                />
              }
            />
            <Spacing spacing="sm" type="margin" placement="left">
              <Button
                variant="contained"
                size="large"
                onClick={() => handleSubmit()}
                disabled={disabled}
                style={{
                  ...getBgButtonByTeam(disabled, team?.colour),
                  position: 'relative',
                }}
              >
                Invite
                <ButtonOverlay disabled={disabled} color={team?.colour} />
              </Button>
            </Spacing>
          </Row>
        </Spacing>
      )}

      {teamUsers.length ? (
        <Row direction="column">
          {teamUsers.map((user) => {
            return <TeamUserCard user={user} key={user.id} roleOptions={roleBaseOptions} />;
          })}
        </Row>
      ) : null}
    </Modal>
  );
};
