import { InputTitle } from 'components';
import { Button, Row, Spacing, TextInput, Typography } from 'thesis-ui';

import { useMyProfileController } from '../core/controllers/my-profile';

export const MyProfilePage = () => {
  const { email, fullName, companyName, disabled, handleChange, handleSubmit, onCancel } =
    useMyProfileController();

  return (
    <Row direction="column">
      <Typography weight={600}>Email address</Typography>
      <Typography
        style={{
          marginTop: 6,
          marginBottom: 12,
        }}
      >
        {email}
      </Typography>
      <Spacing
        spacing="sm"
        placement="bottom"
        type="margin"
        style={{
          width: '100%',
        }}
      >
        <InputTitle title="Full name" required />
        <TextInput type="text" name="fullName" value={fullName} onChange={handleChange} />
      </Spacing>
      <TextInput
        title="Company"
        type="text"
        name="companyName"
        value={companyName}
        onChange={handleChange}
      />
      <Spacing
        spacing="xl"
        placement="top"
        type="margin"
        style={{
          width: '100%',
        }}
      >
        <Row align="center" justify="space-between">
          <Button
            style={{
              width: 198,
            }}
            disabled={disabled}
            variant="contained"
            size="large"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            style={{
              width: 198,
            }}
            disabled={disabled}
            onClick={() => handleSubmit()}
            variant="contained"
            size="large"
            color="primary"
          >
            Save
          </Button>
        </Row>
      </Spacing>
    </Row>
  );
};
