import * as Yup from 'yup';

import { requireEmailValidation } from 'helpers/validate';

export const formTeamSchema = Yup.object().shape({
  name: Yup.string().min(1).required(),
  color: Yup.string().min(1).required(),
});

export const shareSchema = Yup.object().shape({
  email: requireEmailValidation,
});
