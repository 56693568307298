import styled from 'styled-components';

import { RadiusConfig, Row, neutral, neutralTrans } from 'thesis-ui';

export const Container = styled(Row)`
  padding: 6px 12px;
  background-color: ${neutral['neutral-1']};
  border-radius: ${RadiusConfig.max}px;
  border: 1px solid ${neutralTrans['neutralTrans-15']};
  width: fit-content;
`;
