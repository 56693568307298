import { Row, Spacing, Typography, blue, neutral } from 'thesis-ui';

import { ConfirmEmailContainer } from '../styles';

import { ReactComponent as Email } from 'assets/images/email.svg';

import { ConfirmEmailType, EMAIL_CONFIRM_TEXT, useConfirmEmailController } from '../core';

export const ConfirmEmailPage = () => {
  const { email, type, isTimerRunning, timer, handleResend } = useConfirmEmailController();
  const { label, title } = EMAIL_CONFIRM_TEXT[type as ConfirmEmailType];
  return (
    <ConfirmEmailContainer>
      <Email />
      <Spacing spacing="xl" placement={['bottom', 'top']} type="margin">
        <Typography variant="h4" weight={600}>
          {title}
        </Typography>
      </Spacing>
      <Typography
        weight={400}
        style={{
          textAlign: 'center',
        }}
      >
        {label}
      </Typography>
      <Spacing spacing="xxs" placement="top" type="margin">
        <Typography weight={600}>{email}</Typography>
      </Spacing>
      <Spacing spacing="3xl" placement="top" type="margin">
        <Row align="center">
          <Typography weight={400}>Didn’t receive an email?</Typography>
          <Spacing spacing="xxs" placement="left">
            <Typography
              weight={400}
              color={isTimerRunning ? neutral['neutral-11'] : blue['blue-6']}
              style={{
                cursor: isTimerRunning ? '' : 'pointer',
              }}
              onClick={handleResend}
            >
              {isTimerRunning ? `Resend in ${timer}s` : 'Resend'}
            </Typography>
          </Spacing>
        </Row>
      </Spacing>
    </ConfirmEmailContainer>
  );
};
