import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

interface TeamListState {
  isRefreshTeamList: boolean;
  isDeleteTeam: boolean;
}

interface TeamListStore {
  state: TeamListState;
  updateRefreshTeamList: (value: boolean) => void;
  updateIsDeleteTeam: (value: boolean) => void;
}

const initTeamListState: TeamListState = {
  isRefreshTeamList: false,
  isDeleteTeam: false,
};

export const useTeamListStore = create(
  immer<TeamListStore>((set) => ({
    state: initTeamListState,
    updateRefreshTeamList: (value: boolean) => {
      set((store) => {
        store.state.isRefreshTeamList = value;
      });
    },
    updateIsDeleteTeam: (value: boolean) => {
      set((store) => {
        store.state.isDeleteTeam = value;
      });
    },
  })),
);

export const { updateRefreshTeamList, updateIsDeleteTeam } = useTeamListStore.getState();

export const isRefreshTeamListSelector = (s: TeamListStore) => s.state.isRefreshTeamList;

export const isDeleteTeamSelector = (s: TeamListStore) => s.state.isDeleteTeam;
