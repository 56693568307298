import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { type MenuItem, type MenuItemDetail, clearAllTable, openModal } from 'thesis-ui';

import {
  type ChangeLogs,
  Role,
  type UserProfile,
  clearAuthState,
  clearUserProfile,
  updateUserProfile,
  useAuthStore,
  userProfileSelector,
} from 'modules/auth/core';
import { clearDeviceState } from 'modules/devices/core';
import {
  MenuCollapseKey,
  type TeamDetail,
  TeamRole,
  clearTeamState,
  createInitTeam,
  getTeamPath,
  isRefreshTeamsSelector,
  teamsSelector,
  updateRefreshTeams,
  useTeamStore,
} from 'modules/team';
import { TeamType } from 'modules/teams/core/enum';

import { ReactComponent as LeadingBoldIcon } from 'assets/icons/leading-bold.svg';

import { apiCall } from 'hooks/service/api';

import { MODAL_KEY } from 'constants/modal-key';
import { PATH } from 'constants/path';

import { EmptyMessage } from '../components/EmptyMessage';
import { MenuItemLabel } from '../components/MenuItemLabel';
import { MenuItemTitle } from '../components/MenuItemTitle';
import { getMenuActiveKey } from './helper';
import {
  clearCollapse,
  menuCollapsedSelector,
  updateCollapsed,
  useMenuCollapsedStore,
} from './menu-collapsed';

export const useMenuItemTitleController = () => {
  const onOpenModal = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    openModal(MODAL_KEY.FORM_TEAM);
  };

  return {
    onOpenModal,
  };
};

export const useController = (isLoadAPI: boolean) => {
  const userProfile = useAuthStore(userProfileSelector);
  const collapsed = useMenuCollapsedStore(menuCollapsedSelector);
  const teams = useTeamStore(teamsSelector);
  const navigationTo = useNavigate();
  const location = useLocation();
  const isRefreshTeams = useTeamStore(isRefreshTeamsSelector);

  useEffect(() => {
    if (userProfile?.id || !isLoadAPI) {
      return;
    }

    getUserProfile();
  }, [userProfile?.id, isLoadAPI]);

  useEffect(() => {
    if (!isLoadAPI) {
      return;
    }

    getTeams();
  }, [isLoadAPI]);

  useEffect(() => {
    if (!isRefreshTeams || !isLoadAPI) {
      return;
    }
    getTeams();
    updateRefreshTeams(false);
  }, [isRefreshTeams, isLoadAPI]);

  const getTeams = async () => {
    const defaultData = {
      page: 1,
      page_size: 6,
      sort: 'viewed_at',
      order: 'desc',
    };

    const myTeamResult: {
      data: TeamDetail[];
    } = await apiCall({
      url: `/api/teams`,
      method: 'GET',
      data: {
        ...defaultData,
        with_recent: 1,
        with_type: TeamType.MyTeam,
      },
      isLoading: true,
      showError: true,
    });

    const shareTeamResult: {
      data: TeamDetail[];
    } = await apiCall({
      url: `/api/teams`,
      method: 'GET',
      data: {
        ...defaultData,
        with_recent: 1,
        with_type: TeamType.SharedWithMe,
      },
      isLoading: true,
      showError: true,
    });

    let newTeams: TeamDetail[] = [];

    if (myTeamResult?.data) {
      newTeams = newTeams.concat(myTeamResult?.data);
    }

    if (shareTeamResult?.data) {
      newTeams = newTeams.concat(shareTeamResult?.data);
    }

    createInitTeam(newTeams);
  };

  const getChangeLog = async () => {
    const changeLogs: ChangeLogs = await apiCall({
      url: `/api/change-logs`,
      method: 'GET',
      isLoading: true,
      showError: true,
    });

    if (changeLogs?.content && changeLogs?.is_viewed === false) {
      openModal(MODAL_KEY.VERSION_UPDATE, changeLogs.content);
    }
  };

  const getUserProfile = async () => {
    const result: UserProfile = await apiCall({
      url: `/api/auth/me`,
      method: 'GET',
      isLoading: true,
      showError: true,
    });

    if (result?.id) {
      updateUserProfile(result);
    }
    if (result?.has_agreed_latest_terms === false) {
      openModal(MODAL_KEY.TERMS_AND_CONDITION_UPDATE);
    } else {
      getChangeLog();
    }
  };

  const onChangeCollapsed = (key: MenuCollapseKey) => () => {
    updateCollapsed(key);
  };

  const onClickMenuItem = (newItem: MenuItem, path: string = PATH.TEAM_DASHBOARD) => {
    navigationTo(`${newItem.path}${path}`);
  };

  const myTeams = teams.filter((el) => el.role === TeamRole.TeamOwner);
  const sharedWithMe = teams.filter((el) => el.role);

  const menus: Record<string, MenuItemDetail> = {
    Teams: {
      items: [
        {
          label: 'Teams',
          path: `${PATH.TEAMS}`,
          icon: <LeadingBoldIcon />,
          width: '196px',
          onClickMenuItem: (newItem: MenuItem) => onClickMenuItem(newItem, `/${PATH.MY_TEAMS}`),
        },
      ],
    },
    'My teams': {
      title: <MenuItemTitle title="My teams" collapsed={collapsed.myTeams} action />,
      items: myTeams.map((myTeam) => {
        return {
          label: <MenuItemLabel name={myTeam.name} colour={myTeam.colour} logo={myTeam.logo} />,
          path: getTeamPath(myTeam.id),
          width: '196px',
          onClickMenuItem: onClickMenuItem,
        };
      }),
      type: 'collapse',
      notItemLabel: <EmptyMessage title="You’re owning no team." />,
      collapsedConfig: {
        activeKey: getMenuActiveKey(collapsed.myTeams),
        onChange: onChangeCollapsed(MenuCollapseKey.MyTeams),
      },
    },
    'Shared with me': {
      title: <MenuItemTitle title="Shared with me" collapsed={collapsed.sharedWithMe} />,
      items: sharedWithMe.map((shared) => {
        return {
          label: <MenuItemLabel name={shared.name} colour={shared.colour} logo={shared.logo} />,
          path: getTeamPath(shared.id),
          width: '196px',
          onClickMenuItem: onClickMenuItem,
        };
      }),
      type: 'collapse',
      notItemLabel: <EmptyMessage title="You haven't been added to any teams yet." />,
      collapsedConfig: {
        activeKey: getMenuActiveKey(collapsed.sharedWithMe),
        onChange: onChangeCollapsed(MenuCollapseKey.SharedWithMe),
      },
    },
  };

  return {
    locationPathName: location.pathname,
    menus,
    getChangeLog,
  };
};

export const useUserProfileController = () => {
  const navigationTo = useNavigate();
  const userProfile = useAuthStore(userProfileSelector);
  const isAdmin = userProfile?.role_id === Role.Admin;

  const onRedirectProfile = () => {
    navigationTo(`${PATH.GENERALS}/${PATH.MY_PROFILE}`);
  };

  return {
    userProfile,
    isAdmin,
    onRedirectProfile,
  };
};

export const onLogout = () => {
  clearAuthState();
  clearUserProfile();
  clearCollapse();
  clearAllTable();
  clearTeamState();
  clearDeviceState();
};
