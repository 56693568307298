import React from 'react';

import { Row, Spacing, Typography, blue, neutral } from 'thesis-ui';

import { Container, ExtraCard, LineBorder, LineCard, LineContent } from './styles';

import { ReactComponent as EllipseIcon } from 'assets/icons/ellipse.svg';

import type { ActionLogItemProps } from './core/type';

import { formatDateAndTimeCombination } from 'helpers';
import { isEmpty } from 'lodash';

import { ActionLogContent } from './ActionLogContent';
import { getRoleName, getTitleLogByKey } from './core/helper';

export const ActionLogItem: React.FC<ActionLogItemProps> = ({
  log,
  colorTeam = blue['blue-5'],
}) => {
  const extraData = log.extra_data ? JSON.parse(log.extra_data) : {};

  return (
    <Container>
      <LineCard colorTeam={colorTeam}>
        <EllipseIcon className="icon" />
        <LineBorder>
          <LineContent />
        </LineBorder>
      </LineCard>
      <Row direction="column" justify="start">
        <Typography variant="body-2" weight={400} color={neutral['neutral-7']}>
          {formatDateAndTimeCombination(log.created_at)}
        </Typography>
        <Row
          align="center"
          style={{
            margin: '6px 0px',
          }}
        >
          <Typography
            style={{
              textAlign: 'start',
            }}
          >
            {log.action_by && (
              <React.Fragment>
                <span
                  style={{
                    whiteSpace: 'nowrap',
                    fontWeight: 600,
                  }}
                >
                  {log.user_fullname}
                </span>
                <span>{` (${log.user_email}) (${getRoleName(
                  log.user_team_role,
                  log.user_role_id,
                )}) `}</span>
              </React.Fragment>
            )}

            <span
              dangerouslySetInnerHTML={{
                __html: log.message.replace(
                  '{created_at}',
                  formatDateAndTimeCombination(log.created_at),
                ),
              }}
            />
          </Typography>
        </Row>
        {!isEmpty(extraData) ? (
          <ExtraCard align="center" direction="column">
            {Object.keys(extraData).map((key) => {
              const allowInline = Object.keys(extraData).length === 1;
              return (
                <ActionLogContent
                  key={key}
                  title={getTitleLogByKey(key)}
                  label={extraData[key] || 'N/A'}
                  allowInline={allowInline}
                />
              );
            })}
          </ExtraCard>
        ) : (
          <Spacing spacing="xl" type="margin" placement="bottom" />
        )}
      </Row>
    </Container>
  );
};
