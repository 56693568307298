import * as Yup from 'yup';

import { CommandType } from './enum';

export const getFormCollectionSchema = (type: CommandType) => {
  const schema = {
    name: Yup.string().max(255),
    note: Yup.string().max(500),
    data: Yup.string().max(255),
    type: Yup.string().required().max(255),
  };

  if (type === CommandType.Collection) {
    schema.name = Yup.string().required().max(255);
  }
  //
  return Yup.object().shape(schema);
};
