import { Button, Modal, Row, Spacing, Typography, neutral } from 'thesis-ui';

import { CommandDataContent } from '../styles/command-label';

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';

import { MODAL_KEY } from 'constants/modal-key';

import { CommandDataLabel } from '../components/CommandDataLabel';
import { CommandType, useCommandNoteModalController } from '../core';

const getNoteTextColor = (text?: string) => {
  return text ? neutral['neutral-11'] : neutral['neutral-7'];
};

export const CommandNoteModal: React.FC<{
  modalKey: string;
}> = ({ modalKey }) => {
  const { command, onEditNote } = useCommandNoteModalController(modalKey);

  return (
    <Modal
      header="Command detail"
      modalKey={MODAL_KEY.COMMAND_NOTE}
      size="lg"
      footerStyle={{
        padding: command && command.type === CommandType.Collection ? 16 : 0,
      }}
      footer={
        command && command.type === CommandType.Collection ? (
          <Row align="center">
            <Button
              onClick={onEditNote}
              size="large"
              placementIcon="left"
              icon={
                <EditIcon
                  style={{
                    marginRight: 6,
                  }}
                />
              }
            >
              Edit
            </Button>
          </Row>
        ) : (
          <Row />
        )
      }
    >
      <Spacing spacing="sm" placement="bottom" type="margin">
        <Row direction="column">
          <Typography weight={600}> Command name </Typography>
          <CommandDataContent>
            <Typography color={getNoteTextColor(command?.name)}>
              {command?.name || 'No information'}
            </Typography>
          </CommandDataContent>
        </Row>
      </Spacing>
      <Spacing spacing="sm" placement="bottom" type="margin">
        <Row direction="column">
          <Typography weight={600}> Command data </Typography>
          <CommandDataContent>
            <CommandDataLabel data={command?.data} labelStyle={{ minHeight: 44 }} />
          </CommandDataContent>
        </Row>
      </Spacing>
      <Row direction="column">
        <Typography weight={600}> Note </Typography>
        <CommandDataContent>
          <Typography
            style={{
              whiteSpace: 'pre-line',
            }}
            color={getNoteTextColor(command?.note)}
          >
            {command?.note || 'No information'}
          </Typography>
        </CommandDataContent>
      </Row>
    </Modal>
  );
};
