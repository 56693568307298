import * as Yup from 'yup';

export const deviceFormSchema = Yup.object().shape({
  custom_id: Yup.string().max(255),
  alias: Yup.string().required().max(255),
  description: Yup.string().max(500),
  location: Yup.string().max(255),
});

export const settingChartSchema = Yup.object().shape({
  name: Yup.string().max(255),
  refresh: Yup.string().min(1),
  refreshType: Yup.string(),
  yAxisType: Yup.string(),
  yAxisMax: Yup.string(),
  yAxisMin: Yup.string(),
});
