import styled from 'styled-components';

import { Row, neutral } from 'thesis-ui';

export const Header = styled(Row)`
  height: 32px;
  margin-bottom: 24px;
`;

export const Body = styled(Row)`
  height: 100%;
`;

export const DeviceChartLabel = styled(Row)`
  svg {
    min-width: 16px;
    height: 16px;
    margin-top: 2px;
    path[stroke] {
      stroke: ${neutral['neutral-11']};
    }
  }
`;
