import styled from 'styled-components';

import { RadiusConfig, neutral, neutralTrans } from 'thesis-ui';

export const Dot = styled.div`
  width: 3px;
  height: 3px;
  border-radius: ${RadiusConfig.max}px;
  background-color: ${neutralTrans['neutralTrans-25']};
  margin: 0px 12px;
`;

export const TitleLink = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  color: ${neutral['neutral-11']};
  text-decoration-line: underline;
  &:hover {
    text-decoration-line: underline;
  }
`;
