import { ConfirmModal } from 'components';
import { ButtonByTeam } from 'components/button/ButtonByTeam';
import { EmptyData } from 'components/data/EmptyData';
import { ChartPinAt } from 'enums/chart';
import {
  Button,
  RadiusConfig,
  Row,
  Select,
  Spacing,
  Tooltip,
  Typography,
  blue,
  neutral,
  neutralTrans,
} from 'thesis-ui';

import { CollectionLabel } from 'modules/device-command/components';

import { Body, BodyChart, BodyHeader } from '../styles/device-public';

import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { ReactComponent as InfoCircle } from 'assets/icons/info-circle.svg';
import { ReactComponent as EmptyPublic } from 'assets/images/chart-skeleton.svg';

import { MODAL_KEY } from 'constants/modal-key';

import { DeviceChartItem } from '../components/DeviceChartItem';
import { DeviceDetailCard } from '../components/DeviceDetailCard';
import { stateMenu, useDevicePublicController } from '../core';
import { DeviceState } from '../core/enum';
import { AddDeviceChartPublicModal } from '../modal/AddDeviceChartPublicModal';

export const DevicePublic = () => {
  const {
    device,
    openSelectState,
    selectStateDisable,
    isPublicView,
    deviceCharts,
    hasDevicePermission,
    isDeviceChartEmpty,
    isEmptyLabel,
    userProfile,
    onChangeDeviceState,
    onChangeDeviceStatus,
    onCopyLink,
    onAddDeviceChart,
    onOpenModalDeleteChart,
    onDeleteChart,
    callbackAddChart,
  } = useDevicePublicController();

  const borderStyle = `1px solid ${neutralTrans['neutralTrans-15']}`;
  const publicStyle = {
    width: '100%',
    borderBottom: borderStyle,
    borderLeft: borderStyle,
    borderRight: borderStyle,
    borderBottomLeftRadius: `${RadiusConfig.xs}px`,
    borderBottomRightRadius: `${RadiusConfig.xs}px`,
    backgroundColor: neutral['neutral-1'],
  };

  const chartLabels = deviceCharts.map((el) => el.label || 'No label');

  const renderChart = () => {
    if (!isDeviceChartEmpty) {
      return (
        <BodyChart direction="template-column" type="full" gap="24px" column={2}>
          {deviceCharts.map((chart) => {
            return (
              <DeviceChartItem
                hasPermission={hasDevicePermission}
                chartTitle=""
                key={chart.id}
                chart={chart}
                onOpenModalDeleteChart={onOpenModalDeleteChart}
                isDatePicker={hasDevicePermission}
                action={!hasDevicePermission ? <Row /> : undefined}
                originChartKey={`UserId-${userProfile?.id}-${ChartPinAt[chart.pin_at]}-${
                  chart.device_id
                }-${chart.id}-${chart.label}`}
              />
            );
          })}
        </BodyChart>
      );
    }

    return (
      <Row
        align="center"
        style={{
          minHeight: 400,
        }}
      >
        <EmptyData image={<EmptyPublic />} permission={false} title="No chart to show." />
      </Row>
    );
  };

  return (
    <Row direction="column">
      {hasDevicePermission && (
        <Spacing spacing="xl" style={publicStyle}>
          <Select
            inputStyle={{
              width: 320,
            }}
            title="Public mode"
            dropdownConfig={{
              theme: 'dark',
              dropdownConfig: {
                offset: [0, 0],
                visible: openSelectState.value,
                onClickOutside: () => openSelectState.setValue(false),
              },
            }}
            disabled={!hasDevicePermission || selectStateDisable}
            options={stateMenu}
            onChange={onChangeDeviceState}
            selectedKeys={String(device?.state ?? DeviceState.Private)}
            activeValue={DeviceState[device?.state ?? DeviceState.Private]}
            onClickSelect={() => openSelectState.setValue((prev) => !prev)}
          />
          {selectStateDisable && (
            <Spacing spacing="xl" type="margin" placement="top">
              <CollectionLabel
                label={
                  <Typography
                    style={{
                      textAlign: 'start',
                    }}
                    color={blue['blue-6']}
                  >
                    To change the public mode to Public, you have to{' '}
                    <span
                      onClick={onChangeDeviceStatus}
                      style={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                      change device status to Active
                    </span>
                  </Typography>
                }
                type="info"
              />
            </Spacing>
          )}
        </Spacing>
      )}

      {isPublicView && (
        <Spacing
          spacing="xl"
          placement="top"
          style={{
            width: '100%',
          }}
        >
          <Body direction="column">
            <BodyHeader align="center" justify="space-between">
              <Row align="center">
                <Typography weight={600} variant="h5">
                  Public view
                </Typography>
                <Tooltip
                  offset={[5, 10]}
                  content={
                    <Row align="flex-start" style={{ width: 253 }}>
                      <Typography
                        variant="body-2"
                        color={neutral['neutral-1']}
                        style={{ textAlign: 'start' }}
                      >
                        People accessing via the public link can’t make any changes to the device
                        data and device information.
                      </Typography>
                    </Row>
                  }
                  placement="top"
                  arrow={false}
                  interactive
                >
                  <InfoCircle
                    style={{
                      cursor: 'pointer',
                      marginLeft: 4,
                    }}
                  />
                </Tooltip>
              </Row>
              <ButtonByTeam
                size="small"
                title="Copy link"
                icon={<CopyIcon />}
                onClick={onCopyLink}
              />
            </BodyHeader>
            <Spacing
              spacing="xl"
              style={{
                width: '100%',
                borderBottom: borderStyle,
              }}
            >
              <Row direction="column">
                <Spacing spacing="xl" type="margin" placement="bottom" style={{ width: '100%' }}>
                  <Row justify="space-between" align="center" style={{ width: '100%' }}>
                    <Typography weight={600} variant="h5">
                      Device data
                    </Typography>
                    {!isEmptyLabel && hasDevicePermission && (
                      <Button
                        placementIcon="left"
                        icon={<AddIcon />}
                        size="small"
                        onClick={onAddDeviceChart}
                      >
                        Add chart
                      </Button>
                    )}
                  </Row>
                </Spacing>
                {renderChart()}
              </Row>
            </Spacing>
            <Spacing
              spacing="xl"
              style={{
                width: '100%',
              }}
            >
              <Row direction="column">
                <Spacing spacing="xl" type="margin" placement="bottom">
                  <Typography weight={600} variant="h5">
                    Device information
                  </Typography>
                </Spacing>
                <Row justify="center">
                  <DeviceDetailCard device={device} />
                </Row>
              </Row>
            </Spacing>
          </Body>
        </Spacing>
      )}
      <AddDeviceChartPublicModal chartLabels={chartLabels} callbackAddChart={callbackAddChart} />
      <ConfirmModal
        modalKey={MODAL_KEY.DELETE_DEVICE_CHART_PUBLIC}
        submitButton={{
          buttonProps: {
            onClick: onDeleteChart,
          },
        }}
      />
    </Row>
  );
};
