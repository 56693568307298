import { URL_IMAGE } from 'constants/environment';

export const getImageUrl = (url: string, apiURl?: string) => {
  const newURL = url.replaceAll(' ', '%20');
  if (url.startsWith(URL_IMAGE)) {
    return newURL;
  }
  return `${apiURl ?? URL_IMAGE}${newURL}`;
};

export const checkTypeFile = (fileType: string, type?: string) => {
  const regFile = RegExp(`^.*.(${type ?? 'rar|zip|7z'})$`);
  return regFile.test(fileType);
};

export const getFileExt = (name: string) => name.split('.').pop();

export const downloadFile = (fileName: string, data: any) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
