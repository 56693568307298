import { Row, Typography, neutral } from 'thesis-ui';

import type { SelectRoleLabelProps } from '../core';

export const SelectRoleLabel: React.FC<SelectRoleLabelProps> = ({ title, description }) => {
  return (
    <Row
      direction="column"
      style={{
        alignItems: 'initial',
      }}
    >
      <Typography
        color={neutral['neutral-1']}
        style={{
          textAlign: 'start',
        }}
      >
        {title}
      </Typography>
      {description && (
        <Typography
          style={{
            textAlign: 'start',
            marginTop: 2,
          }}
          color={neutral['neutral-7']}
        >
          {description}
        </Typography>
      )}
    </Row>
  );
};
