import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getChartOptions } from 'components/chart/core/constant';
import { ChartDataType, ChartPinAt, ChartType } from 'enums/chart';
import { ChartDateRange } from 'enums/date-range';
import { HttpStatusCode } from 'enums/http';
import { useFormik } from 'formik';
import {
  type DateRangePickerInputState,
  type DropdownMenuItem,
  closeModal,
  openModal,
  showToastMessage,
  useDialogStore,
} from 'thesis-ui';

import { useAuthStore, userProfileSelector } from 'modules/auth/core';
import { teamDetailSelector, useTeamStore } from 'modules/team';

import { type ConfirmModalData } from 'components/modal/core/type';

import { endOfDay, startOfDay } from 'date-fns';
import { checkDateSelected, convertDateToUTC, downloadFile, getUTCDate } from 'helpers';
import { getDevicePermission } from 'helpers/permission';
import { getResponseData } from 'helpers/response';
import { useBoolean, useNumber, useString } from 'hooks';
import { apiCall } from 'hooks/service/api';
import { cloneDeep, includes, isEmpty, isUndefined, orderBy } from 'lodash';

import { ERROR_MESSAGE, SUCCESS_MESSAGE } from 'constants/message';
import { MODAL_KEY } from 'constants/modal-key';
import { type ApiResponse } from 'types';
import { type Pagination } from 'types/api';

import {
  type ChartSettingModalProps,
  type DeviceRecord,
  type FormSettingChart,
  type RelativeRange,
  type UpdateDeviceChartRequest,
} from '..';
import { defaultDateExport, defaultDeviceChart, defaultFormSettingChart } from '../constant';
import {
  DeviceChartDateRangeTabKey,
  DeviceChartKey,
  DeviceChartRelativeRange,
  DeviceChartYAxisSettingType,
} from '../enum';
import {
  checkDeviceChartFilterLastTime,
  getChartTimeUnit,
  getDateRangeField,
  getDeviceChartDateFilter,
  getRefreshInterval,
  getYAxisChartSetting,
  updateSettingChartState,
} from '../helper';
import { settingChartSchema } from '../schema';
import { useSettingChartKey } from '../setting-key';
import {
  clearSettingChartByKey,
  deviceDetailSelector,
  updateDeviceDetail,
  updateSettingChart,
  useDeviceSettingChartStore,
  useDeviceStore,
} from '../store';
import { defaultRelativeRange } from './../constant';

export const useDeviceDataController = () => {
  const params = useParams();
  const team = useTeamStore(teamDetailSelector);
  const device = useDeviceStore(deviceDetailSelector);
  const userProfile = useAuthStore(userProfileSelector);
  const setting = useDeviceSettingChartStore((s) => s.state.setting);
  const { id, deviceId } = params;
  const defaultChart: DeviceRecord = {
    ...defaultDeviceChart,
    device_id: deviceId ?? '',
    team_id: id ?? '',
    pin_at: ChartPinAt.DevicePage,
  };

  const openExport = useBoolean(false);
  const [dateExport, setDateExport] = useState<DateRangePickerInputState[]>(defaultDateExport);
  const [charts, setCharts] = useState<DeviceRecord[]>([]);
  const actionVisible = useBoolean(false);
  const chartId = useString();

  useEffect(() => {
    if (!deviceId || !id || !device) {
      return;
    }
    setCharts([]);
    setDateExport(defaultDateExport);
    getDeviceCharts();
  }, [id, deviceId, device?.id]);

  const getDeviceCharts = async () => {
    const result: {
      data: DeviceRecord[];
      pagination: Pagination;
    } = await apiCall({
      url: `/api/teams/${id}/charts`,
      data: {
        filter: JSON.stringify({
          type: ChartType.Line,
          pin_at: ChartPinAt.DevicePage,
          device_id: deviceId,
        }),
      },
      method: 'GET',
      isLoading: true,
      showError: true,
    });

    const responseData: DeviceRecord[] = getResponseData(result?.data);

    const deviceLabels = device?.labels || [];
    const newCharts: DeviceRecord[] = deviceLabels.map((el) => {
      const chartDetail = responseData.find((response) => response.label === el.label);
      if (chartDetail) {
        return chartDetail;
      }
      return {
        ...defaultChart,
        label: el.label,
      };
    });

    setCharts(newCharts);
  };

  const onAddDeviceChart = (chartLabel: string) => async () => {
    if (!deviceId) {
      showToastMessage('error', ERROR_MESSAGE.SHOW_DEVICE_CHART);
      return;
    }
    const result: DeviceRecord = await apiCall({
      url: `/api/teams/${id}/charts`,
      data: {
        name: 'Chart name',
        type: ChartType.Line,
        pin_at: ChartPinAt.DevicePage,
        device_id: deviceId,
        label: chartLabel,
      },
      method: 'POST',
      isLoading: true,
      showError: true,
    });
    if (result?.id) {
      showToastMessage('success', SUCCESS_MESSAGE.SHOW_DEVICE_CHART);
      const newCharts = cloneDeep(charts);
      const index = newCharts.findIndex(
        (el) => el.label === result.label && el.device_id === result.device_id,
      );
      if (index > -1) {
        newCharts[index] = result;
        setCharts(newCharts);
      }
    }
  };

  const onChangeExportDate = (newDate: DateRangePickerInputState[]) => {
    setDateExport(newDate);
  };

  const onChangeOpenExport = (newValue: boolean) => () => {
    openExport.setValue(newValue);
    setDateExport(defaultDateExport);
  };

  const onSubmitExport = async () => {
    if (!deviceId || !dateExport.length) {
      return showToastMessage('error', ERROR_MESSAGE.EXPORT_DEVICE_DATA);
    }
    const result: any = await apiCall({
      url: `/api/teams/${id}/devices/${deviceId}/records/export`,
      data: {
        order: 'asc',
        sort: 'created_at',
        filter: JSON.stringify({
          created_at: {
            $gte: getUTCDate(dateExport[0].startDate, true, false),
            $lte: getUTCDate(endOfDay(dateExport[0].endDate), true, false, '999'),
          },
        }),
      },
      method: 'GET',
      isLoading: true,
      showError: true,
      isResponseHeader: true,
    });
    if (result?.data && result?.headers) {
      showToastMessage('success', SUCCESS_MESSAGE.EXPORT_DEVICE_DATA);
      const headerContentDisposition: string = result.headers['content-disposition'] ?? '';
      const fileName = headerContentDisposition.replace(`attachment; filename=`, '');
      downloadFile(fileName, result.data);
      onChangeOpenExport(false)();
    }
  };

  const onChangeDropdownAction = () => {
    actionVisible.setValue((prev) => !prev);
  };

  const onClickMenuItem = (_item: DropdownMenuItem) => {
    actionVisible.setValue(false);
    openModal(MODAL_KEY.DELETE_DEVICE_DATA, {
      header: `Delete data`,
      label: `All the stored data of this device will be deleted. This action can not undo.`,
    } as ConfirmModalData);
  };

  const onDeleteData = async () => {
    if (!deviceId) {
      return showToastMessage('error', ERROR_MESSAGE.DELETE_DEVICE_DATA);
    }

    const result: ApiResponse = await apiCall({
      url: `/api/teams/${id}/devices/${deviceId}/records/clear`,
      method: 'DELETE',
      isLoading: true,
      showError: true,
    });
    if (result?.statusCode === HttpStatusCode.Success) {
      showToastMessage('success', SUCCESS_MESSAGE.DELETE_DEVICE_DATA);
      closeModal(MODAL_KEY.DELETE_DEVICE_DATA);
      setCharts([]);
      if (device) {
        updateDeviceDetail({
          ...device,
          labels: [],
        });
      }
      setTimeout(() => {
        onClearChartSetting();
      }, 2000);
    }
  };

  const onClearChartSetting = () => {
    if (!setting) {
      return;
    }
    Object.keys(setting).forEach((key) => {
      if (key.startsWith(`UserId-${userProfile?.id}-${ChartPinAt[1]}-${deviceId}`)) {
        clearSettingChartByKey(key);
      }
    });
  };

  const onOpenModalDeleteChart = (newChartId: string) => {
    openModal(MODAL_KEY.DELETE_DEVICE_CHART, {
      header: `Delete chart`,
      label: `All the settings you made with this chart will also be deleted. This action can not undo.`,
    } as ConfirmModalData);
    chartId.setValue(newChartId);
  };

  const onDeleteChart = async () => {
    if (!chartId.value) {
      return showToastMessage('error', SUCCESS_MESSAGE.DELETE_DEVICE_CHART);
    }
    const result: DeviceRecord = await apiCall({
      url: `/api/teams/${id}/charts/${chartId.value}`,
      method: 'DELETE',
      isLoading: true,
      showError: true,
    });
    if (result?.id) {
      showToastMessage('success', SUCCESS_MESSAGE.DELETE_DEVICE_CHART);
      closeModal(MODAL_KEY.DELETE_DEVICE_CHART);
      const newCharts = cloneDeep(charts);
      const index = newCharts.findIndex((el) => el.id === chartId.value);
      if (index > -1) {
        newCharts[index] = {
          ...defaultDeviceChart,
          device_id: deviceId ?? '',
          team_id: id ?? '',
          pin_at: ChartPinAt.DevicePage,
          label: result.label,
        };
        setCharts(newCharts);
      }
    }
  };

  const hasDevicePermission = getDevicePermission(team?.role);
  const isEmptyChart = isEmpty(charts);

  return {
    charts,
    openExport,
    dateExport,
    actionVisible,
    hasDevicePermission,
    isEmptyChart,
    onAddDeviceChart,
    onChangeExportDate,
    onSubmitExport,
    onChangeOpenExport,
    onClickMenuItem,
    onChangeDropdownAction,
    onDeleteData,
    onOpenModalDeleteChart,
    onDeleteChart,
  };
};

export const useDeviceChartItemController = (
  originChart: DeviceRecord,
  onOpenModalDeleteChart?: (chartId: string, chartKey: string) => void,
  teamId?: string,
  originChartKey?: string,
) => {
  const userProfile = useAuthStore(userProfileSelector);
  const device = useDeviceStore(deviceDetailSelector);
  const [datasets, setDatasets] = useState<number[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  const actionVisible = useBoolean(false);
  const chartLoading = useBoolean(false);
  const [deviceChart, setDeviceChart] = useState<DeviceRecord>(defaultDeviceChart);
  const chartKeyState = useSettingChartKey(
    originChart.pin_at,
    originChart.device_id || '',
    originChart.label,
  );
  const chartKey = originChartKey || chartKeyState.chartKey;
  const setting = useDeviceSettingChartStore((s) => s.state.setting[chartKey]);
  const isPublicChart = originChart.pin_at === ChartPinAt.PublicPage;
  const [deviceUploads, setDeviceUploads] = useState<DeviceRecord[]>([]);
  const [date, setDate] = useState<DateRangePickerInputState[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [minmaxChart, setMinMaxChart] = useState({
    min: new Date(),
    max: new Date(),
  });

  const dateRangePickerTabActive = useNumber(DeviceChartDateRangeTabKey.QUICK_OPTIONS);
  const selectedQuickOption = useNumber(0);
  const [relativeRange, setRelativeRange] = useState<RelativeRange>(defaultRelativeRange);
  const originTeamId = teamId ?? device?.team_id;

  useEffect(() => {
    setDatasets([]);
    setLabels([]);
    if (!deviceChart.device_id || !userProfile?.id) {
      return;
    }

    if (!setting) {
      if (isPublicChart) {
        updateSettingChart(chartKey, updateSettingChartState(deviceChart));
        return;
      }
      updateSettingChart(chartKey, {
        ...defaultFormSettingChart,
        name: deviceChart.name,
      });

      return;
    }

    const { startDate, endDate } = getDeviceChartDateFilter({
      ...setting,
      dateRangeTabKey: DeviceChartDateRangeTabKey.QUICK_OPTIONS,
    });

    setDate([
      {
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
      },
    ]);

    selectedQuickOption.setValue(setting.dateRangeType);

    if (!isUndefined(setting.dateRangeTabKey)) {
      dateRangePickerTabActive.setValue(Number(setting.dateRangeTabKey));
    }

    setRelativeRange({
      action: !isUndefined(setting.relativeRangeAction) ? setting.relativeRangeAction : 1,
      total: setting.relativeRangeTotal || 1,
    });

    if (deviceChart.name !== setting.name) {
      updateSettingChart(chartKey, {
        ...setting,
        name: deviceChart.name,
      });
      return;
    }
    getRecordDevice(true);

    const timeInterval = getRefreshInterval(setting.refreshType, setting.refresh);
    const interval = setInterval(() => {
      chartLoading.setValue(true);
      getRecordDevice(false);
    }, timeInterval);

    return () => {
      clearInterval(interval); // Cleanup the interval on component unmount
    };
  }, [deviceChart?.id, deviceChart?.device_id, setting, userProfile?.id]);

  useEffect(() => {
    if (!originChart.device_id) {
      setDeviceChart(defaultDeviceChart);
      return;
    }

    setDeviceChart(originChart);
  }, [originChart]);

  const getRecordDevice = async (loading: boolean) => {
    if (!originTeamId) {
      return;
    }
    const { startDate, endDate } = getDeviceChartDateFilter(setting);
    setMinMaxChart({
      min: startDate,
      max: endDate,
    });
    const isLastTime = checkDeviceChartFilterLastTime(setting);

    const result: {
      data: DeviceRecord[];
      pagination: Pagination;
    } = await apiCall({
      url: `/api/teams/${originTeamId}/devices/${originChart.device_id}/records`,
      data: {
        sort: 'created_at',
        order: 'desc',
        filter: JSON.stringify({
          label: originChart.label,
          created_at: {
            $gte: getUTCDate(startDate, true, isLastTime),
            $lte: getUTCDate(endDate, true, isLastTime, '999'),
          },
        }),
      },
      method: 'GET',
      isLoading: loading,
      showError: true,
      onFinally: () => {
        setTimeout(() => {
          chartLoading.setValue(false);
        }, 500);
      },
    });

    if (result?.data?.length) {
      const dataCharts = result?.data.filter((el) => el.data_type === ChartDataType.Number);
      setDatasets(dataCharts.map((el) => Number(el.data)));
      setLabels(dataCharts.map((el) => el.created_at));
      setDeviceUploads(orderBy(result?.data, 'created_at', 'asc'));
    } else {
      setDeviceUploads([]);
      setLabels([]);
      setDatasets([]);
    }
  };

  const onChangeDropdownAction = () => {
    actionVisible.setValue((prev) => !prev);
  };

  const onClickMenuItem = (item: DropdownMenuItem) => {
    actionVisible.setValue(false);
    if (item.key === DeviceChartKey.Setting) {
      return openModal(`${MODAL_KEY.SETTING_DEVICE_CHART}-${originChart.id}`);
    }
    onOpenModalDeleteChart?.(originChart.id, chartKey);
  };

  const onSubmitSettingChart = async (newValue: FormSettingChart) => {
    if (!originTeamId) {
      return showToastMessage('error', ERROR_MESSAGE.SETTING_DEVICE_CHART);
    }

    updateDeviceChart(newValue);
  };

  const updateDeviceChart = async (newValue: FormSettingChart) => {
    const chartName = newValue.name || 'Chart name';
    if (newValue.name === deviceChart.name && !isPublicChart) {
      showToastMessage('success', SUCCESS_MESSAGE.SETTING_DEVICE_CHART);
      closeModal(`${MODAL_KEY.SETTING_DEVICE_CHART}-${originChart.id}`);
      updateSettingChart(chartKey, newValue);
      return;
    }

    const dataRequest: UpdateDeviceChartRequest = {
      name: chartName,
    };

    if (originChart.pin_at === ChartPinAt.PublicPage) {
      dataRequest.refresh_interval_value = newValue.refresh;
      dataRequest.refresh_interval_type = newValue.refreshType;
      dataRequest.y_axis_display_type = newValue.yAxisType;
      dataRequest.y_axis_min = newValue.yAxisMin;
      dataRequest.y_axis_max = newValue.yAxisMax;
      dataRequest.date_range_type = newValue.dateRangeType;
      dataRequest.tab_key = newValue.dateRangeTabKey;
    }
    if (
      newValue.dateRangeType === ChartDateRange.Custom &&
      newValue.dateRangeTabKey === DeviceChartDateRangeTabKey.QUICK_OPTIONS
    ) {
      dataRequest.from_datetime = convertDateToUTC(newValue?.startDate) as string;
      dataRequest.to_datetime = convertDateToUTC(newValue?.endDate) as string;
    }
    if (newValue.dateRangeTabKey === DeviceChartDateRangeTabKey.RELATIVE_RANGE) {
      dataRequest.relative_range_action = newValue.relativeRangeAction;
      dataRequest.relative_range_total = newValue.relativeRangeTotal;
    }

    const result: DeviceRecord = await apiCall({
      url: `/api/teams/${originTeamId}/charts/${originChart.id}`,
      data: dataRequest,
      method: 'PATCH',
      isLoading: true,
      showError: true,
    });

    if (result?.id) {
      closeModal(`${MODAL_KEY.SETTING_DEVICE_CHART}-${originChart.id}`);
      showToastMessage('success', SUCCESS_MESSAGE.SETTING_DEVICE_CHART);
      const newDeviceChart: DeviceRecord = {
        ...deviceChart,
        name: result.name,
      };

      setDeviceChart(newDeviceChart);
      const newSetting: FormSettingChart = {
        ...newValue,
        name: chartName,
      };
      setTimeout(() => {
        updateSettingChart(chartKey, newSetting);
      }, 500);
    }
  };

  const onUpdateDateSetting = () => {
    if (Number(dateRangePickerTabActive.value) === DeviceChartDateRangeTabKey.RELATIVE_RANGE) {
      updateSettingByRelativeRange();
      return;
    }
    updateSettingByQuickOption();
  };
  const updateSettingByQuickOption = () => {
    if (
      isEmpty(date) ||
      (selectedQuickOption.value === setting?.dateRangeType &&
        selectedQuickOption.value !== ChartDateRange.Custom &&
        Number(dateRangePickerTabActive.value) === setting.dateRangeTabKey)
    ) {
      setRelativeRange({
        action: setting.relativeRangeAction,
        total: setting.relativeRangeTotal,
      });
      return;
    }

    const isChangeCustom = checkDateSelected(
      {
        start: setting.startDate,
        end: setting.endDate,
      },
      {
        start: date[0].startDate,
        end: date[0].endDate,
      },
    );

    if (
      isChangeCustom &&
      selectedQuickOption.value === ChartDateRange.Custom &&
      Number(dateRangePickerTabActive.value) === setting.dateRangeTabKey
    ) {
      setRelativeRange({
        action: setting.relativeRangeAction,
        total: setting.relativeRangeTotal,
      });
      return;
    }

    const newSetting: FormSettingChart = {
      ...setting,
      endDate: date[0].endDate,
      startDate: date[0].startDate,
      dateRangeType: selectedQuickOption.value,
      dateRangeTabKey: DeviceChartDateRangeTabKey.QUICK_OPTIONS,
      relativeRangeAction: DeviceChartRelativeRange.HOUR,
      relativeRangeTotal: 1,
    };
    updateDeviceChart(newSetting);
  };

  const updateSettingByRelativeRange = () => {
    if (
      relativeRange.action === setting.relativeRangeAction &&
      relativeRange.total === setting.relativeRangeTotal &&
      Number(dateRangePickerTabActive.value) === setting.dateRangeTabKey
    ) {
      const { startDate, endDate } = getDeviceChartDateFilter({
        ...setting,
        dateRangeTabKey: DeviceChartDateRangeTabKey.QUICK_OPTIONS,
      });

      selectedQuickOption.setValue(setting.dateRangeType);
      setDate([
        {
          startDate,
          endDate,
          key: 'selection',
        },
      ]);
      return;
    }

    const newSetting: FormSettingChart = {
      ...setting,
      relativeRangeAction: relativeRange.action,
      relativeRangeTotal: relativeRange.total,
      dateRangeTabKey: DeviceChartDateRangeTabKey.RELATIVE_RANGE,
    };

    updateDeviceChart(newSetting);
  };

  const onDateRangePickerTabClick = (key: string) => {
    dateRangePickerTabActive.setValue(Number(key));
  };

  const onChangeDateRangePicker = (newDate: DateRangePickerInputState[]) => {
    if (isEmpty(newDate)) {
      return;
    }

    const { startDate, endDate } = newDate[0];
    const dateRangeType = getDateRangeField(startDate, endDate);
    selectedQuickOption.setValue(dateRangeType);
    if (
      includes([ChartDateRange['Last 5 minutes'], ChartDateRange['Last 1 hour']], dateRangeType)
    ) {
      setDate(newDate);
      return;
    }

    setDate([
      {
        startDate: startOfDay(startDate),
        endDate: endOfDay(endDate),
        key: 'selection',
      },
    ]);
  };

  const onChangeRelativeRangeTotal = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRelativeRange({
      ...relativeRange,
      total: parseInt(event.target.value, 0),
    });
  };

  const onChangeRelativeRangeAction = (item: DropdownMenuItem) => {
    if (!item) {
      return;
    }
    setRelativeRange({
      ...relativeRange,
      action: Number(item.key),
    });
  };

  const yAxisSetting = getYAxisChartSetting(setting);

  const timeUnit = getChartTimeUnit(setting);
  const isLastTime = checkDeviceChartFilterLastTime(setting);

  const chartOptions = getChartOptions(
    minmaxChart.min,
    minmaxChart.max,
    isLastTime,
    timeUnit,
    yAxisSetting,
  );

  const isShowQuickOptions =
    Number(dateRangePickerTabActive.value) === DeviceChartDateRangeTabKey.QUICK_OPTIONS;
  const isShowRelativeRange =
    Number(dateRangePickerTabActive.value) === DeviceChartDateRangeTabKey.RELATIVE_RANGE;

  return {
    setting,
    deviceChart,
    datasets,
    device,
    labels,
    actionVisible,
    chartLoading,
    chartOptions,
    date,
    deviceUploads,
    dateRangePickerTabActive,
    isShowQuickOptions,
    isShowRelativeRange,
    selectedQuickOption,
    relativeRange,
    chartKey,
    onChangeDropdownAction,
    onClickMenuItem,
    onSubmitSettingChart,
    onUpdateDateSetting,
    onDateRangePickerTabClick,
    onChangeDateRangePicker,
    onChangeRelativeRangeTotal,
    onChangeRelativeRangeAction,
  };
};

export const useChartSettingModalController = (setting: ChartSettingModalProps) => {
  const { chart, originChartKey, onSubmitSettingChart } = setting;
  const chartKey = `${MODAL_KEY.SETTING_DEVICE_CHART}-${chart.id}`;
  const modalKey = useDialogStore((s) => s.state.modalKey[chartKey]);
  const deviceSetting = useDeviceSettingChartStore((s) => s.state.setting[originChartKey]);

  const openSelectRefresh = useBoolean(false);

  const formik = useFormik({
    initialValues: defaultFormSettingChart,
    validationSchema: settingChartSchema,
    onSubmit: async (values) => {
      onSubmitSettingChart(values);
    },
  });

  const { values, isSubmitting, isValid, handleSubmit, handleChange, setFieldValue, setValues } =
    formik;
  const { name, refresh, refreshType, yAxisType, yAxisMax, yAxisMin } = values;

  useEffect(() => {
    if (modalKey && deviceSetting) {
      console.log(deviceSetting, 'deviceSetting');
      setValues(deviceSetting);
    }
  }, [modalKey, deviceSetting]);

  const onChangeRefreshType = (newItem: DropdownMenuItem) => {
    if (newItem) {
      setFieldValue('refreshType', Number(newItem?.key));
    }
    openSelectRefresh.setValue(false);
  };

  const onChangeYAxis = (newYAxisType: DeviceChartYAxisSettingType) => () => {
    setValues({
      ...values,
      yAxisType: newYAxisType,
      yAxisMin: 0,
      yAxisMax: 1000,
    });
  };

  const errorYAxis = yAxisMin > yAxisMax;
  const disabled = !refresh || errorYAxis || isSubmitting || !isValid;

  return {
    openSelectRefresh,
    name,
    refresh,
    refreshType,
    yAxisType,
    yAxisMax,
    yAxisMin,
    errorYAxis,
    disabled,
    handleSubmit,
    handleChange,
    onChangeRefreshType,
    onChangeYAxis,
  };
};
