import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useActionLogController } from 'components/action-log/core/controller';
import { LogType } from 'components/action-log/core/enum';
import { useFormik } from 'formik';
import {
  type DropdownMenuItem,
  type QueryState,
  closeModal,
  initialQueryState,
  initialQueryStateTable,
  openModal,
  showToastMessage,
  updateQueryStateTable,
  useDialogStore,
  useTableStore,
} from 'thesis-ui';

import { deviceDetailSelector, useDeviceStore } from 'modules/devices/core';
import { teamDetailSelector, useTeamStore } from 'modules/team';

import type { CommandDetail } from './type';
import type { ConfirmModalData } from 'components/modal/core/type';

import { getDeviceCommandPath, getTableKeyById, hasEmptyValues, reArrangeRowOrder } from 'helpers';
import { getDevicePermission } from 'helpers/permission';
import { useBoolean, useNumber, useString } from 'hooks';
import { apiCall } from 'hooks/service/api';
import { cloneDeep, isEmpty, omit } from 'lodash';

import { ERROR_MESSAGE, SUCCESS_MESSAGE } from 'constants/message';
import { MODAL_KEY } from 'constants/modal-key';
import { PATH } from 'constants/path';
import { TABLE_KEY } from 'constants/table';
import type { Pagination } from 'types/api';

import { defaultFormCollectionRequest } from './constant';
import { CommandType, DeviceCommandKey } from './enum';
import {
  getButtonTitleByType,
  getCollectionLabel,
  getCommandModalKey,
  getCommandModalMessageError,
  getCommandModalMessageSuccess,
  getFormCommandModalURL,
} from './helper';
import { getFormCollectionSchema } from './schema';

export const useDeviceCommandLayoutController = () => {
  const navigationTo = useNavigate();
  const team = useTeamStore(teamDetailSelector);
  const device = useDeviceStore(deviceDetailSelector);
  const location = useLocation();

  const onTabClick = (key: string) => {
    navigationTo(key);
  };

  return {
    team,
    device,
    locationPath: location.pathname,
    onTabClick,
  };
};

export const useDeviceCommandQueueController = () => {
  const navigationTo = useNavigate();
  const team = useTeamStore(teamDetailSelector);
  const device = useDeviceStore(deviceDetailSelector);

  const deviceQueueTableKey = getTableKeyById(TABLE_KEY.DEVICES_COMMAND_QUEUE, device?.id);
  const queryState = useTableStore((s) => s.state.queryState[deviceQueueTableKey]);
  const [queues, setQueues] = useState<CommandDetail[]>([]);
  const total = useNumber(0);
  const isLoading = useBoolean(true);
  const [actionVisible, setActionVisible] = useState<Record<string, boolean>>({});
  const queueId = useString('');
  const loadingAPI = useBoolean(false);

  useEffect(() => {
    if (queryState) {
      if (device?.id) {
        getCommandQueue();
      }
      return;
    }
    if (device?.id) {
      initialQueryStateTable(deviceQueueTableKey, omit(initialQueryState, ['page_size']) as any);
    }
  }, [queryState, device]);

  const getCommandQueue = async () => {
    isLoading.setValue(true);

    const result: {
      data: CommandDetail[];
      pagination: Pagination;
    } = await apiCall({
      url: `/api/teams/${device?.team_id}/devices/${device?.id}/commands`,
      data: {
        ...queryState,
        filter: JSON.stringify({
          type: CommandType.Queue,
          retrieved_time: null,
        }),
        sort: 'position',
        order: 'asc',
      },
      method: 'GET',
      isLoading: loadingAPI.value,
      showError: true,
    });

    loadingAPI.setValue(false);
    if (result?.data) {
      setQueues(result?.data ?? []);
    }
    if (result?.pagination) {
      total.setValue(result.pagination.total);
    }
    isLoading.setValue(false);
  };

  const onChangeDropdownAction = (idDevice: string) => () => {
    const newAction = cloneDeep(actionVisible);
    newAction[idDevice] = !newAction[idDevice];
    setActionVisible(newAction);
  };

  const onClickMenuItem = (item: DropdownMenuItem, command: CommandDetail) => {
    setActionVisible({});

    switch (item.key) {
      case DeviceCommandKey.Edit:
        openModal(MODAL_KEY.FORM_COMMAND_QUEUE, command);
        break;
      case DeviceCommandKey.ViewNode:
        openModal(MODAL_KEY.COMMAND_NOTE, command);
        break;
      default:
        queueId.setValue(command.id);
        openModal(MODAL_KEY.DELETE_QUEUE, {
          header: `Delete command`,
          label: `This command will be deleted from queue and no longer be sent to the device.`,
        } as ConfirmModalData);
        break;
    }
  };

  const onDeleteQueue = async () => {
    if (!queueId.value || !device?.id) {
      showToastMessage('error', ERROR_MESSAGE.DELETE_COMMAND);
      return;
    }
    const result: CommandDetail = await apiCall({
      url: `/api/teams/${device.team_id}/devices/${device.id}/commands/${queueId.value}`,
      method: 'DELETE',
      isLoading: true,
      showError: true,
    });
    if (result?.id) {
      closeModal(MODAL_KEY.DELETE_QUEUE);
      showToastMessage('success', SUCCESS_MESSAGE.DELETE_COMMAND);
      loadingAPI.setValue(true);
      getCommandQueue();
      queueId.setValue('');
    }
  };

  const updateQueryState = (key: string, newState: QueryState) => {
    updateQueryStateTable(key, newState);
  };

  const callbackWhenAddCollection = () => {
    if (device?.id) {
      navigationTo(
        getDeviceCommandPath(PATH.TEAM_DEVICE_COMMAND_COLLECTION, device.team_id, device.id),
      );
    }
  };

  const callbackWhenAddQueue = () => {
    loadingAPI.setValue(true);
    getCommandQueue();
  };

  const devicePermission = getDevicePermission(team?.role);

  const onDraggableEnd = async (oldIndex: number, newIndex: number) => {
    const originQueues = cloneDeep(queues);

    if (!device || !originQueues[oldIndex]?.id || !originQueues[newIndex]?.id) {
      showToastMessage('error', ERROR_MESSAGE.REORDERED);
      return;
    }
    const newQueues: CommandDetail[] = reArrangeRowOrder(originQueues, oldIndex, newIndex);

    setQueues(newQueues);

    await apiCall({
      url: `/api/teams/${device.team_id}/devices/${device.id}/commands/re-arrange`,
      data: newQueues.map((el) => el.id),
      method: 'POST',
      isLoading: false,
      showError: false,
    });

    showToastMessage('success', SUCCESS_MESSAGE.REORDERED);
  };

  return {
    devicePermission,
    deviceQueueTableKey,
    queryState,
    queues,
    total,
    isLoading,
    actionVisible,
    onChangeDropdownAction,
    onClickMenuItem,
    updateQueryState,
    callbackWhenAddCollection,
    callbackWhenAddQueue,
    onDeleteQueue,
    onDraggableEnd,
  };
};

export const useButtonAddCommandController = () => {
  const visible = useBoolean(false);

  const onChangeDropdownAction = () => {
    visible.setValue(!visible.value);
  };

  const onClickMenuItem = (item: DropdownMenuItem) => {
    onChangeDropdownAction();
    openModal(
      item.key === DeviceCommandKey.AddCollection
        ? MODAL_KEY.ADD_COMMAND_FROM_COLLECTION
        : MODAL_KEY.FORM_COMMAND_QUEUE,
    );
  };

  return {
    visible,
    onChangeDropdownAction,
    onClickMenuItem,
  };
};

export const useAddCommandFromCollectionModalController = (callback?: () => void) => {
  const device = useDeviceStore(deviceDetailSelector);
  const modalKey = useDialogStore((s) => s.state.modalKey[MODAL_KEY.ADD_COMMAND_FROM_COLLECTION]);
  const [collections, setCollections] = useState<CommandDetail[]>([]);
  const [collectionSelect, setCollectionSelect] = useState<CommandDetail | null>(null);

  useEffect(() => {
    if (!modalKey) {
      return;
    }
    if (device?.id) {
      getCommandCollection();
    }
  }, [modalKey, device]);

  const getCommandCollection = async () => {
    const result: {
      data: CommandDetail[];
      pagination: Pagination;
    } = await apiCall({
      url: `/api/teams/${device?.team_id}/devices/${device?.id}/commands`,
      data: {
        filter: JSON.stringify({
          type: CommandType.Collection,
        }),
        sort: 'name',
        order: 'asc',
      },
      method: 'GET',
      isLoading: false,
      showError: true,
    });

    if (result?.data) {
      setCollections(result?.data ?? []);
    }
  };

  const onOpenFormAddCommand = () => {
    closeModal(MODAL_KEY.ADD_COMMAND_FROM_COLLECTION);
    openModal(MODAL_KEY.FORM_COMMAND_COLLECTION);
  };

  const onCollectionSelect = (newCollection: CommandDetail) => () => {
    setCollectionSelect(newCollection);
  };

  const handleSubmit = async () => {
    if (!device?.id || !collectionSelect) {
      return showToastMessage('error', ERROR_MESSAGE.ADD_QUEUE);
    }
    const { data, name, note } = collectionSelect;
    const result: CommandDetail = await apiCall({
      url: `/api/teams/${device.team_id}/devices/${device.id}/commands`,
      data: {
        name,
        note: note ?? '',
        data,
        type: CommandType.Queue,
      },
      method: 'POST',
      isLoading: true,
      showError: true,
    });
    if (result?.id) {
      showToastMessage('success', SUCCESS_MESSAGE.ADD_QUEUE);
      closeModal(MODAL_KEY.ADD_COMMAND_FROM_COLLECTION);
      callback?.();
    }
  };

  const isCollection = Boolean(collections.length);
  const disabled = isEmpty(collectionSelect);

  return {
    disabled,
    collections,
    collectionSelect,
    isCollection,
    onOpenFormAddCommand,
    onCollectionSelect,
    handleSubmit,
  };
};

export const useFormCommandModalController = (type: CommandType, callback?: () => void) => {
  const device = useDeviceStore(deviceDetailSelector);
  const modalKey = getCommandModalKey(type);

  const modalProps: CommandDetail = useDialogStore((s) => s.state.data[modalKey]);
  const isEdit = Boolean(modalProps);

  const formik = useFormik({
    initialValues: defaultFormCollectionRequest,
    validationSchema: getFormCollectionSchema(type),
    onSubmit: async (values, { setSubmitting }) => {
      if (!device?.id) {
        return showToastMessage('error', getCommandModalMessageError(isEdit, type));
      }
      setSubmitting(true);
      const url = getFormCommandModalURL(isEdit, device.team_id, device.id, modalProps?.id ?? '');

      const result: CommandDetail = await apiCall({
        url: url,
        data: {
          ...values,
          type: type,
        },
        method: isEdit ? 'PATCH' : 'POST',
        isLoading: true,
        showError: true,
      });

      if (result?.id) {
        showToastMessage('success', getCommandModalMessageSuccess(isEdit, type));
        onClose();
        callback?.();
      }
    },
  });

  const { values, isSubmitting, isValid, handleSubmit, handleChange, setValues } = formik;
  const { data, name, note } = values;

  useEffect(() => {
    if (modalProps) {
      setValues({
        data: modalProps.data,
        name: modalProps.name,
        note: modalProps.note ?? '',
        type: modalProps.type,
      });
      return;
    }

    setValues(defaultFormCollectionRequest);
  }, [modalProps]);

  const onClose = () => {
    closeModal(modalKey);
  };

  const buttonTitleAddByType = getButtonTitleByType(type);

  const buttonTitle = isEdit ? 'Save' : buttonTitleAddByType;

  const disabled =
    (type === CommandType.Collection && hasEmptyValues({ name })) ||
    isSubmitting ||
    !isValid ||
    !data;

  return {
    name,
    data,
    note,
    disabled,
    buttonTitle,
    isEdit,
    handleSubmit,
    handleChange,
    onClose,
  };
};

export const useCommandNoteModalController = (modalKey: string) => {
  const modalProps: CommandDetail = useDialogStore((s) => s.state.data[MODAL_KEY.COMMAND_NOTE]);

  const onEditNote = () => {
    if (!modalProps) {
      return;
    }
    closeModal(MODAL_KEY.COMMAND_NOTE);
    openModal(modalKey, modalProps);
  };

  const note = modalProps?.note ? modalProps?.note : null;
  return {
    note,
    command: modalProps,
    onEditNote,
  };
};

export const useCommandMethodModalController = () => {
  const modalProps: CommandDetail = useDialogStore((s) => s.state.data[MODAL_KEY.COMMAND_METHOD]);
  const device = useDeviceStore(deviceDetailSelector);

  return {
    command: modalProps,
    device,
  };
};

export const useDeviceCommandCollectionController = () => {
  const team = useTeamStore(teamDetailSelector);
  const device = useDeviceStore(deviceDetailSelector);

  const deviceCollectionTableKey = getTableKeyById(
    TABLE_KEY.DEVICES_COMMAND_COLLECTION,
    device?.id,
  );

  const queryState = useTableStore((s) => s.state.queryState[deviceCollectionTableKey]);
  const [collections, setCollections] = useState<CommandDetail[]>([]);
  const total = useNumber(0);
  const isLoading = useBoolean(true);
  const collectionId = useString('');
  const loadingAPI = useBoolean(false);

  useEffect(() => {
    if (!device?.id) {
      return;
    }
    if (queryState) {
      getCommandCollection();
      return;
    }
    initialQueryStateTable(deviceCollectionTableKey, omit(initialQueryState, ['page_size']) as any);
  }, [queryState, device]);

  const getCommandCollection = async () => {
    isLoading.setValue(true);

    const result: {
      data: CommandDetail[];
      pagination: Pagination;
    } = await apiCall({
      url: `/api/teams/${device?.team_id}/devices/${device?.id}/commands`,
      data: {
        ...queryState,
        filter: JSON.stringify({
          type: CommandType.Collection,
        }),
      },
      method: 'GET',
      isLoading: loadingAPI.value,
      showError: true,
    });
    loadingAPI.setValue(false);
    if (result?.data) {
      setCollections(result?.data ?? []);
    }
    if (result?.pagination) {
      total.setValue(result.pagination.total);
    }
    isLoading.setValue(false);
  };

  const onClickMenuItem = (item: DropdownMenuItem, command: CommandDetail) => {
    switch (item.key) {
      case DeviceCommandKey.AddToQueue:
        handleAddCollectionToQueue(command);

        break;
      case DeviceCommandKey.Edit:
        openModal(MODAL_KEY.FORM_COMMAND_COLLECTION, command);

        break;
      case DeviceCommandKey.ViewNode:
        openModal(MODAL_KEY.COMMAND_NOTE, command);
        break;
      default:
        collectionId.setValue(command.id);
        openModal(MODAL_KEY.DELETE_COLLECTION, {
          header: `Delete command`,
          label: `This command will be deleted from the collection.`,
        } as ConfirmModalData);
        break;
    }
  };

  const handleAddCollectionToQueue = async (command: CommandDetail) => {
    if (!device?.id) {
      return showToastMessage('error', ERROR_MESSAGE.ADD_TO_QUEUE);
    }
    const { data, name, note } = command;
    const result: CommandDetail = await apiCall({
      url: `/api/teams/${device?.team_id}/devices/${device?.id}/commands`,
      data: {
        name,
        note: note ?? '',
        data,
        type: CommandType.Queue,
      },
      method: 'POST',
      isLoading: true,
      showError: true,
    });
    if (result?.id) {
      showToastMessage('success', SUCCESS_MESSAGE.ADD_TO_QUEUE);
    }
  };

  const onDeleteCollection = async () => {
    if (!collectionId.value || !device?.id) {
      showToastMessage('error', ERROR_MESSAGE.DELETE_COMMAND);
      return;
    }
    const result: CommandDetail = await apiCall({
      url: `/api/teams/${device.team_id}/devices/${device.id}/commands/${collectionId.value}`,
      method: 'DELETE',
      isLoading: true,
      showError: true,
    });
    if (result?.id) {
      closeModal(MODAL_KEY.DELETE_COLLECTION);
      showToastMessage('success', SUCCESS_MESSAGE.DELETE_COMMAND);
      loadingAPI.setValue(true);
      getCommandCollection();
      collectionId.setValue('');
    }
  };

  const updateQueryState = (key: string, newState: QueryState) => {
    updateQueryStateTable(key, newState);
  };

  const onAddCommand = () => {
    openModal(MODAL_KEY.FORM_COMMAND_COLLECTION);
  };

  const callbackWhenAddCollection = () => {
    loadingAPI.setValue(true);
    getCommandCollection();
  };

  const devicePermission = getDevicePermission(team?.role);

  const isCommandMaximum = collections.length >= 5;
  const collectionLabel = getCollectionLabel(isCommandMaximum);
  const disableBtnAddCommand = !devicePermission || isCommandMaximum;

  return {
    devicePermission,
    deviceCollectionTableKey,
    queryState,
    collections,
    total,
    isLoading,
    isCommandMaximum,
    collectionLabel,
    disableBtnAddCommand,
    onClickMenuItem,
    updateQueryState,
    callbackWhenAddCollection,
    onAddCommand,
    onDeleteCollection,
  };
};

export const useCommandActionLogController = () => {
  const team = useTeamStore(teamDetailSelector);
  const device = useDeviceStore(deviceDetailSelector);

  const { logs, isLoadMore, getActionLog, onLoadMore } = useActionLogController(
    device?.team_id ?? '',
    JSON.stringify({
      device_id: device?.id,
      type: LogType.Command,
    }),
  );

  useEffect(() => {
    if (device?.id) {
      getActionLog();
    }
  }, [device]);

  return { logs, isLoadMore, colorTeam: team?.colour, onLoadMore };
};
