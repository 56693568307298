import { useRef } from 'react';
import { Outlet } from 'react-router-dom';

import { Body, Container, Header } from '../styles/team-layout';

import { TeamHeader } from './TeamHeader';

export const TeamLayout = () => {
  const teamLayoutRef = useRef<HTMLDivElement>(null);

  return (
    <Container>
      <Header>
        <TeamHeader teamLayoutRef={teamLayoutRef} />
      </Header>
      <Body ref={teamLayoutRef}>
        <Outlet />
      </Body>
    </Container>
  );
};
