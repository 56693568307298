import { SelectRoleLabel } from '../components';
import { ListTeamRole } from './constant';
import { TeamRole } from './enum';

export const roleBaseOptions = [
  {
    key: String(TeamRole.TeamGuest),
    label: (
      <SelectRoleLabel
        title={ListTeamRole[TeamRole.TeamGuest]}
        description="Can only view data and device information. Can’t export or delete data of devices."
      />
    ),
    isIconTick: false,
  },
  {
    key: String(TeamRole.TeamMember),
    label: (
      <SelectRoleLabel
        title={ListTeamRole[TeamRole.TeamMember]}
        description="Can do anything with devices."
      />
    ),
    isIconTick: false,
  },
  {
    key: String(TeamRole.TeamAdmin),
    label: (
      <SelectRoleLabel
        title={ListTeamRole[TeamRole.TeamAdmin]}
        description="Can do anything with devices. Can also edit team, manage Team Member and Guest."
      />
    ),
    isIconTick: false,
  },
];

export const roleOwnerOptions = [
  {
    key: String(TeamRole.TeamOwner),
    label: (
      <SelectRoleLabel
        title={ListTeamRole[TeamRole.TeamOwner]}
        description="Can do anything with devices and team."
      />
    ),
    isIconTick: false,
  },
  {
    key: 'remove',
    label: <SelectRoleLabel title="Remove" />,
    isIconTick: false,
    isLine: true,
    style: {
      paddingTop: 6,
      paddingBottom: 6,
      minHeight: 'auto',
    },
  },
];
