import { Row, Spacing, Typography, neutral } from 'thesis-ui';

import { Container } from '../styles/dashboard-device-card';

import { ReactComponent as ArrowUpRight } from 'assets/icons/arrow-up-right.svg';

import type { DashboardDeviceCardProps } from '../core';

export const DashboardDeviceCard: React.FC<DashboardDeviceCardProps> = ({
  icon,
  title,
  value,
  onClick,
}) => {
  return (
    <Container direction="column" onClick={onClick}>
      <Spacing
        spacing="xl"
        type="margin"
        placement="bottom"
        style={{
          width: '100%',
        }}
      >
        <Row justify="space-between">
          {icon}
          <ArrowUpRight className="icon" />
        </Row>
      </Spacing>
      <Spacing spacing="xxs" type="margin" placement="bottom">
        <Typography weight={500} variant="body-2" color={neutral['neutral-7']}>
          {title}
        </Typography>
      </Spacing>
      <Typography weight={600} height={30} variant="h4">
        {value}
      </Typography>
    </Container>
  );
};
