import { ChartDateRange } from 'enums/date-range';
import { definedDateRanges } from 'thesis-ui';

import { addDays, subDays, subHours, subMinutes, subMonths } from 'date-fns';

const currentDate = new Date();

export const getRangeOptions = () => [
  // To-do-design
  {
    label: 'Today',
    start: definedDateRanges.startOfToday,
    end: definedDateRanges.endOfToday,
    field: ChartDateRange.Today,
  },
  {
    label: 'This week',
    start: definedDateRanges.startOfWeek,
    end: definedDateRanges.endOfWeek,
    field: ChartDateRange['This week'],
  },
  {
    label: 'This month',
    start: definedDateRanges.startOfMonth,
    end: definedDateRanges.endOfMonth,
    field: ChartDateRange['This month'],
  },
  {
    label: 'This year',
    start: definedDateRanges.startOfYear,
    end: definedDateRanges.endOfYear,
    field: ChartDateRange['This year'],
  },
  {
    label: 'Last 5 minutes',
    start: subMinutes(currentDate, 5),
    end: currentDate,
    field: ChartDateRange['Last 5 minutes'],
  },
  {
    label: 'Last 1 hour',
    start: subHours(currentDate, 1),
    end: currentDate,
    field: ChartDateRange['Last 1 hour'],
  },
  {
    label: 'Last 7 days',
    start: subDays(definedDateRanges.startOfToday, 6),
    end: definedDateRanges.endOfToday,
    field: ChartDateRange['Last 7 days'],
  },
  {
    label: 'Last 30 days',
    start: subDays(definedDateRanges.startOfToday, 29),
    end: definedDateRanges.endOfToday,
    field: ChartDateRange['Last 30 days'],
  },

  {
    label: 'Last 6 months',
    start: subMonths(definedDateRanges.startOfToday, 6),
    end: definedDateRanges.endOfToday,
    field: ChartDateRange['Last 6 months'],
  },
];

export const getStaticDateRanges = (selectedQuickOption: number) => {
  const options = getRangeOptions().concat([
    {
      label: 'Custom',
      start: definedDateRanges.startOfToday,
      end: addDays(definedDateRanges.startOfToday, 1),
      field: ChartDateRange.Custom,
    },
  ]);

  return options.map((option) => {
    return {
      label: option.label,
      range: () => ({
        startDate: option.start,
        endDate: option.end,
      }),
      isSelected: () => {
        return selectedQuickOption === option.field;
      },
    };
  });
};
