import { useRef } from 'react';
import { Outlet } from 'react-router-dom';

import { Spacing } from 'thesis-ui';

import {
  Body,
  Container,
  Content,
  ContentLeft,
  ContentRight,
  Header,
  LeftMenu,
  StyledOutlet,
} from '../styles';

import { ReactComponent as Logo } from 'assets/images/logo.svg';

import { useTermAndPolicyLayoutController } from '../core';
import { TermAndPolicyMenu } from './TermAndPolicyMenu';

export const TermAndPolicyLayout = () => {
  const { redirectLogin } = useTermAndPolicyLayoutController();
  const refPage = useRef<HTMLDivElement>(null);

  return (
    <Container>
      <Header>
        <Spacing spacing="xl" placement="left">
          <Logo
            onClick={redirectLogin}
            style={{
              cursor: 'pointer',
            }}
          />
        </Spacing>
      </Header>
      <Body ref={refPage}>
        <Content>
          <ContentLeft>
            <LeftMenu>
              <TermAndPolicyMenu refPage={refPage} />
            </LeftMenu>
          </ContentLeft>
          <ContentRight>
            <StyledOutlet>
              <Outlet />
            </StyledOutlet>
          </ContentRight>
        </Content>
      </Body>
    </Container>
  );
};
