import styled from 'styled-components';

import { RadiusConfig, Row, neutral, neutralTrans } from 'thesis-ui';

export const Body = styled(Row)`
  border-radius: ${RadiusConfig.xs}px;
  border: 1px solid ${neutralTrans['neutralTrans-15']};
  background: ${neutral['neutral-1']};
  padding: 24px;
`;

export const Content = styled(Row)`
  width: 640px;
`;
