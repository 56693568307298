import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useFormik } from 'formik';
import {
  type AvatarUploadRef,
  type DropdownMenuItem,
  closeModal,
  neutral,
  openModal,
  showToastMessage,
  useDialogStore,
} from 'thesis-ui';

import { Role, useAuthStore, userProfileSelector } from 'modules/auth/core';
import { updateIsDeleteTeam, updateRefreshTeamList } from 'modules/teams/core/store';

import { type FormTeamRequest, type TeamDetail, type TeamUser } from '../type';
import { type ConfirmModalData } from 'components/modal/core/type';

import { compileFormData, getImageUrl, getInputStatus, hasEmptyValues, trimObject } from 'helpers';
import { useBoolean, useNumber, useString } from 'hooks';
import { apiCall } from 'hooks/service/api';

import { ERROR_MESSAGE, SUCCESS_MESSAGE } from 'constants/message';
import { MODAL_KEY } from 'constants/modal-key';
import { PATH } from 'constants/path';

import { LIST_COLOR_SELECT, defaultFormTeam } from '../constant';
import { TeamActionKey, TeamRole, TeamWithDeviceType, UserStatus } from '../enum';
import {
  checkStorageLimitReached,
  getAvatarStyles,
  getButtonTeamShareDisabled,
  getEmailMessageError,
  getTeamPath,
  getTeamSharePermission,
  isAdminByTeamRole,
} from '../helper';
import { formTeamSchema, shareSchema } from '../schema';
import {
  createTeam,
  deleteTeam,
  idTransferTeamOwnerSelector,
  isOpenLimitReachModalSelector,
  isRefreshTeamShareSelector,
  teamDetailSelector,
  teamUsersSelector,
  updateIdTransferTeamOwner,
  updateRefreshTeamShare,
  updateRefreshTeams,
  updateTeam,
  updateTeamDetail,
  updateTeamUsers,
  useTeamStore,
} from '../store';

export const useFormTeamController = () => {
  const navigateTo = useNavigate();
  const location = useLocation();
  const isErrorAvatar = useBoolean(false);
  const [logo, setLogo] = useState<File | null>(null);
  const logoRef = useRef<AvatarUploadRef>(null);
  const modalProps = useDialogStore((s) => s.state.data[MODAL_KEY.FORM_TEAM]);
  const isEdit = Boolean(modalProps);
  const logoURL = useString('');
  const isRemoveLogo = useBoolean(false);
  const isTeamsPath = location.pathname.startsWith(PATH.TEAMS);

  const formik = useFormik({
    initialValues: defaultFormTeam,
    validationSchema: formTeamSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const request: FormTeamRequest = {
        name: values.name,
        colour: values.color,
      };
      if (logo) {
        request.logo = logo;
      }
      if (isRemoveLogo.value && !logo) {
        request.logo = null;
      }

      const result: TeamDetail = await apiCall({
        url: isEdit ? `/api/teams/${modalProps?.id}` : `/api/teams`,
        data: compileFormData(trimObject(request)),
        method: isEdit ? 'PATCH' : 'POST',
        isLoading: true,
        showError: true,
      });

      if (result.id) {
        showToastMessage(
          'success',
          isEdit ? SUCCESS_MESSAGE.EDIT_TEAM : SUCCESS_MESSAGE.CREATED_TEAM,
        );
        closeModal(MODAL_KEY.FORM_TEAM);

        if (isEdit) {
          updateTeam(result);
          isRemoveLogo.setValue(false); // reset remove logo flag
          if (isTeamsPath) {
            updateRefreshTeamList(true);
          } else {
            updateTeamDetail(result);
          }
          return;
        }

        createTeam({
          ...result,
          role: TeamRole.TeamOwner,
        });
        navigateTo(getTeamPath(result.id, PATH.TEAM_DASHBOARD));
      }
    },
  });

  const { values, isSubmitting, isValid, handleSubmit, handleChange, setFieldValue, setValues } =
    formik;
  const customColor = useString(neutral['neutral-13']);
  const isCustomColorActive = useBoolean(false);

  useEffect(() => {
    setLogo(null);
    logoURL.setValue('');
    if (!modalProps) {
      setValues(defaultFormTeam);
      customColor.setValue(neutral['neutral-13']);
      isCustomColorActive.setValue(false);
      return;
    }

    setValues({
      name: modalProps?.name,
      color: modalProps?.colour ?? defaultFormTeam.color,
    });
    if (modalProps.logo) {
      logoURL.setValue(getImageUrl(modalProps.logo));
    }
  }, [modalProps]);

  const { color, name } = values;

  useEffect(() => {
    if (color && !LIST_COLOR_SELECT.includes(color.toLowerCase())) {
      customColor.setValue(color);
      isCustomColorActive.setValue(true);
    }
  }, [color]);

  const disabled = hasEmptyValues({ name, color }) || isSubmitting || !isValid;

  const onChangePicker = (newColor: { hex: string }) => {
    setFieldValue('color', newColor.hex?.toUpperCase());
  };

  const onChangeColorSelection = (newColor: string) => () => {
    setFieldValue('color', newColor);
    isCustomColorActive.setValue(false);
  };

  const onChangeAvatar = (file: File) => {
    setLogo(file);
  };

  const onErrorAvatar = (file: File) => {
    isErrorAvatar.setValue(Boolean(file));
  };

  const onDeleteLogo = () => {
    logoRef.current?.clearAvatar();
    setLogo(null);
    if (logoURL.value) {
      isRemoveLogo.setValue(true);
    }
  };

  return {
    color,
    customColor,
    isCustomColorActive,
    name,
    disabled,
    isErrorAvatar: isErrorAvatar.value,
    logoRef,
    isEdit,
    logoURL,
    handleSubmit,
    handleChange,
    onChangePicker,
    onChangeColorSelection,
    onErrorAvatar,
    onChangeAvatar,
    onDeleteLogo,
  };
};

export const useTeamHeaderController = (teamLayoutRef: React.RefObject<HTMLDivElement>) => {
  const params = useParams();
  const location = useLocation();
  const navigationTo = useNavigate();
  const { id } = params;
  const actionVisible = useBoolean(false);
  const team = useTeamStore(teamDetailSelector);
  const isAdmin = isAdminByTeamRole(team?.role);
  const userProfile = useAuthStore(userProfileSelector);
  const isAdminUser = userProfile?.role_id === Role.Admin;
  const isTeamOwner = isAdminUser || team?.role === TeamRole.TeamOwner;
  const isTeamAdmin = isAdminUser || (team && team?.role <= TeamRole.TeamAdmin);
  const isTeamDashboardPath = location.pathname.includes(PATH.TEAM_DASHBOARD);
  const isOpenLimitReachModal = useTeamStore(isOpenLimitReachModalSelector);

  useEffect(() => {
    if (!id) {
      navigationTo(PATH.TEAMS);
      return;
    }
    getTeamDetail(id);
  }, [id]);

  useEffect(() => {
    useTeamStore.getState().setIsOpenLimitReachModal(true);
  }, [id?.toString()]);

  useEffect(() => {
    if (!isTeamDashboardPath || !id || team?.id !== id) {
      return;
    }

    getTeamDetail(id);
  }, [isTeamDashboardPath]);

  const getTeamDetail = async (idTeam: string) => {
    const result: TeamDetail = await apiCall({
      url: `/api/teams/${idTeam}`,
      data: {
        with_device: TeamWithDeviceType.Active,
      },
      method: 'GET',
      isLoading: true,
      showError: true,
    });
    if (result?.id) {
      updateTeamDetail(result);
      updateRefreshTeams(true);
      if (teamLayoutRef.current) {
        teamLayoutRef.current.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
      if (
        checkStorageLimitReached(result.raw_usage || '', result.raw_storage_limit || '') &&
        isOpenLimitReachModal
      ) {
        openModal(MODAL_KEY.STORAGE_LIMIT_REACHED);
      }
    }
  };

  const onChangeDropdownAction = () => {
    actionVisible.setValue((prev) => !prev);
  };

  const onClickMenuItem = (item: DropdownMenuItem) => {
    actionVisible.setValue(false);
    if (item.key === TeamActionKey.EditTeam) {
      openModal(MODAL_KEY.FORM_TEAM, team);
      return;
    }
    openModal(MODAL_KEY.CONFIRM, {
      header: `Delete ${team?.name} team`,
      label: `All data and devices will be deleted. The public devices will no longer be accessible. This action can not undo.`,
    } as ConfirmModalData);
  };

  const onTabClick = (key: string) => {
    navigationTo(key);
  };

  const openTeamShareModal = () => {
    openModal(MODAL_KEY.TEAM_SHARE);
  };

  return {
    team,
    isAdmin,
    actionVisible,
    locationPath: location.pathname,
    onChangeDropdownAction,
    onClickMenuItem,
    onTabClick,
    openTeamShareModal,
    isTeamOwner,
    isTeamAdmin,
    isAdminUser,
  };
};

export const useTeamModalController = () => {
  const team = useTeamStore(teamDetailSelector);
  const navigationTo = useNavigate();
  const location = useLocation();
  const isTeamsPath = location.pathname.startsWith(PATH.MY_TEAMS);
  const modalProps = useDialogStore((s) => s.state.data[MODAL_KEY.CONFIRM]);
  const { teamId } = modalProps || {};
  const originTeamId = teamId || team?.id;

  const onSubmit = async () => {
    if (!originTeamId) {
      showToastMessage('error', ERROR_MESSAGE.DELETE_TEAM);
      return;
    }
    const result: TeamDetail = await apiCall({
      url: `/api/teams/${originTeamId}`,
      method: 'DELETE',
      isLoading: true,
      showError: true,
    });

    if (result?.id) {
      closeModal(MODAL_KEY.CONFIRM);
      deleteTeam(originTeamId);
      showToastMessage('success', SUCCESS_MESSAGE.DELETE_TEAM);
      updateRefreshTeams(true);
      updateIsDeleteTeam(true);
      if (isTeamsPath) {
        updateRefreshTeamList(true);
      } else {
        updateTeamDetail(null);
        navigationTo(PATH.TEAMS);
      }
    }
  };

  return {
    onSubmit,
  };
};

export const useTeamShareModalController = () => {
  const teamState = useTeamStore(teamDetailSelector);
  const teamUsers = useTeamStore(teamUsersSelector);
  const userProfile = useAuthStore(userProfileSelector);
  const modalKey = useDialogStore((s) => s.state.modalKey[MODAL_KEY.TEAM_SHARE]);
  const modalData = useDialogStore((s) => s.state.data[MODAL_KEY.TEAM_SHARE]);
  const isRefreshTeamShare = useTeamStore(isRefreshTeamShareSelector);

  const team = modalData || teamState;

  const roleKey = useNumber(TeamRole.TeamGuest);
  const roleSelect = useBoolean(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: shareSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const result: TeamUser = await apiCall({
        url: `/api/teams/${team?.id}/roles`,
        data: trimObject({
          team_id: team?.id,
          email: values.email,
          role: roleKey.value,
        }),
        method: 'POST',
        isLoading: true,
        showError: true,
      });

      if (result.id) {
        getUserShare(team?.id);
        showToastMessage('success', SUCCESS_MESSAGE.INVITED);
        roleKey.setValue(TeamRole.TeamGuest);
        formik.setValues({
          email: '',
        });
      }
    },
  });

  const { values, isSubmitting, isValid, handleSubmit, handleChange } = formik;
  const { email } = values;

  const emailStatus = getInputStatus(email, SUCCESS_MESSAGE.EMAIL, getEmailMessageError(email));

  useEffect(() => {
    if (!modalKey || !team?.id) {
      return;
    }

    formik.setValues({
      email: '',
    });

    getUserShare(team.id);
  }, [modalKey, team]);

  useEffect(() => {
    if (!isRefreshTeamShare) {
      return;
    }

    getUserShare(team?.id);
    updateRefreshTeamShare(false);
  }, [isRefreshTeamShare]);

  const getUserShare = async (idTeam: string) => {
    const result: {
      data: TeamUser[];
    } = await apiCall({
      url: `/api/teams/${idTeam}/roles`,
      data: {
        sort: 'created_at',
        order: 'asc',
      },
      method: 'GET',
      isLoading: true,
      showError: true,
    });

    if (result?.data) {
      // mapping position
      // current user logged always on top of list
      const currentUserLogged = result.data.find((item) => item.user_id === userProfile.id);
      const owner = result.data.find(
        (item) => item.user_id !== userProfile.id && item.role === TeamRole.TeamOwner,
      );
      const restOfList = result.data.filter(
        (item) => item.user_id !== userProfile.id && item.role !== TeamRole.TeamOwner,
      );

      let newTeamUsers: TeamUser[] = [];
      if (currentUserLogged) {
        newTeamUsers.push(currentUserLogged);
      }
      if (owner) {
        newTeamUsers.push(owner);
      }
      newTeamUsers = newTeamUsers.concat(restOfList);
      updateTeamUsers(newTeamUsers);
    }
  };

  const onChangeSelectTeamRole = (newItem: DropdownMenuItem) => {
    if (newItem) {
      roleKey.setValue(Number(newItem?.key));
    }
    roleSelect.setValue(false);
  };

  const isAdmin = userProfile?.role_id === Role.Admin;
  const hasShare = getTeamSharePermission(team?.role, isAdmin);
  const disabled = getButtonTeamShareDisabled(email, isSubmitting, isValid);

  return {
    team,
    hasShare,
    email,
    disabled,
    emailStatus,
    roleKey,
    roleSelect,
    teamUsers,
    isAdmin,
    handleSubmit,
    handleChange,
    onChangeSelectTeamRole,
  };
};

export const useTeamUserShareController = (user?: TeamUser) => {
  const team = useTeamStore(teamDetailSelector);
  const userProfile = useAuthStore(userProfileSelector);
  const teamUsers = useTeamStore(teamUsersSelector);
  const idTransferTeamOwner = useTeamStore(idTransferTeamOwnerSelector);
  const isAdmin = userProfile?.role_id === Role.Admin;
  const isTeamOwner = user?.role === TeamRole.TeamOwner;
  const teamOwnerId = teamUsers.find((teamUser) => teamUser.role === TeamRole.TeamOwner)?.user_id;
  const isAdminChangingTeamOwner = isAdmin && userProfile.id !== teamOwnerId;

  const changeRole = useBoolean(false);

  const onChangeRoleUser = async (newItem: DropdownMenuItem, id: string) => {
    if (!newItem) {
      changeRole.setValue(false);
      return;
    }
    switch (newItem.key) {
      case 'remove':
        onSubmitRemoveUserShare(id);
        break;
      case '0':
        openModal(MODAL_KEY.TRANSFER, {
          header: `Transfer Team Owner role`,
          label: isAdminChangingTeamOwner
            ? `After transferring, their new role will be Team Member.`
            : `After transferring your Team Owner role to this user, your new role will be Team Member.`,
        } as ConfirmModalData);
        changeRole.setValue(false);
        updateIdTransferTeamOwner(id);
        break;
      default:
        onChangeRole(Number(newItem.key), id);
        break;
    }
  };

  const onSubmitRemoveUserShare = async (id: string) => {
    const resultRemove: TeamUser = await apiCall({
      url: `/api/teams/${team?.id}/roles/${id}`,
      method: 'DELETE',
      isLoading: true,
      showError: true,
    });
    if (resultRemove?.id) {
      updateRefreshTeamShare(true);
      changeRole.setValue(false);
      showToastMessage('success', SUCCESS_MESSAGE.REMOVE);
    }
  };

  const onSubmitTransfer = async () => {
    onChangeRole(TeamRole.TeamOwner, idTransferTeamOwner, true);
  };

  const onChangeRole = async (role: number, id: string, isUpdateTeam = false) => {
    const resultChangeRole: TeamUser = await apiCall({
      url: `/api/teams/${team?.id}/roles/${id}`,
      data: trimObject({
        role: role,
      }),
      method: 'PATCH',
      isLoading: true,
      showError: true,
    });

    if (resultChangeRole?.id) {
      updateRefreshTeamShare(true);
      changeRole.setValue(false);
      showToastMessage('success', SUCCESS_MESSAGE.CHANGE_ROLE);
      if (isUpdateTeam && team) {
        updateTeamDetail({
          ...team,
          role: TeamRole.TeamMember,
        });

        updateTeam({
          ...team,
          role: TeamRole.TeamMember,
        });
        closeModal(MODAL_KEY.TRANSFER);
      }
    }
  };

  const onResend = (userEmail: string, role: TeamRole) => async () => {
    const result: TeamUser = await apiCall({
      url: `/api/teams/${team?.id}/roles`,
      data: trimObject({
        team_id: team?.id,
        email: userEmail,
        role: role,
      }),
      method: 'POST',
      isLoading: true,
      showError: true,
    });

    if (result?.id) {
      updateRefreshTeamShare(true);
      showToastMessage('success', SUCCESS_MESSAGE.RESEND);
    }
  };

  const isEdit =
    (Number(team?.role) < Number(user?.role) && Number(team?.role) <= TeamRole.TeamAdmin) ||
    isAdmin;

  const isUserActive = Number(user?.user_status) < UserStatus.Pending;
  const isLoggedAccount = userProfile && userProfile.id === user?.user_id;
  const { avatarBg, avatarBorder } = getAvatarStyles(isUserActive);

  return {
    team,
    changeRole,
    avatarBorder,
    avatarBg,
    isEdit,
    isUserActive,
    isAdmin,
    isLoggedAccount,
    isTeamOwner,
    onChangeRoleUser,
    onResend,
    onSubmitTransfer,
  };
};
