import { InputTitle } from 'components';
import { Spacing, TextInput, Typography } from 'thesis-ui';

import { AuthForm } from '../styles';

import { FormSignUpCard } from '../components/FormSignUpCard';
import { useSignUpController } from '../core';

export const SignUpPage = () => {
  const {
    email,
    fullName,
    companyName,
    disabled,
    password,
    emailStatus,
    passwordStatus,
    handleChange,
    handleSubmit,
    isTermAndPolicy,
  } = useSignUpController();

  return (
    <AuthForm onSubmit={handleSubmit}>
      <Spacing spacing="xl" placement="bottom" type="margin">
        <Typography
          variant="h4"
          weight={700}
          style={{
            textAlign: 'center',
          }}
        >
          Sign up
        </Typography>
      </Spacing>
      <Spacing spacing="sm" placement="bottom" type="margin">
        <InputTitle title="Full name" required />
        <TextInput type="text" name="fullName" value={fullName} onChange={handleChange} />
      </Spacing>

      <Spacing spacing="sm" placement="bottom" type="margin">
        <InputTitle title="Email" required />
        <TextInput
          name="email"
          value={email}
          onChange={handleChange}
          helperText={emailStatus.helperText}
          color={emailStatus.color}
        />
      </Spacing>
      <FormSignUpCard
        companyName={companyName}
        disabled={disabled}
        handleChange={handleChange}
        isTermAndPolicy={isTermAndPolicy}
        password={password}
        passwordStatus={passwordStatus}
      />
    </AuthForm>
  );
};
