export enum ChartType {
  Line,
  Bar,
}
export enum ChartPinAt {
  DataPage,
  DevicePage,
  PublicPage,
}

export enum ChartDataType {
  File = 'file',
  Number = 'number',
  Text = 'text',
}
