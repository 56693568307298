import { Button, Checkbox, Row, Tooltip, Typography, neutral, primary } from 'thesis-ui';

import { Body, Container, Header, TitleClearFilter } from '../styles/device-filter';

import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';

import { includes } from 'lodash';

import { deviceFilterState, deviceFilterStatus, useDeviceFilterController } from '../core';

export const DeviceFilter = () => {
  const { listStatus, listState, onChangeCheckbox, onClear } = useDeviceFilterController();

  return (
    <Tooltip
      bg="transparent"
      trigger="click"
      arrow={false}
      placement="bottom-end"
      content={
        <Container direction="column">
          <Header align="center" justify="space-between">
            <Typography color={neutral['neutral-1']} weight={600} variant="h5">
              Filter
            </Typography>
            <TitleClearFilter
              color={primary['primary-6']}
              weight={600}
              variant="body-2"
              onClick={onClear}
            >
              Clear filter
            </TitleClearFilter>
          </Header>
          <Body direction="column">
            <Typography
              color={neutral['neutral-7']}
              weight={600}
              style={{
                marginBottom: 6,
                textAlign: 'start',
              }}
            >
              Status
            </Typography>
            {deviceFilterStatus.map((status) => {
              const checked = includes(listStatus, status.key);

              return (
                <Checkbox
                  checked={checked}
                  onClick={onChangeCheckbox('status', status.key, !checked)}
                  key={status.key}
                  theme="dark"
                  style={{
                    padding: '2px 0px',
                    marginBottom: 8,
                    justifyContent: 'start',
                    width: '100%',
                  }}
                >
                  {status.label}
                </Checkbox>
              );
            })}
            <Typography
              color={neutral['neutral-7']}
              weight={600}
              style={{
                marginBottom: 6,
                marginTop: 16,
                textAlign: 'start',
              }}
            >
              Public mode
            </Typography>
            {deviceFilterState.map((state, index) => {
              const checked = includes(listState, state.key);

              return (
                <Checkbox
                  key={state.key}
                  checked={checked}
                  onClick={onChangeCheckbox('state', state.key, !checked)}
                  theme="dark"
                  style={{
                    padding: '2px 0px',
                    marginBottom: index + 1 === deviceFilterStatus.length ? 0 : 8,
                    justifyContent: 'start',
                    width: '100%',
                  }}
                >
                  {state.label}
                </Checkbox>
              );
            })}
          </Body>
        </Container>
      }
      offset={[7, 5]}
      allowHTML
      interactive
    >
      <Row>
        <Button
          placementIcon="left"
          size="small"
          icon={<FilterIcon />}
          style={{
            paddingLeft: 8,
            paddingRight: 12,
          }}
        >
          Filter
        </Button>
      </Row>
    </Tooltip>
  );
};
