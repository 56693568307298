import styled from 'styled-components';

import { RadiusConfig, Row, neutral, neutralTrans } from 'thesis-ui';

export const CommandCard = styled(Row)`
  border-radius: ${RadiusConfig.xxs}px;
  border: 1px solid ${neutralTrans['neutralTrans-15']};
  background: ${neutral['neutral-2']};
  padding: 10px 12px;
  margin-top: 6px;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
`;

export const BodyRaw = styled(Row)`
  border-radius: ${RadiusConfig.xxs}px;
  border: 1px solid ${neutralTrans['neutralTrans-15']};
  background: ${neutral['neutral-2']};
  padding: 10px 12px;
  margin-top: 6px;
`;

export const Label = styled(Row)`
  border-radius: ${RadiusConfig.xxs}px;
  background: ${neutral['neutral-4']};
  padding: 6px 12px;
  width: fit-content;
  margin-bottom: 8px;
`;
