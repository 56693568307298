import { ChartDateRange } from 'enums/date-range';

import { Role } from 'modules/auth/core';
import {
  DeviceChartRefreshIntervalType,
  DeviceChartYAxisSettingType,
} from 'modules/devices/core/enum';
import { TeamRole } from 'modules/team';

import { isNull, isUndefined, startCase } from 'lodash';

export const getTitleLogByKey = (key: string) => {
  return `${startCase(key)}:`;
};

export const getLabelLogByKey = (key: string, value: string) => {
  switch (key) {
    case 'date_range_type':
      return ChartDateRange[Number(value)];
    case 'y_axis_display_type':
      return `${DeviceChartYAxisSettingType[Number(value)]} adjust`;
    case 'refresh_interval_type':
      return DeviceChartRefreshIntervalType[Number(value)];
    default:
      return value;
  }
};

export const getRoleName = (teamRole: TeamRole, userRoleId: string) => {
  if (!isUndefined(teamRole) && !isNull(teamRole)) {
    return startCase(TeamRole[teamRole]);
  }
  if (userRoleId === Role.Admin) {
    return 'Administration';
  }
  return 'Not in team';
};
