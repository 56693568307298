import { TABLE_KEY } from 'constants/table';

import { TeamType } from './enum';

export const getEmptyDataTitle = (isMyTeamsPath: boolean, isSharedWithMePath: boolean) => {
  switch (true) {
    case isMyTeamsPath:
      return `You’re owning no team.`;
    case isSharedWithMePath:
      return `You haven't been added to any teams yet.`;
    default:
      return `There’s no team under your management.`;
  }
};

export const getTeamTableKey = (isMyTeamsPath: boolean, isSharedWithMePath: boolean) => {
  switch (true) {
    case isMyTeamsPath:
      return TABLE_KEY.MY_TEAMS;
    case isSharedWithMePath:
      return TABLE_KEY.SHARED_WITH_ME_TEAMS;
    default:
      return TABLE_KEY.ALL_TEAMS;
  }
};

export const getTeamType = (isMyTeamsPath: boolean, isSharedWithMePath: boolean) => {
  switch (true) {
    case isMyTeamsPath:
      return TeamType.MyTeam;
    case isSharedWithMePath:
      return TeamType.SharedWithMe;
    default:
      return TeamType.All;
  }
};
