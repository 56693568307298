import { Row, Typography, blue, getFirstLetterInName, neutral } from 'thesis-ui';

import { Logo, Name } from '../styles/menu-item-label';

import type { MenuItemLabelProps } from '../core';

export const MenuItemLabel: React.FC<MenuItemLabelProps> = ({ colour, logo, name }) => {
  return (
    <Row align="center">
      {logo ? (
        <Logo logo={logo} />
      ) : (
        <Name colour={colour ?? blue['blue']}>
          <Typography color={neutral['neutral-1']} variant="body-2" weight={500}>
            {getFirstLetterInName(name, true)}
          </Typography>
        </Name>
      )}
      <Typography
        className="text-ellipsis-1"
        color={neutral['neutral-1']}
        weight={500}
        style={{
          marginLeft: 10,
          textAlign: 'start',
        }}
      >
        {name}
      </Typography>
    </Row>
  );
};
