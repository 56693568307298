import { Row, Typography, neutral } from 'thesis-ui';

import { StyledIcon, StyledTitle } from '../styles/menu-item-title';

import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';

import type { MenuItemTitleProps } from '../core';
import { useMenuItemTitleController } from '../core/controller';

export const MenuItemTitle: React.FC<MenuItemTitleProps> = ({ title, collapsed, action }) => {
  const { onOpenModal } = useMenuItemTitleController();

  return (
    <Row align="center" justify="space-between">
      <StyledTitle align="center">
        <Typography variant="body-2" color={neutral['neutral-7']} weight={600}>
          {title}
        </Typography>
        <StyledIcon>
          <ArrowDownIcon
            style={{
              transform: `rotate(${collapsed ? 0 : 180}deg)`,
            }}
          />
        </StyledIcon>
      </StyledTitle>
      {action && (
        <StyledIcon onClick={onOpenModal}>
          <AddIcon />
        </StyledIcon>
      )}
    </Row>
  );
};
