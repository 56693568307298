import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  type DropdownMenuItem,
  type QueryState,
  initialQueryStateTable,
  openModal,
  showToastMessage,
  updateQueryStateTable,
  useTableStore,
} from 'thesis-ui';

import { Role, useAuthStore, userProfileSelector } from 'modules/auth/core';
import { type TeamDetail } from 'modules/team';

import { type ConfirmModalData } from 'components/modal/core/type';

import { compileFormData, getTeamPath } from 'helpers';
import { useBoolean, useNumber } from 'hooks';
import { apiCall } from 'hooks/service/api';
import { useClearTableQueryStateOnUnmount } from 'hooks/table';
import { isEmpty } from 'lodash';

import { MODAL_KEY } from 'constants/modal-key';
import { PATH } from 'constants/path';
import { type Pagination } from 'types/api';

import { TeamsTableActionKey } from '../enum';
import { getTeamTableKey, getTeamType } from '../helper';
import {
  isDeleteTeamSelector,
  isRefreshTeamListSelector,
  updateIsDeleteTeam,
  updateRefreshTeamList,
  useTeamListStore,
} from '../store';

export const useTeamsController = () => {
  const location = useLocation();
  const navigationTo = useNavigate();
  const isMyTeamsPath = location.pathname.includes(PATH.MY_TEAMS);
  const isSharedWithMePath = location.pathname.includes(PATH.SHARED_WITH_ME_TEAMS);
  const teamTableKey = getTeamTableKey(isMyTeamsPath, isSharedWithMePath);
  useClearTableQueryStateOnUnmount(teamTableKey);

  const queryState = useTableStore((s) => s.state.queryState[teamTableKey]);
  const userProfile = useAuthStore(userProfileSelector);
  const isAdminUser = userProfile?.role_id === Role.Admin;
  const isRefreshTeamList = useTeamListStore(isRefreshTeamListSelector);
  const isDeleteTeam = useTeamListStore(isDeleteTeamSelector);
  const [teams, setTeams] = useState<TeamDetail[]>([]);
  const total = useNumber(0);
  const isLoading = useBoolean(true);

  useEffect(() => {
    if (queryState) {
      getTeams();

      return;
    }
    initialQueryStateTable(teamTableKey);
  }, [queryState, location.pathname]);

  useEffect(() => {
    if (!isRefreshTeamList) {
      return;
    }
    if (queryState && teams.length <= 1 && queryState.page > 1 && isDeleteTeam) {
      updateQueryStateTable(teamTableKey, { ...queryState, page: queryState.page - 1 });
    } else {
      getTeams();
    }
    updateRefreshTeamList(false);
    updateIsDeleteTeam(false);
  }, [isRefreshTeamList]);

  const getTeams = async () => {
    isLoading.setValue(true);
    const result: {
      data: TeamDetail[];
      pagination: Pagination;
    } = await apiCall({
      url: `/api/teams`,
      method: 'GET',
      data: {
        ...queryState,
        with_usage: 1, // 1 = true
        with_device: 1, // 1 = true
        with_type: getTeamType(isMyTeamsPath, isSharedWithMePath),
      },
      isLoading: true,
      showError: true,
    });
    if (result?.data) {
      setTeams(result?.data);
    }
    if (result?.pagination) {
      total.setValue(result.pagination.total);
    }
    isLoading.setValue(false);
  };

  const onClickRow = (row: any) => {
    const original: TeamDetail = row?.original;
    if (original?.id) {
      navigationTo(`${getTeamPath(original.id, PATH.TEAM_DASHBOARD)}`);
    }
  };

  const updateQueryState = (key: string, newState: QueryState) => {
    updateQueryStateTable(key, newState);
  };

  const onCreateTeam = () => {
    openModal(MODAL_KEY.FORM_TEAM);
  };

  const onToggleEnableDeviceDashboard = async (team: TeamDetail) => {
    const result: TeamDetail = await apiCall({
      url: `/api/teams/${team?.id}`,
      data: compileFormData({
        enabled_device_dashboard: !team.enabled_device_dashboard,
      }),
      method: 'PATCH',
      isLoading: true,
      showError: true,
    });

    if (result.id) {
      updateRefreshTeamList(true);
      showToastMessage(
        'success',
        `${result.enabled_device_dashboard ? 'Enable' : 'Disable'} device dashboard successfully!`,
      );
    }
  };

  const onClickMenuItem = (originTeam: TeamDetail) => (item: DropdownMenuItem) => {
    switch (item.key) {
      case TeamsTableActionKey.Share:
        openModal(MODAL_KEY.TEAM_SHARE, originTeam);
        break;
      case TeamsTableActionKey.Edit:
        openModal(MODAL_KEY.FORM_TEAM, originTeam);
        break;
      case TeamsTableActionKey.dashboard:
        onToggleEnableDeviceDashboard(originTeam);
        break;
      default:
        openModal(MODAL_KEY.CONFIRM, {
          header: `Delete ${originTeam?.name} team`,
          label: `All data and devices will be deleted. The public devices will no longer be accessible. This action can not undo.`,
          teamId: originTeam.id,
        } as ConfirmModalData);
        break;
    }
  };

  const isTeamEmpty = isEmpty(teams);

  return {
    isMyTeamsPath,
    isSharedWithMePath,
    teams,
    isLoading,
    isTeamEmpty,
    teamTableKey,
    total,
    queryState,
    isAdminUser,
    onCreateTeam,
    updateQueryState,
    onClickRow,
    onClickMenuItem,
  };
};
