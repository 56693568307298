import { FormChart } from 'components/chart/FormChart';
import {
  Button,
  DateRangePickerInput,
  Dropdown,
  Row,
  Select,
  Spacing,
  Tabs,
  TextInput,
  Typography,
  blockInvalidCharacters,
  primary,
  red,
} from 'thesis-ui';

import { DateRangePickerTab, FilterDateContainer } from '../styles/device-chart-item';

import { ReactComponent as ThreeDotsIcon } from 'assets/icons/three-dots.svg';

import type { TabItemType } from 'thesis-ui/components/Navigation/Tabs/core/types';

import { getUTCDate } from 'helpers';
import { isUndefined } from 'lodash';

import { getStaticDateRanges } from 'constants/data-range';

import {
  type DeviceChartItemProps,
  deviceChartActions,
  deviceChartRelativeRangeActions,
  getDataRangeLabel,
  useDeviceChartItemController,
} from '../core';
import { DeviceChartDateRangeTabKey } from '../core/enum';
import { ChartSettingModal } from '../modal/ChartSettingModal';
import { DeviceDataUpload } from './DeviceDataUpload';

export const DeviceChartItem: React.FC<DeviceChartItemProps> = ({
  chart,
  chartTitle,
  rootStyle,
  teamId,
  action,
  isEmptyData,
  isShowChart,
  emptyMessage,
  hasPermission,
  isDatePicker = true,
  isShowDataUpload,
  originChartKey,
  onOpenModalDeleteChart,
}) => {
  const {
    deviceChart,
    datasets,
    device,
    labels,
    actionVisible,
    chartLoading,
    chartOptions,
    date,
    setting,
    deviceUploads,
    dateRangePickerTabActive,
    isShowQuickOptions,
    isShowRelativeRange,
    selectedQuickOption,
    relativeRange,
    chartKey,
    onChangeDropdownAction,
    onClickMenuItem,
    onSubmitSettingChart,
    onUpdateDateSetting,
    onDateRangePickerTabClick,
    onChangeDateRangePicker,
    onChangeRelativeRangeTotal,
    onChangeRelativeRangeAction,
  } = useDeviceChartItemController(chart, onOpenModalDeleteChart, teamId, originChartKey);

  const tabs: TabItemType[] = [
    {
      label: 'Quick options',
      key: String(DeviceChartDateRangeTabKey.QUICK_OPTIONS),
    },
    {
      label: 'Relative range',
      key: String(DeviceChartDateRangeTabKey.RELATIVE_RANGE),
    },
  ];

  const disableCloseDateRange =
    (relativeRange.total < 1 || String(relativeRange.total) === 'NaN') && isShowRelativeRange;

  return (
    <Row className="device-chart-item" direction="column" style={rootStyle}>
      <FormChart
        loading={chartLoading.value}
        chartName={deviceChart.name}
        title={!isUndefined(chartTitle) ? chartTitle : device?.alias ?? ''}
        isEmptyData={isEmptyData ?? Boolean(datasets.length === 0)}
        isShowChart={isShowChart}
        emptyMessage={emptyMessage}
        label={chart.label || 'No label'}
        data={{
          datasets: [
            {
              label: '',
              data: datasets,
              borderColor: primary['primary-6'],
              backgroundColor: primary['primary-6'],
            },
          ],
          labels: labels.map((el) => getUTCDate(el, false)),
        }}
        action={
          <FilterDateContainer align="center" isShowDateRange={isShowQuickOptions}>
            {isDatePicker && (
              <DateRangePickerInput
                state={date}
                onChange={onChangeDateRangePicker}
                isStaticRanges={true}
                staticRanges={getStaticDateRanges(selectedQuickOption.value)}
                bodyDateWidth={548}
                tooltipConfig={{
                  offset: [80, 0],
                }}
                months={2}
                valueSelect={getDataRangeLabel(setting)}
                disableCloseDateRange={disableCloseDateRange}
                onClickOutside={onUpdateDateSetting}
                dropdownContent={
                  isShowRelativeRange ? (
                    <Row
                      direction="column"
                      style={{
                        padding: '16px 24px',
                      }}
                    >
                      <Row align="center" justify="space-between">
                        <Typography>Last </Typography>
                        <TextInput
                          onKeyDown={(e) => blockInvalidCharacters(e, 'integer')}
                          type="number"
                          value={relativeRange.total}
                          containerStyle={{
                            width: 298,
                          }}
                          color={disableCloseDateRange ? 'error' : 'default'}
                          onChange={onChangeRelativeRangeTotal}
                        />
                        <Select
                          inputStyle={{
                            width: 298,
                          }}
                          dropdownConfig={{
                            theme: 'dark',
                            dropdownConfig: {
                              offset: [0, 0],
                            },
                          }}
                          onChange={onChangeRelativeRangeAction}
                          options={deviceChartRelativeRangeActions}
                          selectedKeys={String(relativeRange.action)}
                        />
                      </Row>
                      {disableCloseDateRange ? (
                        <Typography
                          style={{
                            marginLeft: 39,
                            marginTop: 6,
                          }}
                          color={red['red-6']}
                        >
                          The minimum must be greater than 0.
                        </Typography>
                      ) : null}
                    </Row>
                  ) : undefined
                }
                dropdownHeader={
                  <DateRangePickerTab>
                    <Tabs
                      variant="text"
                      items={tabs}
                      activeKey={String(dateRangePickerTabActive.value)}
                      onTabClick={onDateRangePickerTabClick}
                      tabItemStyle={{
                        height: 52,
                      }}
                    />
                  </DateRangePickerTab>
                }
              />
            )}

            {action || (
              <Spacing spacing="sm" placement="left" type="margin">
                <Dropdown
                  theme="dark"
                  dropdownConfig={{
                    placement: 'bottom-end',
                    trigger: 'click',
                    offset: [10, 5],
                    visible: actionVisible.value,
                    onClickOutside: onChangeDropdownAction,
                  }}
                  dropdownMenuStyle={{
                    minWidth: 160,
                  }}
                  menu={{
                    items: hasPermission ? deviceChartActions : [deviceChartActions[0]],
                    selectedKeys: [],
                    onClick: onClickMenuItem,
                  }}
                >
                  <Button
                    onClick={onChangeDropdownAction}
                    placementIcon="left"
                    icon={<ThreeDotsIcon />}
                    style={{
                      width: 32,
                      height: 32,
                    }}
                  />
                </Dropdown>
              </Spacing>
            )}
          </FilterDateContainer>
        }
        options={chartOptions}
      />
      {isShowDataUpload && (
        <DeviceDataUpload
          deviceUploads={deviceUploads}
          heightContent={chart.id ? 280 : 560}
          isDataUploadLoading={chartLoading.value}
        />
      )}

      <ChartSettingModal
        chart={deviceChart}
        onSubmitSettingChart={onSubmitSettingChart}
        hasPermission={hasPermission}
        originChartKey={chartKey}
      />
    </Row>
  );
};
