import type { TeamDetail, TeamUser } from './type';

import { cloneDeep, remove } from 'lodash';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

interface TeamState {
  teams: TeamDetail[];
  teamDetail: TeamDetail | null;
  teamUsers: TeamUser[];
  idTransferTeamOwner: string;
  isRefreshChart: boolean;
  isRefreshTeams: boolean;
  isOpenLimitReachModal: boolean;
  isRefreshTeamShare: boolean;
}

interface TeamStore {
  state: TeamState;
  updateTeam: (updateTeam: TeamDetail) => void;
  updateRefreshTeams: (value: boolean) => void;
  createTeam: (createTeam: TeamDetail) => void;
  createInitTeam: (teams: TeamDetail[]) => void;
  deleteTeam: (id: string) => void;
  updateTeamDetail: (updateTeam: TeamDetail | null) => void;
  updateTeamUsers: (update: TeamUser[]) => void;
  updateIdTransferTeamOwner: (newId: string) => void;
  updateRefreshChart: (value: boolean) => void;
  clearTeamState: () => void;
  setIsOpenLimitReachModal: (value: boolean) => void;
  updateRefreshTeamShare: (value: boolean) => void;
}

const initTeamState: TeamState = {
  teams: [],
  teamDetail: null,
  teamUsers: [],
  idTransferTeamOwner: '',
  isRefreshChart: false,
  isRefreshTeams: false,
  isOpenLimitReachModal: true,
  isRefreshTeamShare: false,
};

export const useTeamStore = create(
  immer<TeamStore>((set) => ({
    state: initTeamState,
    updateTeam: (payload) => {
      set((store) => {
        const newTeams = cloneDeep(store.state.teams);
        const index = newTeams.findIndex((el) => el.id === payload.id);
        newTeams[index] = payload;
        store.state.teams = newTeams;
      });
    },
    updateRefreshTeams: (payload) => {
      set((store) => {
        store.state.isRefreshTeams = payload;
      });
    },
    createTeam: (payload) => {
      set((store) => {
        const newTeams = cloneDeep(store.state.teams);
        newTeams.unshift(payload);
        store.state.teams = newTeams;
      });
    },
    createInitTeam: (payload) => {
      set((store) => {
        store.state.teams = payload;
      });
    },
    deleteTeam: (id: string) => {
      set((store) => {
        const newTeams = cloneDeep(store.state.teams);
        remove(newTeams, (el) => el.id === id);
        store.state.teams = newTeams;
      });
    },
    updateTeamDetail: (payload) => {
      set((store) => {
        store.state.teamDetail = payload;
      });
    },
    updateTeamUsers: (payload) => {
      set((store) => {
        store.state.teamUsers = payload;
      });
    },
    updateIdTransferTeamOwner: (id) => {
      set((store) => {
        store.state.idTransferTeamOwner = id;
      });
    },
    updateRefreshChart: (value: boolean) => {
      set((store) => {
        store.state.isRefreshChart = value;
      });
    },
    clearTeamState: () => {
      set((store) => {
        store.state = initTeamState;
      });
    },
    setIsOpenLimitReachModal: (value: boolean) => {
      set((store) => {
        store.state.isOpenLimitReachModal = value;
      });
    },
    updateRefreshTeamShare: (value: boolean) => {
      set((store) => {
        store.state.isRefreshTeamShare = value;
      });
    },
  })),
);

export const {
  createInitTeam,
  createTeam,
  deleteTeam,
  updateTeam,
  updateTeamDetail,
  updateTeamUsers,
  updateIdTransferTeamOwner,
  updateRefreshChart,
  updateRefreshTeams,
  clearTeamState,
  updateRefreshTeamShare,
} = useTeamStore.getState();

export const teamsSelector = (s: TeamStore) => s.state.teams;

export const teamDetailSelector = (s: TeamStore) => s.state.teamDetail;

export const teamUsersSelector = (s: TeamStore) => s.state.teamUsers;

export const idTransferTeamOwnerSelector = (s: TeamStore) => s.state.idTransferTeamOwner;

export const isRefreshDataChartSelector = (s: TeamStore) => s.state.isRefreshChart;

export const isRefreshTeamsSelector = (s: TeamStore) => s.state.isRefreshTeams;

export const isOpenLimitReachModalSelector = (s: TeamStore) => s.state.isOpenLimitReachModal;

export const isRefreshTeamShareSelector = (s: TeamStore) => s.state.isRefreshTeamShare;
