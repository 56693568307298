import { InputTitle } from 'components';
import { ButtonByTeam } from 'components/button/ButtonByTeam';
import { Button, Modal, Row, Spacing, TextInput, Textarea } from 'thesis-ui';

import { CollectionLabel } from '../components';
import { CommandType, type FormCommandModalProps, useFormCommandModalController } from '../core';

export const FormCommandModal: React.FC<FormCommandModalProps> = ({ modalKey, type, callback }) => {
  const { name, data, disabled, note, buttonTitle, isEdit, handleChange, handleSubmit, onClose } =
    useFormCommandModalController(type, callback);

  return (
    <Modal
      header={`${isEdit ? 'Edit' : 'Add'} command`}
      modalKey={modalKey}
      size="lg"
      footer={
        <Row align="center" justify="end">
          <Spacing spacing="sm" placement="right" type="margin">
            <Button onClick={onClose} size="large">
              Cancel
            </Button>
          </Spacing>
          <ButtonByTeam title={buttonTitle} disabled={disabled} onClick={() => handleSubmit()} />
        </Row>
      }
    >
      <Row direction="column">
        {isEdit && type === CommandType.Collection && (
          <Spacing spacing="sm" placement="bottom" type="margin">
            <CollectionLabel
              label="Any changes you make with this command won’t affect commands already added to the queue."
              type="info"
            />
          </Spacing>
        )}

        <Row align="center">
          <Row direction="column">
            <InputTitle title="Command name" required={type === CommandType.Collection} />
            <TextInput type="text" name="name" value={name} onChange={handleChange} />
          </Row>
        </Row>
        {(type === CommandType.Collection || !isEdit) && (
          <Spacing
            spacing="sm"
            placement="top"
            type="margin"
            style={{
              width: '100%',
            }}
          >
            <InputTitle title="Command data" required />
            <Textarea
              name="data"
              value={data}
              onChange={handleChange}
              style={{
                resize: 'none',
              }}
            />
          </Spacing>
        )}

        <Spacing
          spacing="sm"
          placement="top"
          type="margin"
          style={{
            width: '100%',
          }}
        >
          <Textarea
            title="Note"
            name="note"
            value={note}
            onChange={handleChange}
            maxLength={500}
            style={{
              resize: 'none', // To-do-design: Add props resize
            }}
          />
        </Spacing>
      </Row>
    </Modal>
  );
};
