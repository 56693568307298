import { Spacing, Typography, neutral } from 'thesis-ui';

import { TermAndPolicyMenuContainer } from '../styles';

import { termAndPolicyMenu, useTermAndPolicyMenuController } from '../core';

export const TermAndPolicyMenu: React.FC<{
  refPage: React.RefObject<HTMLDivElement>;
}> = ({ refPage }) => {
  const { redirectTo, locationPath } = useTermAndPolicyMenuController(refPage);

  return (
    <TermAndPolicyMenuContainer>
      {termAndPolicyMenu.map((el) => {
        const isActive = el.path === locationPath;
        const color = isActive ? neutral['neutral-11'] : neutral['neutral-7'];
        const weight = isActive ? 700 : 400;

        return (
          <Spacing key={el.label} spacing="xl" placement="bottom" type="margin">
            <Typography
              onClick={redirectTo(el.path)}
              style={{
                cursor: 'pointer',
              }}
              color={color}
              weight={weight}
            >
              {el.label}
            </Typography>
          </Spacing>
        );
      })}
    </TermAndPolicyMenuContainer>
  );
};
