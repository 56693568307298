import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Row, Spacing, Typography, neutral } from 'thesis-ui';

import { ReactComponent as Logo404 } from 'assets/images/404.svg';

import { PATH } from 'constants/path';

// To-do-base: Update 404 page
export const Error404Page = () => {
  const navigationTo = useNavigate();
  return (
    <Container>
      <Logo404 />
      <Spacing spacing="xl" type="padding" placement={['top', 'bottom']}>
        <Row direction="column" align="center">
          <Typography>We can't find that page.</Typography>
          <Typography>
            The link you followed might be broken, or the page might be removed.
          </Typography>
        </Row>
      </Spacing>
      <Button style={{ width: 128 }} color="primary" onClick={() => navigationTo(PATH.LOGIN)}>
        Go to Home
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-color: ${neutral['neutral-2']};
`;
