import { Route, Routes } from 'react-router-dom';

import { DeviceRoutes } from 'modules/devices/Routers';

import { PATH } from 'constants/path';

import { TeamLayout } from './components';
import { Dashboard, TeamData } from './pages';
import { TeamActionLog } from './pages/TeamActionLog';

export const TeamRoutes = () => (
  <Routes>
    <Route element={<TeamLayout />}>
      <Route path={PATH.TEAM_DATA} element={<TeamData />} />
      <Route path={PATH.TEAM_DASHBOARD} element={<Dashboard />} />
      <Route path={`${PATH.TEAM_DEVICES}/*`} element={<DeviceRoutes />} />
      <Route path={PATH.TEAM_ACTION_LOG} element={<TeamActionLog />} />
    </Route>
  </Routes>
);
