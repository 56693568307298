import { Button, Dropdown, type DropdownMenuItem } from 'thesis-ui';

import { ButtonTransparent } from './styles';

import { ReactComponent as ThreeDotsIcon } from 'assets/icons/three-dots.svg';

import type { DropdownMenuProps } from './core/type';

import { useBoolean } from 'hooks';

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  items,
  icon,
  onClickMenuItem,
  dropdownConfig,
  dropdownMenuStyle,
}) => {
  const { placement, offset, ...dropdownConfigProps } = dropdownConfig ?? {};
  const visible = useBoolean(false);

  const onChangeVisible = (newVisible: boolean) => (event: any) => {
    if (event) {
      event?.preventDefault();
      event?.stopPropagation();
    }
    visible.setValue(newVisible);
  };

  const onClickMenu = (item: DropdownMenuItem) => {
    visible.setValue(false);
    onClickMenuItem(item);
  };

  return (
    <Dropdown
      theme="dark"
      dropdownConfig={{
        placement: placement ?? 'bottom-end',
        trigger: 'click',
        offset: offset ?? [10, 5],
        visible: visible.value,
        onClickOutside: () => visible.setValue(false),
        ...dropdownConfigProps,
      }}
      dropdownMenuStyle={{
        minWidth: 120,
        ...dropdownMenuStyle,
      }}
      menu={{
        items: items,
        selectedKeys: [],
        onClick: onClickMenu,
      }}
    >
      <ButtonTransparent>
        <Button
          onClick={onChangeVisible(!visible.value)}
          placementIcon="left"
          icon={icon ?? <ThreeDotsIcon />}
          style={{
            width: 32,
            height: 32,
          }}
        />
      </ButtonTransparent>
    </Dropdown>
  );
};
