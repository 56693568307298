import styled, { css } from 'styled-components';

import { Grid, RadiusConfig, Row, neutral, neutralTrans } from 'thesis-ui';

export const Body = styled(Row)`
  ${() => {
    const bg = neutral['neutral-1'];
    const border = `1px solid ${neutralTrans['neutralTrans-15']}`;

    return css`
      width: 100%;
      border-radius: ${RadiusConfig.xs}px;
      background-color: ${bg};
      border: ${border};
    `;
  }}
`;

export const BodyChart = styled(Grid)`
  .form-chart {
    .label {
      margin-top: 0px !important;
      p {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        color: ${neutral['neutral-11']};
      }
      svg {
        min-width: 16px;
        height: 16px;
        path[stroke] {
          stroke: ${neutral['neutral-11']};
        }
        stroke-width: 1.5;
      }
    }
  }
`;

export const BodyHeader = styled(Row)`
  min-height: 64px;
  border-bottom: 1px solid ${neutralTrans['neutralTrans-15']};
  padding: 0px 24px;
`;

export const DevicePublicLabel = styled(Row)`
  svg {
    min-width: 16px;
    height: 16px;
    margin-top: 2px;
    path[stroke] {
      stroke: ${neutral['neutral-11']};
    }
  }
`;
