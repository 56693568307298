import styled from 'styled-components';

import { Row, darkNeutralTrans } from 'thesis-ui';

export const Container = styled(Row)`
  .dropdown {
    max-height: 300px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: ${darkNeutralTrans['darkNeutralTrans-10']} #222222;

    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: ${darkNeutralTrans['darkNeutralTrans-10']};
      border-radius: 999px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: ${darkNeutralTrans['darkNeutralTrans-20']};
    }
  }
`;
