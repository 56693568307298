import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { initialQueryState, updateQueryStateTable, useTableStore } from 'thesis-ui';

export const useClearTableQueryStateOnUnmount = (tableKey: string) => {
  const location = useLocation();

  useEffect(() => {
    return () => {
      const queryState = useTableStore.getState().state.queryState;

      updateQueryStateTable(tableKey, {
        ...initialQueryState,
        filter: queryState[tableKey]?.filter,
      });
    };
  }, [location.pathname]);
};
