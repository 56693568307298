import { Row, Typography } from 'thesis-ui';

const MAXIMUM_CHARACTER_COUNT = 80;

export const ActionLogContent: React.FC<{
  title: string;
  label: string;
  style?: React.CSSProperties;
  allowInline?: boolean;
}> = ({ label, title, style, allowInline }) => {
  const isInline = allowInline && label.length <= MAXIMUM_CHARACTER_COUNT;
  return (
    <Row
      style={{
        display: 'flex',
        flexDirection: isInline ? 'row' : 'column',
        width: isInline ? 'fit-content' : '100%',
        alignItems: 'initial', //To do design: Change default alignItems
        ...style,
      }}
    >
      <Typography
        variant="body-2"
        weight={600}
        style={{
          textTransform: 'capitalize',
          whiteSpace: 'nowrap',
          marginBottom: isInline ? 0 : 2,
          textAlign: 'start',
        }}
      >
        {title}
      </Typography>

      <Typography
        style={{
          textAlign: 'start',
          whiteSpace: 'pre-line',
          marginLeft: isInline ? 4 : 0,
        }}
        variant="body-2"
      >
        {label}
      </Typography>
    </Row>
  );
};
