import styled from 'styled-components';

import { RadiusConfig, Row, neutral, neutralTrans } from 'thesis-ui';

export const Container = styled(Row)`
  border-radius: ${RadiusConfig.xs}px;
  border: 1px solid ${neutralTrans['neutralTrans-15']};
  background: ${neutral['neutral-1']};
`;

export const Header = styled(Row)`
  height: 56px;
  padding: 0px 16px;
  .rdrCalendarWrapper {
    width: 548px;
  }
  .device-label-icon {
    min-width: 14px;
  }
`;

export const LoadingCard = styled(Row)`
  position: absolute;
  inset: 0px;
`;
