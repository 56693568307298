import { Navigate, Route, Routes } from 'react-router-dom';

import { PATH } from 'constants/path';

import { AuthLayout } from './components';
import { CaptchaContainer } from './components/CaptchaContainer';
import {
  ConfirmEmailPage,
  ForgotPasswordPage,
  InvitationPage,
  LoginPage,
  PasswordUpdatedPage,
  ResetPasswordPage,
  SignUpPage,
  VerifyPage,
} from './pages';

export const AuthRoutes = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route
        path={PATH.LOGIN}
        element={
          <CaptchaContainer>
            <LoginPage />
          </CaptchaContainer>
        }
      />
      <Route
        path={PATH.SIGN_UP}
        element={
          <CaptchaContainer>
            <SignUpPage />
          </CaptchaContainer>
        }
      />
      <Route
        path={PATH.INVITATION}
        element={
          <CaptchaContainer>
            <InvitationPage />
          </CaptchaContainer>
        }
      />
      <Route path={PATH.CONFIRM_EMAIL} element={<ConfirmEmailPage />} />
      <Route path={PATH.VERIFY} element={<VerifyPage />} />
      <Route
        path={PATH.FORGOT_PASSWORD}
        element={
          <CaptchaContainer>
            <ForgotPasswordPage />
          </CaptchaContainer>
        }
      />
      <Route
        path={PATH.RESET_PASSWORD}
        element={
          <CaptchaContainer>
            <ResetPasswordPage />
          </CaptchaContainer>
        }
      />
      <Route path={PATH.PASSWORD_UPDATED} element={<PasswordUpdatedPage />} />

      <Route index element={<Navigate to={PATH.LOGIN} />} />
      <Route path="*" element={<Navigate to={PATH.LOGIN} />} />
    </Route>
  </Routes>
);
