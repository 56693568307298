import { Button, type DropdownMenuItem, Row, Select, Typography, neutral } from 'thesis-ui';

import { Container } from '../styles/setting';

import { isEmpty } from 'lodash';

import { useSettingController } from '../core/controllers/setting';
import allTimezones from '../timezone.json';

export const SettingPage = () => {
  const {
    selectedTimezone,
    openSelectTimezone,
    disable,
    onSelectTimezone,
    onTimezoneCancel,
    onTimezoneSave,
  } = useSettingController();

  const timezoneOptions: DropdownMenuItem[] = !isEmpty(allTimezones)
    ? allTimezones.map((el) => {
        return {
          key: el.value,
          label: el.label,
          isIconTick: false,
        };
      })
    : [];

  return (
    <Container direction="column">
      <Select
        inputStyle={{
          width: 406,
        }}
        title="Time zone"
        dropdownConfig={{
          theme: 'dark',
          dropdownConfig: {
            offset: [-35, 2],
            visible: openSelectTimezone.value,
            onClickOutside: () => openSelectTimezone.setValue(false),
          },
        }}
        options={timezoneOptions}
        onChange={onSelectTimezone}
        selectedKeys={selectedTimezone.value}
        onClickSelect={() => openSelectTimezone.setValue((prev) => !prev)}
      />
      <Typography
        color={neutral['neutral-7']}
        style={{
          marginTop: 6,
          textAlign: 'start',
        }}
      >
        Changing your time zone will change the time displayed on Tracelium Connect
      </Typography>
      <Row
        align="center"
        justify="space-between"
        style={{
          marginTop: 24,
        }}
      >
        <Button
          style={{
            width: 198,
          }}
          disabled={disable}
          variant="contained"
          size="large"
          onClick={onTimezoneCancel}
        >
          Cancel
        </Button>
        <Button
          style={{
            width: 198,
          }}
          disabled={disable}
          onClick={onTimezoneSave}
          variant="contained"
          size="large"
          color="primary"
        >
          Save
        </Button>
      </Row>
    </Container>
  );
};
