import {
  Avatar,
  NavigationProfile,
  Row,
  Tooltip,
  Typography,
  darkNeutralTrans,
  getFirstLetterInName,
  neutral,
} from 'thesis-ui';

import { useAuthStore, userProfileSelector } from 'modules/auth/core';
import { getUserColor } from 'modules/generals/core/helper';

import { Container, Line } from '../styles/menu-footer';

import { getImageUrl } from 'helpers';
import { useBoolean } from 'hooks';

import { PopoverUserProfile } from './PopoverUserProfile';

export const MenuFooter = () => {
  const userProfile = useAuthStore(userProfileSelector);
  const visible = useBoolean(false);
  const avatarSrc = userProfile?.avatar ? getImageUrl(userProfile.avatar) : '';
  const avatarConfig = {
    bg: userProfile?.avatar ? 'transparent' : getUserColor(userProfile?.fullname),
    border: `1px solid ${
      userProfile?.avatar ? 'transparent' : darkNeutralTrans['darkNeutralTrans-20']
    }`,
    color: neutral['neutral-1'],
  };

  return (
    <Container>
      <Line />
      <NavigationProfile
        theme="dark"
        content={
          <Tooltip
            bg="transparent"
            trigger="click"
            arrow={false}
            placement="right-end"
            content={<PopoverUserProfile setVisible={visible.setValue} />}
            visible={visible.value}
            offset={[5, 25]}
            allowHTML
            interactive
            onClickOutside={() => visible.setValue(false)}
          >
            <Row
              align="center"
              onClick={() => visible.setValue((prev) => !prev)}
              style={{
                padding: '6px 12px',
              }}
            >
              <Avatar
                items={{
                  label: getFirstLetterInName(userProfile?.fullname, true),
                  src: avatarSrc,
                }}
                size="small"
                config={avatarConfig}
                containerStyle={{
                  width: 'fit-content',
                }}
              />
              <Typography
                className="text-ellipsis-1"
                weight={500}
                color={neutral['neutral-1']}
                style={{
                  textAlign: 'start',
                  marginLeft: 12,
                }}
              >
                {userProfile?.fullname}
              </Typography>
            </Row>
          </Tooltip>
        }
      />
    </Container>
  );
};
