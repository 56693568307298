import styled from 'styled-components';

import { RadiusConfig, Row, Typography, neutral, neutralTrans } from 'thesis-ui';

export const Container = styled(Row)`
  width: 280px;
  background-color: ${neutral['neutral-11']};
  border-radius: ${RadiusConfig.xs}px;
  border: 1px solid ${neutralTrans['neutralTrans-15']};
`;

export const Header = styled(Row)`
  padding: 12px 12px 0px 16px;
  height: 52px;
`;

export const Body = styled(Row)`
  padding: 16px;
  max-height: 600px;
`;

export const TitleClearFilter = styled(Typography)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const FilterLabelContainer = styled(Row)`
  padding: 6px 12px;
  background-color: ${neutral['neutral-1']};
  border-radius: ${RadiusConfig.max}px;
  border: 1px solid ${neutralTrans['neutralTrans-15']};
  width: fit-content;
`;
