export enum TeamRole {
  TeamOwner,
  TeamAdmin,
  TeamMember,
  TeamGuest,
}

export enum UserStatus {
  Blocked,
  Active,
  Pending,
  WaitingForVerified,
}

export enum MenuCollapseKey {
  MyTeams = 'myTeams',
  SharedWithMe = 'sharedWithMe',
  AllTeams = 'allTeams',
}

export enum TeamActionKey {
  EditTeam = 'edit',
  DeleteTeam = 'delete',
}

export enum TeamWithDeviceType {
  Inactive,
  Active,
}
