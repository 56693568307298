import { useEffect } from 'react';

import { useActionLogController } from 'components/action-log/core/controller';

import { teamDetailSelector, useTeamStore } from '../store';

export const useTeamActionLogController = () => {
  const team = useTeamStore(teamDetailSelector);

  const { logs, isLoadMore, getActionLog, onLoadMore } = useActionLogController(team?.id ?? '');

  useEffect(() => {
    if (!team) {
      return;
    }
    getActionLog();
  }, [team]);

  return { logs, isLoadMore, onLoadMore, colorTeam: team?.colour };
};
