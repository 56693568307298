export enum CommandMethod {
  Get,
  Post,
}
export enum CommandType {
  Collection,
  Queue,
}

export enum DeviceCommandKey {
  AddCollection = 'add-collection',
  CreateQueue = 'create-queue',
  Edit = 'edit',
  Delete = 'delete',
  ViewNode = 'view-node',
  AddToQueue = 'add-to-queue',
}
