import { ConfirmModal } from 'components';
import { EmptyData } from 'components/data/EmptyData';
import { Button, Row, Spacing, Tooltip, Typography } from 'thesis-ui';

import { Body, Container, Header } from '../styles/device-data';

import { ReactComponent as ChartIcon } from 'assets/icons/chart.svg';
import { ReactComponent as EmptyUpload } from 'assets/images/empty-upload.svg';

import { MODAL_KEY } from 'constants/modal-key';

import { DeviceChartItem } from '../components/DeviceChartItem';
import { DeviceDataAction } from '../components/DeviceDataAction';
import { useDeviceDataController } from '../core';

export const DeviceData = () => {
  const {
    charts,
    openExport,
    dateExport,
    actionVisible,
    hasDevicePermission,
    isEmptyChart,
    onChangeDropdownAction,
    onClickMenuItem,
    onChangeExportDate,
    onChangeOpenExport,
    onSubmitExport,
    onAddDeviceChart,
    onDeleteData,
    onDeleteChart,
    onOpenModalDeleteChart,
  } = useDeviceDataController();

  const getAction = (chartId: string, chartLabel: string) => {
    if (!hasDevicePermission && !chartId) {
      return <Row />;
    }
    if (!chartId) {
      return (
        <Spacing spacing="sm" placement="left" type="margin">
          <Tooltip placement="top" content="View chart" arrow={false} offset={[0, 0]}>
            <Row>
              <Button
                onClick={onAddDeviceChart(chartLabel)}
                placementIcon="left"
                icon={<ChartIcon />}
                style={{
                  width: 32,
                  height: 32,
                }}
              />
            </Row>
          </Tooltip>
        </Spacing>
      );
    }
    return null;
  };

  const renderBody = () => {
    if (isEmptyChart) {
      return (
        <Row align="center" style={{ height: 400 }}>
          <EmptyData
            title="No data was uploaded from the device."
            permission={false}
            image={<EmptyUpload />}
          />
        </Row>
      );
    }
    return (
      <Body direction="template-column" type="full" gap="24px" column={2}>
        {charts.map((chart) => {
          return (
            <DeviceChartItem
              hasPermission={hasDevicePermission}
              chartTitle=""
              key={chart.label}
              chart={chart}
              onOpenModalDeleteChart={onOpenModalDeleteChart}
              action={getAction(chart.id, chart.label)}
              isShowChart={Boolean(chart.id)}
              isShowDataUpload
            />
          );
        })}
      </Body>
    );
  };
  return (
    <Container direction="column">
      <Header justify="space-between" align="center">
        <Typography weight={600} variant="h5">
          Device data
        </Typography>
        {hasDevicePermission && !isEmptyChart && (
          <DeviceDataAction
            openExport={openExport}
            actionVisible={actionVisible}
            dateExport={dateExport}
            onChangeExportDate={onChangeExportDate}
            onChangeOpenExport={onChangeOpenExport}
            onSubmitExport={onSubmitExport}
            onChangeDropdownAction={onChangeDropdownAction}
            onClickMenuItem={onClickMenuItem}
          />
        )}
      </Header>
      {renderBody()}
      <ConfirmModal
        modalKey={MODAL_KEY.DELETE_DEVICE_DATA}
        submitButton={{
          buttonProps: {
            onClick: onDeleteData,
          },
        }}
      />
      <ConfirmModal
        modalKey={MODAL_KEY.DELETE_DEVICE_CHART}
        submitButton={{
          buttonProps: {
            onClick: onDeleteChart,
          },
        }}
      />
    </Container>
  );
};
