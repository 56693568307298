import { PATH } from 'constants/path';
import type { MenuItem } from 'types';

export const termAndPolicyMenu: MenuItem[] = [
  {
    label: 'Terms and Conditions',
    path: PATH.TERMS_AND_CONDITIONS,
  },
  {
    label: 'Privacy Policy',
    path: PATH.PRIVACY_POLICY,
  },
];
