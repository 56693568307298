import styled, { css } from 'styled-components';

import { RadiusConfig, Row, neutral, neutralTrans } from 'thesis-ui';

export const Container = styled(Row)`
  height: 100%;
  table {
    table-layout: fixed;
  }
`;

export const StyledButtonAdd = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

export const ButtonDrag = styled(Row)`
  width: 16px;
  height: 24px;
  border-radius: ${RadiusConfig.xxs}px;
  &:hover {
    background-color: ${neutralTrans['neutralTrans-6']};
    svg path[stroke] {
      stroke: ${neutral['neutral-11']};
    }
    svg path[fill] {
      fill: ${neutral['neutral-11']};
    }
  }
`;

export const StyledDraggable = css`
  background-color: ${neutralTrans['neutralTrans-6']};
  display: flex;
  cursor: pointer;

  td:nth-child(1) {
    width: 36px;
    min-width: 36px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    .button-drag {
      border-radius: ${RadiusConfig.xxs}px;
      background-color: ${neutralTrans['neutralTrans-6']};
      svg path[stroke] {
        stroke: ${neutral['neutral-11']};
      }
      svg path[fill] {
        fill: ${neutral['neutral-11']};
      }
    }
  }

  td:nth-child(2) {
    width: 67px;
    min-width: 67px;
    padding-top: 16px;
  }

  td:nth-child(3) {
    flex: 1;
    padding-top: 16px;
  }

  td:nth-child(4) {
    padding-top: 10px;
    flex: 1;
  }

  td:nth-child(5) {
    width: 160px;
    min-width: 160px;
    padding-top: 16px;
  }

  td:nth-child(6) {
    display: flex;
    align-items: center;
    width: 52px;
    min-width: 52px;
  }
`;
