import { Button } from 'thesis-ui';

import { ButtonOverlay, ButtonOverlayIcon } from 'styles/button';

import type { ButtonByTeamProps } from './core/type';

import { getBgButtonByTeam } from 'helpers';
import { isUndefined } from 'lodash';

import { useController } from './core';

export const ButtonByTeam: React.FC<ButtonByTeamProps> = ({
  icon,
  title,
  disabled = false,
  size = 'large',
  onClick,
  rootStyle,
}) => {
  const { team } = useController();

  const onClickButton = () => {
    if (disabled || isUndefined(onClick)) {
      return;
    }
    onClick();
  };

  return (
    <ButtonOverlayIcon
      disabled={disabled}
      color={team?.colour}
      onClick={onClickButton}
      style={rootStyle}
    >
      <Button
        variant="contained"
        size={size}
        placementIcon="left"
        icon={icon}
        disabled={disabled}
        style={{
          ...getBgButtonByTeam(disabled, team?.colour),
          position: 'relative',
          width: '100%',
          whiteSpace: 'nowrap',
        }}
      >
        {title}
        <ButtonOverlay disabled={disabled} color={team?.colour} />
      </Button>
    </ButtonOverlayIcon>
  );
};
