import styled from 'styled-components';

import { neutral } from 'thesis-ui';

export const Container = styled.div`
  display: flex;
  height: 100vh;
`;

export const Menu = styled.div`
  width: 220px;
  min-width: 220px;
  height: 100%;
`;

export const Content = styled.div`
  flex-grow: 1;
  height: 100%;
  background-color: ${neutral['neutral-2']};
`;
