import { useState } from 'react';

function useDefault<T>(defaultValue: T) {
  const [value, setValue] = useState(defaultValue);
  return { value, setValue };
}

const useBoolean = (defaultValue = false) => {
  return useDefault(defaultValue);
};

const useNumber = (defaultValue = 0) => {
  return useDefault(defaultValue);
};

const useString = (defaultValue = '') => {
  return useDefault(defaultValue);
};

export { useDefault, useNumber, useString, useBoolean };
