import { Button, Modal, Typography, closeModal } from 'thesis-ui';

import { StorageLimitHeader } from '../styles/dashboard';
import { ButtonOverlay } from 'styles/button';

import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';

import { getBgButtonByTeam } from 'helpers';

import { MODAL_KEY } from 'constants/modal-key';

import { useTeamStore } from '../core';

export const StorageLimitReachedModal: React.FC<{
  colour: string;
}> = ({ colour }) => {
  return (
    <Modal
      header={
        <StorageLimitHeader align="center">
          <WarningIcon />
          <Typography style={{ marginLeft: 8 }} variant="h5" weight={600}>
            Low storage!
          </Typography>
        </StorageLimitHeader>
      }
      modalKey={MODAL_KEY.STORAGE_LIMIT_REACHED}
      closeCallback={(keyModal: string) => {
        useTeamStore.getState().setIsOpenLimitReachModal(false);
      }}
      size="md"
      footer={
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            closeModal(MODAL_KEY.STORAGE_LIMIT_REACHED);
            useTeamStore.getState().setIsOpenLimitReachModal(false);
          }}
          style={{
            ...getBgButtonByTeam(false, colour),
            position: 'relative',
            width: '100%',
          }}
        >
          I understand
          <ButtonOverlay disabled={false} color={colour} />
        </Button>
      }
    >
      <Typography style={{ textAlign: 'start' }}>
        Your team's storage capacity is running low, which can stops your devices from uploading new
        data. To avoid this problem, please delete the stored device data to free up storage.
      </Typography>
    </Modal>
  );
};
