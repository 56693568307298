import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ChartPinAt, ChartType } from 'enums/chart';
import { initialQueryState } from 'thesis-ui';

import type { DeviceDetail, DeviceRecord } from 'modules/devices/core';

import { apiCall } from 'hooks/service/api';

import { PATH } from 'constants/path';
import type { Pagination } from 'types/api';

export const usePublicModePageController = () => {
  const params = useParams();
  const navigationTo = useNavigate();
  const { teamId, deviceId } = params;

  const [device, setDevice] = useState<DeviceDetail | null>(null);

  useEffect(() => {
    if (teamId && deviceId) {
      getDeviceDetail();
    }
  }, [deviceId, teamId]);

  const getDeviceDetail = async () => {
    const result: DeviceDetail = await apiCall({
      url: `/api/teams/${teamId}/devices/${deviceId}/public`,
      method: 'GET',
      isLoading: true,
      showError: true,
      isPublic: true,
    });
    if (result?.id) {
      setDevice(result);
    }
  };

  const redirectLogin = () => {
    navigationTo(PATH.LOGIN);
  };

  return {
    device,
    redirectLogin,
  };
};

export const usePublicModeChartController = (device: DeviceDetail | null) => {
  const [deviceCharts, setDeviceCharts] = useState<DeviceRecord[]>([]);

  useEffect(() => {
    if (!device?.id) {
      return;
    }
    getDeviceCharts();
  }, [device]);

  const getDeviceCharts = async () => {
    const result: {
      data: DeviceRecord[];
      pagination: Pagination;
    } = await apiCall({
      url: `/api/teams/${device?.team_id}/charts`,
      data: {
        ...initialQueryState,
        page_size: 100,
        filter: JSON.stringify({
          type: ChartType.Line,
          pin_at: ChartPinAt.PublicPage,
          device_id: device?.id,
        }),
      },
      method: 'GET',
      isLoading: true,
      showError: true,
      isPublic: true,
    });
    if (result?.data) {
      setDeviceCharts(result?.data ?? []);
    }
  };
  return {
    deviceCharts,
  };
};
