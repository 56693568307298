import { PATH } from 'constants/path';

export const replacePath = (path: string, searchValue: string, replaceValue: string) => {
  return path.replace(searchValue, replaceValue);
};

export const getTeamPath = (id: string, path: string) => {
  return `/team/${id}/${path ?? ''}`;
};

export const getDeviceDetailPath = (path: string, teamId: string, deviceId: string) => {
  return `${getTeamPath(teamId ?? '', PATH.TEAM_DEVICES)}/${deviceId}/${path}`;
};

export const getDeviceCommandPath = (path: string, teamId: string, deviceId: string) => {
  return `${getDeviceDetailPath(PATH.TEAM_DEVICE_COMMAND, teamId, deviceId)}/${path}`;
};

export const getDevicePublicModePath = (teamId: string, deviceId: string) => {
  return `/team/${teamId}/device/${deviceId}/public-mode`;
};
