import { EmptyData } from 'components/data/EmptyData';
import { Button, Row, Table } from 'thesis-ui';

import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import { ReactComponent as EmptyTeams } from 'assets/images/small-card-skeleton.svg';

import { getTeamsColumns } from '../core/columns';
import { useTeamsController } from '../core/controllers/teams';
import { getEmptyDataTitle } from '../core/helper';

export const TeamsPage = () => {
  const {
    teams,
    isMyTeamsPath,
    isSharedWithMePath,
    isTeamEmpty,
    isLoading,
    teamTableKey,
    total,
    queryState,
    isAdminUser,
    onCreateTeam,
    updateQueryState,
    onClickRow,
    onClickMenuItem,
  } = useTeamsController();

  const columns = getTeamsColumns(isAdminUser, onClickMenuItem);

  const renderBody = () => {
    if (isLoading.value) {
      return null;
    }
    if (isTeamEmpty) {
      return (
        <Row align="center" style={{ height: 411 }}>
          <EmptyData
            image={<EmptyTeams />}
            button={
              <Button
                icon={<AddIcon />}
                onClick={onCreateTeam}
                placementIcon="left"
                size="large"
                variant="contained"
                color="primary"
              >
                Create team
              </Button>
            }
            title={getEmptyDataTitle(isMyTeamsPath, isSharedWithMePath)}
            permission={isMyTeamsPath}
          />
        </Row>
      );
    }

    return (
      <Table
        columns={columns}
        tableKey={teamTableKey}
        dataSource={teams}
        total={total.value}
        queryState={queryState}
        onClickRow={onClickRow}
        updateQueryState={updateQueryState}
      />
    );
  };

  return (
    <Row direction="column">
      {isMyTeamsPath && !isTeamEmpty && (
        <Row align="center" justify="end" style={{ marginBottom: 24 }}>
          <Button
            icon={<AddIcon />}
            onClick={onCreateTeam}
            placementIcon="left"
            size="small"
            variant="contained"
            color="primary"
          >
            Create team
          </Button>
        </Row>
      )}

      {renderBody()}
    </Row>
  );
};
