import { Row, primary } from 'thesis-ui';

import { getUTCDate } from 'helpers';

import {
  type DevicePublicChartProps,
  useDevicePublicChartController,
} from '../../modules/devices/core';
import { FormChart } from './FormChart';

export const DevicePublicChart: React.FC<DevicePublicChartProps> = ({ chart, originDevice }) => {
  const { datasets, labels, chartOptions } = useDevicePublicChartController(chart, originDevice);

  return (
    <Row direction="column">
      <FormChart
        loading={false}
        label={chart.label || 'No label'}
        chartName={chart.name}
        title={''}
        isEmptyData={Boolean(datasets.length === 0)}
        data={{
          datasets: [
            {
              label: '',
              data: datasets,
              borderColor: primary['primary-6'],
              backgroundColor: primary['primary-6'],
            },
          ],
          labels: labels.map((el) => getUTCDate(el, false)),
        }}
        options={chartOptions}
      />
    </Row>
  );
};
