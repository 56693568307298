import { ConfirmModal } from 'components';
import { ButtonByTeam } from 'components/button/ButtonByTeam';
import { type ButtonSize } from 'components/button/core';
import { EmptyData } from 'components/data/EmptyData';
import { Grid, Row, Spacing, Typography } from 'thesis-ui';

import { DeviceChartItem } from 'modules/devices/components/DeviceChartItem';

import { Body, Header } from '../styles/team-data';

import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import { ReactComponent as ChartSkeleton } from 'assets/images/chart-skeleton.svg';

import { cloneDeep, unionBy } from 'lodash';

import { MODAL_KEY } from 'constants/modal-key';

import { useTeamDataController } from '..';
import { TeamDataFilter } from '../components/TeamDataFilter';
import { TeamDataFilterLabel } from '../components/TeamDataFilterLabel';
import { AddDataChartModal } from '../modal/AddDataChartModal';

export const TeamData = () => {
  const {
    charts,
    isEmptyChart,
    devices,
    team,
    isFilter,
    teamDataTableKey,
    listChartByFilter,
    hasDevicePermission,
    onAddChart,
    onOpenModalDeleteChart,
    onDeleteChart,
  } = useTeamDataController();

  const chartLabels = charts.map((el) => {
    return {
      label: el.label || 'No label',
      id: el.device_id,
    };
  });

  const renderButtonAddChart = (size?: ButtonSize) => {
    return <ButtonByTeam size={size} onClick={onAddChart} icon={<AddIcon />} title="Add chart" />;
  };

  const emptyDataTitle = isFilter
    ? 'No chart matches your selected filters.'
    : 'No chart is added.';

  const renderChart = () => {
    if (isEmptyChart) {
      return (
        <EmptyData
          image={<ChartSkeleton />}
          permission={hasDevicePermission}
          title={emptyDataTitle}
          button={renderButtonAddChart()}
          isFilter={isFilter}
          tableKey={teamDataTableKey}
        />
      );
    }
    return (
      <Row style={{ paddingBottom: 24 }}>
        <Grid direction="template-column" type="full" gap="24px" column={2}>
          {listChartByFilter.map((chart) => {
            return (
              <DeviceChartItem
                hasPermission={hasDevicePermission}
                chartTitle={chart.device_name}
                key={chart.id}
                chart={chart}
                onOpenModalDeleteChart={onOpenModalDeleteChart}
                teamId={team?.id}
              />
            );
          })}
        </Grid>
      </Row>
    );
  };

  return (
    <Row
      direction="column"
      style={{
        height: '100%',
      }}
    >
      <Header align="center" justify="space-between">
        <Typography variant="h4" weight={600}>
          Data
        </Typography>
        {(!isEmptyChart || isFilter) && (
          <Row align="center" style={{ width: 'fit-content' }}>
            <TeamDataFilter charts={unionBy(charts, 'device_id')} />
            {hasDevicePermission && (
              <Spacing spacing="sm" type="margin" placement="left">
                {renderButtonAddChart('small')}
              </Spacing>
            )}
          </Row>
        )}
      </Header>
      {isFilter && <TeamDataFilterLabel devices={devices} />}

      <Body>{renderChart()}</Body>
      <AddDataChartModal devices={cloneDeep(devices)} chartLabels={chartLabels} />
      <ConfirmModal
        modalKey={MODAL_KEY.DELETE_DEVICE_CHART}
        submitButton={{
          buttonProps: {
            onClick: onDeleteChart,
          },
        }}
      />
    </Row>
  );
};
