import { InputTitle } from 'components';
import { ButtonByTeam } from 'components/button/ButtonByTeam';
import { Button, Grid, Row, Spacing, TextInput, Textarea } from 'thesis-ui';

import { Body } from '../styles/device-information';

import { formatDateAndTimeCombination } from 'helpers';

import { DeviceLabel } from '../components/DeviceLabel';
import { useDeviceInformationController } from '../core';

export const DeviceInformation = () => {
  const {
    device,
    alias,
    description,
    location,
    custom_id,
    disabled,
    isChange,
    hasDevicePermission,
    handleChange,
    handleSubmit,
    onCancelChanges,
  } = useDeviceInformationController();

  return (
    <Row align="center" direction="column">
      <Body>
        {hasDevicePermission && (
          <Grid
            direction="template-column"
            type="x"
            gap="24px"
            column={2}
            style={{
              marginBottom: 18,
            }}
          >
            <DeviceLabel title="Serial number" value={device?.serial_number} />
            <DeviceLabel title="Secure token key" hideText value={device?.secure_token_keys} />
          </Grid>
        )}

        <Grid
          direction="template-column"
          type="x"
          gap="24px"
          column={2}
          style={{
            marginBottom: 18,
          }}
        >
          <DeviceLabel
            title="Registered date"
            value={formatDateAndTimeCombination(device?.created_at ?? '')}
          />
          <DeviceLabel
            title="Last update"
            value={formatDateAndTimeCombination(device?.updated_at ?? '')}
          />
        </Grid>
        <Row align="center">
          <Spacing
            style={{
              minWidth: 200,
            }}
            spacing="sm"
            placement="right"
            type="margin"
          >
            <InputTitle title="Device ID" />
            <TextInput
              type="text"
              name="custom_id"
              value={custom_id}
              onChange={handleChange}
              disabled={!hasDevicePermission}
            />
          </Spacing>
          <Row direction="column">
            <InputTitle title="Device name" required />
            <TextInput
              type="text"
              name="alias"
              value={alias}
              onChange={handleChange}
              disabled={!hasDevicePermission}
            />
          </Row>
        </Row>
        <Spacing spacing="sm" placement={['top', 'bottom']} type="margin">
          <InputTitle title="Location" />
          <TextInput
            type="text"
            name="location"
            value={location}
            onChange={handleChange}
            disabled={!hasDevicePermission}
          />
        </Spacing>
        <Spacing spacing="sm" placement="bottom" type="margin">
          <InputTitle title="Description" />
          <Textarea
            name="description"
            value={description}
            onChange={handleChange}
            maxLength={500}
            bodyStyle={{
              minHeight: 132,
            }}
            style={{
              resize: 'none',
            }}
            disabled={!hasDevicePermission}
          />
        </Spacing>
        {hasDevicePermission && (
          <Row align="center">
            <ButtonByTeam
              onClick={() => handleSubmit()}
              title="Save changes"
              size="large"
              disabled={disabled}
              rootStyle={{ width: 143 }}
            />
            {isChange && (
              <Spacing onClick={onCancelChanges} spacing="sm" placement="left" type="margin">
                <Button size="large">Cancel changes </Button>
              </Spacing>
            )}
          </Row>
        )}
      </Body>
    </Row>
  );
};
