import { Grid, Row, Spacing } from 'thesis-ui';

import type { DeviceDetail } from '../core';
import { DeviceLabel } from './DeviceLabel';

export const DeviceDetailCard: React.FC<{
  device?: DeviceDetail | null;
  rootStyled?: React.CSSProperties;
}> = ({ device, rootStyled }) => {
  return (
    <Row
      direction="column"
      style={{
        width: 640,
        ...rootStyled,
      }}
    >
      <Grid direction="template-column" type="x" gap="24px" column={2}>
        <DeviceLabel title="Device ID" value={device?.custom_id} />
        <DeviceLabel title="Device name" value={device?.alias} />
      </Grid>
      <Spacing spacing="sm" type="margin" placement="top">
        <DeviceLabel title="Location" value={device?.location} />
      </Spacing>
      <Spacing spacing="sm" type="margin" placement="top">
        <DeviceLabel title="Description" value={device?.description} />
      </Spacing>
    </Row>
  );
};
