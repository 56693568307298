import * as Yup from 'yup';

import { REGEX_PASSWORD } from 'modules/auth/core';

import { ERROR_MESSAGE } from 'constants/message';

export const requireEmailValidation = Yup.string().required();

export const requirePasswordValidation = Yup.string()
  .min(1)
  .required()
  .matches(REGEX_PASSWORD, ERROR_MESSAGE.PASSWORD_VALIDATE);
