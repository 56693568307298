import React from 'react';
import styled from 'styled-components';

import { NavigationBar, neutral } from 'thesis-ui';

import { TeamModal } from 'modules/team/modal';

import { useController } from '../core/controller';
import { MenuFooter } from './MenuFooter';
import { MenuHeader } from './MenuHeader';

//To-do-contribute design: Update these below attributes to dropdown items (reduce hoverable area)
const NavigationWrapper = styled.div`
  height: 100%;
  .rc-collapse-header {
    margin-top: 20px;
    margin-bottom: 6px;
    padding: 0 16px 0 10px !important;
  }
`;

export const SidebarMenu = () => {
  const { locationPathName, menus } = useController(true);
  return (
    <React.Fragment>
      <NavigationWrapper>
        <NavigationBar
          header={<MenuHeader />}
          containerStyle={{
            paddingLeft: 12,
          }}
          menuItems={menus}
          mode="vertical"
          width="100%"
          height="100%"
          bg={neutral['neutral-11']}
          locationPathName={locationPathName}
          footer={<MenuFooter />}
        />
      </NavigationWrapper>
      <TeamModal />
    </React.Fragment>
  );
};
