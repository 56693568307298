import type { MyProfileRequest, PasswordRequest } from './type';

export const defaultMyProfileRequest: MyProfileRequest = {
  companyName: '',
  fullName: '',
};

export const defaultPasswordRequest: PasswordRequest = {
  currentPassword: '',
  newPassword: '',
};
