import {
  Avatar,
  Row,
  Spacing,
  Tag,
  Typography,
  getFirstLetterInName,
  neutral,
  neutralTrans,
} from 'thesis-ui';

import { getUserColor } from 'modules/generals/core/helper';

import { Container, UserAction, UserActionItem, Version } from '../styles/user-profile';

import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg';

import { getImageUrl } from 'helpers';

import { onLogout, useUserProfileController } from '../core/controller';
import { UserTierCard } from './UserTierCard';

export const PopoverUserProfile: React.FC<{
  setVisible: (value: boolean) => void;
}> = ({ setVisible }) => {
  const { userProfile, isAdmin, onRedirectProfile } = useUserProfileController();

  const avatarSrc = userProfile?.avatar ? getImageUrl(userProfile.avatar) : '';

  return (
    <Container>
      <Spacing spacing="md">
        <Spacing spacing="sm" type="margin" placement="bottom">
          <Row>
            {userProfile?.tier && <UserTierCard tier={userProfile.tier} />}
            {isAdmin && (
              <Tag
                style={{
                  padding: '6px 12px',
                }}
                color="blue"
              >
                Admin
              </Tag>
            )}
          </Row>
        </Spacing>
        <Row align="center">
          <Avatar
            items={{
              label: getFirstLetterInName(userProfile?.fullname, true),
              src: avatarSrc,
            }}
            size="large"
            config={{
              bg: userProfile?.avatar ? 'transparent' : getUserColor(userProfile?.fullname),
              color: neutral['neutral-1'],
              border: ` 1px solid ${neutralTrans['neutralTrans-15']}`,
            }}
            containerStyle={{
              width: 'fit-content',
            }}
            width={44}
          />
          <Spacing spacing="sm" type="margin" placement="left">
            <Row direction="column">
              <Typography weight={600} variant="h5">
                {userProfile?.fullname}
              </Typography>
              <Typography weight={400} color={neutral['neutral-7']}>
                {userProfile?.email}
              </Typography>
            </Row>
          </Spacing>
        </Row>
        <Spacing spacing="md" type="margin" placement="top">
          <Typography variant="body-2" weight={400} color={neutral['neutral-7']}>
            {`ID: ${userProfile?.id}`}
          </Typography>
        </Spacing>
      </Spacing>
      <UserAction direction="column">
        <UserActionItem
          align="center"
          onClick={() => {
            onRedirectProfile();
            setVisible(false);
          }}
        >
          <Spacing
            spacing="xxxs"
            style={{
              height: 24,
            }}
          >
            <ProfileIcon />
          </Spacing>

          <Typography
            weight={500}
            color={neutral['neutral-7']}
            style={{
              marginLeft: 8,
            }}
          >
            My profile
          </Typography>
        </UserActionItem>
        <UserActionItem align="center" onClick={onLogout}>
          <Spacing
            spacing="xxxs"
            style={{
              height: 24,
            }}
          >
            <LogoutIcon />
          </Spacing>
          <Typography
            weight={500}
            color={neutral['neutral-7']}
            style={{
              marginLeft: 8,
            }}
          >
            Log out
          </Typography>
        </UserActionItem>
      </UserAction>
      <Version>
        <Typography variant="body-2" color={neutral['neutral-7']}>
          {userProfile?.version}
        </Typography>
      </Version>
    </Container>
  );
};
