import { Spacing, Typography } from 'thesis-ui';

import { VerifyContainer } from '../styles';

import { ReactComponent as TickCircle } from 'assets/icons/tick-circle.svg';

import { useVerifyController } from '../core';

export const VerifyPage = () => {
  useVerifyController();

  return (
    <VerifyContainer>
      <TickCircle />
      <Spacing spacing="xl" placement={['bottom', 'top']} type="margin">
        <Typography variant="h4" weight={600}>
          Your email is verified!
        </Typography>
      </Spacing>
      <Typography
        weight={400}
        style={{
          textAlign: 'center',
        }}
      >
        We will review your registration and reach out to you via email. Please check your inbox and
        keep an eye out for our email.
      </Typography>
    </VerifyContainer>
  );
};
