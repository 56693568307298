import { DevicePublicChart } from 'components/chart';
import { EmptyData } from 'components/data/EmptyData';
import { Row, Spacing, Typography } from 'thesis-ui';

import { type DeviceDetail } from 'modules/devices/core';

import { BodyChart } from '../styles';

import { ReactComponent as EmptyPublic } from 'assets/images/chart-skeleton.svg';

import { usePublicModeChartController } from '../core';

export const PublicModeChart: React.FC<{
  device: DeviceDetail | null;
}> = ({ device }) => {
  const { deviceCharts } = usePublicModeChartController(device);

  const renderChart = () => {
    if (deviceCharts.length && device) {
      return (
        <BodyChart direction="template-column" type="full" gap="24px" column={2}>
          {deviceCharts.map((chart) => {
            return <DevicePublicChart key={chart.id} chart={chart} originDevice={device} />;
          })}
        </BodyChart>
      );
    }

    return (
      <Row
        align="center"
        style={{
          minHeight: 400,
        }}
      >
        <EmptyData image={<EmptyPublic />} permission={false} title="No chart to show." />
      </Row>
    );
  };

  return (
    <Row
      direction="column"
      style={{
        padding: 24,
      }}
    >
      <Spacing spacing="xl" placement="bottom" type="margin">
        <Typography variant="h5" weight={600}>
          Device data
        </Typography>
      </Spacing>
      {renderChart()}
    </Row>
  );
};
