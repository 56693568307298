import type { DropdownMenuItem } from 'thesis-ui';

import { DropDownLabel, SelectRoleLabel } from 'modules/team';

import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as SettingIcon } from 'assets/icons/setting.svg';

import {
  DeviceChartKey,
  DeviceChartRefreshIntervalType,
  DeviceChartRelativeRange,
  DeviceDataKey,
  DeviceState,
  DeviceStatus,
} from './enum';

export const statusMenu: DropdownMenuItem[] = [
  {
    key: String(DeviceStatus.Active),
    label: 'Active',
  },
  {
    key: String(DeviceStatus.Inactive),
    label: 'Inactive',
  },
];

export const stateMenu: DropdownMenuItem[] = [
  {
    key: String(DeviceState.Private),
    label: (
      <SelectRoleLabel
        title="Private"
        description="Only team members can access device information."
      />
    ),
    isIconTick: false,
  },
  {
    key: String(DeviceState.Public),
    label: (
      <SelectRoleLabel
        title="Public"
        description="Anyone with the link can view device information and data charts, excluding sensitive details like the Serial Number and Secure Token Key."
      />
    ),
    isIconTick: false,
  },
];

export const stateMenuInActive: DropdownMenuItem[] = [
  {
    key: '',
    label: (
      <SelectRoleLabel
        title=""
        description="To make this device public, you have to change state to Active."
      />
    ),
  },
];

export const deviceChartActions: DropdownMenuItem[] = [
  {
    key: DeviceChartKey.Setting,
    label: <DropDownLabel title="Chart settings" icon={<SettingIcon />} />,
  },
  {
    key: DeviceChartKey.Delete,
    label: <DropDownLabel title="Delete chart" icon={<DeleteIcon />} />,
  },
];

export const deviceChartRefreshActions: DropdownMenuItem[] = [
  {
    key: String(DeviceChartRefreshIntervalType.Second),
    label: 'Second',
    isIconTick: false,
  },
  {
    key: String(DeviceChartRefreshIntervalType.Minute),
    label: 'Minute',
    isIconTick: false,
  },
  {
    key: String(DeviceChartRefreshIntervalType.Hour),
    label: 'Hour',
    isIconTick: false,
  },
];

export const deviceDataActions: DropdownMenuItem[] = [
  {
    key: DeviceDataKey.Delete,
    label: <DropDownLabel title="Delete data" icon={<DeleteIcon />} />,
  },
];

export const deviceChartRelativeRangeActions: DropdownMenuItem[] = [
  {
    key: String(DeviceChartRelativeRange.MINUTE),
    label: 'Minute',
    isIconTick: false,
  },
  {
    key: String(DeviceChartRelativeRange.HOUR),
    label: 'Hour',
    isIconTick: false,
  },
  {
    key: String(DeviceChartRelativeRange.DAY),
    label: 'Day',
    isIconTick: false,
  },
  {
    key: String(DeviceChartRelativeRange.MONTH),
    label: 'Month',
    isIconTick: false,
  },
];
