import { DropdownMenu } from 'components';
import { EmptyValue } from 'components/data/EmptyValue';
import {
  Avatar,
  type DropdownMenuItem,
  RadiusConfig,
  Row,
  Typography,
  getFirstLetterInName,
  neutral,
} from 'thesis-ui';

import { type TeamDetail, TeamRole } from 'modules/team/core';

import { getImageUrl } from 'helpers';

import { getTeamsTableAction } from './actions';

export const getTeamsColumns = (
  isAdminUser: boolean,
  onClickMenuItem: (team: TeamDetail) => (item: DropdownMenuItem) => void,
) => {
  return [
    {
      id: 'name',
      Header: 'Team',
      width: '100%',
      minWidth: '100%',
      sort: true,
      sortType: 'name',
      Cell: ({ ...props }) => {
        const name = props.data[props.row.index]?.name;
        const logo = props.data[props.row.index]?.logo;
        const colour = props.data[props.row.index]?.colour;

        return (
          <Row align="center">
            <Avatar
              containerStyle={{
                width: 'fit-content',
                marginRight: 8,
              }}
              items={{
                label: getFirstLetterInName(name ?? '', true),
                src: logo ? getImageUrl(logo) : '',
                borderRadius: RadiusConfig.xs,
                bg: logo ? 'transparent' : colour,
                color: neutral['neutral-1'],
                border: '1px soild transparent',
              }}
              size="medium"
            />
            <Typography style={{ textAlign: 'start' }}>{name}</Typography>
          </Row>
        );
      },
    },
    {
      id: 'total_devices',
      Header: 'Devices',
      width: '109px',
      minWidth: '109px',
      sort: true,
      sortType: 'total_devices',
      Cell: ({ ...props }) => {
        const totalDevices = props.data[props.row.index]?.total_devices;
        return <Typography>{totalDevices || 0}</Typography>;
      },
    },
    {
      id: 'usage',
      Header: 'Storage used',
      width: '124px',
      minWidth: '124px',
      sort: true,
      sortType: 'usage',
      Cell: ({ ...props }) => {
        const usage = props.data[props.row.index]?.usage;
        return <EmptyValue value={usage} />;
      },
    },

    {
      id: 'action',
      Header: '',
      width: '40px',
      minWidth: '40px',
      Cell: ({ ...props }) => {
        const role = props.data[props.row.index]?.role;
        const isTeamOwner = isAdminUser || role === TeamRole.TeamOwner;
        const isTeamAdmin = isAdminUser || role <= TeamRole.TeamAdmin;
        const showDashboardItem = isAdminUser;

        return (
          <DropdownMenu
            items={getTeamsTableAction(
              isTeamAdmin,
              isTeamOwner,
              showDashboardItem,
              props.data[props.row.index]?.enabled_device_dashboard,
            )}
            onClickMenuItem={onClickMenuItem(props.data[props.row.index])}
          />
        );
      },
    },
  ];
};
