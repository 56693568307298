import { useEffect } from 'react';

import { clearTimer, timerSelector, updateTimer, useCountDownTimeStore } from 'stores';

import { defaultTimer } from 'constants/count-down-time';

export const useCountdownTime = (key: string) => {
  const timerStore = useCountDownTimeStore(timerSelector);
  const timer = timerStore[key];
  const isTimerRunning = timerStore[key] ? true : false;

  useEffect(() => {
    if (isTimerRunning) {
      const interval = setInterval(() => {
        const newTimer = timer - 1;
        // Clear interval when timer reaches 0
        if (timer === 0) {
          clearInterval(interval);
          clearTimer(key);
          return;
        }
        updateTimer({
          key,
          value: newTimer,
        });
      }, 1000);

      return () => {
        clearInterval(interval); // Cleanup the interval on component unmount
      };
    }
  }, [timer]);

  const startTimer = () => {
    updateTimer({
      key,
      value: defaultTimer,
    });
  };

  return {
    timer: timer,
    isTimerRunning,
    startTimer,
  };
};
