export enum TeamsTableActionKey {
  Edit = 'edit',
  Delete = 'delete',
  Share = 'share',
  dashboard = 'dashboard',
}

export enum TeamType {
  MyTeam = '0',
  SharedWithMe = '1',
  All = '2',
}
