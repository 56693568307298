import { Row, Tooltip, Typography, red } from 'thesis-ui';

import { ReactComponent as InfoCircle } from 'assets/icons/info-circle.svg';

import type { InputTitleProps } from '../../modules/auth/core';

export const InputTitle: React.FC<InputTitleProps> = ({ title, tooltipMessage, required }) => {
  return (
    <Row
      align="center"
      style={{
        marginBottom: 6,
      }}
    >
      <Typography weight={600}>{title}</Typography>
      {required && (
        <Typography
          style={{
            marginLeft: 2,
          }}
          weight={600}
          color={red['red-6']}
        >
          *
        </Typography>
      )}
      {tooltipMessage && (
        <Tooltip content={tooltipMessage} placement="top" arrow={false}>
          <InfoCircle
            style={{
              marginLeft: 2,
              cursor: 'pointer',
            }}
          />
        </Tooltip>
      )}
    </Row>
  );
};
