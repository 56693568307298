import styled, { css } from 'styled-components';

import { RadiusConfig, Row, Typography, neutral, neutralTrans, red } from 'thesis-ui';

export const Container = styled(Row)`
  border-radius: ${RadiusConfig.xs}px;
  border: 1px solid ${neutralTrans['neutralTrans-15']};
  background: ${neutral['neutral-1']};
  height: fit-content;
`;

export const StorageLabel = styled(Row)<{ iconColor: string }>`
  ${({ iconColor }) => {
    return css`
      .icon-used circle[fill] {
        fill: ${iconColor};
      }
    `;
  }}
`;

export const ContainerDevice = styled(Row)`
  .pagination-container {
    display: none;
  }
  table {
    border: none;
  }

  tbody tr {
    min-height: 60.2px;
    height: 60.2px;
  }
`;

export const HeaderDevice = styled(Row)`
  min-height: 54px;
  border-style: solid;
  border-width: 1px;
  border-color: ${neutralTrans['neutralTrans-15']};
  padding: 0px 16px;
  background-color: ${neutral['neutral-1']};
  border-top-right-radius: ${RadiusConfig.xs}px;
  border-top-left-radius: ${RadiusConfig.xs}px;
`;

export const TitleLink = styled(Typography)`
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
`;

export const DeviceEmptyData = styled(Row)`
  border-bottom-left-radius: ${RadiusConfig.xs}px;
  border-bottom-right-radius: ${RadiusConfig.xs}px;
  border: 1px solid ${neutralTrans['neutralTrans-15']};
  border-top-width: 0px;
  background: ${neutral['neutral-1']};
  height: 100%;
`;

export const StorageLimitHeader = styled(Row)`
  svg path[fill] {
    fill: ${red['red-6']};
  }
`;

export const DeviceTable = styled(Row)`
  height: 354px;
  overflow-y: auto;
  background-color: ${neutral['neutral-1']};
  border-bottom-left-radius: ${RadiusConfig.xs}px;
  border-bottom-right-radius: ${RadiusConfig.xs}px;
  border-color: ${neutralTrans['neutralTrans-15']};
  border-width: 0px 1px 1px 1px;
  border-style: solid;
`;

export const ContainerStorageLimitLabel = styled(Row)`
  padding: 6px 12px;
  background-color: ${neutral['neutral-4']};
  border-radius: ${RadiusConfig.max}px;
  width: fit-content;
`;
