export enum Role {
  Admin = 'ad9ee233-a2c5-4623-a0a8-c9fd724414cc',
  User = 'f484788d-7d6e-4a4a-b94e-75e6b21ad9a9',
}

export enum Policy {
  Dashboard = 'dashboard',
  User = 'user',
  Team = 'team',
}

export enum Permission {
  ViewDashboard = 'view-dashboard',
  ViewUser = 'view-user',
  EditUser = 'edit-user',
  DeleteUser = 'delete-user',
}

export enum ConfirmEmailType {
  Register = 'verification',
  ForgotPassword = 'forgot_password',
}

export enum UserSystem {
  Tracelium,
  TraceliumConnect,
}

export enum UserTierName {
  BetaProUser = 'Beta Pro User',
  BetaUser = 'Beta User',
  FreeUser = 'Free User',
}
