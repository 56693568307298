import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Row, Spacing, Typography, neutral } from 'thesis-ui';

import { ReactComponent as Lock } from 'assets/images/lock.svg';

import { PATH } from 'constants/path';

// To-do-base: Update 403 page
export const Error403Page = () => {
  const navigationTo = useNavigate();
  return (
    <Container>
      <Lock />
      <Spacing spacing="xl" type="padding" placement={['top', 'bottom']}>
        <Row direction="column" align="center">
          <Typography>You’re not authorized to access this page.</Typography>
        </Row>
      </Spacing>
      <Button style={{ width: 128 }} color="primary" onClick={() => navigationTo(PATH.LOGIN)}>
        Go to Home
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-color: ${neutral['neutral-2']};
`;
