import { Row, Spacing, Typography, neutral } from 'thesis-ui';

import { Label } from '../styles/command-label';

import { merge } from 'lodash';

import { type CommandDataLabelProps } from '../core';

export const CommandDataLabel: React.FC<CommandDataLabelProps> = ({
  data,
  labelStyle = {},
  typoStyle = {},
}) => {
  if (!data) {
    return <span>No information</span>;
  }
  return (
    <Row>
      <Label
        align="center"
        style={merge(
          {
            background: neutral['neutral-2'],
            width: '100%',
            paddingLeft: 4,
            minHeight: 32,
            height: 'auto',
          },
          labelStyle,
        )}
      >
        <Spacing spacing="xs" placement="left" type="margin">
          <Typography
            weight={400}
            style={merge({ whiteSpace: 'pre-line', textAlign: 'left' }, typoStyle)}
          >
            {data}
          </Typography>
        </Spacing>
      </Label>
    </Row>
  );
};
