export const MODAL_KEY = Object.freeze({
  FORM_TEAM: 'form-team',
  CONFIRM: 'confirm',
  TEAM_SHARE: 'team-share',
  TRANSFER: 'transfer',
  DEVICE_DELETE: 'device-delete',
  CHANGE_STATUS_TO_INACTIVE: 'change-status-to-inactive',
  ADD_COMMAND_FROM_COLLECTION: 'add-command-from-collection',
  FORM_COMMAND_COLLECTION: 'form-command-collection',
  FORM_COMMAND_QUEUE: 'form-command-queue',
  DELETE_QUEUE: 'delete-queue',
  COMMAND_NOTE: 'command-note',
  COMMAND_METHOD: 'command-method',
  DELETE_COLLECTION: 'delete-collection',
  ADD_DEVICE_CHART: 'add-device-chart',
  SETTING_DEVICE_CHART: 'setting-device-chart',
  DELETE_DEVICE_CHART: 'delete-device-chart',
  DELETE_DEVICE_DATA: 'delete-device-data',
  ADD_DATA_CHART: 'add-data-chart',
  STORAGE_LIMIT_REACHED: 'storage-limit-reached',
  VERSION_UPDATE: 'version-update',
  TERMS_AND_CONDITION_UPDATE: 'terms-and-condition-update',
  DELETE_DEVICE_CHART_PUBLIC: 'delete-device-chart-public',
  ADD_DEVICE_CHART_PUBLIC: 'add-device-chart-public',
});
