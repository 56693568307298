import { ChartPinAt } from 'enums/chart';

import type { DeviceDetail, FormSettingChart } from './type';

import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { LOCAL_STORAGE_DEVICE_SETTING_CHART } from 'constants/local-storage';

interface DeviceState {
  deviceDetail: DeviceDetail | null;
}

interface DeviceSettingChartState {
  setting: Record<string, FormSettingChart>;
  isRefreshChart: boolean;
}

interface DeviceStore {
  state: DeviceState;
  updateDeviceDetail: (update: DeviceDetail | null) => void;
  clearDeviceState: () => void;
}

interface DeviceSettingChartStore {
  state: DeviceSettingChartState;
  updateSettingChart: (key: string, update: FormSettingChart) => void;
  updateRefreshChart: (value: boolean) => void;
  clearSettingChartState: () => void;
  clearSettingChartByKey: (key: string) => void;
}

const initState: DeviceState = {
  deviceDetail: null,
};

const initSettingChartState: DeviceSettingChartState = {
  setting: {},
  isRefreshChart: false,
};

export const useDeviceStore = create(
  immer<DeviceStore>((set) => ({
    state: initState,

    updateDeviceDetail: (payload) => {
      set((store) => {
        store.state.deviceDetail = payload;
      });
    },
    clearDeviceState: () => {
      set((store) => {
        store.state = initState;
      });
    },
  })),
);

export const useDeviceSettingChartStore = create(
  persist(
    immer<DeviceSettingChartStore>((set) => ({
      state: initSettingChartState,
      updateSettingChart: (key: string, update: FormSettingChart) => {
        set((store) => {
          const newSetting = store.state?.setting ?? {};
          newSetting[key] = update;
          store.state.setting = newSetting;
        });
      },
      updateRefreshChart: (value: boolean) => {
        set((store) => {
          store.state.isRefreshChart = value;
        });
      },
      clearSettingChartByKey: (key: string) => {
        set((store) => {
          const newSetting = store.state?.setting ?? {};
          delete newSetting[key];
          store.state.setting = newSetting;
        });
      },
      clearSettingChartState: () => {
        set((store) => {
          store.state = initSettingChartState;
        });
      },
    })),
    {
      name: LOCAL_STORAGE_DEVICE_SETTING_CHART,
      partialize: (store: DeviceSettingChartStore) => ({
        state: {
          ...store.state,
          setting: updateSettingState(store.state.setting),
        },
      }),
    },
  ),
);

const updateSettingState = (settings: Record<string, FormSettingChart>) => {
  const newSettings: Record<string, FormSettingChart> = {};
  Object.keys(settings).forEach((key: string) => {
    const isPublic = key.startsWith(ChartPinAt[2]);
    if (!isPublic) {
      newSettings[key] = settings[key];
    }
  });
  return newSettings;
};

export const { updateDeviceDetail, clearDeviceState } = useDeviceStore.getState();
export const {
  updateSettingChart,
  updateRefreshChart,
  clearSettingChartByKey,
  clearSettingChartState,
} = useDeviceSettingChartStore.getState();

export const deviceDetailSelector = (s: DeviceStore) => s.state.deviceDetail;
export const isRefreshChartSelector = (s: DeviceSettingChartStore) => s.state.isRefreshChart;
