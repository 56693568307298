import { EmptyData } from 'components/data/EmptyData';
import { Button, Row, Spacing } from 'thesis-ui';

import { Content } from './styles';

import { ReactComponent as EmptyLog } from 'assets/images/empty-log.svg';

import type { ListActionLogProps } from './core/type';

import { ActionLogItem } from './ActionLogItem';

export const ActionLogList: React.FC<ListActionLogProps> = ({
  logs,
  colorTeam,
  isLoadMore,
  onLoadMore,
}) => {
  const renderContent = () => {
    if (logs.length) {
      return (
        <Row direction="column">
          {logs.map((log) => {
            return <ActionLogItem log={log} key={log.id} colorTeam={colorTeam} />;
          })}
          {isLoadMore && (
            <Spacing
              style={{
                width: '100%',
              }}
              spacing="xl"
              type="margin"
              placement="top"
            >
              <Row justify="center">
                <Button size="large" onClick={onLoadMore}>
                  Load more
                </Button>
              </Row>
            </Spacing>
          )}
        </Row>
      );
    }
    return <EmptyData title="No action was logged." image={<EmptyLog />} permission={false} />;
  };

  return <Content>{renderContent()}</Content>;
};
