import * as Yup from 'yup';

import { requireEmailValidation, requirePasswordValidation } from 'helpers/validate';

export const loginSchema = Yup.object().shape({
  email: requireEmailValidation,
  password: requirePasswordValidation,
});

export const signUpSchema = Yup.object().shape({
  fullName: Yup.string().required(),
  email: requireEmailValidation,
  password: requirePasswordValidation,
  companyName: Yup.string(),
});

export const invitationSchema = Yup.object().shape({
  fullName: Yup.string().required(),
  password: requirePasswordValidation,
  companyName: Yup.string(),
});

export const forgotPasswordSchema = Yup.object().shape({
  email: requireEmailValidation,
});

export const resetPasswordSchema = Yup.object().shape({
  password: requirePasswordValidation,
});
