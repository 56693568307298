import styled from 'styled-components';

import { RadiusConfig, Row, darkNeutralTrans, neutral } from 'thesis-ui';

export const StyledIcon = styled.div`
  border-radius: ${RadiusConfig.xxs}px;
  height: 24px;
  &:hover {
    background-color: ${darkNeutralTrans['darkNeutralTrans-10']};
    svg path[stroke] {
      stroke: ${neutral['neutral-1']};
    }
    svg path[fill] {
      fill: ${neutral['neutral-1']};
    }
  }
`;

export const StyledTitle = styled(Row)`
  &:hover {
    p {
      color: ${neutral['neutral-1']};
    }
    svg path[stroke] {
      stroke: ${neutral['neutral-1']};
    }
    svg path[fill] {
      fill: ${neutral['neutral-1']};
    }
  }
`;
