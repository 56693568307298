import { Typography, neutral } from 'thesis-ui';

import { isEmpty } from 'lodash';

export const EmptyValue: React.FC<{
  value: string;
  rootStyle?: React.CSSProperties;
}> = ({ value, rootStyle }) => {
  const isEmptyValue = isEmpty(value);

  const color = neutral[isEmptyValue ? 'neutral-7' : 'neutral-11'];

  return (
    <Typography color={color} style={rootStyle}>
      {isEmptyValue ? '-' : value}
    </Typography>
  );
};
