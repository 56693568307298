import styled from 'styled-components';

import { Row, Typography, neutral } from 'thesis-ui';

export const InputPasswordTooltipMessage = () => {
  const passwordLabels = [
    'At least 8 characters long',
    'At least 1 uppercase letter',
    'At least 1 lowercase letter',
    'At least 1 number',
    'At least 1 special character',
  ];
  return (
    <Row direction="column">
      <Typography variant="body-2" color={neutral['neutral-1']}>
        Password must includes:
      </Typography>
      <Ul>
        {passwordLabels.map((el) => {
          return <Li key={el}>{el}</Li>;
        })}
      </Ul>
    </Row>
  );
};

const Ul = styled.ul`
  margin: 0px;
  padding-left: 23px;
`;
const Li = styled.li`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;
