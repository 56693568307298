import { useNavigate } from 'react-router-dom';

import { updateQueryStateTable, useTableStore } from 'thesis-ui';

import { teamDetailSelector, useTeamStore } from 'modules/team';

import { getTeamPath } from 'helpers';
import { cloneDeep } from 'lodash';

import { PATH } from 'constants/path';

export const useController = (tableKey?: string) => {
  const navigationTo = useNavigate();
  const team = useTeamStore(teamDetailSelector);
  const queryState = useTableStore((s) => s.state.queryState[tableKey ?? '']);

  const onClickAddDevice = () => {
    navigationTo(`${getTeamPath(team?.id ?? '', PATH.TEAM_DEVICES)}/${PATH.TEAM_DEVICE_ADD}`);
  };

  const onClearFilter = () => {
    if (!tableKey) {
      return;
    }
    const newQueryState = cloneDeep(queryState);
    newQueryState.filter = undefined;
    newQueryState.page = 1;
    updateQueryStateTable(tableKey, newQueryState);
  };

  return {
    team,
    onClickAddDevice,
    onClearFilter,
  };
};
