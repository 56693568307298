import { blue, green, neutral, orange, pink, purple, red } from 'thesis-ui';

export const LIST_COLOR_SELECT: string[] = [
  blue['blue-2'],
  purple['purple-2'],
  pink['pink-3'],
  red['red-3'],
  orange['orange-3'],
  green['green-2'],
  neutral['neutral-5'].toLowerCase(),
  blue['blue-5'],
  purple['purple-5'],
  pink['pink-5'],
  red['red-5'],
  orange['orange-5'],
  green['green-5'],
  neutral['neutral-7'].toLowerCase(),
  blue['blue-7'],
  purple['purple-7'],
  pink['pink-7'],
  red['red-7'],
  orange['orange-7'],
  green['green-7'],
  neutral['neutral-9'].toLowerCase(),
];

export const defaultFormTeam = {
  name: '',
  color: blue['blue-5'].toUpperCase(),
};

export const MAX_FILE_SIZE = 10 * 1024 * 1024; //Max file size is 10Mb

export const ListTeamRole = ['Team Owner', 'Team Admin', 'Team Member', 'Guest'];
