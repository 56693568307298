import { DropdownMenu } from 'components';
import { EmptyValue } from 'components/data/EmptyValue';
import { type DropdownMenuItem, Tag, neutral } from 'thesis-ui';

import { DropDownLabel } from 'modules/team';

import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';

import { PublicModeTag } from '../components/PublicModeTag';
import { DeviceActionKey, DeviceStatus } from './enum';

export const devicesColumns = (
  devicePermission: boolean,
  onClickMenuItem: (item: DropdownMenuItem, id: string) => void,
) => {
  return [
    {
      id: 'custom_id',
      Header: 'Device ID',
      minWidth: '140px',
      maxWidth: '140px',
      sort: true,
      sortType: 'custom_id',
      Cell: ({ ...props }) => {
        const customId = props.data[props.row.index].custom_id;

        return (
          <EmptyValue
            value={customId}
            rootStyle={{
              color: neutral['neutral-7'],
              wordBreak: 'break-word',
            }}
          />
        );
      },
    },
    {
      id: 'alias',
      Header: 'Device name',
      accessor: 'alias',
      width: '100%',
      minWidth: '100%',
      sort: true,
      sortType: 'alias',
    },
    {
      id: 'status',
      Header: 'Status',
      width: '140px',
      minWidth: '140px',
      sort: true,
      sortType: 'status',
      Cell: ({ ...props }) => {
        const status = props.data[props.row.index].status;
        return (
          <Tag dot color={status === DeviceStatus.Active ? 'green' : 'red'}>
            {DeviceStatus[status]}
          </Tag>
        );
      },
    },
    {
      id: 'state',
      Header: 'Public mode',
      width: '140px',
      minWidth: '140px',
      sort: true,
      sortType: 'state',
      Cell: ({ ...props }) => {
        const newValue = props.data[props.row.index]?.state;
        return <PublicModeTag state={newValue} />;
      },
    },
    {
      id: 'usage',
      Header: 'Used',
      width: '108px',
      minWidth: '108px',
      sort: true,
      sortType: 'usage',
      Cell: ({ ...props }) => {
        const usage = props.data[props.row.index]?.usage;
        return <EmptyValue value={usage} />;
      },
    },
    {
      id: 'action',
      Header: '',
      width: '40px',
      minWidth: '40px',
      Cell: ({ ...props }) => {
        const newId = props.data[props.row.index]?.id;
        if (!devicePermission) {
          return null;
        }

        return (
          <DropdownMenu
            items={[
              {
                key: DeviceActionKey.Edit,
                label: <DropDownLabel title="Edit" icon={<EditIcon />} />,
              },
              {
                key: DeviceActionKey.Delete,
                label: <DropDownLabel title="Delete" icon={<DeleteIcon />} />,
              },
            ]}
            onClickMenuItem={(item: DropdownMenuItem) => onClickMenuItem(item, newId)}
          />
        );
      },
    },
  ];
};
