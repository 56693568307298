import { Outlet } from 'react-router-dom';

import { Row, Tabs, Typography } from 'thesis-ui';

import { Body, Container, Header, StyledOutlet, StyledTab } from '../styles/teams-layout';

import { useTeamsLayoutController } from '../core/controllers/teams-layout';
import { getTeamsTabs } from '../core/tabs';

export const TeamsLayout = () => {
  const { isAdminUser, isMyTeamsPath, locationPath, onTabClick } = useTeamsLayoutController();

  const tabs = getTeamsTabs();

  return (
    <Row direction="column" style={{ height: '100vh' }}>
      <Header align="center">
        <Typography variant="h4" weight={600}>
          Teams
        </Typography>
      </Header>
      <Container>
        <Body direction="column">
          <StyledTab>
            <Tabs
              variant="text"
              items={isAdminUser ? tabs : [tabs[0], tabs[1]]}
              activeKey={locationPath}
              onTabClick={onTabClick}
              tabItemStyle={{
                height: 52,
              }}
            />
          </StyledTab>
          <StyledOutlet isMyTeams={isMyTeamsPath}>
            <Outlet />
          </StyledOutlet>
        </Body>
      </Container>
    </Row>
  );
};
