import { updateQueryStateTable, useTableStore } from 'thesis-ui';

import { cloneDeep, isEmpty } from 'lodash';

export const useController = (tableKey: string, field: string) => {
  const queryState = useTableStore((s) => s.state.queryState[tableKey]);

  const { filter } = queryState ?? {};
  const parserFilter = filter ? JSON.parse(filter) : {};

  const onClearFilter = () => {
    const newFilter = cloneDeep(parserFilter);
    const newQueryState = cloneDeep(queryState);
    delete newFilter[field];
    newQueryState.filter = isEmpty(newFilter) ? undefined : JSON.stringify(newFilter);
    updateQueryStateTable(tableKey, newQueryState);
  };

  return {
    onClearFilter,
  };
};
