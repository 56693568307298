export enum DeviceState {
  Private,
  Public,
}

export enum DeviceStatus {
  Active,
  Inactive,
}

export enum DeviceActionKey {
  Edit = 'edit',
  Delete = 'delete',
  Active = 'active',
  Inactive = 'Inactive',
}

export enum DeviceChartKey {
  Setting = 'setting',
  Delete = 'delete',
}

export enum DeviceChartRefreshIntervalType {
  Second,
  Minute,
  Hour,
}

export enum DeviceChartYAxisSettingType {
  Auto,
  Manually,
}

export enum DeviceWithUsage {
  Inactive,
  Active,
}

export enum DeviceDataKey {
  Delete = 'delete',
}

export enum DeviceChartDateRangeTabKey {
  QUICK_OPTIONS,
  RELATIVE_RANGE,
}

export enum DeviceChartRelativeRange {
  MINUTE,
  HOUR,
  DAY,
  MONTH,
}
