import { type QueryState, blue, neutral, red } from 'thesis-ui';
import isEmail from 'validator/lib/isEmail';

import { type DeviceDetail } from 'modules/devices/core';

import { hasEmptyValues } from 'helpers';
import { ceil, cloneDeep, floor, includes, isEmpty, remove } from 'lodash';

import { ERROR_MESSAGE } from 'constants/message';

import { TeamRole } from './enum';

export const getTeamPath = (id: string, path?: string) => {
  return `/team/${id}/${path ?? ''}`;
};

export const isAdminByTeamRole = (role?: TeamRole) => {
  return Number(role) < Number(TeamRole.TeamMember);
};

export const getItemAliasByDevice = (deviceId: string, devices: DeviceDetail[]) => {
  const item = devices.find((el) => el.id === deviceId);
  return item?.alias || '';
};

export const checkStorageLimitReached = (rawUsage: string, rawStorageLimit: string) => {
  const progress =
    rawUsage && rawUsage !== 'NaN' && rawStorageLimit
      ? ceil(Number(rawUsage)) / Number(rawStorageLimit)
      : 0;
  return progress >= 0.9; // This modal appears when a user accesses a team that has used >= 90% of its storage.
};

export const updateTeamDataQueryState = (
  queryState: QueryState,
  queryFilter: any,
  filterKey: string,
  deviceId: string,
) => {
  const newFilter = cloneDeep(queryFilter);
  const newQueryState = cloneDeep(queryState);
  newFilter[filterKey] = newFilter[filterKey] || [];
  remove(newFilter[filterKey], (el) => el === deviceId);
  newQueryState.filter = isEmpty(newFilter) ? undefined : JSON.stringify(newFilter);
  return newQueryState;
};

export const getEmailMessageError = (email: string) => {
  return !isEmail(email.trim()) ? ERROR_MESSAGE.EMAIL : '';
};

export const getTeamSharePermission = (role: TeamRole, isAdmin: boolean) => {
  return Number(role) < Number(TeamRole.TeamMember) || isAdmin;
};

export const getButtonTeamShareDisabled = (
  email: string,
  isSubmitting: boolean,
  isValid: boolean,
) => {
  return hasEmptyValues({ email }) || isSubmitting || !isValid || !isEmail(email.trim());
};

export const getAvatarStyles = (isUserActive: boolean) => {
  let avatarBorder = 'none';
  let avatarBg = blue['blue-5'];
  if (!isUserActive) {
    avatarBorder = `1px dashed ${neutral['neutral-7']}`;
    avatarBg = neutral['neutral-1'];
  }
  return {
    avatarBorder,
    avatarBg,
  };
};

export const getStorageProgress = (rawUsage: string, rawStorageLimit: string) => {
  let progress = 0;
  if (rawUsage && rawUsage !== 'NaN' && rawStorageLimit) {
    progress = floor((ceil(Number(rawUsage)) / Number(rawStorageLimit)) * 100, 2);
  }
  return progress;
};

export const getChartColor = (progress: number) => {
  return progress >= 80 ? red['red-6'] : blue['blue-5'];
};

// eslint-disable-next-line no-irregular-whitespace
//  Sort selected values ​​by devices
export const getSelectedDeviceSort = (
  selectedDevice: Record<string, string[]>,
  devices: DeviceDetail[],
) => {
  const newSelected: {
    id: string;
    label: string;
  }[] = [];

  Object.keys(selectedDevice).forEach((key) => {
    if (selectedDevice[key]) {
      selectedDevice[key].forEach((label) => {
        newSelected.push({
          id: key,
          label,
        });
      });
    }
  });

  return newSelected.sort((a, b) => {
    const indexA = devices.findIndex((e) => e.id === a.id);
    const indexB = devices.findIndex((e) => e.id === b.id);

    if (indexA > indexB) {
      return -1;
    }

    if (indexA < indexB) {
      return 1;
    }

    const labelIndexA = devices[indexA].labels.findIndex((e) => e.label === a.label);
    const labelIndexB = devices[indexA].labels.findIndex((e) => e.label === b.label);

    return labelIndexB - labelIndexA;
  });
};

export const getListDeviceChartAdd = (
  devices: DeviceDetail[],
  chartLabels: { id: string; label: string }[],
) => {
  const results: DeviceDetail[] = [];
  devices.forEach((device) => {
    if (!isEmpty(device?.labels)) {
      const activeLabels = chartLabels
        .filter((el) => el.id === device.id)
        .map((active) => active.label);
      const deviceLabels = device.labels;
      remove(deviceLabels, (el) => includes(activeLabels, el.label));
      if (!isEmpty(deviceLabels)) {
        results.push({
          ...device,
          labels: deviceLabels,
        });
      }
    }
  });
  return results;
};
