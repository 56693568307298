import { InputTitle } from 'components';
import { Spacing, TextInput, Typography } from 'thesis-ui';

import { AuthForm } from '../styles';

import { FormSignUpCard } from '../components/FormSignUpCard';
import { useInvitationController } from '../core';

export const InvitationPage = () => {
  const {
    fullName,
    companyName,
    disabled,
    password,
    passwordStatus,
    email,
    handleChange,
    handleSubmit,
    isTermAndPolicy,
  } = useInvitationController();

  return (
    <AuthForm onSubmit={handleSubmit}>
      <Spacing spacing="xl" placement="bottom" type="margin">
        <Typography
          variant="h4"
          weight={700}
          style={{
            textAlign: 'center',
          }}
        >
          Sign up
        </Typography>
      </Spacing>
      <Spacing spacing="xl" type="margin" placement="bottom">
        <Typography>You are signing up an account with the email:</Typography>
        <Typography weight={600}>{email}</Typography>
      </Spacing>

      <Spacing spacing="sm" placement="bottom" type="margin">
        <InputTitle title="Full name" required />
        <TextInput type="text" name="fullName" value={fullName} onChange={handleChange} />
      </Spacing>

      <FormSignUpCard
        companyName={companyName}
        disabled={disabled}
        handleChange={handleChange}
        isTermAndPolicy={isTermAndPolicy}
        password={password}
        passwordStatus={passwordStatus}
      />
    </AuthForm>
  );
};
