import { Button, Modal, Row, Typography } from 'thesis-ui';

import { ButtonOverlay } from 'styles/button';

import type { ConfirmModalProps } from './core/type';

import { useController } from './core';

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  cancelButton,
  submitButton,
  modalKey,
  overlay,
}) => {
  const { header, label, onCancel } = useController(modalKey);

  return (
    <Modal
      header={header}
      modalKey={modalKey}
      size="md"
      footer={
        <Row align="center" justify="space-between">
          {!cancelButton?.hide && (
            <Button
              size="large"
              style={{
                width: 178,
              }}
              onClick={onCancel}
              {...cancelButton?.buttonProps}
            >
              {cancelButton?.label ?? 'Cancel'}
            </Button>
          )}

          {!submitButton?.hide && (
            <Button
              color="danger"
              size="large"
              style={{
                width: 178,
              }}
              {...submitButton?.buttonProps}
            >
              {submitButton?.label ?? 'Delete'}
              {overlay?.show && <ButtonOverlay disabled={overlay.disabled} color={overlay.color} />}
            </Button>
          )}
        </Row>
      }
    >
      <Typography weight={400}>{label}</Typography>
    </Modal>
  );
};
