import styled, { css } from 'styled-components';

import { RadiusConfig, Row, neutral, neutralTrans } from 'thesis-ui';

import LineIcon from 'assets/icons/line.svg';

export const ExtraCard = styled(Row)`
  border-radius: ${RadiusConfig.xxs}px;
  border: 1px solid ${neutralTrans['neutralTrans-15']};
  background: ${neutral['neutral-2']};
  padding: 8px 12px;
  margin-bottom: 24px;
  width: fit-content;
  gap: 8px;
`;

export const Container = styled.div`
  display: flex;
`;

export const LineCard = styled.div<{ colorTeam: string }>`
  ${({ colorTeam }) => {
    return css`
      min-width: 30px;
      width: 30;
      display: flex;
      flex-direction: column;
      .icon circle[fill] {
        fill: ${colorTeam};
      }
      .icon path[fill] {
        fill: ${colorTeam};
      }
    `;
  }}
`;

export const LineBorder = styled.div`
  flex-grow: 1;
`;

export const LineContent = styled.div`
  display: flex;
  width: 18px;
  height: 100%;
  background-image: url(${LineIcon});
`;

export const Content = styled.div`
  width: 640px;
  min-width: 640px;
`;
