import { ButtonByTeam } from 'components/button/ButtonByTeam';
import { Dropdown } from 'thesis-ui';

import { DropDownLabel } from 'modules/team';

import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import { ReactComponent as CollectionIcon } from 'assets/icons/collection.svg';
import { ReactComponent as CommandIcon } from 'assets/icons/command.svg';

import { type ButtonAddCommandProps, useButtonAddCommandController } from '../core';
import { DeviceCommandKey } from '../core/enum';

export const ButtonAddCommand: React.FC<ButtonAddCommandProps> = ({
  size,
  placement = 'bottom',
  offset = [0, 5],
  disabled,
}) => {
  const { visible, onChangeDropdownAction, onClickMenuItem } = useButtonAddCommandController();

  return (
    <Dropdown
      theme="dark"
      dropdownConfig={{
        placement: placement,
        trigger: 'click',
        offset: offset,
        visible: visible.value,
        onClickOutside: onChangeDropdownAction,
      }}
      dropdownMenuStyle={{
        minWidth: 212,
      }}
      menu={{
        items: [
          {
            key: DeviceCommandKey.AddCollection,
            label: <DropDownLabel title="Add from collection" icon={<CollectionIcon />} />,
          },
          {
            key: DeviceCommandKey.CreateQueue,
            label: <DropDownLabel title="Create new command" icon={<CommandIcon />} />,
          },
        ],
        selectedKeys: [],
        onClick: onClickMenuItem,
      }}
    >
      <ButtonByTeam
        disabled={disabled}
        size={size}
        icon={<AddIcon />}
        title="Add command"
        onClick={onChangeDropdownAction}
      />
    </Dropdown>
  );
};
