import * as Yup from 'yup';

import { requirePasswordValidation } from 'helpers/validate';

export const myProfileSchema = Yup.object().shape({
  fullName: Yup.string().required(),
  companyName: Yup.string(),
});

export const passwordSchema = Yup.object().shape({
  currentPassword: requirePasswordValidation,
  newPassword: requirePasswordValidation,
});
