import styled from 'styled-components';

import { Row } from 'thesis-ui';

export const MAIN_NODE_WIDTH = 186;
export const NODE_WIDTH = 324;

export const EXCLUDE_WIDTH_FOR_CANVAS = 318;

export const CANVAS_WIDTH = window.innerWidth - EXCLUDE_WIDTH_FOR_CANVAS;

export const CanvasContainer = styled.div`
  width: calc(100vw - ${EXCLUDE_WIDTH_FOR_CANVAS}px);
  height: calc(100vh - 338px);

  .edge-line {
    path {
      stroke-width: 2px;
      stroke-dasharray: 5;
      stroke-linecap: butt;
      stroke-linejoin: round;
    }

    &.selected path {
      stroke: red;
    }
  }
`;

export const LegendContainer = styled(Row)`
  width: fit-content;
  > div {
    width: fit-content;

    &:not(:last-child) {
      margin-right: 24px;
    }

    > div {
      margin-right: 8px;
    }

    [class^='nota'] {
      width: 20px;
      height: 14px;
      border-radius: 4px;
      border: 2px solid red;
    }
    .nota-2 {
      border-color: #00000026;
      background-color: #f7f7f7;
    }
  }
`;

export const CustomNode = styled(Row)`
  width: 300px;
  height: 164px;
  border-radius: 6px;
  border: 1.5px solid #d4d4d4;
  /* overflow: auto; */

  &::-webkit-scrollbar-track {
    background-color: red;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: red;
    border-radius: 6px;
  }

  .header {
    padding: 12px;
    border-bottom: 1.5px solid #00000026;
    background-color: #fff;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    svg {
      width: 16px;
      height: 16px;
      path {
        stroke: #222222;
      }
    }
    p {
      width: fit-content;
      line-height: 20px;
      color: #222222;
      padding-left: 4px;
      text-align: left;
    }
  }
  .content {
    width: 100%;
    background: #f7f7f7;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 8px 12px;
    height: 118px;
    overflow: auto;

    > div {
      justify-content: flex-end;
      min-height: 102px;
    }
  }
`;
