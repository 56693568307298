import { ConfirmModal } from 'components';
import { ButtonByTeam } from 'components/button/ButtonByTeam';
import { EmptyData } from 'components/data/EmptyData';
import { Row, Spacing, Table, Typography, neutral } from 'thesis-ui';

import { Container, Header } from '../styles/devices';
import { ButtonOverlayIcon } from 'styles/button';

import { ReactComponent as AddIcon } from 'assets/icons/add.svg';

import { MODAL_KEY } from 'constants/modal-key';

import { DeviceFilter } from '../components/DeviceFilter';
import { DeviceFilterLabel } from '../components/DeviceFilterLabel';
import { devicesColumns, useDevicesController } from '../core';

export const DevicesPage = () => {
  const {
    queryState,
    devices,
    total,
    team,
    isLoading,
    isFilter,
    devicePermission,
    deviceTableKey,
    onClickAddDevice,
    onClickMenuItem,
    onSubmitDeleteDevice,
    onClickRow,
    updateQueryState,
  } = useDevicesController();

  const columns = devicesColumns(devicePermission, onClickMenuItem);

  const emptyDataTitle = isFilter
    ? 'No device matches your selected filters.'
    : 'The team has not added any devices.';

  const renderBody = () => {
    if (isLoading.value) {
      return null;
    }

    if (!devices.length) {
      return (
        <EmptyData
          title={emptyDataTitle}
          isFilter={isFilter}
          permission={devicePermission}
          tableKey={deviceTableKey}
        />
      );
    }
    return (
      <Table
        columns={columns}
        tableKey={deviceTableKey}
        dataSource={devices}
        total={total.value}
        queryState={queryState}
        onClickRow={onClickRow}
        updateQueryState={updateQueryState}
      />
    );
  };
  return (
    <Container>
      <Header>
        <Typography weight={600} variant="h4" style={{ height: '100%' }}>
          Devices
        </Typography>

        {(Boolean(devices.length) || isFilter) && (
          <Row
            align="center"
            style={{
              width: 'fit-content',
            }}
          >
            <DeviceFilter />
            {devicePermission && (
              <Spacing spacing="sm" type="margin" placement="left">
                <ButtonOverlayIcon disabled={false} color={team?.colour}>
                  <ButtonByTeam
                    onClick={onClickAddDevice}
                    title="Add device"
                    size="small"
                    icon={<AddIcon stroke={neutral['neutral-1']} />}
                    rootStyle={{ width: 123 }}
                  />
                </ButtonOverlayIcon>
              </Spacing>
            )}
          </Row>
        )}
      </Header>
      {isFilter && <DeviceFilterLabel />}
      <Spacing
        style={{
          height: '100%',
        }}
        spacing="sm"
        type="margin"
        placement="top"
      >
        {renderBody()}
      </Spacing>
      <ConfirmModal
        modalKey={MODAL_KEY.DEVICE_DELETE}
        submitButton={{
          buttonProps: {
            onClick: onSubmitDeleteDevice,
          },
        }}
      />
    </Container>
  );
};
